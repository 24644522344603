<template>
	<div id='CompanyApprove' v-loading='loading' v-if='IsPageDome'>
		<div class="mask" v-if="maskhidden">
			<!--公司-->
			<div class="addone" v-if="addone">
				<div class="head">
					<span class="title">{{R.CompanyApprove_Setting_1}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="contents">
					<el-tree class="commontree" :load="loadNode" lazy ref="tree" :default-checked-keys='defaultExpandKeys' highlight-current :props='defaultProps' @node-click="clicktree">
						<span class="custom-tree-node" slot-scope="{ node, data }">
		        			<span  class="mytreelistitem" :title=" node.label">{{ node.label }}
		        			</span>
						</span>
					</el-tree>
				</div>
				<div class="foots">
					<el-button class="orangetext add orangebd" @click="updateOrg">{{R.Common_Button_Save}}</el-button>
				</div>
			</div>
			<!--添加审批流-->
			<div class="addone ApprovalForm" v-if="ApprovalForm">
				<div class="head">
					<span class="title">{{R.CompanyApprove_Setting_3}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content">
					<div>
						<span>{{R.CompanyApprove_Setting_4}}：</span>
						<span class="rightName" :title="kAOName">{{kAOName}}</span>
					</div>
					<div>
						<span class="leftName">{{R.CompanyApprove_Setting_5}}：</span>
						<span class="rightName">
							<el-select v-model="Productvalue" :placeholder="R.CompanyApprove_Button_Add">
						    <el-option
						      v-for="item in Productoptions"
						      :key="item.ID"
						      :label="item.Name"
						      :value="item.ID">
						    </el-option>
						  </el-select>
						</span>
						<span style="color: red">*</span>
					</div>
					<div>
						<span class="leftName">{{R.CompanyApprove_Button_Edit}}：</span>
						<span class="rightName">
							<el-select v-model="Billvalue" :placeholder="R.CompanyApprove_Button_Add">
						    <el-option
						      v-for="item in Billoptions"
						      :key="item.BillType"
						      :label="item.BillName"
						      :value="item.BillType">
						    </el-option>
						  </el-select>
						</span>
						<span style="color: red">*</span>
					</div>
					<div>
						<span class="leftName" style="line-height: 15px;">{{R.CompanyApprove_Button_Enable}}：</span>
						<span class="rightName">
							<el-input @blur="checkVersionNumber()" :placeholder='R.CompanyApprove_Button_Delete' v-model="ProcessName" ></el-input>
							<span style="color: red">*</span>
						<span style="color: red;" v-if="flag">{{R.CompanyApprove_Button_Delete}}</span>
						</span>
					</div>
					<div>
						<span style="line-height: 15px;" class="leftName">{{R.CompanyApprove_Button_PrintLabel}}：</span>
						<span class="rightName">
							<el-input @blur="checkVersionNumber('aa')" :placeholder="R.CompanyApprove_Button_ExportToExcel" v-model="VersionNumber" disabled></el-input>
							<span style="color: red">*</span>
						<!--<span style="color: red;" v-if="flag">请输入正确的版本号</span>-->
						</span>
					</div>
					<div>
						<span class="textarealeft leftName">{{R.CompanyApprove_Button_AdvanceSearch}}：</span>
						<span class="rightName">
							<el-input type="textarea" maxlength='200' :placeholder="R.CompanyApprove_SystemIndex_2" class="textarea" resize='none'  v-model="desc"></el-input>
						</span>
					</div>
				</div>
				<div class="foots">
					<el-button style="padding-right: 20px;margin-left: 10px;" @click="closemask">{{R.Common_Button_Cancel}}</el-button>
					<el-button style="padding-right: 20px;margin-left: 10px;" class="orangetext add orangebd" @click="saveDraft">{{R.Common_Button_Save}}</el-button>
				</div>
			</div>
			<!--更多流程-->
			<div class="addone DetailList" v-if="DetailList">
				<div class="head">
					<span class="title">{{R.CompanyApprove_Button_DeleteDraft}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="listTable" ref="table">
					<Table sortable='custom' stripe :columns="MasktableColumns" height='330' :data="MaskTableData"></Table>
				</div>
				<div class="foots">
					<el-button @click="closemask">{{R.CompanyApprove_Button_Commit}}</el-button>
				</div>
			</div>
		</div>

		<!--主体-->
		<div class="heads">
			<div>
				<span class="two_title">
					<span>
					<el-popover  placement="bottom" width="150" v-model="urlInto" trigger="click">
						<div class="Printed" @click="changeUrl('b')"  >
							{{R.CompanyApprove_Button_Print}}
						</div>
						<div class="Printed" @click="changeUrl('a')"  >
							{{R.CompanyApprove_Button_Query}}
						</div>
						<img slot="reference" @blur="urlInto==false" style="position: relative;top:2px;height: 18px;cursor: pointer;margin-right: 5px;" src="/static/images/enterprise.png"/>
					</el-popover>
					{{kAOName}}
					<span class="el-icon-refresh"style="font-size: 18px;cursor: pointer;" @click="showmask"></span> </span>
				</span>
			</div>
			<span class="set">
				
			</span>
		</div>
		<div class="rightConetnt">
			<div v-for="(item,index) in listMenu" :key="index">
				<div class="productTitle">
					<img :src="item.Icon" alt="" srcset="">
					<span>{{item.ProductName}}</span>
					<span v-if="getpermission('OperateApproval')" @click="addList(item.ProductId)"><icon class="el-icon-circle-plus-outline" style='font-size: 16px;margin-right: 5px;cursor: pointer;'></icon>{{R.CompanyApprove_Button_Reset}}</span>
				</div>
				<div class="productContent">
					<ul>
						<li>{{R.CompanyApprove_Button_Save}}</li>
						<li>{{R.CompanyApprove_Button_Operation}}</li>
						<li>{{R.CompanyApprove_Button_Search}}</li>
						<li>{{R.CompanyApprove_Button_SelectFile}}</li>
						<li>{{R.CompanyApprove_Button_UploadFile}}</li>
						<li>{{R.CompanyApprove_Button_BackToList}}</li>
						<li>{{R.CompanyApprove_Msg_NoneBillSelected}}</li>
						<li>{{R.Common_Button_Operation}}</li>
					</ul>
					<ul v-for="(Billitem,index) in item.Details" :key="index">
						<li><span style="height: 12px;width: 12px;border-radius: 2px;display: inline-block;margin-right: 5px;" :class="Billitem.IsEnabled==true?'green':'grey'"></span>{{Billitem.IsEnableApprovalFlowTxt}}</li>
						<li>{{Billitem.BillName}}</li>
						<li :title="Billitem.FlowName" @click="intoUrl(Billitem)"><span v-if="Billitem.FlowName==''">-</span><span class="needbottom" v-if="Billitem.FlowName!=''">{{Billitem.FlowName==''?'-':Billitem.FlowName}}</span></li>
						<li>{{Billitem.FlowTypeTxt}}</li>
						<li>{{Billitem.FlowVersion==''?'-':Billitem.FlowVersion}}</li>
						<li :title="Billitem.Remark">{{Billitem.Remark==''?'-':Billitem.Remark}}</li>
						<li>
							<span class="needbottom" @click="intoUrl(item)" v-for="(item,myindex) in Billitem.OtherVersions.filter(item=>{return item.IsEnabled==false})" :key="index" v-if="myindex<=3">
                            <span >{{item.FlowVersion}} </span>
							<span>(</span>
							<span style="color:red;">{{item.FlowStatus==2?item.IsEnabledTxt:item.FlowStatusTxt}}</span><span>&nbsp;){{Billitem.OtherVersions.length>1?(Billitem.OtherVersions.length>myindex+1?'、':''):''}}</span>
							</span>
						</li>
						<li>
							<el-button @click="showDetail(Billitem.OtherVersions)">{{R.CompanyApprove_Msg_OnlyDraftBillCanBeDelete}}</el-button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var R = {}
	export default {
		data() {
			return {
				IsPageDome: true,
				R: R,
				loading: false,
				urlInto: false,
				flag: false,
				maskhidden: false,
				DetailList: false,
				ApprovalForm: false,
				addone: false,
				loadingL: false,
				kAOName: '',
				kAOId: '',
				Productvalue: 1,
				VersionNumber: '',
				ProcessName: '',
				desc: '',
				Productoptions: [],
				Billvalue: 1,
				node: null,
				Billoptions: [],
				listMenu: [],
				MasktableColumns: [{
						title: R.CompanyApprove_Msg_OnlyDraftBillCanBeOperate,
						key: 'FlowName',
						width: 240,
						tooltip: true,
						align: 'center',
						render: (h, params) => {
							return h('div', {
								props: {},
								style: {
									fontSize: '12px',
									color: '#07BAD4',
									textDecoration: 'underline',
									cursor: 'pointer'
								},
								on: {
									click: () => {
										if(params.row.FlowId) {
											if(params.row.FlowId) {
												this.$router.push({
													name: 'ProcessEngine',
													params: {
														type: "edit",
														ID: params.row.FlowId,
														status: 'CompanyApprove'
													}
												})
											}
										}
									}
								}
							}, params.row.FlowName);
						}
					},
					{
						title: R.CompanyApprove_Msg_Operation_Success,
						key: 'FlowStatusTxt',
						width: 100,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.CompanyApprove_Msg_Operation_Failed,
						key: 'IsEnabledTxt',
						width: 120,
						tooltip: true,
						align: 'center',
						render: (h, params) => {
							return h('div', {
								props: {},
								style: {
									textAlign: 'center'
								},
							}, params.row.FlowStatus == 2 ? params.row.IsEnabledTxt : '-');
						},
					},
					{
						title: R.CompanyApprove_Msg_Upload_Bill_Attactment_Note,
						key: 'FlowVersion',
						width: 120,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.CompanyApprove_Msg_Upload_Bill_Attactment_Exceed,
						key: 'Creator',
						width: 160,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.CompanyApprove_SystemIndex_1,
						key: 'CreateTime',
						width: 180,
						tooltip: true,
						align: 'center'
					}
				],
				MasktableHeight: '330px',
				MaskTableData: []

			}
		},
		watch: {
			Productvalue(newValue, old) {
				this.getProduct(newValue);
			}
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		methods: {
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {
						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}

			},
			getProduct(id) {
				this.$http.post('/BillType/GetByProduct', {
					Data: {
						"ProductId": id /*产品id*/
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.Billoptions = res.data.Data
						this.Billvalue = this.Billoptions.length > 0 ? this.Billoptions[0].BillType : null
					} else {
						
					}

				})
			},
			saveDraft() {
				this.checkVersionNumber();
				if(this.flag) {
					//					this.$message.warning('请完整填写内容')
					return;
				}
				this.$http.post('/BillApproval/ApprovalFlow/Add', {
					Data: {
						"CropId": this.kAOId,
						"ProductId": this.Productvalue,
						/*产品id*/
						"FlowType": 2,
						/*流程类型1.标准 2.自定义*/
						"BillType": this.Billvalue,
						/*单据类型*/
						"FlowName": this.ProcessName,
						/*流程名称*/
						"Remark": this.desc /*备注说明*/
					}
				}).then(res => {
					if(res.data.Code == 0) {
						if(res.data.Data.ID) {
							this.$router.push({
								name: 'ProcessEngine',
								params: {
									type: "new",
									ID: res.data.Data.ID,
									status: 'CompanyApprove'
								}
							})
						}
					} else {
						
					}
				})
			},
			checkVersionNumber(aa) {
				if(this.ProcessName == '' || this.ProcessName.length > 20) {
					this.flag = true
				} else {
					this.flag = false
				}

			},
			intoUrl(res) {
				if(!res.FlowId) {
					return
				}
				this.$router.push({
					name: 'ProcessEngine',
					params: {
						type: "edit",
						ID: res.FlowId,
						status: 'CompanyApprove'
					}
				})
			},
			changeUrl(command) {
				if(command == 'a') {
					this.$router.push('/system/BillApproval/CompanyApproveDetail')
				} else {
					return
				}
			},
			addList(index) {
				this.maskhidden = true;
				this.ApprovalForm = true;
				this.Productvalue = index
			},
			showDetail(OtherVersions) {
				this.MaskTableData = OtherVersions;
				this.maskhidden = true;
				this.DetailList = true;
			},
			clicktree(node, data) {
				this.node = node
			},
			closemask() {
				this.maskhidden = false;
				this.addone = false;
				this.ApprovalForm = false;
				this.DetailList = false;
				this.flag = false
			},
			showmask() {
				this.maskhidden = true;
				this.addone = true
			},
			//左侧树懒加载
			loadNode(node, resolve) {
				if(node.level === 0) { //第一级
					this.getOrgByParentID(null, resolve);
				} else {
					this.getOrgByParentID(node.data.id, resolve);
				}
			},
			getOrgByParentID(parentID, resolve) {
				this.loadingL = true
				this.$http.post('system:/Organization/GetTree', {
					Data: {
						Delay: true,
						ParentId: parentID,
						OnlyLoadPrivilegeOrg: true,
						LoadOrganizationType: 2,
						'onlyloadEnableOrg': true,
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) { //成功
						//
						let items = rsp.data.Data;
						resolve(items);
					} else {
						this.$message.error(rsp.data.Message)
					}
					this.loadingL = false
				})
			},
			updateOrg() {
				this.kAOName = this.node.label
				this.kAOId = this.node.id;
				this.maskhidden = false;
				this.addone = false
				this.pageChange(this.kAOId)
			},
			pageChange(id) {
				this.loading = true
				this.$http.post('/BillApproval/CorpApprovalFlow/ByCorpID', {
					Data: {
						"CorpId": id /*公司id*/
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.listMenu = res.data.Data ? res.data.Data : [];
						this.loading = false
					} else {
						this.loading = false
						
					}
				}).catch((error) => {
					this.loading = false
				})
			}
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'system_BillApproval_CompanyApprove')
		},
		mounted() {
			this.$PermissionUtil.getPermission('system/BillApproval/CompanyApprove').then((res)=>{
				this.permissionCode = res;
			});
			this.kAOName = this.$Store.get('CorpName');
			this.kAOId = this.$Store.get('CorpID');
			this.$http.post('/Product/All', {
				Data: {
					"Type": 1 /*获取类型1.表单下拉列表2.查询条件*/
				}
			}).then(res => {
				if(res.data.Code == '0') {
					this.Productoptions = res.data.Data;
					this.getProduct(this.Productoptions[0].ID)
				} else {
					
				}
			})
			this.pageChange(this.kAOId)

		}
	}
</script>

<style lang='scss'>
	#CompanyApprove {
		.red {
			background-color: red;
		}
		.green {
			background-color: green;
		}
		.grey {
			background-color: grey;
		}
		li {
			.needbottom {
				text-decoration: underline;
				color: #07BAD4;
				cursor: pointer;
				margin-right: 2px;
			}
		}
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.addone {
				width: 500px;
				height: 500px;
				position: fixed;
				margin: auto;
				background-color: white;
				.listTable {
					margin-top: 10px;
				}
				.foots {
					position: absolute;
					bottom: 10px;
					left: 0;
					right: 0;
					height: 30px;
					line-height: 30px;
					margin-right: 10px;
					.el-button {
						float: right;
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					top: 35px;
					left: 0px;
					right: 0px;
					bottom: 40px;
					position: absolute;
					.commontree {
						top: 0;
					}
					>div {
						height: 30px;
						line-height: 30px;
						padding-left: 20px;
						margin-top: 10px;
						.leftName {
							display: inline-block;
							width: 71px;
							text-align: right;
						}
						.rightName {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							.el-input {
								width: 210px;
							}
							.textarea {
								margin-top: 10px;
								width: 210px;
								.el-textarea__inner {
									height: 60px;
								}
							}
						}
						.textarealeft {
							position: relative;
							top: -30px;
						}
					}
					.el-select {
						height: 30px;
						width: 210px;
					}
					.el-input__inner {
						height: 30px;
						line-height: 30px;
					}
				}
			}
			.ApprovalForm {
				width: 420px;
				height: 380px;
			}
			.DetailList {
				width: 800px;
				height: 420px;
			}
		}
		.heads {
			margin-bottom: 10px;
			padding-bottom: 15px;
			border-bottom: 1px solid #EEEEEE;
			/*height: 6%;
			padding-top:14px;
			padding-left: 20px;*/
			display: flex;
			justify-content: space-between;
			align-items: center;
			>div {
				display: flex;
				align-items: center;
			}
			img {
				height: 42px;
			}
			.dots {
				height: 20px;
				width: 5px;
				background-color: #009900;
				margin-right: 10px;
			}
			.title {
				font-size: 16px;
				font-family: FZLTHK--GBK1-0;
				font-weight: bold;
				color: rgba(98, 103, 104, 1);
			}
			.two_title {
				margin-left: 10px;
				font-size: 14px;
				font-family: FZLTHK--GBK1-0;
				font-weight: 400;
				color: rgba(171, 177, 179, 1);
				display: flex;
				align-items: center;
			}
			.set {
				float: right;
				margin-right: 6px;
			}
		}
		.rightConetnt {
			position: absolute;
			top: 50px;
			left: 0;
			right: 0;
			bottom: 75px;
			overflow: auto;
			>div {
				>.productTitle {
					display: flex;
					align-items: center;
					line-height: 30px;
					>span {
						font-size: 14px;
						margin-left: 10px;
						font-weight: bold;
						color: #333333;
					}
				}
				>.productContent {
					padding-left: 38px;
					>ul:nth-child(1) {
						border-top: 1px solid #d7d7d7;
						>li {
							font-size: 14px;
							font-weight: bold;
						}
					}
					>ul {
						display: flex;
						align-items: center;
						border-bottom: 1px solid #d7d7d7;
						>li {
							text-align: center;
							line-height: 30px;
							font-size: 14px;
							color: #333333;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						>li:nth-child(1) {
							width: 150px;
							text-align: left;
							padding-left: 20px;
							box-sizing: border-box;
							text-align: left;
						}
						>li:nth-child(2) {
							width: 150px;
							text-align: left;
						}
						>li:nth-child(3) {
							width: 180px;
							text-align: left;
						}
						>li:nth-child(4) {
							width: 100px;
							text-align: left;
						}
						>li:nth-child(5) {
							width: 100px;
							text-align: left;
						}
						>li:nth-child(6) {
							width: 200px;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}
						>li:nth-child(7) {
							width: 560px;
							text-align: left;
						}
						>li:nth-child(8) {
							width: 120px;
							.el-button {
								height: 22px!important;
								padding: 0!important;
							}
						}
					}
				}
			}
		}
	}
</style>