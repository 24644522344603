<template>
	<div id="role" v-loading="loading" v-if="IsPageDome">
		<div class="mask" v-if="mask">


				<div class="removeMask" v-if="editMember.removeMessageBox">
					<div class="remove">
						<div class="head">
							<span class="title">{{R.Role_Edit_18}}</span>
							<span class="icon" @click="editMember.removeMessageBox=false"><i class="el-icon-circle-close"></i></span>
						</div>
						<div class="contentdel" v-if="editMember.selection.length==1">
							<i class="el-icon-info"></i>{{R.Role_List_13}} {{editMember.selections[0].Name}} {{page.list.module3.para5}}
						</div>
						<div class="contentdel" v-if="editMember.selection.length>1">
							<i class="el-icon-info"></i>{{R.Role_Edit_19}} {{editMember.selection.length}} {{R.Role_Edit_20}}
						</div>
						<div class="footer">
							<el-button class="orangebd" @click="confirmDeletion">{{R.Role_Edit_21}}</el-button>
							<el-button @click="editMember.removeMessageBox=false">{{R.Role_Edit_8}}</el-button>
						</div>
					</div>
				</div>
				

				<UserSelect v-if="personSelector" @close="personSelector = false" :title="R.Role_Edit_22" :roleId="selectedRoleId" @ok="editMemberRequest" />


			<div :class="addoneClassName" v-if="addone" v-loading="CartridgeLoading">
				<div class="head">
					<span class="title" v-if="status=='add'">{{page.list.module3.para1}}</span>
					<span class="title" v-if="status=='edit'">{{page.list.module3.para2}}</span>
					<span class="title" v-if="status=='del'">{{page.list.module3.para3}}</span>
					<span class="title" v-if="status=='Mem'">{{page.list.module3.para17}}</span>
					<span class="title" v-if="status=='lock'">{{page.list.module3.para4}}</span>
					<span class="title" v-if="status!='lock'&&status!='del'&&status!='edit'&&status!='add'&&status!='Mem'">{{page.list.module3.para16}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>


				<div class="Mem" v-if="status=='Mem'" v-loading="editMember.loading">
						<div class="header">
							<div class="left">
								<el-button @click="addsMembers" class="orangebd"><i class="el-icon-plus"></i>{{R.Role_Edit_23}}</el-button>
								<el-button @click="removeMembers"><i class="el-icon-delete"></i>{{R.Role_Edit_24}}</el-button>
							</div>
							<div class="right">
								<el-input v-model="editMember.qst"  :placeholder="R.Role_Edit_25" @keyup.enter.native="searchMember">
									<i slot="suffix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<el-button @click="searchMember">{{R.Common_Button_Search}}</el-button>
							</div>
						</div>
						<div class="table">
							<el-table
								:row-style="{height:'40px'}"
								:cell-style="{padding:'0px'}"
								@selection-change="getRemoveMembersList"
								:data="editMember.list"
								height="350px"
								style="width: 100%">
									<el-table-column
										type="selection"
										width="55"
										align="center">
									</el-table-column>
									<el-table-column
										v-for="(item,index) in editMember.columns"
										:key="index"
										:prop="item.prop"
										:label="item.label"
										:width="item.width"
										:align="item.align"
										>
									</el-table-column>
							</el-table>
						</div>
						<div class="page">
							<el-pagination
							small
							@size-change="editMemberSize"
							@current-change="editMemberCurrent"
							:current-page="editMember.currentPage"
							:page-sizes="[10, 20, 30, 40]"
							:page-size="editMember.pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							:total="editMember.total">
							</el-pagination>
						</div>
				</div>
				




				<div class="contentdel" v-if="status=='del'">
					<i class="el-icon-info"></i>{{page.list.module3.para5}}
				</div>
				<div class="contentdel" v-if="status=='lock'">
					<i class="el-icon-info"></i>{{page.list.module3.para6}}
				</div>
				<div class="foot" v-if="status=='del'">
					<el-button @click="confirmDel" :disabled="isDisabled" class="orangetext add orangebd">{{page.list.module3.para7}}</el-button>
					<el-button @click='closemask'>{{page.list.module3.para8}}</el-button>
				</div>
				<div class="foot" v-if="status=='lock'">
					<el-button @click="confirmLock" :disabled="isDisabled" class="orangetext add orangebd">{{page.list.module3.para9}}</el-button>
					<el-button @click='closemask'>{{page.list.module3.para8}}</el-button>
				</div>
				<div class="content" v-if="status!='del'&&status!='lock'&&status!='Mem'">
					<ul>
						<li>
							<span class="fl"><span v-if="$i18n.locale=='en'">&ensp;</span>{{page.list.module3.para10}}</span>
							<el-input maxlength=50 class="errorinput" @blur="changeerror" v-if="status!='viewPermission'" :placeholder="page.list.module3.para11" v-model="page.input.name"></el-input>
							<el-input maxlength=50 v-else :placeholder="page.list.module3.para11" v-model="page.input.name"></el-input>
							<span class="red">*</span>
							<span class="red" v-if="errortext">{{errortexts}}</span>
						</li>
						<li>
							<span class="fl beizhu"><span v-if="$i18n.locale=='zh'">&ensp;&ensp;&ensp;&ensp;</span>{{page.list.module3.para12}}</span>
							<el-input v-if="status!='viewPermission'" type="textarea" maxlength="200" :rows="2" :placeholder="page.list.module3.para13" resize='none' v-model="page.input.remark"></el-input>
							<el-input v-else type="textarea" :rows="2" maxlength="200" :placeholder="page.list.module3.para13" resize='none' v-model="page.input.remark"></el-input>
						</li>
						<li v-if="status!='add'">
							<span class="fl">{{page.list.module3.para14}}</span>
							<div class="rolepay">
								<div :class="$i18n.locale=='zh'?'checkbox-table':'checkbox-table englishcolor'" v-for="(item,indexkey) in page.functions.data" :key="indexkey">
									<template>
										<el-checkbox v-if="status!='viewPermission'"  class="check1" v-model="item.selected" @change="handleCheckedCitiesChange(1,indexkey)">{{item.label}}</el-checkbox>
										<el-checkbox v-else class="check1" v-model="item.selected" @change="handleCheckedCitiesChange(1,indexkey)">{{item.label}}</el-checkbox>
										<div v-for="(list,index2) in item.children" class="line-check" :key="index2">
											<!-- 名称 -->
											<div>
												<el-checkbox v-if="status!='viewPermission'" class="check2" @change="handleCheckedCitiesChange(2,indexkey,index2)"  v-model="list.selected">{{list.label}}</el-checkbox>
												<el-checkbox v-else class="check2" @change="handleCheckedCitiesChange(2,indexkey,index2,index2)"  v-model="list.selected">{{list.label}}</el-checkbox>
											</div>
											<!-- funciontion -->
											<div class="check3 functionsColor">
												<el-checkbox v-for="(functions,index1) in list.functions" :key="index1" v-model="functions.selected"  @change="handleCheckedCitiesChange(3,indexkey,index2,index1)">{{functions.name}}</el-checkbox>
											</div>
											<!-- children -->
											<div class="check1">
											
												<div v-for="(children3,indexchild3) in list.children" :key="indexchild3">
													<div>
														<el-checkbox v-if="status!='viewPermission'"  class="check3" v-model="children3.selected" @change="handleCheckedCitiesChange(4,indexkey,index2,indexchild3)">{{children3.label}}</el-checkbox>
													
														<el-checkbox v-else class="check3" v-model="children3.selected" @change="handleCheckedCitiesChange(4,indexkey,index2,indexchild3)">{{children3.label}}</el-checkbox>
													</div>
													<div class="check4 functionsColor">
													
														<el-checkbox v-for="(functions4,indexfunc4) in children3.functions" :key="indexfunc4" v-model="functions4.selected"  @change="handleCheckedCitiesChange(5,indexkey,index2,indexchild3,indexfunc4)">{{functions4.name}}</el-checkbox>
													</div>
												</div>
											</div>
										</div>
								
								</template>
								</div>
					
						   </div>
					</li>
				</ul>
			</div>
			<div class="foot" v-if="status!='del'&&status!='lock'">
				<el-button @click="save" :disabled="isDisabled" v-if="status!='viewPermission'&&status!='Mem'" class="orangetext add orangebd">{{page.list.module1.Public_6}}</el-button>
				<el-button @click='closemask' v-if="status!=='Mem'">{{page.list.module3.para8}}</el-button>
			</div>
		</div>
	</div>
	<div class="right">
		<div class="clickbtn">
			<el-button v-if="getpermission('Add')" class="orangetext add orangebd" @click="add"><i class="el-icon-plus"></i> {{page.list.module1.Public_1}}</el-button>
			<el-popover placement="bottom" width="150" v-model="value" trigger="click">
				<div class="Printed" v-if="getpermission('Edit')" @click="edit">
					{{page.list.module1.Public_2}}
				</div>
				<div class="Printed" v-if="getpermission('EditUser')" @click="editMembers">
					{{page.list.module1.Public_7}}
				</div>
				<div class="Printed" v-if="getpermission('Enabled')" @click="lock">
					{{page.list.module1.Public_3}}
				</div>
				<div class="Printed" v-if="getpermission('Delete')" @click="del">
					{{page.list.module1.Public_4}}
				</div>
				<el-button slot="reference"> <span>{{page.list.module3.para15}}</span> <i style="font-size:12px;color:" class="el-icon-caret-bottom"></i></el-button>
			</el-popover>
			<el-button v-if="getpermission('ViewRolePermission')" @click="viewPermission()">{{page.list.module3.para16}}</el-button>
			<el-button v-if="getpermission('Export')" @click="exportToExcel()">{{page.list.module1.Public_5}}</el-button>
			<el-button v-if="getpermission('Query')" style="float: right; margin-left: 10px;" @click="searchmylist">{{R.Common_Button_Search}}</el-button>
			<el-input v-if="getpermission('Query')" :placeholder="page.list.module2.para1" v-model="page.list.QST" @keyup.enter.native="searchmylist">
				<i slot="suffix" class="el-input__icon el-icon-search"></i>
			</el-input>
		</div>

		<div class="mytable" ref="table">
			<Table stripe @on-select="selectionChange" @on-select-cancel="selectionCancel" @on-selection-change="selectShow" :columns="page.list.columnNames" :data="page.list.data" :height="tableHeight"></Table>
		</div>
		<div class="rightfoot">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.list.currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="page.list.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.list.total">
			</el-pagination>
		</div>
	</div>

	</div>
	</div>
</template>

<script>
	import { rejects } from 'assert';

	var R = {};
	export default {
		data() {
			return {
				editMember:{
					columns:[
						{label: R.Role_Edit_26,align:'center', width: '160', prop: 'Code'},
						{label: R.Role_Edit_27,align:'center', width: '160', prop: 'UserName'},
						{label: R.Role_Edit_28,align:'center', width: '160', prop: 'Name'},
						{label: R.Role_Edit_29,align:'center', width: '160', prop: 'OrgName'},
						{label: R.Role_Edit_30,align:'center', width: '160', prop: 'Phone'},
						{label: R.Role_Edit_31,align:'center', width: '160', prop: 'EffectiveTime'},
                	],
					list:[],
					total:0,
					// 总页数
					currentPage:1,
					// 当前页码
					pageSize:10,
					// 每页条数
					selection:[],
					// 列表选中目标
					loading:false,
					removeMessageBox:false,
					qst:null,
					selections:[],
				},

				expandkey: [],
				elCurrentPage:1,//当前页码
				elPageSize:10,//每页的数据条数
				defaultExpandKey: [],
				defaultProp: {
					children: 'children',
					label: 'label',
					isLeaf: 'is_leaf'
				},
				QSE:'',


				IsPageDome: true,
				CartridgeLoading: false,
				R: R,
				isDisabled: false,
				value: false,
				errortexts: R.Role_Error_2,
				errortext: false,
				permissionCode: [],
				tableHeight: '',
				page: {
					list: {
						//
						module1: {
							Public_1: R.Common_Button_Add,
							Public_2: R.Common_Button_Edit,
							Public_3: R.Common_Button_Enable,
							Public_4: R.Common_Button_Delete,
							Public_5: R.Common_Button_ExportToExcel,
							Public_6: R.Common_Button_Save,
							Public_7: R.Role_Edit_17,
							search: R.search,
						}, 
						//
						module2: {
							para1: R.Role_List_Select_1
						},
						//
						module3: {
							para1: R.Role_Edit_1,
							para2: R.Role_Edit_2,
							para3: R.Role_Edit_3,
							para4: R.Role_Edit_4,
							para5: R.Role_Edit_5,
							para6: R.Role_Edit_6,
							para7: R.Role_Edit_7,
							para8: R.Role_Edit_8,
							para9: R.Role_Edit_9,
							para10: R.Role_Edit_10,
							para11: R.Role_Edit_11,
							para12: R.Role_Edit_12,
							para13: R.Role_Edit_13,
							para14: R.Role_Edit_14,
							para15: R.Common_Button_Operation,
							para16: R.Role_Edit_16,
							para17: R.Role_Edit_17,
						},
						//
						columnNames: [{
								type: 'selection',
								width: 50,
								align: 'center'
							},
							{
								type: 'index',
								width: 55,
								align: 'center',
								tooltip: true,
							},
							{
								align: 'center',
								title: R.Role_ColumnName_1,
								key: 'Name',
								width: 160,
								tooltip: true,
							},
							{
								align: 'center',
								title: R.Role_ColumnName_2,
								key: 'RoleUsersDesc',
								width: 160,
								tooltip: true,
							},
							{
								align: 'center',
								title: R.Role_ColumnName_3,
								key: 'StatusText',
								width: 100,
								tooltip: true,
							},
							{
								align: 'center',
								title: R.Role_ColumnName_4,
								key: 'Remark',
								width: 160,
								tooltip: true,
							},
							{
								title: ' '
							}
						],
						//
						//快速搜索文本
						QST: "",
						//排序
						sortDirection: 0,
						//列表及分页
						data: [],
						//总页数
						total: 0,
						//当前页码
						currentPage: 1,
						//每页记录数
						pageSize: 10,
						//列表选中目标
						selectedId: "",
						selection: [], //所有选中项集合
						//列表选中目标状态
						enabled: ""
					},
					input: {
						//名称
						name: "",
						//描述
						remark: "",
						//被选中的ID集合
						selectedIDs: []
					},
					functions: {
						data: [],
						ids: [], //选择的id集合,
						level_1_Ids: [], //第1层节点选中值
						level_2_Ids: [], //第2层节点选中值
						level_3_Ids: [], //第3层节点选中值
					}
				},

				//------
				highcurrent: true,
				checkbox: true,
				urla: '',
				api: [],
				status: '',
				IsEnabled: true,
				loading: false,
				mask: false,
				addone: false,
				defaultProps: {
					children: 'Children',
					label: 'Name'
				},
				personSelector:false,
			};
		},
		beforeUpdate() {
			const that = this
			window.onresize = () => {
				return(() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					that.screenWidth = window.screenWidth
					that.screenHeight = window.screenHeight
				})()
			}
			this.tableHeight = this.$refs.table.offsetHeight;
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_Role')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted() {

			this.page.list.module1 = {
				Public_1: R.Common_Button_Add,
				Public_2: R.Common_Button_Edit,
				Public_3: R.Common_Button_Enable,
				Public_4: R.Common_Button_Delete,
				Public_5: R.Common_Button_ExportToExcel,
				Public_6: R.Common_Button_Save,
				Public_7: R.Role_Edit_17,
				search: R.search,
			}
			this.page.list.module2 = {
				para1: R.Role_List_Select_1
			}
			this.page.list.module3 = {
				para1: R.Role_Edit_1,
				para2: R.Role_Edit_2,
				para3: R.Role_Edit_3,
				para4: R.Role_Edit_4,
				para5: R.Role_Edit_5,
				para6: R.Role_Edit_6,
				para7: R.Role_Edit_7,
				para8: R.Role_Edit_8,
				para9: R.Role_Edit_9,
				para10: R.Role_Edit_10,
				para11: R.Role_Edit_11,
				para12: R.Role_Edit_12,
				para13: R.Role_Edit_13,
				para14: R.Role_Edit_14,
				para15: R.Common_Button_Operation,
				para16: R.Role_Edit_16,
				para17: R.Role_Edit_17,
			}
			this.page.list.columnNames = [{
					type: 'selection',
					width: 30,
					align: 'center'
				},
				{
					type: 'index',
					width: 50,
					align: 'center',
					tooltip: true,
				},
				{
					align: 'center',
					title: R.Role_ColumnName_1,
					key: 'Name',
					width: 140,
					tooltip: true,
				},
				{
					align: 'left',
					title: R.Role_ColumnName_2,
					key: 'RoleUsersDesc',
					minWidth: 180,
					maxWidth: 400,
					tooltip: true,
				},
				{
					align: 'center',
					title: R.Role_ColumnName_3,
					key: 'StatusText',
					width: 100,
					tooltip: true,
				},
				{
					align: 'left',
					title: R.Role_ColumnName_4,
					key: 'Remark',
					minWidth: 180,
				},
				{
					title: ' '
				}
			]
			//获取表头元数据

			document.querySelectorAll('.ivu-checkbox-wrapper')[0].style.display = 'none';
			this.$PermissionUtil.getPermission('System/Role').then((res)=>{
				this.permissionCode = res;
			});
			this.page.list.pageSize = Number(this.GetPageSize('System/Role'))
			this.getList();
			this.getAllMenuPermission();

		},
		computed:{
			addoneClassName(){
				const className = this.status=='del'||this.status=='lock'?'addone del':'addone';
				let sizeName;
				switch (this.status) {
					case "del":
						sizeName = "popup-size-del";
						break;
					case "lock":
						sizeName =  "popup-size-lock";
						break;
					case "add":
						sizeName =  "popup-size-add";
						break;
					case "edit":
						sizeName =  "popup-size-edit";
						break;
					case "viewPermission":
						sizeName = "popup-size-viewPermission";
						break;
					default:
						sizeName =  "";
						break;
				}
				return `${className} ${sizeName}`;
			},
			selectedRoleId(){
				return this.page.list.selection?this.page.list.selection[0].ID:null;
			}
		},
		watch: {
			screenWidth(val) {
				this.tableHeight = this.$refs.table.offsetHeight;
			},
			screenHeight(val) {
				this.tableHeight = this.$refs.table.offsetHeight;
			}
		},
		methods: {
			confirmDeletion(){
				this.$http.post('system:/Role/Users/Delete', {
					Data: {
						IDs:this.editMember.selection
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) { 
						this.editMemberRequest(1)
					}
					this.editMember.removeMessageBox=false;
				})
			},
			removeMembers(){
				if(this.editMember.selection.length<=0){
					this.$message.error(R.Role_Edit_32);
					return
				}
				this.editMember.removeMessageBox=true;
			},


			addsMembers(){
				let orgId=null;
				this.personSelector=true;
				this.addMemberData.loading=true;
				this.addsMemberRequest();
			},
			addsMemberRequest(){
				this.$http.post('system:/Role/Users/Query', {
					Data: {
						ID:this.page.list.selectedId,
						OrgID:null,
						QST:this.editMember.qst
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) {
						this.addMemberData.list=rsp.data.Data
					} 
					this.addMemberData.loading=false;
				})
			},
			searchMember(){
				this.editMember.currentPage=1;
				this.editMemberRequest();
			},
			getRemoveMembersList(val){
				let IDs=val.map(function(item,index){
					return item.ID
				})
				this.editMember.selection=IDs;
				this.editMember.selections=val;
			},

			//点击左侧树结构
			clicktree(node, data) {
				this.addMemberData.tree.QSE = data.data.QSE;
				this.addMemberData.tree.id = data.data.id;
				this.$http.post('system:/Role/Users/Query', {
					Data: {
						ID:this.page.list.selectedId,
						OrgID:data.data.id
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) {
						this.addMemberData.list=rsp.data.Data
					} 
				}).catch((error) => {});
				// this.tableList()
			},


			editMemberSize(val){
				this.editMember.pageSize=val;
				this.editMemberRequest();
			},
			editMemberCurrent(val){
				this.editMember.currentPage=val;
				this.editMemberRequest();
			},

			
		
			changeerror() {
				//名称校验
				if(this.page.input.name.trim() == '') {
					this.errortext = true;
					document.querySelectorAll('.el-input__inner')[0].style.border = '1px solid red'
					return;
				} else {
					this.errortext = false;
					document.querySelectorAll('.el-input__inner')[0].style.border = '1px solid #dcdfe6'
				}
			},
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {
						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}
			},
			//权限选择
			handleCheckedCitiesChange(type, a, b, c, d) {
				if(type == 1) { //最高级全选
					if(this.page.functions.data[a].selected) {
						for(var i = 0; i < this.page.functions.data[a].children.length; i++) {
							this.page.functions.data[a].children[i].selected = true;
							for(var j = 0; j < this.page.functions.data[a].children[i].functions.length; j++) {
								this.page.functions.data[a].children[i].functions[j].selected = true;
							}
							for(var j = 0; j < this.page.functions.data[a].children[i].children.length; j++) {
								this.page.functions.data[a].children[i].children[j].selected = true;
								for(var k = 0; k < this.page.functions.data[a].children[i].children[j].functions.length; k++) {
									this.page.functions.data[a].children[i].children[j].functions[k].selected = true;
								}
							}
						}
					} else {
						for(var i = 0; i < this.page.functions.data[a].children.length; i++) {
							this.page.functions.data[a].children[i].selected = false;
							//一级function
							for(var j = 0; j < this.page.functions.data[a].children[i].functions.length; j++) {
								this.page.functions.data[a].children[i].functions[j].selected = false;
							}
							for(var j = 0; j < this.page.functions.data[a].children[i].children.length; j++) {
								this.page.functions.data[a].children[i].children[j].selected = false;
								//二级function
								for(var k = 0; k < this.page.functions.data[a].children[i].children[j].functions.length; k++) {
									this.page.functions.data[a].children[i].children[j].functions[k].selected = false;
								}
							}
						}
					}
				} else if(type == 2) { //第二级全选

					for(var i = 0; i < this.page.functions.data[a].children[b].functions.length; i++) {
						this.page.functions.data[a].children[b].functions[i].selected = true;
					}
					for(var i = 0; i < this.page.functions.data[a].children[b].children.length; i++) {
						this.page.functions.data[a].children[b].children[i].selected = true;
						for(var j = 0; j < this.page.functions.data[a].children[b].children[i].functions.length; j++) {
							this.page.functions.data[a].children[b].children[i].functions[j].selected = true;
						}
					}
					if(this.page.functions.data[a].children[b].selected) {
						this.page.functions.data[a].selected = true;

					} else {
						for(var i = 0; i < this.page.functions.data[a].children[b].functions.length; i++) {
							this.page.functions.data[a].children[b].functions[i].selected = false;
						}
						for(var i = 0; i < this.page.functions.data[a].children[b].children.length; i++) {
							this.page.functions.data[a].children[b].children[i].selected = false;
							for(var j = 0; j < this.page.functions.data[a].children[b].children[i].functions.length; j++) {
								this.page.functions.data[a].children[b].children[i].functions[j].selected = false;
							}
						}
					}
				} else if(type == 3) {
					var childNodeLen = this.page.functions.data[a].children[b].children.length; //单选框长度
					for(var i = 0; i < childNodeLen; i++) {
						if(this.page.functions.data[a].children[b].children[i].selected) {
							this.page.functions.data[a].children[b].selected = true;
							this.page.functions.data[a].selected = true;
							break;
						}
					}

					var funNodeLen = this.page.functions.data[a].children[b].functions.length; //单选框长度
					for(var i = 0; i < funNodeLen; i++) {
						if(this.page.functions.data[a].children[b].functions[i].selected) {
							this.page.functions.data[a].children[b].selected = true;
							this.page.functions.data[a].selected = true;
							break;
						}
					}
				} else if(type == 4) //二级children
				{
					//赋值
					if(this.page.functions.data[a].children[b].children[c].selected) {

						for(var i = 0; i < this.page.functions.data[a].children[b].children[c].functions.length; i++) {
							this.page.functions.data[a].children[b].children[c].functions[i].selected = true;
						}
						this.page.functions.data[a].children[b].selected = true;
						this.page.functions.data[a].selected = true;
					} else {
						for(var i = 0; i < this.page.functions.data[a].children[b].children[c].functions.length; i++) {
							this.page.functions.data[a].children[b].children[c].functions[i].selected = false;
						}
					}

				} else //type=5二级function
				{
					if(this.page.functions.data[a].children[b].children[c].functions[d].selected) {
						this.page.functions.data[a].children[b].children[c].selected = true;
						this.page.functions.data[a].children[b].selected = true;
						this.page.functions.data[a].selected = true;
					}
				}
				this.page.functions.ids = [];
				for(var k = 0; k < this.page.functions.data.length; k++) {
					for(var i = 0; i < this.page.functions.data[k].children.length; i++) {

						if(this.page.functions.data[k].children[i].selected) {
							this.page.functions.ids.push(this.page.functions.data[k].children[i].id);
						}

						for(var j = 0; j < this.page.functions.data[k].children[i].functions.length; j++) {
							if(this.page.functions.data[k].children[i].functions[j].selected) {
								this.page.functions.ids.push(this.page.functions.data[k].children[i].functions[j].id);
							}
						}
						for(var m = 0; m < this.page.functions.data[k].children[i].children.length; m++) {
							for(var n = 0; n < this.page.functions.data[k].children[i].children[m].functions.length; n++) {
								if(this.page.functions.data[k].children[i].children[m].functions[n].selected) {
									this.page.functions.ids.push(this.page.functions.data[k].children[i].children[m].functions[n].id);
								}
							}
						}
					}
				}
				this.$forceUpdate();
			},
			//////Get Start
			getList() {
				this.page.list.selectedId = '';
				this.page.list.enabled = '';
				this.loading = true
				let params = {

					Data: {
						"QST": this.page.list.QST,
						'SortDirection': this.page.list.sortDirection,
						'PageSize': this.page.list.pageSize,
						'PageNO': this.page.list.currentPage,
						'IsSetNumber': true,
					}
				}
				this.SetPageSize(this.page.list.pageSize, 'System/Role')
				this.$http.post('/Role/List', params).then(data => {

					var rp = data.data.Data;
					if(data.data.Code == 0) {
						this.page.list.data = rp.Items;
						this.page.list.total = rp.TotalRecordQty;
						this.page.list.currentPage = rp.PageNO;
						this.page.list.pageSize = rp.PageSize
					} else {
						this.$message.error(data.data.Message)
					}
					this.loading = false
				})
			},
			//获取角色信息
			getRole() {
				var params = {
					token: this.$Store.get('token'),
					data: this.page.list.selectedId
				}
				this.CartridgeLoading = true;
				this.$http.post('/Role/Permission/Get', params).then(data => {
					var rp = data.data.Data;
					if(data.data.Code == '0') {
						this.page.input.name = rp.Name;
						this.page.input.remark = rp.Remark; 
						this.page.functions.ids = rp.PermissionIDs; //选择ID集合

						//根据所选择的ID集合重构树
						for(var k = 0; k < this.page.functions.data.length; k++) {
							var _menuIndex = 0;

							for(var i = 0; i < this.page.functions.data[k].children.length; i++) {
								if(rp.PermissionIDs.includes(this.page.functions.data[k].children[i].id)) {
									this.page.functions.data[k].children[i].selected = true;
									_menuIndex++; //当前菜单下已勾选的数目
								} else {
									this.page.functions.data[k].children[i].selected = false;
								}
								var allFunctionsCount = this.page.functions.data[k].children[i].functions.length; //当前菜单下的所有功能数目

								for(var j = 0; j < this.page.functions.data[k].children[i].functions.length; j++) {
									if(rp.PermissionIDs.indexOf(this.page.functions.data[k].children[i].functions[j].id) > -1) //如果选中集合内包含此ID则勾选
									{
										this.page.functions.data[k].children[i].functions[j].selected = true;
									} else {
										this.page.functions.data[k].children[i].functions[j].selected = false;
									}
								}

								var blowChildNodeNum = this.page.functions.data[k].children[i].children.length;
								for(var m = 0; m < blowChildNodeNum; m++) {
									// console.log(this.page.functions.data[k].children[i].children[m]);
									var _functionsIndex = 0;
									var belowFuncLen = this.page.functions.data[k].children[i].children[m].functions.length;
									for(var n = 0; n < belowFuncLen; n++) {
										if(rp.PermissionIDs.indexOf(this.page.functions.data[k].children[i].children[m].functions[n].id) > -1) {
											_functionsIndex++;
											this.page.functions.data[k].children[i].children[m].functions[n].selected = true;
										} else {
											this.page.functions.data[k].children[i].children[m].functions[n].selected = false;
										}
									}
									if(_functionsIndex > 0) {
										this.page.functions.data[k].children[i].children[m].selected = true;
									} else {
										this.page.functions.data[k].children[i].children[m].selected = false;
									}
								}
							}
							if(_menuIndex > 0) //如果当前菜单下的所有功能数目=当前菜单下已勾选的数目 则该菜单为全选
							{
								this.page.functions.data[k].selected = true;
							} else {
								this.page.functions.data[k].selected = false;
							}
						}
					} else {
						this.$message.error(data.data.Message)
					}
					this.CartridgeLoading = false;
				}).catch(() => {
					this.CartridgeLoading = false;
				})

			},
			//获取所有菜单权限
			getAllMenuPermission() {
				var params = {
					token: this.$Store.get('token'),
					data: this.page.list.selectedId
				}
				this.$http.post('system:/Role/Menu/All', params).then(data => {
					var rp = data.data.Data.Products;
					if(data.data.Code == '0') {
						this.page.functions.data = rp;
					} else {
						this.$message.error(data.data.Message)
					}
				})
			},
			//获取所有选中项
			selectShow(selection, row) {
				this.page.list.selection = selection
			},
			//是否只选择一行
			isSelectedRowOnlyOne() {
				if(this.page.list.selectedId == '') {
					this.$message({
						message: this.R.Common_Msg_NoneBillSelected,
						type: 'error'
					});
					return false;
				}
				if(this.page.list.selection.length == 0) {
					this.$message({
						message: this.R.Common_Msg_NoneBillSelected,
						type: 'error'
					});
					return false;
				} else if(this.page.list.selection.length > 1) {
					this.$message({
						message: this.R.Common_Msg_OnlyOneCanBeSelected,
						type: 'error'
					});
					return false;
				} else {
					return true;
				}
			},
			//新增
			add() {
				this.mask = true;
				this.addone = true;
				this.status = 'add';
				//清空选择
				for(var k = 0; k < this.page.functions.data.length; k++) {
					this.page.functions.data[k].selected = false;
					for(var i = 0; i < this.page.functions.data[k].children.length; i++) {
						this.page.functions.data[k].children[i].selected = false;

						for(var j = 0; j < this.page.functions.data[k].children[i].functions.length; j++) {
							this.page.functions.data[k].children[i].functions[j].selected = false;
						}

						var blowChildNodeNum = this.page.functions.data[k].children[i].children.length;
						for(var m = 0; m < blowChildNodeNum; m++) {
							var belowFuncLen = this.page.functions.data[k].children[i].children[m].functions.length;
							for(var n = 0; n < belowFuncLen; n++) {
								this.page.functions.data[k].children[i].children[m].functions[n].selected = false;
							}
						}
					}
				}
			},
			//编辑
			edit() {
				if(this.isSelectedRowOnlyOne()) {
					this.value = false

					this.getRole();

					this.mask = true;
					this.addone = true;
					this.status = 'edit';
				}
			},
			save() {
				//名称校验
				if(this.page.input.name.trim() == '') {
					this.errortext = true;
					document.querySelectorAll('.el-input__inner')[0].style.border = '1px solid red'
					return;
				} else {
					this.errortext = false;
					document.querySelectorAll('.el-input__inner')[0].style.border = '1px solid #dcdfe6'
				}
				if(this.page.input.name.length > 50) {
					this.$message.error(R.Role_Error_3);
					return;
				}

				//备注校验
				if(this.page.input.remark && this.page.input.remark.length > 200) {
					this.$message.error(this.R.Role_Error_4);
					return;
				}
				this.isDisabled = true;
				this.CartridgeLoading = true;
				//新增
				var flag = false;
				this.page.functions.data.filter((item => {
					item.children.filter((items) => {
						if(items.children.length > 0) {
							var items2 = items.children;
							items2.filter((items3) => {
								items3.functions.filter((items4) => {
									if(items4.Code == "Query") {
										if(items3.selected && !items4.selected) {
											flag = true
											return
										}
									}

								})
							})
						} else {
							items.functions.filter((items1) => {
								if(items1.Code == "Query") {
									if(items.selected && !items1.selected) {
										flag = true
										return
									}
								}
							})
						}
					})
				}))
				if(flag) {
					this.$message.error(R.Role_List_12)
					this.isDisabled = false;
					this.CartridgeLoading = false;
					return
				}
				if(this.status == "add") {
					let params = {

						Data: {
							"Name": this.page.input.name,
							"Remark": this.page.input.remark,
							"PermissionIDS": this.page.functions.ids
						}
					}
					this.$http.post('/Role/Add', params).then(data => {
						if(data.data.Code == '0') {
							this.$message({
								message: data.data.Message,
								type: 'success'
							});
							this.page.list.currentPage = 1
							this.getList(); //重新加载列表
							this.clearInput(); //清空文本框
							this.mask = false;
							this.addone = false;
						}
						this.isDisabled = false;
						this.CartridgeLoading = false;
					}).catch(() => {
						this.isDisabled = false;
						this.CartridgeLoading = false;
					});
				}
				//编辑
				else if(this.status == "edit") {
					let params = {

						Data: {
							"ID": this.page.list.selectedId,
							"Name": this.page.input.name,
							"Remark": this.page.input.remark,
							"PermissionIDS": this.page.functions.ids
						}
					}
					this.$http.post('/Role/Edit', params).then(data => {
						if(data.data.Code == '0') {
							this.$message({
								message: data.data.Message,
								type: 'success'
							});
							this.getList(); //重新加载列表
							this.clearInput(); //清空文本框
							this.mask = false;
							this.addone = false;
							this.isDisabled = false;
						} else {
							this.$message.error(data.data.Message)
							this.isDisabled = false;
						}
						this.CartridgeLoading = false;

					}).catch(() => {
						this.isDisabled = false;
						this.CartridgeLoading = false;
					});
				}
			},
			//是否删除
			del() {
				if(this.isSelectedRowOnlyOne()) {
					this.value = false
					this.mask = true;
					this.addone = true;
					this.status = 'del';
				}
			},
			//删除
			confirmDel() {
				this.isDisabled = true;
				var params = {
					token: this.$Store.get('token'),
					data: this.page.list.selectedId
				}
				this.$http.post('/Role/Delete', params).then(data => {
					if(data.data.Code == '0') {
						this.loadtree = false;
						this.$message({
							message: data.data.Message,
							type: 'success'
						});
						this.page.list.currentPage = 1
						this.getList(); //重新加载列表
						this.mask = false;
						this.addone = false;
						this.isDisabled = false;
					} else {
						this.$message.error(data.data.Message)
						this.isDisabled = false;
					}
				})
			},
			//是否停用/启用
			lock() {
				if(this.isSelectedRowOnlyOne()) {
					this.value = false;
					this.mask = true;
					this.addone = true;
					this.status = 'lock';

				}
			},
			//停用/启用
			confirmLock() {
				this.isDisabled = true;
				let params = {

					Data: {
						"ID": this.page.list.selectedId,
						"Enabled": this.page.list.enabled == "启用" || this.page.list.enabled == "Enabled" ? false : true
					}
				}
				this.$http.post('/Role/EnableOrDisable', params).then(data => {
					if(data.data.Code == '0') {
						this.$message({
							message: data.data.Message,
							type: 'success'
						});
						this.getList(); //重新加载列表
						this.clearInput(); //清空文本框
						this.mask = false;
						this.addone = false;
						this.isDisabled = false;
					} else {
						this.$message.error(data.data.Message)
						this.isDisabled = false;
					}
				})

			},
			//查看权限
			viewPermission() {
				if(this.isSelectedRowOnlyOne()) {
					this.getRole();
					this.mask = true;
					this.addone = true;
					this.status = 'viewPermission';
				}
			},
			//导出excel
			exportToExcel() {
				let params = {

					Data: {
						"QST": this.page.list.QST,
						'SortDirection': this.page.list.sortDirection
					}
				}
				this.$http.post('/Role/ExportToExcel', params, {
					headers: {
						"Content-Disposition": "attachment;filename=roles.xls",
						"Content-Type": "application/octet-stream"
					},
					responseType: 'blob'
				}).then((rsp) => {
					if(!rsp) {
						return;
					}
					//
					let url = window.URL.createObjectURL(new Blob([rsp.data]));
					let link = document.createElement('a');
					if(window.navigator.msSaveBlob) {
						var blobObject = new Blob([rsp.data])
						window.navigator.msSaveBlob(blobObject, this.R.Role_List_1 + '.xls'); // The user only has the option of clicking the Save button.
						return
					}  
					link.style.display = 'none';
					link.href = url;
					link.setAttribute('download', this.R.Role_List_1 + '.xls');
					//
					document.body.appendChild(link);
					link.click();
				}).catch((error) => {
					alert(error)
				});
			},
			//////Operating End

			//////Verify Start
			//选择框 变更选中
			//选择框 变更选中
			selectionChange(selects, row) {
				this.page.list.selectedId = row.ID;
				this.page.list.enabled = row.StatusText;
			},
			//选择框 取消选中
			selectionCancel(selection, row) {
				if(selection.length >= 1) {
					this.page.list.selectedId = selection[selection.length - 1].ID;
					this.page.list.enabled = selection[selection.length - 1].StatusText;
				} else {
					this.page.list.selectedId = '';
					this.page.list.enabled = '';
				}
			},
			//关闭弹窗并清空
			closemask() {
				this.mask = false;
				this.showlock = false;
				this.addone = false;
				this.rolepaytrue = false;
				this.CartridgeLoading = false;
				this.errortext = false;
				this.clearInput();
				this.getList();
			},
			clearInput() {
				this.page.input.name = "";
				this.page.input.remark = "";
				this.page.input.selectedIDs = [];
				this.page.functions.ids = [];
				for(var k = 0; k < this.page.functions.data.length; k++) {
					var _menuIndex = 0
					for(var i = 0; i < this.page.functions.data[k].children.length; i++) {
						this.page.functions.data[k].children[i].selected = false;
						var allFunctionsCount = this.page.functions.data[k].children[i].functions.length; //当前菜单下的所有功能数
						for(var j = 0; j < this.page.functions.data[k].children[i].functions.length; j++) {
							this.page.functions.data[k].children[i].functions[j].selected = false;
						}

						var blowChildNodeNum = this.page.functions.data[k].children[i].children.length;
						for(var m = 0; m < blowChildNodeNum; m++) {
							var belowFuncLen = this.page.functions.data[k].children[i].children[m].selected = false;
							var belowFuncLen = this.page.functions.data[k].children[i].children[m].functions.length;
							for(var n = 0; n < belowFuncLen; n++) {
								this.page.functions.data[k].children[i].children[m].functions[n].selected = false;
							}
						}
					}
				}
			},
			//////Verify End

			childByValue: function(childValue) {
				// childValue就是子组件传过来的值
				this.page.list.QSE = childValue
				this.getList();
			},

			searchmylist() {
				this.page.list.currentPage = 1;
				this.getList();
			},
			handleSizeChange(val) {
				this.page.list.pageSize = val
				this.page.list.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page.list.currentPage = val;
				this.getList();
			},
			editMembers(){
				if(this.isSelectedRowOnlyOne()) {
					this.value = false
					this.mask = true;
					this.addone = true;
					this.status = 'Mem';
					this.editMember.qst="";
					this.editMember.currentPage=1;
					this.editMemberRequest();
				}
			},
			editMemberRequest(pageNo){
				this.editMember.loading=true;
					this.$http.post('system:/Role/Users/Get', {
					Data: {
						ID:this.page.list.selectedId,
						QST:this.editMember.qst,
						PageSize: this.editMember.pageSize,
						PageNO: pageNo?pageNo:this.editMember.currentPage,
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) {
						this.editMember.list=rsp.data.Data.Items;
						this.editMember.total=rsp.data.Data.TotalRecordQty;
					} 
					this.editMember.loading=false;
				})
			}
		},
	};
</script>

<style lang='scss'>
	#role {
		.popup-size-del{
			width: 400px !important;
			height:200px !important;
		};
		.popup-size-lock{
			width:400px !important;
			height:200px !important;
		};
		.popup-size-add{
			width:400px !important;
			height:239px !important;
		};
		.popup-size-edit{
			width:800px !important;
			height:480px !important;
		};
		.popup-size-viewPermission{
			width:800px !important;
			height:480px !important;
		};
		.el-checkbox+.el-checkbox {
			margin: 0;
		}
		.functionsColor {
			.el-checkbox__inner {
				border: 1px solid #fa905d;
			}
			color: #fa905d;
		}
		.check1 {
			margin-left: 0!important;
		}
		.check2 {
			margin-left: 40px!important;
			margin-right: 40px!important;
		}
		.check3 {
			margin-left: 80px;
		}
		.check4 {
			margin-left: 120px;
		}
		.el-checkbox__label {
			font-size: 14px;
			width: 120px;
			margin-bottom: 10px;
			margin-right: 10px;
		}
		.el-button+.el-button {
			margin-left: 0px;
		}
		.el-button {
			margin-right: 5px;
		}
		.englishcolor {
			.el-checkbox__label {
				font-size: 14px;
				width: 210px;
				margin-bottom: 10px;
				margin-right: 10px;
			}
		}
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
			.addone {
				height: 480px;
				width: 800px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					// height: 342px;
					padding-left: 20px;
					box-sizing: border-box;
					li {
						margin-top: 15px;
						display: flex;
						.el-input {
							height: 30px;
							width: 160px;
						}
						.el-input__inner {
							height: 30px;
						}
						.red {
							color: red;
							line-height: 30px;
							margin-left: 5px;
						}
						.el-textarea {
							width: 60%;
						}
						.beizhu {}
					}
					.expand {
						height: 220px!important;
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 45px;
					margin-right: 10px;
					float: right;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
			.del {
				.contentdel {
					padding-top: 50px;
					margin-bottom: 20px;
					text-align: center;
					i {
						color: deepskyblue;
						margin-right: 10px;
						font-size: 16px;
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 25px;
					margin-right: 10px;
					float: right;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
		}
		.right {
			width: 100%;
			background-color: white;
			height: 100%;
			padding: 0;
			.rightfoot {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 60px;
				padding-left: 15px;
				padding-top: 14px;
				background-color: #EFF1F1;
			}
			.mytable {
				position: absolute;
				top: 45px;
				bottom: 78px;
				left: 0px;
				right: 0px;
				overflow: hidden;
			}
			.clickbtn {
				margin-bottom: 15px;
				height: 30px;
				.el-input {
					display: inline-block;
					float: right;
					width: 220px;
					i {
						position: relative;
						cursor: pointer;
					}
				}
				.el-input__inner {
					height: 30px!important;
					line-height: 30px!important;
					font-size: 14px;
					position: relative;
				}
			}
		}
		.rolepay {
			overflow-x: auto;
			overflow-y: auto;
			height: 230px;
			width: 700px;
			.checkbox-table {}
		}
	}




	.clear::after{
		content: '';
		clear: both;
		display: block;
	}

	

	.Mem{
		.header{
			padding:10px;
			.left{
				float: left;
			}
			.right{
				text-align: right;
				.el-input{
					display: inline-block;
					width: 220px;
					margin:0 5px;
					i {
							position: relative;
							cursor: pointer;
					}
				}
			}
		}
		.table{
			padding:0 10px;
		}
		.page{
			padding:5px 10px;
		}
		
	}


	.selectMask{
		position: fixed;
		width:100%;
		height: 100vh;
		left: 0;
		top: 0;
		z-index: 10;
		background:rgba(0,0,0,.5);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.personSelector{
		width: 1000px !important;
		height: 550px;
		border-radius: 5px;
		background-size: 100%;
		background: #fff;
		overflow: hidden;
		.head{
			height: 35px;
			line-height: 35px;
			background-color: #fa905d;
			.title{
				padding-left: 10px;
				display: block;
				width: 90%;
				float: left;
				font-weight: 600;
				color: white;
			}
			.icon{
				float: right;
				margin-right: 10px;
				text-align: center;
				cursor: pointer;
				color: white;
				i{
					    font-size: 16px;
				}
			}
		}
		.el-row{
			.el-col{
				&:nth-child(1){
					width:250px;
					p{
						height: 48px;
						line-height: 48px;
						background-color: #EFF1F1;
						text-align: center;
						margin-bottom: 10px;
						font-size: 14px;
						border-right: 1px solid #eee;
						font-family: FZLTHK--GBK1-0;
						font-weight: bold;
						color: #7b8082;
					}
					.tree{
						overflow: auto;
					}
				}
				&:nth-child(2){
					width:750px;
					padding: 10px;
					.header{
						&:nth-child(1){
							padding:10px 0;
							text-align: right;
							.el-input{
								display: inline-block;
								width: 266px;
								margin:0 5px;
								i {
										position: relative;
										cursor: pointer;
								}
							}
						}
					}
					.page{
						padding:5px 0;
					}
					.footer{
						text-align: right;
					}
				}
			}
		}
	}
	.removeMask{
		position: fixed;
		width:100%;
		height: 100vh;
		left: 0;
		top: 0;
		z-index: 10;
		background:rgba(0,0,0,.5);
		display: flex;
		justify-content: center;
		align-items: center;
		.remove{
			width: 400px;
    		height: 190px;
			background:#ffff;
			.head{
				height: 35px;
				line-height: 35px;
				background-color: #fa905d;
				.title{
					padding-left: 10px;
					display: block;
					width: 90%;
					float: left;
					font-weight: 600;
					color: white;
				}
				.icon{
					float: right;
					margin-right: 10px;
					text-align: center;
					cursor: pointer;
					color: white;
					i{
							font-size: 16px;
					}
				}
			}
			.contentdel{
				padding-top: 50px;
					text-align: center;
					margin-bottom: 20px;
					i {
						color: deepskyblue;
						margin-right: 10px;
						font-size: 16px;
					}
			}
			.footer{
				text-align: right;
				padding:20px 0;
			}
		}
	}
</style>