<template>
  <div id="system_addnotice_div" v-loading="loading">
    <el-form
      ref="form"
      class="changepass demo-ruleForm"
      :model="form"
      label-width="80px"
      style="margin-top: 30px"
    >
      <el-form-item :label="R.Common_Label_Title">
        <el-input
          v-model="form.Title"
          maxlength="50"
          :placeholder="R.Common_Msg_CheckTitle"
          style="height: 30px; width: 400px"
        ></el-input>
        <span class="red">*</span>
      </el-form-item>
      <el-form-item :label="R.Common_Label_Context">
        <div style="display: flex">
          <CjVueEditor
            v-on:OrderShow="changeInput"
            v-if="ShowContent"
            :content="form.Content"
            :placeholder="R.Common_Msg_CheckContext"
          />
          <span class="red">*</span>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          style="margin-top: 100px; margin-left: 80px"
          @click="onSubmit"
          class="orangetext add orangebd"
          >{{ R.Common_Button_Add }}</el-button
        >
        <el-button @click="resetForm">{{ R.Common_Button_Reset }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
var R = {};
export default {
  data() {
    return {
      R: R,
      loading: false,
      ShowContent: true,
      form: {
        Title: "",
        Content: "",
      },
    };
  },
  beforeCreate() {
    R = this.getSelfLanguage("System", "System_Notice");
  },
  methods: {
    changeInput(data) {
      this.form.Content = data;
    },
    onSubmit() {
      if (!this.form.Title) {
        this.$message.error(R.Common_Msg_CheckTitle);
        return;
      }
      if (!this.form.Content) {
        this.$message.error(R.Common_Msg_CheckContext);
        return;
      }
      this.$http
        .post("system:/Notification/Add", {
          Data: {
            Title: this.form.Title,
            Content: this.form.Content,
          },
        })
        .then((res) => {
          if (res.data.Code == "0") {
            this.resetForm();
            this.$message.success(res.data.Message);
          }
        });
    },
    resetForm() {
      this.loading = true;
      this.form.Content = null;
      this.ShowContent = false;
      this.form.Title = "";
      clearTimeout(Time);
      var Time = setTimeout(() => {
        this.ShowContent = true;
        this.loading = false;
      }, 0);
    },
  },
};
</script>

<style lang="scss">
#system_addnotice_div {
  .ql-editor {
    height: 500px !important;
  }
}
</style>