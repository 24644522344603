import Vue from 'vue'
import I18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import iviewEn from 'view-design/dist/locale/en-US'
import iviewZh from 'view-design/dist/locale/zh-CN'

Vue.use(I18n)
const messages = {
  en: Object.assign( enLocale,iviewEn),
  zh: Object.assign( zhLocale,iviewZh)
}

function getLocal () {
  let lang = 'en'
  if (Vue.env && Vue.env.language) {
    lang = Vue.env.language
  }
  return lang
}

const i18n = new I18n({
  locale: getLocal(),
  messages:messages
})

export default i18n