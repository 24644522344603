var ajax = {}; //封装一个ajax

ajax.x = function () { //定义x,用于创建xhr。
  if (typeof XMLHttpRequest !== 'undefined') {
    return new XMLHttpRequest();
  }
  var versions = [
    "MSXML2.XmlHttp.6.0", "MSXML2.XmlHttp.5.0", "MSXML2.XmlHttp.4.0", "MSXML2.XmlHttp.3.0", "MSXML2.XmlHttp.2.0", "Microsoft.XmlHttp"
  ];
  var xhr;
  for (var i = 0; i < versions.length; i++) {
    try {
      xhr = new ActiveXObject(versions[i]);
      break;
    } catch (e) {}
  }
  return xhr;
};
//定义 send()
ajax.send = function (url, callback, method, data, async,token) {
  if (async ===undefined) {
    async = true;
  }
  var x = ajax.x();
  x.open(method, url, async);
  x.onreadystatechange = function () {
    if (x.readyState == 4) {
      callback(x.responseText)
    }
  };
  if (method == 'POST') {
    x.setRequestHeader('Content-type', 'application/json;charset=utf-8');
     x.setRequestHeader('X-Token', token);
  }
  x.send(data)
};
//定义 get 请求
ajax.get = function (url, data, callback, async) {
  var query = [];
  for (var key in data) {
    query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
  }
  ajax.send(url + (query.length ? '?' + query.join('&') : ''), callback, 'GET', null, async)
};
//定义 post 请求
ajax.post = function (url, data, callback, async) {
//	ajax.setRequestHeader("Token",data)
  var query = [];
  var token = data.data.Token
	data = JSON.stringify(data)
  ajax.send(url, callback, 'POST',data, async,token)
};
export default {
    ajax: ajax
}