<template>
	<div id="CompanyApproveDetail" v-loading='loading' v-if="IsPageDome">
		<div class="mask" v-if='maskhidden'>
			<!--添加审批流-->
			<div class="addone ApprovalForm" v-if="ApprovalForm">
				<div class="head">
					<span class="title">{{R.EnableApproval_System_1}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content">
					<div>
						<span>{{R.EnableApproval_System_2}}：</span>
						<span class="rightName" :title="kAOName">{{kAOName}}</span>
					</div>
					<div>
						<span class="leftName">{{R.EnableApproval_System_3}}：</span>
						<span class="rightName">
							<el-select v-model="Productvalue" :placeholder="R.EnableApproval_System_4" @change="changeProductoptions(Productvalue)">
						    <el-option
						      v-for="item in Productoptions"
						      :key="item.ID"
						      :label="item.Name"
						      :value="item.ID">
						    </el-option>
						  </el-select>
						</span>
						<span style="color: red">*</span>
					</div>
					<div>
						<span class="leftName">{{R.EnableApproval_System_5}}：</span>
						<span class="rightName">
							<el-select v-model="Billvalue" :placeholder="R.EnableApproval_SystemIndex_1">
						   	<el-option
						      v-for="item in Billoptions"
						      :key="item.BillType"
						      :label="item.BillName"
						      :value="item.BillType">
						    </el-option>
						  </el-select>
						</span>
						<span style="color: red">*</span>
					</div>
					<div>
						<span style="line-height: 15px;" class="leftName">{{R.EnableApproval_SystemIndex_2}}：</span>
						<span class="rightName">
							<el-input @blur="checkVersionNumber()" :placeholder="R.EnableApproval_SystemIndex_3"  v-model="ProcessName" ></el-input>
							<span style="color: red">*</span>
						<span style="color: red;" v-if="flag">{{R.EnableApproval_SystemIndex_3}}</span>
						</span>
					</div>
					<div>
						<span style="line-height: 15px;" class="leftName">{{R.EnableApproval_SystemIndex_4}}：</span>
						<span class="rightName">
							<el-input @blur="checkVersionNumber('aa')" disabled v-model="VersionNumber"></el-input>
							<span style="color: red">*</span>
						<!--<span style="color: red;" v-if="flag">请输入正确的版本号</span>-->
						</span>
					</div>
					<div>
						<span class="textarealeft leftName">{{R.EnableApproval_SystemIndex_5}}：</span>
						<span class="rightName">
							<el-input type="textarea"  :placeholder="R.EnableApproval_SystemIndex_38" maxlength="200" class="textarea" resize='none'  v-model="desc"></el-input>
						</span>
					</div>
				</div>
				<div class="foots">
					<el-button style="padding-right: 20px;margin-left: 10px;" @click="closemask">{{R.Common_Button_Cancel}}</el-button>
					<el-button style="padding-right: 20px;margin-left: 10px;" class="orangetext add orangebd" @click="saveDraft">{{R.Common_Button_Save}}</el-button>
				</div>
			</div>

		</div>
		<div class="head" style="display: flex;align-items: center;">
			<span class="two_title">
				<span>
					<el-popover  placement="bottom" width="150" v-model="urlInto" trigger="click">
						<div class="Printed" @click="changeUrl('b')"  >
							{{R.EnableApproval_SystemIndex_7}}
						</div>
						<div class="Printed" @click="changeUrl('aa')"  >
							{{R.EnableApproval_SystemIndex_8}}
						</div>
						<img slot="reference" @blur="urlInto==false" style="position: relative;top:8px;height: 27px;cursor: pointer;" src="/static/images/changeUrl.png"/>
					</el-popover>
				</span>
			<span>
					{{R.EnableApproval_SystemIndex_36}}：
					<OrganizationSelect
						style="width: 200px;"
						v-if="getpermission('Query')"
						:placeholder="R.EnableApproval_SystemIndex_11"
						v-model="kAOId" 
						:OnlyLoadEnableOrg="true"
						:defaultValue="defaultValue"
						:LoadOrganizationType='2'
					/>
			</span>
			<span v-if="getpermission('Query')">
					{{R.EnableApproval_SystemIndex_25}}：<el-select style="width:150px" v-model="Productvaluea" :placeholder="R.EnableApproval_SystemIndex_20">
						    <el-option
						      v-for="item in Productoptionsa"
						      :key="item.ID"
						      :label="item.Name"
						      :value="item.ID">
						    </el-option>
						  </el-select>
			</span>
			<span v-if="getpermission('Query')"> 
					{{R.EnableApproval_SystemIndex_12}}：<el-select style="width:150px" v-model="Statusvalue" :placeholder="R.EnableApproval_SystemIndex_20">
						    <el-option
						      v-for="item in Statusoptions"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
					
			</span>
			<span v-if="getpermission('Query')">
					{{R.Common_Button_Enable}}：<el-select style="width:150px" v-model="Turevalue" :placeholder="R.EnableApproval_SystemIndex_11">
						    <el-option
						      v-for="item in Trueoptions"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
					
			</span>
			<span v-if="getpermission('Query')">
				<el-input @keyup.enter.native="pageChange('new')"  style="width: 230px;" v-model="QST" :placeholder='R.EnableApproval_SystemIndex_14'></el-input>
			</span>
			<span>
				<el-button v-if="getpermission('Query')" style="margin-left: 10px;"class="orangetext add orangebd" @click="pageChange('new')">{{R.Common_Button_Query}}</el-button>
				<el-button v-if="getpermission('OperateApproval')" @click="addList(0)">{{R.EnableApproval_SystemIndex_16}}</el-button>
			</span>
			</span>
		</div>
		<div class="content">
			<div class="mytable" ref="table">
				<Table sortable='custom' :height='tableHeight' stripe :columns="tableColumns" :data="tableData"></Table>
			</div>
			<div class="rightfoot">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
	var R = {}
	export default {
		data() {
			return {
				defaultValue:[],
				IsPageDome: true,
				R: R,
				flag: false,
				maskhidden: false,
				ApprovalForm: false,
				addone: false,
				loadingL: false,
				kAOName: '',
				kAOId: '',
				Productvalue: 2,
				VersionNumber: '',
				ProcessName: '',
				kAOName: '',
				desc: '',
				Productoptions: [],
				Billvalue: '1',
				Billoptions: [],
				urlInto: false,
				OrgId: null,
				Topapi: [],
				Productvaluea: 0,
				Productoptionsa: [],
				Statusvalue: 0,
				Statusoptions: [{
					value: 1,
					label: R.EnableApproval_SystemIndex_17
				}, {
					value: 2,
					label: R.EnableApproval_SystemIndex_18
				}, {
					value: 3,
					label: R.EnableApproval_SystemIndex_19
				}, {
					value: 0,
					label: R.EnableApproval_SystemIndex_20
				}],
				Turevalue: 2,
				Trueoptions: [{
					value: 1,
					label: R.EnableApproval_SystemIndex_21
				}, {
					value: 0,
					label: R.EnableApproval_SystemIndex_22
				}, {
					value: 2,
					label: R.EnableApproval_SystemIndex_20
				}],
				QST: '',
				tableColumns: [{
					title: R.EnableApproval_SystemIndex_23,
					key: 'FlowTypeTxt',
					width: 140,
					tooltip: true,
					align: 'center'
				}, {
					title: R.EnableApproval_SystemIndex_24,
					key: 'CorpName',
					width: 180,
					tooltip: true,
					align: 'center'
				}, {
					title: R.EnableApproval_SystemIndex_25,
					key: 'ProductName',
					width: 140,
					tooltip: true,
					align: 'center'
				}, {
					title: R.EnableApproval_SystemIndex_26,
					key: 'BillName',
					width: 140,
					tooltip: true,
					align: 'center'
				}, {
					title: R.EnableApproval_SystemIndex_27,
					key: 'IsEnabledTxt',
					width: 120,
					tooltip: true,
					align: 'center',
					render: (h, params) => {
						return h('div', {
							style: {
								display: 'flex',
								alignItems: 'center',
								padding: '0 10px'
							}
						}, [
							h('div', {
								props: {},
								style: {
									width: '12px',
									height: '12px',
									background: params.row.IsEnableApprovalFlow == true ? 'green' : 'grey',
									borderRadius: '2px',
									display: params.row.FlowStatus == '1' ? 'none' : 'block'
								}
							}),
							h('div', {
								props: {},
								style: {
									fontSize: '12px',
									color: '#626667',
									marginLeft: '5px',
								},
							}, params.row.FlowStatus == '1' ? '-' : params.row.IsEnableApprovalFlowTxt)
						]);
					}
				}, {
					title: R.EnableApproval_SystemIndex_28,
					key: 'FlowName',
					width: 180,
					tooltip: true,
					align: 'center',
					render: (h, params) => {
						return h('div', {
							props: {},
							style: {
								fontSize: '12px',
								color: '#07BAD4',
								textDecoration: 'underline',
								cursor: 'pointer'
							},
							on: {
								click: () => {
									if(params.row.FlowID) {
										if(params.row.FlowID) {
											this.$router.push({
												name: 'ProcessEngine',
												params: {
													type: "edit",
													ID: params.row.FlowID,
													status: 'CompanyApprove'
												}
											})
										}
									}
								}
							}
						}, params.row.FlowName);
					}
				}, {
					title: R.EnableApproval_SystemIndex_29,
					key: 'FlowVersion',
					width: 140,
					tooltip: true,
					align: 'center'
				}, {
					title: R.EnableApproval_SystemIndex_30,
					key: 'FlowStatusTxt',
					width: 100,
					tooltip: true,
					align: 'center'
				}, {
					title: R.Common_Button_Enable,
					key: 'open',
					width: 100,
					tooltip: true,
					align: 'left',
					render: (h, params) => {
						return h('div', {
							props: {},
							style: {
								fontSize: '12px',
								color: params.row.IsEnabled == true ? (params.row.FlowStatus == '1' ? 'grey' : 'green') : 'grey',
							},
						}, params.row.FlowStatus == '1' ? '-' : params.row.IsEnabledTxt);
					}
				}, {
					title: R.EnableApproval_SystemIndex_32,
					key: 'Remark',
					minWidth: 180,
					maxWidth:400,
					tooltip: true,
					align: 'left'
				}, {
					title: R.EnableApproval_SystemIndex_33,
					key: 'Creator',
					width: 100,
					tooltip: true,
					align: 'left'
				}, {
					title: R.EnableApproval_SystemIndex_34,
					key: 'CreatedTime',
					width: 180,
					tooltip: true,
					align: 'left'
				}, {
					title: ' '
				}],
				tableData: [],
				tableHeight: '',
				total: 0,
				pagesize: 10,
				currentPage: 1,
				loading: false,
			}
		},
		methods: {
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {

						if(code == this.permissionCode[i].Code) {
							return true
						}
					}
				} else {
					return false
				}

			},
			showTreeNodeLabel(node, defaultValues) {
				if(node.raw.label) {    // 有label属性，则表示节点数据已经找到
					return node.raw.label;                
				} else {                     // 未找到节点，从defaultValues中查找
					if(defaultValues && defaultValues.length > 0) {      
						return defaultValues;                    
					}                     //没找到
					return '';                
				}                 //
				return node.raw.label;
			},
			saveDraft() {
				//插眼
				this.checkVersionNumber();
				if(this.flag) {
					//					this.$message.error('请完整填写内容')
					return;
				}
				this.$http.post('/BillApproval/ApprovalFlow/Add', {
					Data: {
						"CropId": this.kAOId,
						"ProductId": this.Productvalue,
						/*产品id*/
						"FlowType": 2,
						/*流程类型1.标准 2.自定义*/
						"BillType": this.Billvalue,
						/*单据类型*/
						"FlowName": this.ProcessName,
						/*流程名称*/
						"Remark": this.desc /*备注说明*/
					}
				}).then(res => {
					if(res.data.Code == 0) {
						if(res.data.Data.ID) {
							this.$router.push({
								name: 'ProcessEngine',
								params: {
									type: "new",
									ID: res.data.Data.ID,
									status: 'CompanyApprove'
								}
							})
						}
					} else {
						alert(res.data.Message)
					}
				})
			},
			changeProductoptions(newValue) {
				this.Productvalue = newValue
				// console.log('hah')
				this.getProduct(newValue)
			},
			getProduct(id) {
				this.$http.post('/BillType/GetByProduct', {
					Data: {
						"ProductId": id /*产品id*/
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.Billoptions = res.data.Data
						this.Billvalue = this.Billoptions.length > 0 ? this.Billoptions[0].BillType : null
					} else {
						
					}

				})
			},
			pageChange(newval) {
				this.loading = true;
				this.SetPageSize(this.pagesize, '/system/BillApproval/CompanyApprove')
				this.$http.post('/BillApproval/CorpApprovalFlow/Query', {
					Data: {
						'PageSize': this.pagesize,
						'PageNO': newval ? 1 : this.currentPage,
						"CorpId": this.kAOId /*公司id*/ ,
						"ProductId": this.Productvaluea,
						/*产品id*/
						"FlowStatus": this.Statusvalue,
						/*状态*/
						"IsEnabled": this.Turevalue,
						/*是否启用*/
						"QST": this.QST,
						/*快速搜索文本*/
						'IsSetNumber': true,
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.currentPage = res.data.Data.PageCount;
						this.pagesize = res.data.Data.PageSize;
						this.total = res.data.Data.TotalRecordQty;
						this.tableData = res.data.Data.Items;
					} else {
						
					}
					this.loading = false;
				})
			},
			checkVersionNumber() {
				if(this.ProcessName == '' || this.ProcessName.length > 20) {
					this.flag = true
				} else {
					this.flag = false
				}

			},
			closemask() {
				this.maskhidden = false;
				this.addone = false;
				this.ApprovalForm = false;
				this.DetailList = false;
				this.flag = false;
				this.Productvalue = 2;
				this.getProduct(1);
				this.ProcessName = '';
				this.desc = '';
			},
			addList(index) {
				this.maskhidden = true;
				this.ApprovalForm = true;
			},
			//当前页size重选
			handleSizeChange(val) {
				this.pagesize = val
				this.currentPage = 1;
				this.pageChange();
			},
			//当前页重选
			handleCurrentChange(val) {
				this.currentPage = val;
				this.pageChange();
			},
			changeUrl(command) {
				// console.log(command)
				if(command == 'b') {
					this.$router.push('/system/BillApproval/CompanyApprove')
				} else {
					return
				}
			},
		},
		watch: {
			watch: {
				screenWidth(val) {
					this.tableHeight = this.$refs.table.offsetHeight;
				},
				screenHeight(val) {
					this.tableHeight = this.$refs.table.offsetHeight;
				},
			},
		},
		beforeUpdate() {
			const that = this
			window.onresize = () => {
				return(() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					that.screenWidth = window.screenWidth
					that.screenHeight = window.screenHeight
				})()
			}
			this.tableHeight = this.$refs.table.offsetHeight;
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'system_BillApproval_Detail')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted() {
			var permissionList = JSON.parse(this.$Store.get('SyetemKinds'))
			for(let i = 0; i < permissionList.length; i++) {
				if(permissionList[i].Children != null) {
					for(let j = 0; j < permissionList[i].Children.length; j++) {
						if(permissionList[i].Children[j].Url == '/system/BillApproval/CompanyApprove') {
							this.permissionCode = permissionList[i].Children[j].Permissions
							// console.log(this.permissionCode)
						}
					}
				}
			}
			this.$http.post('/Organization/GetTree', {
				Data: {
					LoadOrganizationType: 3,
					OnlyLoadPrivilegeOrg: true, //正式应该为true
					'ParentId': '',
					"Delay": true,
					'onlyloadEnableOrg': true,
				}
			}).then(data => {
				if(data.data.Code == '0') {
					this.Topapi = data.data.Data
				} else {
					
				}
			})
			this.kAOName = this.$Store.get('CorpName');
			this.kAOId = Number(this.$Store.get('CorpID'));
			this.defaultValue = [{id: this.kAOId,label:this.kAOName}]
			this.$http.post('/Product/All', {
				Data: {
					"Type": 1 /*获取类型1.表单下拉列表2.查询条件*/
				}
			}).then(res => {
				if(res.data.Code == '0') {
					this.Productoptions = res.data.Data;
					console.log(this.Productoptions)
					this.getProduct(this.Productoptions[0].ID)
				} else {
					
				}
			})
			this.$http.post('/Product/All', {
				Data: {
					"Type": 2 /*获取类型1.表单下拉列表2.查询条件*/
				}
			}).then(res => {
				if(res.data.Code == '0') {
					this.Productoptionsa = res.data.Data;
				} else {
					
				}
			})
			this.pagesize = this.GetPageSize('System/BillApproval/CompanyApprove')
			this.pageChange()

		}
	}
</script>

<style lang='scss'>
	#CompanyApproveDetail {
		.head {
			.el-input__inner {
				height: 30px;
				line-height: 30px;
			}
			.two_title {
				span {
					display: inline-block;
					>.el-input__icon {
						height: 28px;
						line-height: 28px;
					}
				}
				
			}
			.vue-treeselect {
				width: 220px;
				.vue-treeselect__menu {
					width: 300px!important;
				}
			}
		}
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.addone {
				width: 500px;
				height: 500px;
				overflow: auto;
				position: fixed;
				margin: auto;
				background-color: white;
				.listTable {
					position: absolute;
					top: 45px;
					bottom: 40px;
				}
				.foots {
					position: absolute;
					bottom: 10px;
					left: 0;
					right: 0;
					height: 30px;
					line-height: 30px;
					margin-right: 10px;
					.el-button {
						float: right;
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					top: 35px;
					left: 0px;
					right: 0px;
					bottom: 40px;
					position: absolute;
					.commontree {
						top: 0;
					}
					>div {
						height: 40px;
						line-height: 40px;
						padding-left: 20px;
						.leftName {
							display: inline-block;
							width: 71px;
						}
						.rightName {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							.el-input {
								width: 210px;
							}
							.textarea {
								margin-top: 10px;
								height: 60px;
								width: 210px;
							}
						}
						.textarealeft {
							position: relative;
							top: -30px;
						}
					}
					.el-select {
						height: 30px;
						width: 210px;
					}
					.el-input__inner {
						height: 30px;
						line-height: 30px;
					}
				}
			}
			.ApprovalForm {
				width: 420px;
				height: 380px;
			}
			.DetailList {
				width: 800px;
				height: 420px;
			}
		}
		.content {
			position: absolute;
			top: 40px;
			bottom: 0px;
			left: 0px;
			right: 0px;
			overflow: hidden;
			.mytable {
				position: absolute;
				top: 10px;
				bottom: 78px;
				left: 0px;
				right: 0px;
				overflow: hidden;
			}
			.rightfoot {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 60px;
				padding-left: 15px;
				padding-top: 14px;
				background-color: #EFF1F1;
			}
		}
	}
</style>