<template>
	<div id="ProcessEngine" v-loading="loading" v-if="IsPageDome">
		<cj-ElasticFrame width="400px" :title="DelTitle" height="200px" :sureText="R.ProcessEngine_list1" :cancelText='R.ProcessEngine_list2' @clickSure="DelclickSure" @clickCancel="DelclickCancel" v-if="DelElasticFrameMask">
			<div slot="content" class="DelDocuments">
				<i class="el-icon-info"></i>{{R.ProcessEngine_list3}}
			</div>
		</cj-ElasticFrame>
		<cj-ElasticFrame width="400px" :title="R.ProcessEngine_list4" height="200px" :sureDisable='btndisabled' :sureText="R.Common_Button_Sure" :cancelText='R.Common_Button_Cancel' @clickSure="SubclickSure" @clickCancel="SubclickCancel" v-if="SubElasticFrameMask">
			<div slot="content" class="DelDocuments">
				<i class="el-icon-info"></i>{{R.ProcessEngine_list7}}
			</div>
		</cj-ElasticFrame>
		<div class="mask" v-if="maskhidden">
			<div class=" ApprovalForm" v-if="ApprovalForm" v-loading="loadingSave">
				<div class="head">
					<span class="title">{{R.ProcessEngine_list8}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content">
					<div v-if="CropName">
						<span class="leftName">{{R.ProcessEngine_list9}}</span>
						<span class="rightName">
							{{CropName}}
						</span>
					</div>
					<div>
						<span class="leftName">{{R.ProcessEngine_list10}}</span>
						<span class="rightName">
							<el-input disabled v-model="ProductName"></el-input>
						</span>
						<span style="color: red">*</span>
					</div>
					<div>
						<span class="leftName">{{R.ProcessEngine_list11}}</span>
						<span class="rightName">
							<el-input disabled v-model="BillName"></el-input>
						</span>
						<span style="color: red">*</span>
					</div>
					<div>
						<span class="leftName">{{R.ProcessEngine_list12}}</span>
						<span class="rightName">
							<el-input maxlength='50' v-model="FlowName"></el-input>
							<span style="color: red">*</span>
						<span v-if="!FlowName.trim()" style="font-size:12px;color:red;">{{R.ProcessEngine_list13}}</span>
						</span>
					</div>
					<div>
						<span class="leftName">{{R.ProcessEngine_list14}}</span>
						<span class="rightName">
							<el-input :disabled="true" v-model="FlowVersion"  :placeholder="R.ProcessEngine_list15" ></el-input>
							<span style="color: red">*</span>
						</span>
					</div>
					<div>
						<span class="textarealeft leftName">{{R.ProcessEngine_list16}}</span>
						<span class="rightName">
							<el-input type="textarea" maxlength="200"   class="textarea" resize='none'  v-model="Remark"></el-input>
						</span>
					</div>
				</div>
				<div class="foots">
					<el-button style="padding-right: 20px;margin-left: 10px;" @click="closemask">{{R.Common_Button_Cancel}}</el-button>
					<el-button style="padding-right: 20px;margin-left: 10px;" class="orangetext add orangebd" @click="processSave">{{R.Common_Button_Save}}</el-button>
				</div>
			</div>
			<!--设置审批节点-->
			<div class="addone" v-if="addone">
				<div class="head">
					<span class="title">{{R.ProcessEngine_list19}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content">
					<div>
						<span class="leftName">{{R.ProcessEngine_list20}}</span>
						<span class="rightName">
							<el-input :disabled="editShow" style="width: 560px;" v-model="desc.name"></el-input>
							<span class="red">*</span>
						<span class="red" v-if="desc.name==''||desc.name.length>20">{{R.ProcessEngine_list21}}</span>
						</span>
					</div>
					<div style="height: 55px;">
						<span style="line-height: 15px;" class="textarealeft leftName">{{R.ProcessEngine_list22}}</span>
						<span class="rightName">
							<el-input :disabled="editShow" style="width: 560px;" type="textarea" :placeholder="R.ProcessEngine_list119" class="textarea" resize='none'  v-model="desc.desc"></el-input>
						</span>
					</div>
					<div style="margin-top: 20px;">
						<span style="line-height: 15px;"  class="leftName">{{R.ProcessEngine_list24}}</span>
						<span class="rightName">
							<el-checkbox :disabled="editShow"  v-model="desc.openEmail">{{R.ProcessEngine_list25}}</el-checkbox>
						</span>
					</div>
					<div style="border-top: 1px solid #EEEEEE;">
						<span class="leftName">{{R.ProcessEngine_list26}}</span>
						<span class="rightName">
							<el-radio-group :disabled="editShow"  v-model="desc.Employeeradio">
							<el-radio :label="1">{{R.ProcessEngine_list27}}</el-radio>
							<el-radio :label="2">{{R.ProcessEngine_list28}}</el-radio>
							<el-radio :label="3">{{R.ProcessEngine_list29}}</el-radio>
							<el-radio :label="4">{{R.ProcessEngine_list138}}</el-radio>
						</el-radio-group>
						</span>
					</div>
					<div v-if="desc.Employeeradio==4">
						<span class="leftName">{{R.ProcessEngine_list138}}</span>
						<span class="">
							<el-select class="selectInput"  :disabled="editShow" style="width:560px"  v-model="desc.billcode" :placeholder="R.ProcessEngine_list139">
						    <el-option
						      v-for="item in billerOptions"
						      :key="item.ID"
						      :label="item.CodeName"
						      :value="item.Code">
						    </el-option>
						  </el-select>
						<span class='red'>*</span>
						</span>
					</div>
					<div v-if="desc.Employeeradio==1">
						<span class="leftName">{{R.ProcessEngine_list30}}</span>
						<span class="">
							<OrganizationSelect
								style="width: 560px;line-height:28px;"
								:disabled="editShow" 
								:placeholder="R.ProcessEngine_list32"
								v-model="desc.OrgId" 
								@select="changeOrgID"
								:OnlyLoadEnableOrg="true"
								:clearable ="false"
								:defaultValue = "desc.CorpDefaultValues"
							/> 
						<span class='red'>*</span>
						</span>
					</div>
					<div v-if="desc.Employeeradio==1" v-loading='maskloading' style="height: 280px;margin-top: 15px;">
						<div class="table need-left">
							<div class="main-tt">
								<div class="one-table">
									<div class="head">
										{{R.ProcessEngine_list33}}
									</div>
									<ul class="main-table">
										<li v-for="(item,index) in mymenus" :key="index" :class="{actived: activeIndex == index}" @click="changecolor($event,index)">{{item.Name}}-{{item.Code}} <i class="el-icon-delete" v-if="item.Code=='FixedCharacter'" @click="deletemymenus(index)"></i></li>
									</ul>
								</div>
								<div class="btns">
									<el-button :disabled="editShow" class="orange" @click="turnleft()">
										<img src="static/images/leftoo.png" alt="" />
									</el-button>
									<el-button :disabled="editShow" class="orange" @click="turnright()">
										<img src="static/images/right00.png" alt="" />
									</el-button>
									<el-button :disabled="editShow" @click="resert">{{R.Common_Button_Reset}}</el-button>
								</div>
								<div class="one-table">
									<div class="head">
										{{R.ProcessEngine_list35}}
									</div>
									<ul class="main-table" ref='parant'>
										<li v-for="(item,index) in mymenues" :key="index" :class="{actives: activeIndexs == index}" @click="changecolors($event,index)" v-dragging="{ item: item, list: mymenues, group: 'item' }">{{item.Name}}-{{item.Code}} </li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div v-if="desc.Employeeradio==2">
						<span style="line-height: 15px;"  class="leftName"></span>
						<span class="rightName">
							<el-radio-group  :disabled="editShow"  v-model="desc.EmployeeOpenradio">
							<el-radio :label="1">{{R.ProcessEngine_list36}}</el-radio>
							<el-radio :label="2">{{R.ProcessEngine_list37}}</el-radio>
						</el-radio-group>
						</span>

					</div>
					<div v-if="desc.EmployeeOpenradio==1&&desc.Employeeradio==2">
						<span class="leftName"></span>
						<span class="rightName">
						<el-select class="selectInput"  :disabled="editShow" style="width:140px"  v-model="desc.employeeOpen" :placeholder="R.ProcessEngine_list32">
						    <el-option
						      v-for="item in employeeOpenOptions"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
						  {{R.ProcessEngine_list38}}
						</span>
					</div>
					<div v-if="desc.EmployeeOpenradio==1&&desc.Employeeradio==2">
						<span class="leftName"></span>
						<span class="rightName">
						{{R.ProcessEngine_list39}}
						<el-select class="selectInput" :disabled="editShow" style="width:140px"  v-model="desc.employeeOpenUp" :placeholder="R.ProcessEngine_list32">
						    <el-option
						      v-for="item in employeeOpenOptionsUp"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
						{{R.ProcessEngine_list40}}
					</span>
					</div>
					<div v-if="desc.EmployeeOpenradio==1&&desc.Employeeradio==2">
						<span class="leftName"></span>
						<span class="rightName">
								<el-checkbox  :disabled="editShow" v-model="desc.transUp">{{R.ProcessEngine_list41}}</el-checkbox>
						</span>
					</div>
					<div v-if="desc.Employeeradio==2&&desc.EmployeeOpenradio==2" style="height: 40px;margin-bottom: 25px;">
						<span class="leftName">{{R.ProcessEngine_list30}}</span>
						<span class="">
							<OrganizationSelect
								style="width: 560px;line-height:28px;"
								:disabled="editShow" 
								:placeholder="R.ProcessEngine_list31"
								v-model="desc.OrgId" 
								@select="changeOrgID"
								:OnlyLoadEnableOrg="true"
								:clearable ="false"
								:defaultValue = "desc.CorpDefaultValues"
							/> 
						<span class='red'>*</span>
						</span>
					</div>
					<div v-if="desc.Employeeradio==3" style="margin-bottom: 10px;height: 80px;">
						<span class="leftName" style="position: relative;top: -30px;">{{R.ProcessEngine_list42}}</span>
						<span class="rightName">
							<el-radio-group  :disabled="editShow" v-model="desc.rangeRadio">
							<li style="margin-top: 10px;display: flex;"><el-radio :label="1">{{R.ProcessEngine_list43}}</el-radio>
							<el-radio :label="2">{{R.ProcessEngine_list44}}</el-radio></li>
							<li style="margin-top: 10px;display: flex;"><el-radio :label="3">{{R.ProcessEngine_list45}}</el-radio>
								<el-radio :label="4">{{R.ProcessEngine_list46}}</el-radio></li>
							<li style="display: flex;height: 30px;margin-top: 10px;"><el-radio :label="5">{{R.ProcessEngine_list47}}</el-radio>
								<OrganizationSelect
									v-if="desc.rangeRadio==5"
									style="left: 20px;width: 210px;position: relative;line-height:28px;"
									:disabled="editShow" 
									:placeholder="R.ProcessEngine_list48"
									v-model="desc.OrgId" 
									@select="changeOrgID"
									:OnlyLoadEnableOrg="true"
									:clearable ="false"
									:defaultValue = "desc.CorpDefaultValues"
								/> 
							<span class='red' v-if="desc.rangeRadio==5" style="margin-left:35px ;margin-top: 10px;">*</span>
							</li>
						</el-radio-group>
						</span>

					</div>
					<div v-if="desc.Employeeradio==3" v-loading='maskloadings' style="height: 280px;margin-top: 30px;">
						<div class="table need-left">
							<div class="main-tt">
								<div class="one-table">
									<div class="head">
										{{R.ProcessEngine_list49}}
									</div>
									<ul class="main-table">
										<!--插眼-->
										<li v-for="(item,index) in mymenueEmployee" :key="index" :class="{actived: activeIndex == index}" @click="changecolor($event,index,'mymenusEmployee')">{{item.ItemTextCN}} <i class="el-icon-delete" v-if="item.Code=='FixedCharacter'" @click="deletemymenus(index,'mymenusEmployee')"></i></li>
									</ul>
								</div>
								<div class="btns">
									<el-button :disabled="editShow" class="orange" @click="turnleft('mymenusEmployee')">
										<img src="static/images/leftoo.png" alt="" />
									</el-button>
									<el-button :disabled="editShow" class="orange" @click="turnright('mymenusEmployee')">
										<img src="static/images/right00.png" alt="" />
									</el-button>
									<el-button :disabled="editShow" @click="resert('mymenuesEmployee')">{{R.Common_Button_Reset}}</el-button>
								</div>
								<div class="one-table">
									<div class="head">
										{{R.ProcessEngine_list50}}
									</div>
									<ul class="main-table" ref='parant'>
										<li v-for="(item,index) in mymenuesEmployee" :key="index" :class="{actives: activeIndexs == index}" @click="changecolors($event,index,'mymenuesEmployee')" v-dragging="{ item: item, list: mymenuesEmployee, group: 'item' }">{{item.ItemTextCN}} </li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div v-if="desc.Employeeradio==2||desc.Employeeradio==3" style="margin-top:10px;height: 54px;">
						<span  style="position: relative;top: -22px;">{{R.ProcessEngine_list51}}</span>
						<span class="rightName">
							<el-radio-group :disabled="editShow"  v-model="desc.unnormalRadio">
							<li><el-radio :label="1">{{R.ProcessEngine_list52}}</el-radio> </li>
							<!--<li><el-radio :label="2">自动通过</el-radio></li>-->
							<li style="display: flex;margin-top: 10px;height: 30px;">
								<el-radio :label="3">{{R.ProcessEngine_list53}}</el-radio>
								<cj-Personnelselector :disabled="editShow"  v-if="desc.unnormalRadio==3" style="width:180px;position: relative;top: -5px;height: 30px;left:20px"  @optionShow="optionShow" @personnelShow="DrawAssetEmployeeShow"  :DrawAssetEmployeeIDs="desc.employeeId" :multiple="false" :IsFilterUser="false"></cj-Personnelselector>
								<span class="red" style="position: relative;top: 7px;left: 129px;"  v-if="desc.unnormalRadio==3">*</span>
							</li>
						</el-radio-group>
						</span>
					</div>
					<div style="border-top: 1px solid #EEEEEE;">
						<span style="line-height: 15px;">{{R.ProcessEngine_list54}}</span>
						<span class="rightName">
							<el-checkbox :disabled="editShow"  v-model="desc.openEmaillater">{{R.ProcessEngine_list55}}</el-checkbox>
						</span>
						</span>
					</div>
					<div style="margin-top:-5px;">
						<span style="line-height: 15px;">{{R.ProcessEngine_list56}}</span>
						<span class="rightName">
							<el-checkbox  :disabled="editShow" v-model="desc.addLine">{{R.ProcessEngine_list57}}</el-checkbox>
						</span>

					</div>
					<div style="display: flex;">
						{{R.ProcessEngine_list58}}
						<el-checkbox-group :disabled="editShow" v-model="desc.SupportedStrategies">
							<el-checkbox label="1">{{R.ProcessEngine_list59}}</el-checkbox>
							<el-checkbox label="2">{{R.ProcessEngine_list60}}</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div class="foots">
					<el-button style="padding-right: 20px;margin-left: 10px;" @click="closemask">{{R.Common_Button_Cancel}}</el-button>
					<el-button v-if="!editShow"  :disabled="editShow" style="padding-right: 20px;margin-left: 10px;" @click="sureAdd('addone')" class="orangetext add orangebd">{{R.Common_Button_Save}}</el-button>
				</div>
			</div>
			<!--设置分支网关-->
			<div class="addone branch" v-if="branch">
				<div class="head">
					<span class="title">{{R.ProcessEngine_list61}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content">
					<div>
						<span class="leftNames">{{R.ProcessEngine_list62}}</span>
						<span class="rightName">
							<el-input  :disabled="editShow" :placeholder="R.ProcessEngine_list89" v-model="desc.name"></el-input>
						</span>
					</div>
					<div>
						<span class="textarealeft leftNames">{{R.ProcessEngine_list63}}</span>
						<span class="rightName">
							<el-input  :disabled="editShow" type="textarea" v-model="desc.desc" class="textarea" resize='none'></el-input>
						</span>
					</div>
					<!--<div>
						<span class="leftNames">类型：</span>
						<span class="rightName">
							<el-radio-group  :disabled="editShow" style="margin-top: 40px;" v-model="desc.radio">
							<li><el-radio label="AndSplit">并行分支</el-radio> <span class="el-icon-question" title="所有的外出顺序流都会被并行使用。"></span></li>
						<li>
							<el-radio label="XOrSplit">唯一分支</el-radio> <span class="el-icon-question" title="根据定义在顺序流中的条件，会有唯一一个外向顺序流被使用。"></span></li>
						<li>
							<el-radio label="OrSplit">包含分支</el-radio> <span class="el-icon-question" title="所有满足条件的顺序流都会创建一个相应的分支，没有定义条件的顺序流将永远会被选择。"></span></li>
						</el-radio-group>
						</span>
					</div>-->
				</div>

				<div class="foots">
					<el-button style="padding-right: 20px;margin-left: 10px;" @click="closemask">{{R.Common_Button_Cancel}}</el-button>
					<el-button :disabled="editShow" v-if="!editShow"   style="padding-right: 20px;margin-left: 10px;" @click="sureAdd('branch')" class="orangetext add orangebd">{{R.Common_Button_Save}}</el-button>
				</div>
			</div>

			<!--设置顺序流-->
			<div class="addone Order" v-if="Order">
				<div class="head">
					<span class="title">{{R.ProcessEngine_list64}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content">
					<!--<div>
						<span class="leftNames">名称：</span>
						<span class="rightName" style="height: 400px;overflow: hidden;">
							<el-input :disabled="editShow"  v-model="desc.name" style="width: 280px;"></el-input>
							<span class='red'>*</span>
						<span class="red" v-if="desc.name==''||desc.name.length>20">请输入名称</span>
						</span>
					</div>-->
					<div style="height: 55px;">
						<span  style="line-height: 15px;"  class="textarealeft leftNames">{{R.ProcessEngine_list63}}</span>
						<span class="rightName">
							<el-input :disabled="editShow"  type="textarea" v-model="desc.desc" style="width: 510px;" class="textarea" resize='none'></el-input>
						</span>
					</div>
					<div style="margin-top: 20px;">
						<span>{{R.ProcessEngine_list65}}</span>
						<span class="rightName">
						 <div class="condition">
						<ul>
							<li v-for="(item,index) in searchSolution.List" :key="index" class="conditioned"> 
								<!-- 字段 -->
								<div>
									<el-select style="width:140px;" @change="selectMYIndex(item.BillPropertyName,index)" :disabled="editShow" v-model="item.BillPropertyName" :placeholder="R.ProcessEngine_list66" >
									<el-option v-for="fieldItem in searchSolution.datasource" :key="fieldItem.BillPropertyName" :label="fieldItem.DisplayName" :value="fieldItem.BillPropertyName">
									</el-option>
									</el-select>
								</div>
								<!-- 操作符 -->
		 						<div>
		 							<el-select style="width:140px;" @change="changeOperator(item.BillPropertyName,index)" :disabled="editShow" v-model="item.Operator" :placeholder="R.ProcessEngine_list66">
									<el-option v-for="oprtItem in item.options" :key="oprtItem.value" :label="oprtItem.label" :value="oprtItem.value">
									</el-option>
									</el-select>
		 						</div>
								<!-- 筛选值 -->
								<div>
									<el-input  style="width:250px;" :disabled="editShow"  v-if="item.BillPropertyType==null" v-model="item.value"></el-input>
									<el-input  style="width:250px;" :disabled="editShow"  v-if="item.BillPropertyType=='1'" v-model="item.value"></el-input>
								<el-input  :disabled="editShow" style="width:250px;" v-if="item.BillPropertyType=='2'"  @blur="checkQty"  v-model="item.value" ></el-input>
								<el-input  :disabled="editShow" style="width:250px;" v-if="item.BillPropertyType=='3'" @blur="checkdDecimal" v-model="item.value" ></el-input>
								<el-date-picker :disabled="editShow"  style="width:250px;" v-if="item.BillPropertyType=='4'"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="item.value" type="date" >
								</el-date-picker>
								<div style="width:250px;" v-if="item.BillPropertyType=='5'" >
									<el-switch  :disabled="editShow"  :width="32" v-model="item.value"  @change='switchConditionCheckStatus(item)' active-color="#13ce66" inactive-color="#ff4949">
									</el-switch><span >{{item.text}}</span>
							</div>
					<OrganizationSelect
						v-if="item.BillPropertyType=='6'&&item.PropertyControlType=='1'"
						style="width: 250px;line-height:28px;"
						:disabled="editShow" 
						:multiple="true"
						:placeholder="R.ProcessEngine_list66"
						v-model="item.defaultValueIds" 
						class='el-select'
						@select="changeIndexDefault"
						@input="changeIndex"
						:Index="index"
						:defaultValue="item.defaultValue"
					/> 
					<!--公司树-->
					<!-- <treeselect  valueFormat='object'  :placeholder="R.ProcessEngine_list66" :disabled="editShow" style="width:250px;" :multiple="true" class='el-select' :flat="true" :noChildrenText='R.ProcessEngine_list31' :noOptionsText="R.ProcessEngine_list31" v-if="item.BillPropertyType=='6'&&item.PropertyControlType=='1'" :options="searchSolution.OrgdataSource" :load-options="lazyLoadOrg" v-model="item.defaultValue" >
						<div slot="value-label" slot-scope="{node}">{{showTreeNodeLabels(node,item.defaultValue)}}</div>
					</treeselect> -->
						 <!--@input="changeIndex(index)"   @select="changeIndexDefault"-->
					<!--人员-->
					<div style="width:250px;height: 30px;line-height: 30px;" v-if="item.BillPropertyType=='6'&&item.PropertyControlType=='2'">
						<cj-Personnelselector :collapseTags="true" :placeholder="R.ProcessEngine_list66" :disabled="editShow" class='el-select' @optionShow="optionShowq" @personnelShow="DrawAssetEmployeeShowq" :multiple="true" :DrawAssetEmployeeIDs='item.value'></cj-Personnelselector>
					</div>
					<!--存放位置-->
					<LocationSelect
						v-if="item.BillPropertyType=='6'&&item.PropertyControlType=='3'"
						style="width: 250px;line-height:28px;"
						:disabled="editShow" 
						:multiple="true"
						:placeholder="R.ProcessEngine_list66"
						v-model="item.defaultValueIds" 
						class='el-select'
						@select="changeIndexDefault"
						@input="changeIndex"
						:Index="index"
						:defaultValue="item.defaultValue"
					/> 
					<!-- <treeselect :placeholder="R.ProcessEngine_list66"  valueFormat='object'    :disabled="editShow" style="width:250px;" :multiple="true" class='el-select' :noChildrenText='R.ProcessEngine_list31' :noOptionsText="R.ProcessEngine_list31" :flat="true" v-if="item.BillPropertyType=='6'&&item.PropertyControlType=='3'" :options="searchSolution.location" :load-options="lazyLoadLocation" v-model="item.defaultValue">
						<div slot="value-label" slot-scope="{node}">{{showTreeNodeLabels(node,item.defaultValue)}}</div>
					</treeselect> -->
					<!--资产分类-->
					<AssetCategorySelect
						v-show="(item.BillPropertyType=='6' && item.PropertyControlType=='4')||(item.BillPropertyType=='7' && item.Operator=='12'&&productId=='2')"
						style="width: 250px;line-height:28px;"
						:disabled="editShow" 
						:multiple="true"
						:placeholder="R.ProcessEngine_list66"
						v-model="item.defaultValueIds" 
						class='el-select'
						@select="changeIndexDefault"
						@input="changeIndex"
						:Index="index"
						:defaultValue="item.defaultValue"
					/> 
					<!-- <treeselect :placeholder="R.ProcessEngine_list66" valueFormat='object'   :disabled="editShow" style="width:250px;" :multiple="true" class='el-select' :noChildrenText='R.ProcessEngine_list31' :noOptionsText="R.ProcessEngine_list31" :flat="true" v-show="(item.BillPropertyType=='6' && item.PropertyControlType=='4')||(item.BillPropertyType=='7' && item.Operator=='12'&&productId=='2')" :options="searchSolution.category" :load-options="lazyLoadCategory" v-model="item.defaultValue">
						<div slot="value-label" slot-scope="{node}">{{showTreeNodeLabels(node,item.defaultValue)}}</div>
					</treeselect> -->
					<!--仓库-->
					<treeselect style="width:250px;"   v-if="item.BillPropertyType=='6' && item.PropertyControlType=='5'" :noChildrenText='R.ProcessEngine_list31' :noOptionsText="R.ProcessEngine_list31" :disabled="editShow" v-model="item.value" :placeholder="R.ProcessEngine_list67" :multiple="false" :flat="true" :options="searchSolution.Warehouse">
						<label slot="option-label" slot-scope="{ node, shouldShowCount,customLabel, count, labelClassName, countClassName }" :class="labelClassName">
							<span :class="node.raw.nodeType==2?'font_family icon-cangkucangchu-xianxing':'font_family icon-qiyeyuanquwuye-xianxing'"></span>
							<span :title ="node.label">{{ node.label }}</span>
						</label>
					</treeselect>
					<!--供应商-->
					<el-select class="selectMY" :multiple='true'  :collapse-tags="true" :placeholder="ProcessEngine_list66" :disabled="editShow" style="width:250px;" size="mini" v-model="item.value" clearable v-if="item.BillPropertyType=='6' && item.PropertyControlType=='6'" filterable :filter-method="searchSupplier">
						<el-option v-for="item1 in searchSolution.supplierDatasource" :key="item1.ID" :label="item1.Name" :value="item1.ID">
						</el-option>
					</el-select>
					<!--易耗品分类-->
					<treeselect :multiple="true" valueFormat='object' style="width:250px;"  @select="changeIndexDefault"   v-show="(item.BillPropertyType=='6' && item.PropertyControlType=='7')||(item.BillPropertyType=='7' && item.Operator=='12'&&productId=='3')" :disabled="editShow" :noChildrenText='R.ProcessEngine_list31' :placeholder="R.ProcessEngine_list68" :noOptionsText="R.ProcessEngine_list31" :options="searchSolution.Topapi" v-model="item.defaultValue" :load-options="loadconCateOptions">
						<div slot="value-label" slot-scope="{node}">{{showTreeNodeLabels(node,item.defaultValue)}}</div>
					</treeselect>
					<!--产权单位-->
					<el-select class="selectMY" style="width:250px;" :multiple='true' :collapse-tags="true" v-if="item.BillPropertyType=='6' && item.PropertyControlType=='8'" :placeholder="R.ProcessEngine_list66" :disabled="editShow" size="mini" clearable v-model="item.value" filterable :filter-method="searchpropertyUnit">
						<el-option v-for="item in searchSolution.propertyRightCorpDatasource" :key="item.ID" :label="item.Name" :value="item.ID">
						</el-option>
					</el-select>
					<!--字典-->
					<el-select class="selectMY" v-if="item.BillPropertyType=='6' && item.PropertyControlType =='9' " style="width:250px;" :multiple='true' :collapse-tags="true" :placeholder="R.ProcessEngine_list66" :disabled="editShow" size="mini" v-model="item.value">
						<el-option v-for="dicItem in item.DictItems" :key="dicItem.ID" :label="dicItem.ItemText" :value="dicItem.ID">
						</el-option>
					</el-select>
					<el-input :disabled="editShow" style="width:250px;" :placeholder="R.ProcessEngine_list69" v-if="item.BillPropertyType=='7' && item.Operator!='12'" v-model="item.value"></el-input>
				<i class="el-icon-remove" style="color: red;margin-left: 10px;position: relative;top: 8px;" @click="removeCondition(index)"></i>
				</div>
				</li>
				<p class="addfr" @click="addCondition" style="position: relative;top:-10px;">
					<i class="el-icon-plus"></i>
					<span>{{R.ProcessEngine_list70}}</span>
				</p>
				</ul>
			</div>
			</span>
		</div>
	</div>

	<div class="foots">
		<el-button style="padding-right: 20px;margin-left: 10px;"  @click="closemask">{{R.Common_Button_Cancel}}</el-button>
		<el-button :disabled="editShow" v-if="!editShow"   style="padding-right: 20px;margin-left: 10px;" @click="sureAddLine()" class="orangetext add orangebd">{{R.Common_Button_Save}}</el-button>
	</div>
	</div>

	<!--设置合并网关-->
	<div class="addone merage" v-if="merage">
		<div class="head">
			<span class="title">{{R.ProcessEngine_list71}}</span>
			<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
		</div>
		<div class="content">
			<div>
				<span class="leftNames">{{R.ProcessEngine_list62}}</span>
				<span class="rightName">
							<el-input  :disabled="editShow" :placholder="R.ProcessEngine_list92" v-model="desc.name" style="width: 250px;"></el-input>
				</span>
			</div>
			<div>
				<span class="textarealeft leftNames">{{R.ProcessEngine_list63}}</span>
				<span class="rightName">
							<el-input :disabled="editShow"  style="width: 250px;" v-model="desc.desc" :placeholder="R.ProcessEngine_list23" type="textarea" class="textarea" resize='none'></el-input>
						</span>
			</div>
			<!--<div>
				<span class="leftNames">类型：</span>
				<span class="rightName">
						 	<el-radio-group  :disabled="editShow" style="margin-top: 30px;"  v-model="desc.radio">
							<li>
							<el-radio label="AndSplit">并行分支</el-radio><span class="el-icon-question" title="所有的进入顺序流都需要被满足才可以进入网关。"></span>
				</li>
				<li>
					<el-radio label="XOrSplit">包含分支</el-radio><span class="el-icon-question" title="只要有一个进入顺序流满足条件，就可以进入网关。"></span>
				</li>
				</el-radio-group>
				</span>
			</div>-->
		</div>

		<div class="foots">
			<el-button style="padding-right: 20px;margin-left: 10px;" @click="closemask">{{R.Common_Button_Cancel}}</el-button>
			<el-button :disabled="editShow" v-if="!editShow"  style="padding-right: 20px;margin-left: 10px;" @click="sureAdd('merage')" class="orangetext add orangebd">{{R.Common_Button_Save}}</el-button>
		</div>
	</div>
	<!--会签审批节点-->
	<div class="addone huiqian" v-if="huiqian">
		<div class="head">
			<span class="title">{{R.ProcessEngine_list72}}</span>
			<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
		</div>
		<div class="content">
			<div>
				<span class="leftName">{{R.ProcessEngine_list20}}</span>
				<span class="rightName">
							<el-input  :disabled="editShow" style="width: 560px;" v-model='desc.name' ></el-input>
							<span class='red'>*</span>
				<span class="red" v-if="desc.name==''||desc.name.length>20">{{R.ProcessEngine_list21}}</span>
				</span>
			</div>
			<div style="height: 55px;">
				<span style="line-height:15px" class="textarealeft leftName">{{R.ProcessEngine_list22}}</span>
				<span class="rightName">
							<el-input  :disabled="editShow" v-model="desc.desc" style="width: 560px;" :placeholder="R.ProcessEngine_list23"  type="textarea" class="textarea" resize='none'></el-input>
						</span>
			</div>
			<div style="margin-top: 20px;">
				<span class="leftName" style="line-height: 15px;">{{R.ProcessEngine_list24}}</span>
				<span class="rightName">
							<el-checkbox :disabled="editShow"  v-model="desc.openEmail">{{R.ProcessEngine_list25}}</el-checkbox>
						</span>
			</div>
			<div style="border-top: 1px solid #EEEEEE;">
				<span class="leftName">{{R.ProcessEngine_list26}}</span>
				<span class="rightName">
							<el-radio-group :disabled="editShow"  v-model="desc.Employeeradio">
							<el-radio :label="1">{{R.ProcessEngine_list27}}</el-radio>
							<el-radio :label="2">{{R.ProcessEngine_list28}}</el-radio>
							<el-radio :label="3">{{R.ProcessEngine_list29}}</el-radio>
							<el-radio :label="4">{{R.ProcessEngine_list138}}</el-radio>
						</el-radio-group>
						</span>
			</div>
			<div v-if="desc.Employeeradio==4">
					<span class="leftName">{{R.ProcessEngine_list138}}</span>
					<span class="">
						<el-select class="selectInput"  :disabled="editShow" style="width:560px"  v-model="desc.billcode" :placeholder="R.ProcessEngine_list139">
						<el-option
							v-for="item in billerOptions"
							:key="item.ID"
							:label="item.CodeName"
							:value="item.Code">
						</el-option>
						</el-select>
					<span class='red'>*</span>
					</span>
				</div>
			<div v-if="desc.Employeeradio==1">
				<span class="leftName">{{R.ProcessEngine_list30}}</span>
				<span class="rightName">
					<OrganizationSelect
						:onlyloadEnableOrg="true"
						style="width: 560px;line-height:28px;"
						:disabled="editShow" 
						:placeholder="R.ProcessEngine_list32"
						v-model="desc.OrgId" 
						@select="changeOrgID"
						:clearable ="false"
						:defaultValue = "desc.CorpDefaultValues"
					/> 
					<!-- <treeselect :disabled="editShow"  style="width:560px;height: 30px;line-height: 30px;position: relative;top: 10px;" :flat='true' :noChildrenText='R.ProcessEngine_list31' :placeholder="R.ProcessEngine_list32"
					:noOptionsText="R.ProcessEngine_list31"
					:options="Topapi" 
					v-model="desc.OrgId" 
					:load-options="uprogloadOptions"
					@select="changeOrgID"
					:clearable ="false"
					
					>
					<div slot="value-label" slot-scope="{node}">{{showTreeNodeLabel(node,desc.CorpDefaultValues)}}</div>
					</treeselect> -->
					<span class='red'>*</span>
					</span>
			</div>
			<div v-if="desc.Employeeradio==1" v-loading='maskloading' style="height: 280px;margin-top: 15px;">
				<div class="table need-left">
					<div class="main-tt">
						<div class="one-table">
							<div class="head">
								{{R.ProcessEngine_list33}}
							</div>
							<ul class="main-table">
								<li v-for="(item,index) in mymenus" :key="index" :class="{actived: activeIndex == index}" @click="changecolor($event,index)">{{item.Name}}-{{item.Code}}<i class="el-icon-delete" v-if="item.Code=='FixedCharacter'" @click="deletemymenus(index)"></i></li>
							</ul>
						</div>
						<div class="btns">
							<el-button class="orange" :disabled="editShow" @click="turnleft()">
								<img src="static/images/leftoo.png" alt="" />
							</el-button>
							<el-button class="orange" :disabled="editShow" @click="turnright()">
								<img src="static/images/right00.png" alt="" />
							</el-button>
							<el-button :disabled="editShow" @click="resert">{{R.Common_Button_Reset}}</el-button>
						</div>
						<div class="one-table">
							<div class="head">
								{{R.ProcessEngine_list35}}
							</div>
							<ul class="main-table" ref='parant'>
								<li v-for="(item,index) in mymenues" :key="index" :class="{actives: activeIndexs == index}" @click="changecolors($event,index)" v-dragging="{ item: item, list: mymenues, group: 'item' }">{{item.Name}}-{{item.Code}} </li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div v-if="desc.Employeeradio==2">
				<span class="leftName"></span>
				<span class="rightName">
						<el-select :disabled="editShow" class="selectInput" style="width:140px"  v-model="desc.employeeOpen" :placeholder="R.ProcessEngine_list32">
						    <el-option
						      v-for="item in employeeOpenOptions"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
						  {{R.ProcessEngine_list38}}
						</span>
			</div>
			<div v-if="desc.Employeeradio==2">
				<span class="leftName"></span>
				<span class="rightName">
						{{R.ProcessEngine_list39}}
						<el-select  :disabled="editShow" class="selectInput" style="width:140px"  v-model="desc.employeeOpenUp" :placeholder="R.ProcessEngine_list32">
						    <el-option
						      v-for="item in employeeOpenOptionsUp"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
						{{R.ProcessEngine_list40}}
					</span>
			</div>
			<div v-if="desc.Employeeradio==2">
				<span class="leftName"></span>
				<span class="rightName">
					<el-checkbox  :disabled="editShow" v-model="desc.transUp">{{R.ProcessEngine_list41}}</el-checkbox>
				</span>
			</div>
			<div v-if="desc.Employeeradio==3" style="margin-bottom: 10px;height: 80px;">
				<span class="leftName" style="position: relative;top: -30px;">{{R.ProcessEngine_list42}}</span>
				<span class="rightName">
							<el-radio-group  :disabled="editShow" v-model="desc.rangeRadio">
							<li style="margin-top: 10px;display: flex;"><el-radio :label="1">{{R.ProcessEngine_list43}}</el-radio>
							<el-radio :label="2">{{R.ProcessEngine_list44}}</el-radio></li>
							<li style="margin-top: 10px;display: flex;"><el-radio :label="3">{{R.ProcessEngine_list45}}</el-radio><el-radio :label="4">{{R.ProcessEngine_list46}}</el-radio></li>
							<li style="display: flex;height: 30px;margin-top: 10px;"><el-radio :label="5">{{R.ProcessEngine_list47}}</el-radio>
								<OrganizationSelect
									v-if="desc.rangeRadio==5"
									:onlyloadEnableOrg="true"
									style="left: 20px;width: 210px;position: relative;line-height:28px;"
									:disabled="editShow" 
									:placeholder="R.ProcessEngine_list32"
									v-model="desc.OrgId" 
									:clearable ="false"
									:defaultValue = "desc.CorpDefaultValues"
								/> 
								<!-- <treeselect  :disabled="editShow" v-if="desc.rangeRadio==5" style="left: 20px;width: 210px;position: relative;height: 30px;line-height: 30px;" :flat='true' :noChildrenText='R.ProcessEngine_list31' :placeholder="R.ProcessEngine_list32"
									:clearable ="false"
									:noOptionsText="R.ProcessEngine_list31"
									:options="Topapi" 
									v-model="desc.OrgId" 
									:load-options="uprogloadOptions">
								<div slot="value-label" slot-scope="{node}">{{showTreeNodeLabel(node,desc.CorpDefaultValues)}}</div>
							</treeselect> -->
							<span class='red' v-if="desc.rangeRadio==5" style="margin-left:35px ;margin-top: 10px;">*</span>
							</li>
						</el-radio-group>
						</span>

			</div>
			<div v-if="desc.Employeeradio==3" v-loading='maskloadings' style="height: 280px;margin-top:30px;">
				<div class="table need-left">
					<div class="main-tt">
						<div class="one-table">
							<div class="head">
								{{R.ProcessEngine_list49}}
							</div>
							<ul class="main-table">
								<li v-for="(item,index) in mymenueEmployee" :key="index" :class="{actived: activeIndex == index}" @click="changecolor($event,index,'mymenusEmployee')">{{item.ItemTextCN}} <i class="el-icon-delete" v-if="item.Code=='FixedCharacter'" @click="deletemymenus(index,'mymenusEmployee')"></i></li>
							</ul>
						</div>
						<div class="btns">
							<el-button class="orange" :disabled="editShow" @click="turnleft('mymenusEmployee')">
								<img src="static/images/leftoo.png" alt="" />
							</el-button>
							<el-button class="orange" :disabled="editShow" @click="turnright('mymenusEmployee')">
								<img src="static/images/right00.png" alt="" />
							</el-button>
							<el-button :disabled="editShow" @click="resert('mymenuesEmployee')">{{R.Common_Button_Reset}}</el-button>
						</div>
						<div class="one-table">
							<div class="head">
								{{R.ProcessEngine_list50}}
							</div>
							<ul class="main-table" ref='parant'>
								<li v-for="(item,index) in mymenuesEmployee" :key="index" :class="{activesd: activeIndexs == index}" @click="changecolors($event,index,'mymenuesEmployee')" v-dragging="{ item: item, list: mymenuesEmployee, group: 'item' }">{{item.ItemTextCN}} </li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div v-if="desc.Employeeradio==2||desc.Employeeradio==3" style="margin-top:10px;height: 54px;">
				<span  style="position: relative;top: -22px;">{{R.ProcessEngine_list51}}</span>
				<span class="rightName">
							<el-radio-group  :disabled="editShow" v-model="desc.unnormalRadio">
							<li><el-radio :label="1">{{R.ProcessEngine_list52}}</el-radio> </li>
							<!--<li><el-radio :label="2">自动通过</el-radio></li>-->
							<li style="display: flex;margin-top: 10px;height: 30px;">
								<el-radio :label="3">{{R.ProcessEngine_list53}}</el-radio>
								<cj-Personnelselector  :disabled="editShow" v-if="desc.unnormalRadio==3" style="width:180px;position: relative;top: -5px;height: 30px;left: 20px;"  @optionShow="optionShow" @personnelShow="DrawAssetEmployeeShow"  :DrawAssetEmployeeIDs="desc.employeeId" :multiple="false" :IsFilterUser="false"></cj-Personnelselector>
								<span class="red" style="position: relative;top: 7px;left: 129px;" v-if="desc.unnormalRadio==3">*</span>
							</li>
						</el-radio-group>
						</span>
			</div>
			<div style="border-top: 1px solid #EEEEEE;">
				<span  style="line-height: 15px;">{{R.ProcessEngine_list54}}</span>
				<span class="rightName">
							<el-checkbox :disabled="editShow"  v-model="desc.openEmaillater">{{R.ProcessEngine_list55}}</el-checkbox>
						</span>
				</span>
			</div>
			<div>
				<span class="rightName">
				<el-radio-group :disabled="editShow"  v-model="desc.DeLradio">
				<li><el-radio :label="1">{{R.ProcessEngine_list73}} </el-radio>
				<el-select @change="changetypeOption" class="selectInput" style="margin-right: 10px;"  :disabled="editShow" v-model="desc.typeValue" :placeholder="R.ProcessEngine_list32">
			    <el-option
			      v-for="item in typeOption"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value"
			      :disabled="desc.DeLradio=='2'"
			    >
			    </el-option>
			  </el-select>
							<span v-if="desc.typeValue==1">{{R.ProcessEngine_list74}}:</span>
				<el-input @input='regtest1' style="width:150px" v-if="desc.typeValue==1" type='number' class="selectInput" min="0" max="100" :disabled="desc.DeLradio=='2'||editShow" :placeholder="R.ProcessEngine_list75" v-model="desc.percentage"></el-input>
				<span class="red" v-if="regtrue1">{{R.ProcessEngine_list76}}</span>
				<span v-if="desc.typeValue==2">{{R.ProcessEngine_list77}}</span>
				<el-input @input='regtest' v-if="desc.typeValue==2" type='number' class="selectInput" min="0" :disabled="desc.DeLradio=='2'||editShow" :placeholder="R.ProcessEngine_list78" v-model="desc.percentage"></el-input>
				<span class="red" v-if="regtrue">{{R.ProcessEngine_list79}}</span>
				</li>

				<li style="margin-top:10px">
					<el-radio :label="2">{{R.ProcessEngine_list80}}</el-radio>
				</li>
				</el-radio-group>
				</span>
			</div>
		</div>
		<div class="foots">
			<el-button style="padding-right: 20px;margin-left: 10px;" @click="closemask">{{R.Common_Button_Cancel}}</el-button>
			<el-button v-if="!editShow"  :disabled="editShow" style="padding-right: 20px;margin-left: 10px;" @click="sureAdd('huiqian')" class="orangetext add orangebd">{{R.Common_Button_Save}}</el-button>
		</div>
	</div>
	</div>
	<div class="content">
		<!--按钮-->
		<div class="head">
			<el-button @click="processSave" :disabled="btndisabled" v-if="getpermission('OperateApproval')">{{R.Common_Button_Save}}</el-button>
			<el-button @click="processSubmit" :disabled="btndisabled" class="orangetext add orangebd" v-if="getpermission('OperateApproval')">{{R.Common_Button_Commit}}</el-button>
			<el-popover style="margin-left: 10px;" placement="bottom" width="150" v-model="urlInto" trigger="click" v-if="getpermission('Cancel')||getpermission('Enabled')||getpermission('OperateApproval')">
				<div class="Printed" @click="EditShow" v-if="getpermission('OperateApproval')">{{R.ProcessEngine_list82}}</div>
				<div class="Printed" @click="delShow" v-if="getpermission('OperateApproval')">{{R.Common_Button_DeleteDraft}}</div>
				<div class="Printed" @click="CopyFlowShow" v-if="getpermission('OperateApproval')" :disabled="btndisabled">{{R.ProcessEngine_list84}}</div>
				<div class="Printed" @click="ToggleEnableShow" v-if="getpermission('Enabled')">{{IsEnabledTxt}}</div>
				<div class="Printed" @click="CancelShow" v-if="getpermission('Cancel')">{{R.ProcessEngine_list85}}</div>
				<el-button slot="reference" @blur="urlInto==false">{{R.Common_Button_Operation}}
					<i class="el-icon-caret-bottom" style="font-size: 16px;"></i>
				</el-button>
			</el-popover>
			<span>{{title}}</span>
		</div>
		<div style="position: absolute;top: 40px;left: 0;right: 0;bottom: 70px;">
			<process></process>
		</div>
		<!--底部栏-->
		<div style="position: absolute;padding-left:20px;bottom: 0;left: 0;right: 0;line-height: 60px;height: 60px;display: flex;align-items: center">

			<el-button @click="goBack"> <i class="el-icon-arrow-left"></i><span>{{R.Common_Button_BackToList}}</span></el-button>
		</div>
	</div>
	</div>
</template>

<script>
	import { debug } from 'util';
	import { constants } from 'fs';
	var R ={}
	export default {
		name: 'ProcessEngine',
		data() {
			return {
				billerOptions:[],
				Mydefaultvalue:0,
				addflag :false,
				IsPageDome:true,
				R:R,
				SubElasticFrameMask: false,
				employeeparams: {
					"UserID": '',
					"EmployeeID": '',
					"EmployeeName": "",
					"DeptName": "",
					"EMail": ""
				},
				btndisabled: false,
				reg: /^[0-9]*[1-9][0-9]*$/,
				regtrue: false,
				regtrue1: false,
				maskloading: false,
				maskloadings: false,
				process: true,
				loading: false,
				loadingSave: false,
				CropName: '',
				ProductName: '',
				productId:'',
				BillName: '',
				FlowName: '',
				FlowVersion: '',
				Remark: '',
				ApprovalForm: false,
				IsEnabledTxt: null,
				title: '',
				DelTitle: R.ProcessEngine_list88,
				DelElasticFrameMask: false,
				editShow: false,
				index: 0,
				desc: {
					billcode:'',
					name: '',
					branchName: R.ProcessEngine_list89,
					orderName: R.ProcessEngine_list90,
					huiqianName: R.ProcessEngine_list91,
					merageName: R.ProcessEngine_list92,
					desc: '',
					openEmail: false,
					Employeeradio: 1,
					OrgId: null,
					OrgName:'',
					// 已选项
					EmployeeOpenradio: 1,
					employeeOpen: 1,
					employeeOpenUp: 1,
					transUp: false,
					rangeRadio: 1,
					employeeId: '',
					unnormalRadio: 1,
					openEmaillater: false,
					addLine: false,
					DeLradio: 1,
					radio: 'XOrSplit',
					typeValue: 1,
					percentage: "",
					DeptDefaultValues: [],
					SupportedStrategies: [],
					CorpDefaultValues:[]
				},
				urlInto: false,
				maskhidden: false,
				branch: false,
				Order: false,
				addone: false,
				merage: false,
				huiqian: false,
				Topapi: [],
				activeIndex: 0,
				activeIndexs: 0,
				alllist: [],
				alllist1: [],
				alllist2: [],
				alllist3: [],

				// 可选项
				mymenus: [],
				employeeOpenOptions: [{
					value: 1,
					label: R.ProcessEngine_list93
				}, {
					value: 2,
					label: R.ProcessEngine_list94
				}],
				employeeOpenOptionsUp: [{
					value: 1,
					label: R.ProcessEngine_list95
				}, {
					value: 3,
					label: R.ProcessEngine_list96
				}, {
					value: 4,
					label: R.ProcessEngine_list97
				}, {
					value: 5,
					label: R.ProcessEngine_list98
				}, {
					value: 6,
					label: R.ProcessEngine_list99
				}, {
					value: 7,
					label: R.ProcessEngine_list100
				}, {
					value: 8,
					label: R.ProcessEngine_list101
				}, {
					value: 9,
					label: R.ProcessEngine_list102
				}, {
					value: 10,
					label: R.ProcessEngine_list103
				}, {
					value: 11,
					label: R.ProcessEngine_list104
				}],
				mymenueEmployee: [],
				typeOption: [{
					value: 1,
					label: R.ProcessEngine_list105
				}, {
					value: 2,
					label: R.ProcessEngine_list106
				}],
				searchSolution: {
					operator: [ //操作符
						{
							value: 1,
							supportedTypes: [1, 2, 3, 4, 5],
							label: R.ProcessEngine_list107
						}, //等于
						{
							value: 2,
							supportedTypes: [1, 2, 3, 4, 5],
							label: R.ProcessEngine_list108
						}, //不等于
						{
							value: 3,
							supportedTypes: [2, 3, 4],
							label: R.ProcessEngine_list109
						}, //大于
						{
							value: 4,
							supportedTypes: [2, 3, 4],
							label: R.ProcessEngine_list110
						}, //大于等于
						{
							value: 5,
							supportedTypes: [2, 3, 4],
							label: R.ProcessEngine_list111
						}, //小于
						{
							value: 6,
							supportedTypes: [2, 3, 4],
							label: R.ProcessEngine_list112
						}, //小于等于
						{
							value: 7,
							supportedTypes: [1, 6],
							label: R.ProcessEngine_list113
						}, //包含
						{
							value: 8,
							supportedTypes: [1, 6],
							label: R.ProcessEngine_list114
						}, //不包含
						{
							value: 9,
							supportedTypes: [1],
							label: R.ProcessEngine_list115
						}, //起始字符
						{
							value: 10,
							supportedTypes: [7],
							label: R.ProcessEngine_list116
						}, //包含明细
						{
							value: 11,
							supportedTypes: [7],
							label: R.ProcessEngine_list117
						}, //不包含明细
						{
							value: 12,
							supportedTypes: [7],
							label: R.ProcessEngine_list137
						}, //限定与分类
					],
					List: [{
						BillPropertyName: '',
						Operator: null,
						BillPropertyType: null,
						value: null,
						options: [{
							value: 1,
							label: R.ProcessEngine_list107
						}, {
							value: 2,
							label: R.ProcessEngine_list108
						}, {
							value: 3,
							label: R.ProcessEngine_list109
						}, {
							value: 4,
							label: R.ProcessEngine_list110
						}, {
							value: 5,
							label: R.ProcessEngine_list111
						}, {
							value: 6,
							label: R.ProcessEngine_list112
						}, {
							value: 7,
							label: R.ProcessEngine_list113
						}, {
							value: 8,
							label: R.ProcessEngine_list114
						}, {
							value: 9,
							label: R.ProcessEngine_list115
						}, {
							value: 10,
							label: R.ProcessEngine_list116
						}, {
							value: 11,
							label: R.ProcessEngine_list117
						}]
					}],
					selectIndex: 0,
					datasource: [],
					supplierDatasource: null,
					propertyRightCorpDatasource: null,
					OrgdataSource: null,
					Warehouse: null,
					Topapi: null,
					oldOrgId: '',
					oldOrgName:'',
					searchSolution: null,
					location: null,
					conditions: []
				},
				mymenues: [],
				mymenuesEmployee: [],
			}
		},
		watch: {
			//			OrgID(newval, old) {
			//				if(){
			//					
			//				}
			//				
			//			}
		},
		computed: {
			//			OrgID() {
			//				return this.desc.OrgId
			//			},
		},
		beforeDestroy(){
			this.IsPageDome=false;
		},
		destroyed(){
			this.$destroy()
			this.$el.remove()
		},
		methods: {
			showTreeNodeLabels(node, defaultValues) {
				if(node.raw.label) {
					// 有label属性，则表示节点数据已经找到
					return node.raw.label;
				} else {
					// 未找到节点，从defaultValues中查找
					if(defaultValues && defaultValues.length > 0) { 
						for(let i=0;i<defaultValues.length;i++){
							let dv =defaultValues[i];
							if(dv.id==node.id){
								if(this.$Store.get('lag')=="en"){
									return dv.label_EN;
								}else{
									return dv.label_CN;
								}
								
							}
						}     
					}
					//没找到
					return '';
				}
				//
				return node.raw.label;
			},
			changeOperator(value, index){
				for(var i = 0; i < this.searchSolution.datasource.length; i++) {
					if(this.searchSolution.datasource[i].BillPropertyName == value) {
						this.searchSolution.List[index].BillPropertyType = this.searchSolution.datasource[i].BillPropertyType
						this.searchSolution.List[index].PropertyControlType = this.searchSolution.datasource[i].PropertyControlType
						this.searchSolution.List[index].value = null;
						if(this.searchSolution.List[index].BillPropertyType == '6' && this.searchSolution.List[index].PropertyControlType == '9') {
							this.searchSolution.List[index].value = [];
						}
						this.searchSolution.List[index].DictItems = this.searchSolution.datasource[i].DictItems
					}
				}
				
			},
			changeIndex(data,index){
				this.searchSolution.List[index].defaultValueIds = data
			
			},
			changeIndexDefault(data,index){
				this.searchSolution.List[index].defaultValue = data
			},
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {
						if(code == this.permissionCode[i].Code) {
							return true
						}
					}
				} else {
					return false
				}

			},
			changetypeOption() {
				this.regtrue = false
				this.regtrue1 = false
				this.desc.percentage = ''
			},
			regtest() {
				if((this.desc.percentage == '' || this.desc.percentage <= 0 || !this.reg.test(this.desc.percentage)) && this.desc.typeValue == 2) {
					this.regtrue = true
				} else {
					this.regtrue = false
				}

			},
			regtest1() {
				if((this.desc.percentage == '' || this.desc.percentage <= 0 || this.desc.percentage > 100) && this.desc.typeValue == 1) {
					this.regtrue1 = true;
				} else {
					this.regtrue1 = false
				}
			},
			changeOrgID(node) {
				console.log(node)
				this.maskloading = true;
				this.desc.OrgName = node.label;
				console.log(this.desc.OrgName)
				this.$http.post('system:/Employee/List', {
					Data: {
						"PageNO": 1,
				        "PageSize": 99999,
				        "SortDirection": 1,
				        "QST": '',
				        "QSE": node.QSE,
						"CorpID": '',
						"IsFilterUser": ''
					}
				}).then(res => {
					if(res.data.Code = '0') {
						this.alllist1 = [];
						this.mymenues = this.mymenues;
						this.mymenus = res.data.Data.Items ? res.data.Data.Items : [];
						this.mymenus.filter((item) => {
							item.show = true;
						})
						this.maskloading = false;
					} else {
						
						this.maskloading = false;
					}
				});
			},
			//数值检查
			checkdDecimal(event) {
				this.addflag =false;
				if(event.target.value) {
					var pattent = /^(\d+)(.\d{0,2})?$/;
					console.log(pattent.test(event.target.value),event.target.value)
					if(!pattent.test(event.target.value)) {
						event.target.style.border = '1px solid red';
						this.addflag =true;
						return;
					}else{
						this.addflag =false;
					}
					let qty = event.target.value;
					if(qty < 0) {
						this.addflag =true;
						event.target.style.border = '1px solid red';
						return;
					} else {
						this.addflag =false;
						event.target.style.border = '';
					}
				} else {
					this.addflag =false;
					event.target.style.border = '';
				}
			},
			// titleShow(data){
			// 	this.title=data
			// },
			//易耗品分类懒加载
			loadconCateOptions({
				parentNode,
				callback
			}) {
				this.$http.post('consumables:/BasicData/Category/GetTree', {
					Data: {
						IsLazyLoad: true,
						ParentId: parentNode ? parentNode.id : null,
					}
				}).then((rsp) => {
					// 
					if(rsp.data.Code == 0) {
						if(parentNode) { //非根节点
							parentNode.children = rsp.data.Data.Items;
						}
						callback();
					} else {
						this.$message.error(rsp.data.Message);
					}
				})
			},
			// 延迟加载资产分类
			lazyLoadCategory({
				parentNode,
				callback
			}) {
				// 
				this.$http.post('asset:/BasicData/AssetCategory/GetTree', {
					Data: {
						Delay: true,
						ParentId: parentNode ? parentNode.id : null,
						OnlyLoadPrivilegeAssetCategory: true,
					}
				}).then((rsp) => {
					// 
					if(rsp.data.Code == 0) {
						if(parentNode) { //非根节点
							parentNode.children = rsp.data.Data.Items;
						} else { //根节点
							this.searchSolution.category = rsp.data.Data.Items;
						}
						callback();
					} else {
						this.$message.error(rsp.data.Message);
					}
				})
			},
			// 检索供应商（自动完成功能）
			searchSupplier(query) {
				if(!query) {
					return;
				}
				this.$http.post('asset:/BasicData/Supplier/List', {
					Data: {
						QST: query,
						PageSize: 20,
						PageNO: 1,
						IsShowDisabled: false
					}
				}).then((rsp) => {
					if(rsp.data.Code == 0) {
						this.searchSolution.supplierDatasource = rsp.data.Data.Items;
					} else {
						this.$message.error(rsp.data.Message);
					}
				})
			},
			//检索产权单位（自动完成功能）
			searchpropertyUnit(query) {
				if(!query || query.length < 2) {
					return;
				}
				this.$http.post('asset:/BasicData/UnitOfRight/List', {
					Data: {
						QST: query,
						PageSize: 20,
						PageNO: 1,
					}
				}).then((rsp) => {

					if(rsp.data.Code == 0) {
						this.searchSolution.propertyRightCorpDatasource = rsp.data.Data.Items;
					} else {
						this.$message.error(rsp.data.Message);
					}
				})
			},
			// 延迟加载存放位置
			lazyLoadLocation({
				parentNode,
				callback
			}) {
				// 
				this.$http.post('asset:/BasicData/Location/GetTree', {
					Data: {
						Delay: true,
						ParentId: parentNode ? parentNode.id : null,
						OnlyLoadPrivilegeLocation: true,
					}
				}).then((rsp) => {
					// 
					if(rsp.data.Code == 0) {
						if(parentNode) { //非根节点
							parentNode.children = rsp.data.Data.Items;
						} else { //根节点
							this.searchSolution.location = rsp.data.Data.Items;
						}
						callback();
					} else {
						this.$message.error(rsp.data.Message);
					}
				})
			},
			//条件盘点开关切换
			switchConditionCheckStatus(item) {

			},
			checkQty(event) {
				this.addflag =false;
				if(event.target.value) {
					var pattent = /^[1-9]\d*$/;
					if(!pattent.test(event.target.value)) {
						event.target.style.border = '1px solid red';
						this.addflag =true;
						return;
					}else{
						this.addflag =false;
					}
					let qty = event.target.value;
					if(qty < 1) {
						event.target.style.border = '1px solid red';
						this.addflag =true;
						return;
					} else {
						this.addflag =false;
						event.target.style.border = '';
					}
				} else {
					this.addflag =false;
					event.target.style.border = '';
				}
			},
			selectMYIndex(value, index) {
				for(var i = 0; i < this.searchSolution.datasource.length; i++) {
					if(this.searchSolution.datasource[i].BillPropertyName == value) {
						this.searchSolution.List[index].BillPropertyType = this.searchSolution.datasource[i].BillPropertyType
						this.searchSolution.List[index].PropertyControlType = this.searchSolution.datasource[i].PropertyControlType
						this.searchSolution.List[index].value = null;
						this.searchSolution.List[index].defaultValue = null;
						this.searchSolution.List[index].defaultValueIds = null;
						if(this.searchSolution.List[index].BillPropertyType == '6' && this.searchSolution.List[index].PropertyControlType == '9') {
							this.searchSolution.List[index].value = [];
						}
						this.searchSolution.List[index].DictItems = this.searchSolution.datasource[i].DictItems

						this.searchSolution.List[index].Operator = this.searchSolution.datasource[i].Operator
					}
				}
				var option = [];
				var oprt = this.searchSolution.operator;
				var BillPropertyType = this.searchSolution.List[index].BillPropertyType
				for(var i = 0; i < oprt.length; i++) {
					var result = oprt[i].supportedTypes.filter(x => x == BillPropertyType);
					if(result.length > 0){
						option.push({
							label: oprt[i].label,
							value: oprt[i].value
						});
					}
					
				}
				if(processlist.pselectedProcessEntity.ProductId=='6'){
					option = option.filter(item=>item.value!=12)
				}
				this.searchSolution.List[index].options = option
				this.myIndex = index
			},
			ShowselectMYIndex(value, index) {
				for(var i = 0; i < this.searchSolution.datasource.length; i++) {
					if(this.searchSolution.datasource[i].BillPropertyName == value) {
						this.searchSolution.List[index].BillPropertyType = this.searchSolution.datasource[i].BillPropertyType
						this.searchSolution.List[index].PropertyControlType = this.searchSolution.datasource[i].PropertyControlType
						this.searchSolution.List[index].DictItems = this.searchSolution.datasource[i].DictItems
					}
				}
				var option = [];
				var oprt = this.searchSolution.operator;
				console.log(this.searchSolution.operator)
				var BillPropertyType = this.searchSolution.List[index].BillPropertyType
				for(var i = 0; i < oprt.length; i++) {
					var result = oprt[i].supportedTypes.filter(x => x == BillPropertyType);
					if(result.length > 0)
						
						option.push({
							label: oprt[i].label,
							value: oprt[i].value
						});
				}
				
				this.searchSolution.List[index].options = option
				//				this.searchSolution.List[index].Operator = option[0].value
				this.myIndex = index
			},
			// 显示树节点选中项标签(支持延迟加载时的默认值)
			showTreeNodeLabel(node, defaultValues) {
				if(node.raw.label) {
					// 有label属性，则表示节点数据已经找到
					return node.raw.label;
				} else {
					// 未找到节点，从defaultValues中查找
					if(defaultValues) {
						console.log('hh')
						return defaultValues;
					}
					//没找到
					return '';
				}
				//
				return node.raw.label;
			},
			sureAdd(add) {
				var data = {}
				if(this.desc.unnormalRadio == '3' && (this.desc.employeeId == '' || this.desc.employeeId == null)) {
					this.$message.error(R.ProcessEngine_list118)
					return
				}
				if(add == "addone") {
					if(this.desc.name.trim() == '') {
						this.$message.error(R.ProcessEngine_list119)
						return
					}
					if(this.desc.SupportedStrategies.length == 0) {
						this.$message.error(R.ProcessEngine_list120)
						return
					}
					if(this.desc.Employeeradio == 1) {
						if(this.mymenues.length == 0) {
							this.$message.error(R.ProcessEngine_list121)
							return
						}
						console.log(this.desc.OrgId)
						if(!this.desc.OrgId||this.desc.OrgId ==''){
							this.$message.error(R.ProcessEngine_list135)
							return
						}
						var mymenuesList = []
						for(var i = 0; i < this.mymenues.length; i++) {
							var params = {
								"UserID": this.mymenues[i].UserID,
								"EmployeeID": this.mymenues[i].ID,
								"EmployeeName": this.mymenues[i].Name,
								"DeptName": this.mymenues[i].OrgName,
								"EMail": this.mymenues[i].Email
							}
							mymenuesList.push(params)
						}
						var page = {
							"Name": this.desc.name,
							"Remark": this.desc.desc,
							"IsEnabledEMailRemining": this.desc.openEmail,
							"IsEnabledEMailNotification": this.desc.openEmaillater,
							"FindCandidateApproverParameter": {
								"FoundMode": this.desc.Employeeradio,
								"CandidateApprovers": mymenuesList,
								"SpecifiedDept": {
									"OrgID": this.desc.OrgId,
									"OrgName": this.desc.OrgName
								},
								
							},
							"IsEnabledAdditionalApproval": this.desc.addLine,
							"SupportedStrategies": this.desc.SupportedStrategies,
						}
					}
					if(this.desc.Employeeradio == 2 && this.desc.EmployeeOpenradio == 1) {
						var page = {
							"Name": this.desc.name,
							"Remark": this.desc.desc,
							"IsEnabledEMailRemining": this.desc.openEmail,
							"IsEnabledEMailNotification": this.desc.openEmaillater,
							"FindCandidateApproverParameter": {
								"FoundMode": this.desc.Employeeradio,
								"FindManagerMode": this.desc.EmployeeOpenradio,
								"PromoterType": this.desc.employeeOpen,
								'ManagerLevel': this.desc.employeeOpenUp,
								'BySuperiorManagerIfNotFound': this.desc.transUp,
								'StrategyOfCandidateApproverNotFound': this.desc.unnormalRadio,
								'DeliverTo': this.employeeparams,
							},
							"IsEnabledAdditionalApproval": this.desc.addLine,
							"SupportedStrategies": this.desc.SupportedStrategies,
						}
					}
					if(this.desc.Employeeradio == 2 && this.desc.EmployeeOpenradio == 2) {
						if(!this.desc.OrgId||this.desc.OrgId ==''){
							this.$message.error(R.ProcessEngine_list135)
							return
						}
						
						var page = {
							"Name": this.desc.name,
							"Remark": this.desc.desc,
							"IsEnabledEMailRemining": this.desc.openEmail,
							"IsEnabledEMailNotification": this.desc.openEmaillater,
							"FindCandidateApproverParameter": {
								"FoundMode": this.desc.Employeeradio,
								"FindManagerMode": this.desc.EmployeeOpenradio,
								"SpecifiedDept": {
									"OrgID": this.desc.OrgId,
									"OrgName": this.desc.OrgName
								},
								'StrategyOfCandidateApproverNotFound': this.desc.unnormalRadio,
								'DeliverTo': this.employeeparams,
							},
							"IsEnabledAdditionalApproval": this.desc.addLine,
							"SupportedStrategies": this.desc.SupportedStrategies,
						}
					}
					if(this.desc.Employeeradio == 3) {
						if((!this.desc.OrgId||this.desc.OrgId =='')&&this.desc.rangeRadio==5){
							this.$message.error(R.ProcessEngine_list135)
							return
						}
						if(this.mymenuesEmployee.length == 0) {
							this.$message.error('请选择职位')
							return
						}
						var Jobs = [];
						for(var i = 0; i < this.mymenuesEmployee.length; i++) {
							var params = {
								"JobID": this.mymenuesEmployee[i].ID,
								"JobName": this.mymenuesEmployee[i].ItemTextCN,
							}
							Jobs.push(params)
						}
						var page = {
							"Name": this.desc.name,
							"Remark": this.desc.desc,
							"IsEnabledEMailRemining": this.desc.openEmail,
							"IsEnabledEMailNotification": this.desc.openEmaillater,
							"FindCandidateApproverParameter": {
								"FoundMode": this.desc.Employeeradio,
								"OrgSpecifiedType": this.desc.rangeRadio,
								"SpecifiedOrg": {
									"OrgID": this.desc.OrgId,
									"OrgName": this.desc.OrgName
								},
								'Jobs': Jobs,
								'StrategyOfCandidateApproverNotFound': this.desc.unnormalRadio,
								'DeliverTo': this.employeeparams,
							},
							"IsEnabledAdditionalApproval": this.desc.addLine,
							"SupportedStrategies": this.desc.SupportedStrategies,
						}
					}else if(this.desc.Employeeradio == 4){
						var page = {
							"Name": this.desc.name,
							"Remark": this.desc.desc,
							"IsEnabledEMailRemining": this.desc.openEmail,
							"IsEnabledEMailNotification": this.desc.openEmaillater,
							"FindCandidateApproverParameter": {
								"FoundMode": this.desc.Employeeradio,
								'BillRelatedEmployeeCode':this.desc.billcode,
							},
							"IsEnabledAdditionalApproval": this.desc.addLine,
							"SupportedStrategies": this.desc.SupportedStrategies,
						}
					}
					activityproperty.saveActivity(page)
				} else if(add == "huiqian") {
					if(this.desc.name.trim() == '') {
						this.$message.error(R.ProcessEngine_list119)
						return
					}
					if(this.desc.percentage.trim() == '' && this.desc.DeLradio == 1 && this.desc.typeValue == 1) {
						this.$message.error(R.ProcessEngine_list122);
						return
					}
					if(this.desc.percentage.trim() == '' && this.desc.DeLradio == 1 && this.desc.typeValue == 2) {
						this.$message.error(R.ProcessEngine_list78);
						return
					}
					if(this.regtrue1){
						this.$message.error(R.ProcessEngine_list76);
						return
					}
					if((this.desc.percentage > 100 || this.desc.percentage <= 0) && this.desc.typeValue == 1 && this.DeLradio == 1) {
						this.$message.error(R.ProcessEngine_list76);
						return
					}
					if((this.desc.percentage <= 0 || !this.reg.test(this.desc.percentage)) && this.desc.typeValue == 2 && this.DeLradio == 1) {
						this.$message.error(R.ProcessEngine_list79);
						return
					}
					var option1 = {
						type: 'MultipleInstanceNode',
						complexType: 'SignTogether',
						mergeType: 'Parallel',
						compareType: this.desc.DeLradio == 2 ? 'Count' : (this.desc.typeValue == 1 ? 'Percentage' : 'Count'),
						completeOrder: this.desc.DeLradio == 2 ? 100 : (this.desc.typeValue == 1 ? this.desc.percentage : this.desc.percentage * 100),
					}
					if(this.desc.Employeeradio == 1) {
						if(!this.desc.OrgId||this.desc.OrgId ==''){
							this.$message.error(R.ProcessEngine_list135)
							return
						}
						if(this.mymenues.length == 0) {
							this.$message.error(R.ProcessEngine_list121)
							return
						}
						var mymenuesList = []
						for(var i = 0; i < this.mymenues.length; i++) {
							var params = {
								"UserID": this.mymenues[i].UserID,
								"EmployeeID": this.mymenues[i].ID,
								"EmployeeName": this.mymenues[i].Name,
								"DeptName": this.mymenues[i].OrgName,
								"EMail": this.mymenues[i].Email
							}
							mymenuesList.push(params)
						}
						var page = {
							"Name": this.desc.name,
							"Remark": this.desc.desc,
							"IsEnabledEMailRemining": this.desc.openEmail,
							"IsEnabledEMailNotification": this.desc.openEmaillater,
							"FindCandidateApproverParameter": {
								"FoundMode": this.desc.Employeeradio,
								"CandidateApprovers": mymenuesList,
								"SpecifiedDept": {
									"OrgID": this.desc.OrgId,
									"OrgName": this.desc.OrgName
								},
								//								:desc.CorpDefaultValues 插眼
							},
							"IsEnabledAdditionalApproval": this.desc.addLine,
							"CounterSignMode": {
								"Strategy": this.desc.DeLradio
							},
							"CounterSignMode": {
								"Strategy": this.desc.DeLradio,
								"PassingRateType": this.desc.typeValue,
								"PassingRateValue": this.desc.percentage,
							}
						}
					} else if(this.desc.Employeeradio == 2) {
						var page = {
							"Name": this.desc.name,
							"Remark": this.desc.desc,
							"IsEnabledEMailRemining": this.desc.openEmail,
							"IsEnabledEMailNotification": this.desc.openEmaillater,
							"FindCandidateApproverParameter": {
								"FoundMode": this.desc.Employeeradio,
								"FindManagerMode": this.desc.EmployeeOpenradio,
								"PromoterType": this.desc.employeeOpen,
								'ManagerLevel': this.desc.employeeOpenUp,
								'BySuperiorManagerIfNotFound': this.desc.transUp,
								'StrategyOfCandidateApproverNotFound': this.desc.unnormalRadio,
								'DeliverTo': this.employeeparams,
								"SpecifiedDept": {
									"OrgID": this.desc.OrgId,
									"OrgName": this.desc.OrgName
								},
							},
							"IsEnabledAdditionalApproval": this.desc.addLine,
							"CounterSignMode": {
								"Strategy": this.desc.DeLradio
							},
							"CounterSignMode": {
								"Strategy": this.desc.DeLradio,
								"PassingRateType": this.desc.typeValue,
								"PassingRateValue": this.desc.percentage,
							}
						}
					} else if(this.desc.Employeeradio == 3) {
						if( (!this.desc.OrgId||this.desc.OrgId =='')&&this.desc.rangeRadio==5){
							this.$message.error(R.ProcessEngine_list135)
							return
						}
						if(this.mymenuesEmployee.length == 0) {
							this.$message.error(R.ProcessEngine_list123)
							return
						}
						var Jobs = [];
						for(var i = 0; i < this.mymenuesEmployee.length; i++) {
							var params = {
								"JobID": this.mymenuesEmployee[i].ID,
								"JobName": this.mymenuesEmployee[i].ItemTextCN,
							}
							Jobs.push(params)
						}
						var page = {
							"Name": this.desc.name,
							"Remark": this.desc.desc,
							"IsEnabledEMailRemining": this.desc.openEmail,
							"IsEnabledEMailNotification": this.desc.openEmaillater,
							"FindCandidateApproverParameter": {
								"FoundMode": this.desc.Employeeradio,
								"OrgSpecifiedType": this.desc.rangeRadio,
								"SpecifiedOrg": {
									"OrgID": this.desc.OrgId,
									"OrgName": this.desc.OrgName
								},
								'Jobs': Jobs,
								'StrategyOfCandidateApproverNotFound': this.desc.unnormalRadio,
								'DeliverTo': this.employeeparams,
							},
							"IsEnabledAdditionalApproval": this.desc.addLine,
							"CounterSignMode": {
								"Strategy": this.desc.DeLradio
							},
							"CounterSignMode": {
								"Strategy": this.desc.DeLradio,
								"PassingRateType": this.desc.typeValue,
								"PassingRateValue": this.desc.percentage,
							}
						}
					}else if(this.desc.Employeeradio == 4){
						var page = {
							"Name": this.desc.name,
							"Remark": this.desc.desc,
							"IsEnabledEMailRemining": this.desc.openEmail,
							"IsEnabledEMailNotification": this.desc.openEmaillater,
							"FindCandidateApproverParameter": {
								"FoundMode": this.desc.Employeeradio,
								'BillRelatedEmployeeCode':this.desc.billcode,
							},
							"IsEnabledAdditionalApproval": this.desc.addLine,
							"CounterSignMode": {
								"Strategy": this.desc.DeLradio
							},
							"CounterSignMode": {
								"Strategy": this.desc.DeLradio,
								"PassingRateType": this.desc.typeValue,
								"PassingRateValue": this.desc.percentage,
							},
							"SupportedStrategies": this.desc.SupportedStrategies,
						}
					}
					activityproperty.saveActivity(page, option1)
				} else if(add == 'merage') {
					var page = {
						"Name": this.desc.name,
						"Remark": this.desc.desc,
						'gatewayDirection': this.desc.radio
					}
					activityproperty.saveActivity(page)
				} else if(add == 'branch') {
					var page = {
						"Name": this.desc.name,
						"Remark": this.desc.desc,
						'gatewayDirection': this.desc.radio
					}
					activityproperty.saveActivity(page)
				}

				this.closemask()
			},
			sureAddLine() {
				if(this.addflag){
					this.$message.error(R.ProcessEngine_list136)
					return
				}
				var conditions = [];
				for(var i = 0; i < this.searchSolution.List.length; i++) {
					if(this.searchSolution.List[i].BillPropertyName == '' || this.searchSolution.List[i].BillPropertyName == null || this.searchSolution.List[i].Operator == null || this.searchSolution.List[i].Operator == '' || (this.searchSolution.List[i].value == null && this.searchSolution.List[i].defaultValue == null) || (this.searchSolution.List[i].value == '' && this.searchSolution.List[i].defaultValue =='')) {
						this.$message.error(R.ProcessEngine_list124)
						return;
					}
					var params = {
						"BillPropertyName": this.searchSolution.List[i].BillPropertyName,
						"Operator": this.searchSolution.List[i].Operator,
						"Values": [],
						'BillPropertyType': this.searchSolution.List[i].BillPropertyType,
					}
					if(this.searchSolution.List[i].defaultValue&&this.searchSolution.List[i].defaultValue!=null&&this.searchSolution.List[i].defaultValue!=''){
						params.defaultValue  = this.searchSolution.List[i].defaultValue
					}
					if(params.defaultValue){
						if(params.defaultValue.constructor === Array) {
						for(var j = 0; j < params.defaultValue.length; j++) {
							console.log(params.defaultValue[j])
							params.Values.push({
								"Value": params.defaultValue[j].id,
							})
						}
					} else {
							params.Values.push({
								"Value": params.defaultValue.id,
							})
						
						
					}
					}else{
						if(this.searchSolution.List[i].value.constructor === Array) {
						for(var j = 0; j < this.searchSolution.List[i].value.length; j++) {
							params.Values.push({
								"Value": this.searchSolution.List[i].value[j],
							})
						}
					} else {
							params.Values.push({
								"Value": this.searchSolution.List[i].value,
							})
						
						
					}
					}
					conditions.push(params)
				}
				console.log(conditions)
				var data = {
					sorce: conditions,
					data: {
						Name: this.desc.name,
						Remark: this.desc.desc,
						List: this.searchSolution.List
					}
				}
				transitionproperty.save(data)
				this.closemask()
			},
			showTabsByActivityType(activity) {
				$(function() {
					//load activity information
					kresource.localize();
					activityproperty.loadActivity();
				});
				var type = activity.type || activity.condition.type;
				if(type === "TaskNode") {
					this.openaddone(activity)
				} else if(type === "GatebranchNode") {
					this.openbranch(activity)
				} else if(type === "GatewayNode") {
					this.openMerage(activity)
				} else if(type === "MultipleInstanceNode") {
					this.openhuiqian(activity)
				} else if(type === "Expression") {
					this.openOrder(activity);
				} else {
					return false;
				}
			},
			activityShow(data) {
				this.showTabsByActivityType(data);
			},

			goBack() {
				this.$router.go(-1)
			},
			openMerage(activity) {
				if(activity.description != '' && activity.description != undefined && activity.description != null) {
					var page = activity
					this.desc.name = page.name;
					this.desc.desc = page.description.trim().replace(/\"/g, "");
					this.desc.radio = 'XOrJoin';
				} else {
					this.desc.name = activity.name == 'undefined' ?R.ProcessEngine_list92: activity.name;
				}
				this.maskhidden = true;
				this.merage = true;
			},
			openhuiqian(activity) {
				this.desc.OrgId = this.oldOrgId
				this.desc.CorpDefaultValues = [{id: this.oldOrgId,label:this.oldOrgName}]
				this.maskloading = true;
				this.maskloadings = true;
				if(activity.description != '' && activity.description && activity.description != null && activity.description != 'null') {
					var option = JSON.parse(activity.description);
					this.desc.desc = option.Remark ? option.Remark : '';
					this.desc.name = option.Name ? option.Name : '';
					this.desc.openEmail = option.IsEnabledEMailRemining;
					this.desc.openEmaillater = option.IsEnabledEMailNotification;
					this.desc.Employeeradio = option.FindCandidateApproverParameter.FoundMode ? option.FindCandidateApproverParameter.FoundMode : 1;
					var menus = option.FindCandidateApproverParameter.CandidateApprovers ? (option.FindCandidateApproverParameter.CandidateApprovers == null ? [] : option.FindCandidateApproverParameter.CandidateApprovers) : (option.FindCandidateApproverParameter.CandidateApprovers == undefined ? [] : option.FindCandidateApproverParameter.CandidateApprovers);
					this.desc.addLine = option.IsEnabledAdditionalApproval;
					this.desc.DeLradio = option.CounterSignMode.Strategy;
					this.desc.DeLradio = option.CounterSignMode.Strategy;
					this.desc.typeValue = option.CounterSignMode.PassingRateType;
					this.desc.percentage = option.CounterSignMode.PassingRateValue;
					this.desc.EmployeeOpenradio = option.FindCandidateApproverParameter.FindManagerMode ? option.FindCandidateApproverParameter.FindManagerMode : 1;
					this.desc.rangeRadio = option.FindCandidateApproverParameter.PromoterType ? option.FindCandidateApproverParameter.PromoterType : 1;
					this.desc.employeeOpenUp = option.FindCandidateApproverParameter.ManagerLevel;
					this.desc.transUp = option.FindCandidateApproverParameter.BySuperiorManagerIfNotFound;
					this.desc.unnormalRadio = option.FindCandidateApproverParameter.StrategyOfCandidateApproverNotFound ? option.FindCandidateApproverParameter.StrategyOfCandidateApproverNotFound : 1;
					this.desc.employeeId = option.FindCandidateApproverParameter.DeliverTo ? option.FindCandidateApproverParameter.DeliverTo.EmployeeID : '';
					this.employeeparams = option.FindCandidateApproverParameter.DeliverTo ?option.FindCandidateApproverParameter.DeliverTo :{
					"UserID": '',
					"EmployeeID": '',
					"EmployeeName": "",
					"DeptName": "",
					"EMail": ""
					};
					this.desc.employeeOpen = option.FindCandidateApproverParameter.PromoterType ? option.FindCandidateApproverParameter.PromoterType : 1;
					if(option.FindCandidateApproverParameter.FoundMode == 1 || option.FindCandidateApproverParameter.FoundMode == 2) {
						this.desc.OrgId = option.FindCandidateApproverParameter.SpecifiedDept.OrgID;
						this.desc.OrgName = option.FindCandidateApproverParameter.SpecifiedDept.OrgName;
					}
					if(option.FindCandidateApproverParameter.FoundMode == 3) {
						this.desc.rangeRadio = option.FindCandidateApproverParameter.OrgSpecifiedType;
						this.desc.OrgId = option.FindCandidateApproverParameter.SpecifiedOrg.OrgID;
						this.desc.OrgName = option.FindCandidateApproverParameter.SpecifiedOrg.OrgName;
						var Jobs = option.FindCandidateApproverParameter.Jobs;
						this.mymenuesEmployee = []
						for(var i = 0; i < Jobs.length; i++) {
							var params = {
								"ID": Jobs[i].JobID,
								"ItemTextCN": Jobs[i].JobName,
							}
							this.mymenuesEmployee.push(params)
						}
					}
					if(option.FindCandidateApproverParameter.FoundMode == 4){
						this.desc.billcode = option.FindCandidateApproverParameter.BillRelatedEmployeeCode
					}
					this.mymenues = [];
					for(var i = 0; i < menus.length; i++) {
						var params = {
							"UserID": menus[i].UserID,
							"ID": menus[i].EmployeeID,
							"Name": menus[i].EmployeeName,
							"OrgName": menus[i].DeptName,
							"Email": menus[i].EMail
						}
						this.mymenues.push(params)
					}
					this.desc.CorpDefaultValues = this.desc.OrgName?[{id: this.desc.OrgId,label:this.desc.OrgName}]:[]
					this.$http.post('system:/Employee/List', {
						Data: {
							"PageNO": 1,
					        "PageSize": 99999,
					        "SortDirection": 1,
					        "QST": '',
					        "QSE": '',
							"CorpID": this.desc.OrgId,
							"IsFilterUser": ''
						}
					}).then(res => {
						if(res.data.Code == '0') {
							var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
								for(var i = 0; i < opt.length; i++) {
									opt[i].noshow = false
									for(var j = 0; j < this.mymenues.length; j++) {
										if(opt[i].ID == this.mymenues[j].one) {
											opt[i].noshow = true
										}
									}
								}
								opt.filter((item,index) => {
									this.mymenues.filter(one =>{
										if(item.ID == one.ID){
											opt[index].noshow = true;
										}
									})
								})
							var arr =[];
							for(var i = 0;i<opt.length;i++){
								if(opt[i].noshow==false){
									arr.push(opt[i])
								}
							}
							this.mymenus = arr;
							this.maskloading = false;
						} else {
							this.maskloading = false;
							
						}

					});
					this.$http.post('system:/Dictionary/GetByCode', {    
						Data: "D007"
					}).then(res => {
						if(res.data.Code == '0') {
							var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
							if(this.mymenuesEmployee.length>1){
								for(var i = 0; i < opt.length; i++) {
									opt[i].noshow = false
									for(var j = 0; j < this.mymenuesEmployee.length; j++) {
										if(opt[i].ID == this.mymenuesEmployee[j].ID) {
											opt[i].noshow = true;
										}
									}
								}
							}
							var arr =[];
							for(var i = 0;i<opt.length;i++){
								if(opt[i].noshow==false){
									arr.push(opt[i])
								}
							}
							this.mymenueEmployee = arr
							this.maskloadings = false;
						} else {
							this.maskloadings = false;
							
						}
					});

				} else {
					this.$http.post('system:/Employee/List', {
						Data: {
							"PageNO": 1,
					        "PageSize": 99999,
					        "SortDirection": 1,
					        "QST": '',
					        "QSE": '',
							"CorpID": this.desc.OrgId,
							"IsFilterUser": ''
						}
					}).then(res => {
						if(res.data.Code == '0') {
							var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
							this.mymenus = opt;
							this.maskloading = false;
						} else {
							this.maskloading = false;
							
						}

					});
					this.$http.post('system:/Dictionary/GetByCode', {    
						Data: "D007"
					}).then(res => {
						if(res.data.Code == '0') {
							var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
							opt.filter(item => {
								return item.show = true
							})
							this.mymenueEmployee = opt
							this.maskloadings = false;
						} else {
							this.maskloadings = false;
							
						}
					});

				}
				this.maskhidden = true;
				this.huiqian = true;
				this.desc.name = activity.name
			},
			openOrder(activity) {
				this.$http.post('consumables:/BasicData/Category/GetTree', {
					Data: {
						ParentId: null,
						IsLazyLoad: true,
					}
				}).then(data => {
					if(data.data.Code == '0') {
						this.searchSolution.Topapi = data.data.Data.Items
					} else {
						this.$message.error(data.data.Message)
					}
				})
				this.$http.post('/BillApproval/BillData/GetMetadata', {
					Data: {
						"ProductId": processlist.pselectedProcessEntity.ProductId,
						/*产品id*/
						"BillType": processlist.pselectedProcessEntity.BillType /*单据类型*/
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.searchSolution.datasource = res.data.Data;
						this.desc.desc = R.ProcessEngine_list125;
						this.desc.name = R.ProcessEngine_list125;
						if(activity.condition.text.trim() != '' && activity.condition.text != undefined && activity.condition.text != null) {
							var option = JSON.parse(activity.condition.text.trim());
							this.desc.name = activity.name;
							this.desc.desc = activity.name;
							this.searchSolution.List = [];
							for(var i = 0; i < option.length; i++) {
								var params = {
									BillPropertyName: option[i].BillPropertyName,
									"Operator": option[i].Operator,
									"value": null,
									'BillPropertyType': option[i].BillPropertyType,
								}
								if(option[i].defaultValue){
									params.defaultValue = option[i].defaultValue;
									params.defaultValueIds=[]
									for(var j = 0;j<option[i].defaultValue.length;j++){
										params.defaultValueIds.push(option[i].defaultValue[j].id)
									}
								}
								if(option[i].Values.length > 1) {
									params.value = []
									for(var j = 0; j < option[i].Values.length; j++) {
										params.value.push(option[i].Values[j].Value)
									}
								} else if(option[i].Values.length ==1){
									if(option[i].BillPropertyType == '6'||option[i].Operator=='12') {
										params.value = []
										params.value.push(option[i].Values[0].Value)
									} else {
										params.value = option[i].Values[0].Value
									}
								}else{
									if(option[i].BillPropertyType == '6'||option[i].Operator=='12') {
										params.value = []
									} else {
										params.value = ''
									}
								}
								this.searchSolution.List.push(params);
								this.ShowselectMYIndex(option[i].BillPropertyName, i);

							}
						}
						this.maskhidden = true;
						this.Order = true;
					} else {
						
						this.maskhidden = true;
						this.Order = true;
					}
				})

				//插眼
				this.$http.post('consumables:/Warehouse/GetTree', {
					Data: {
						IsEnableDataPermission: true,
						IsDisableOrgSelect: true
					}
				}).then(res => {
					if(res.data.Code == 0) {
						this.searchSolution.Warehouse = res.data.Data
					} else {
						alert(res.data.Message)
					}
				})

			},
			openbranch(activity) {
				if(activity.description != '' && activity.description != undefined && activity.description != null) {
					var page = activity
					this.desc.name = page.name;
					this.desc.desc = page.description.trim().replace(/\"/g, "");
					this.desc.radio = "XOrSplit";
				} else {
					this.desc.name = activity.name == 'undefined' ? R.ProcessEngine_list89 : activity.name;
				}
				this.maskhidden = true;
				this.branch = true;
			},
			openaddone(activity) {
				this.desc.OrgId = this.oldOrgId
				this.desc.CorpDefaultValues = [{id: this.oldOrgId,label:this.oldOrgName}]
				this.maskloading = true;
				this.maskloadings = true;
				if(activity.description != '' && activity.description) {
					var option = JSON.parse(activity.description);
					this.desc.desc = option.Remark ? option.Remark : '';
					this.desc.name = option.Name ? option.Name : '';
					this.desc.openEmail = option.IsEnabledEMailRemining;
					this.desc.openEmaillater = option.IsEnabledEMailNotification;
					this.desc.Employeeradio = option.FindCandidateApproverParameter.FoundMode ? option.FindCandidateApproverParameter.FoundMode : 1;
					var menus = option.FindCandidateApproverParameter.CandidateApprovers ? (option.FindCandidateApproverParameter.CandidateApprovers == null ? [] : option.FindCandidateApproverParameter.CandidateApprovers) : (option.FindCandidateApproverParameter.CandidateApprovers == undefined ? [] : option.FindCandidateApproverParameter.CandidateApprovers);
					this.desc.addLine = option.IsEnabledAdditionalApproval;
					this.desc.SupportedStrategies = option.SupportedStrategies;
					this.desc.EmployeeOpenradio = option.FindCandidateApproverParameter.FindManagerMode ? option.FindCandidateApproverParameter.FindManagerMode : 1;
					this.desc.rangeRadio = option.FindCandidateApproverParameter.PromoterType ? option.FindCandidateApproverParameter.PromoterType : 1;
					this.desc.employeeOpenUp = option.FindCandidateApproverParameter.ManagerLevel;
					this.desc.transUp = option.FindCandidateApproverParameter.BySuperiorManagerIfNotFound;
					this.desc.unnormalRadio = option.FindCandidateApproverParameter.StrategyOfCandidateApproverNotFound ? option.FindCandidateApproverParameter.StrategyOfCandidateApproverNotFound : 1;
					this.desc.employeeId = option.FindCandidateApproverParameter.DeliverTo ? option.FindCandidateApproverParameter.DeliverTo.EmployeeID : '';
					this.mymenues = [];
					this.desc.employeeOpen = option.FindCandidateApproverParameter.PromoterType ? option.FindCandidateApproverParameter.PromoterType : 1;
					this.employeeparams = option.FindCandidateApproverParameter.DeliverTo ?option.FindCandidateApproverParameter.DeliverTo :{
						"UserID": '',
						"EmployeeID": '',
						"EmployeeName": "",
						"DeptName": "",
						"EMail": ""
						};
					for(var i = 0; i < menus.length; i++) {
						var params = {
							"UserID": menus[i].UserID,
							"ID": menus[i].EmployeeID,
							"Name": menus[i].EmployeeName,
							"OrgName": menus[i].DeptName,
							"Email": menus[i].EMail
						}
						this.mymenues.push(params)
					}
					if(option.FindCandidateApproverParameter.FoundMode == 1 || (option.FindCandidateApproverParameter.FoundMode == 2 && this.desc.EmployeeOpenradio == 2)) {
						this.desc.OrgId = option.FindCandidateApproverParameter.SpecifiedDept.OrgID;
						this.desc.OrgName = option.FindCandidateApproverParameter.SpecifiedDept.OrgName;
					}
					if(option.FindCandidateApproverParameter.FoundMode == 3) {
						this.desc.rangeRadio = option.FindCandidateApproverParameter.OrgSpecifiedType;
						this.desc.OrgId = option.FindCandidateApproverParameter.SpecifiedOrg.OrgID;
						this.desc.OrgName = option.FindCandidateApproverParameter.SpecifiedOrg.OrgName;
						var Jobs = option.FindCandidateApproverParameter.Jobs;
						this.mymenuesEmployee = []
						for(var i = 0; i < Jobs.length; i++) {
							var params = {
								"ID": Jobs[i].JobID,
								"ItemTextCN": Jobs[i].JobName,
							}
							this.mymenuesEmployee.push(params)
						}
						
					}
					if(option.FindCandidateApproverParameter.FoundMode == 4){
						this.desc.billcode = option.FindCandidateApproverParameter.BillRelatedEmployeeCode
					}
					this.desc.CorpDefaultValues = this.desc.OrgName?[{id: this.desc.OrgId,label:this.desc.OrgName}]:[]
					this.$http.post('system:/Employee/List', {
						Data: {
							"PageNO": 1,
					        "PageSize": 99999,
					        "SortDirection": 1,
					        "QST": '',
					        "QSE": '',
							"CorpID": this.desc.OrgId,
							"IsFilterUser": ''
						}
					}).then(res => {
						if(res.data.Code == '0') {
							var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
							opt.filter((item,index)=>{
								opt[index].noshow = false;
								this.mymenues.filter((one,index1)=>{
									if(item.ID == one.ID){
										opt[index].noshow = true
									}
								})
							})
							var arr =[];
							for(var i = 0;i<opt.length;i++){
								if(opt[i].noshow==false){
									arr.push(opt[i])
								}
							}
							this.mymenus = arr;
							this.maskloading = false;
						} else {
							this.maskloading = false;
						}
					});
					this.$http.post('system:/Dictionary/GetByCode', {    
						Data: "D007"
					}).then(res => {
						if(res.data.Code == '0') {
							var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
							for(var i = 0; i < opt.length; i++) {
								opt[i].noshow = false
								for(var j = 0; j < this.mymenuesEmployee.length; j++) {
									if(opt[i].ID == this.mymenuesEmployee[j].ID) {
										opt[i].noshow = true
									}
								}
							}
							var arr =[];
							for(var i = 0;i<opt.length;i++){
								if(opt[i].noshow==false){
									arr.push(opt[i])
								}
							}
							this.mymenueEmployee = arr;
							this.maskloadings = false;

						} else {
							
							this.maskloadings = false;
						}
					});

				} else {
					this.$http.post('system:/Employee/List', {
						Data: {
							"PageNO": 1,
					        "PageSize": 99999,
					        "SortDirection": 1,
					        "QST": '',
					        "QSE": '',
							"CorpID": this.desc.OrgId,
							"IsFilterUser": ''
						}
					}).then(res => {
						if(res.data.Code == '0') {
							var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
							opt.filter(item => {
								return item.show = true
							})
							this.mymenus = opt
							this.maskloading = false;
						} else {
							
							this.maskloadings = false;
						}
					});
					this.$http.post('system:/Dictionary/GetByCode', {
						Data: "D007"
					}).then(res => {
						if(res.data.Code == '0') {
							var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
							opt.filter(item => {
								return item.show = true
							})
							this.mymenueEmployee = opt
							this.maskloadings = false;
						} else {
							
							this.maskloadings = false;
						}
					});

				}

				this.maskhidden = true;
				this.addone = true;
				//				this.desc.name = '设置审批节点'
				this.desc.name = activity.name
			},
			changecolor(e, index) {
				this.activeIndex = index;
			},
			changecolors(e, index) {
				this.activeIndexs = index;
			},
			turnright(name) {

				if(name) {
					if(this.mymenueEmployee.length == 0) {
						return
					}
					if(this.activeIndex == this.mymenueEmployee.length) {
						return
					}
					this.mymenuesEmployee.push(this.mymenueEmployee[this.activeIndex])
					this.mymenueEmployee.splice(this.activeIndex, 1);
					return
				}
				if(this.mymenus.length == 0) {
					return
				}
				if(this.activeIndex == this.mymenus.length) {
					return
				}
				for(let i=0;i<this.mymenues.length;i++){
					if((this.mymenus[this.activeIndex].Name+'-'+this.mymenus[this.activeIndex].Code)==(this.mymenues[i].Name+'-'+this.mymenues[i].Code)){
						this.$message.error(R.ProcessEngine_list140)
						return false
					}
				}
				this.mymenues.push(this.mymenus[this.activeIndex])
				this.mymenus.splice(this.activeIndex, 1);
			},
			turnleft(name) {
				if(name) {
					if(this.mymenuesEmployee.length == 0) {
						return
					}
					if(this.activeIndexs == this.mymenuesEmployee.length) {
						return
					}
					this.mymenueEmployee.push(this.mymenuesEmployee[this.activeIndexs])
					this.mymenuesEmployee.splice(this.activeIndexs, 1);
					return
				}
				if(this.mymenues.length == 0) {
					return
				}
				if(this.activeIndexs == this.mymenues.length) {
					return
				}
				for(let i=0;i<this.mymenus.length;i++){
					if((this.mymenues[this.activeIndexs].Name+'-'+this.mymenues[this.activeIndexs].Code)==(this.mymenus[i].Name+'-'+this.mymenus[i].Code)){
						this.mymenues.splice(this.activeIndexs, 1);
						return 
					}
				}
				this.mymenus.push(this.mymenues[this.activeIndexs])
				this.mymenus[this.mymenus.length - 1].show = true;
				this.mymenues.splice(this.activeIndexs, 1);
			},
			addneslist(name) {
				this.masklist = true;
			},
			resert() {
				this.maskloading = true;
				this.$http.post('system:/Employee/List', {
					Data: {
						"PageNO": 1,
						        "PageSize": 99999,
						        "SortDirection": 1,
						        "QST": '',
						        "QSE": '',
						"CorpID": this.desc.OrgId,
						"IsFilterUser": ''
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.mymenus = res.data.Data.Items == null ? [] : res.data.Data.Items;
						this.$http.post('system:/Dictionary/GetByCode', {    
							Data: "D007"
						}).then(res => {
							if(res.data.Code == '0') {
								var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
								for(var i = 0; i < opt.length; i++) {
									for(var j = 0; j < this.mymenuesEmployee.length; j++) {
										if(opt[i].ID == this.mymenuesEmployee[j].ID) {
											opt.splice(i, 1)
										}
									}
								}
								this.mymenueEmployee = opt
								this.maskloading = false;
							}
						});
					} else {
						this.maskloading = false;
						
					}
				});

				this.mymenues = [];
				this.mymenuesEmployee = [];
			},
			deletemymenus(index) {
				this.mymenus.splice(index, 1);
				this.mymenuesEmployee.splice(index, 1);
			},
			// 接收人员选择器数据
			optionShow(data) {
				if(data == '') {
					this.desc.employeeId = null;
				} else {
					this.desc.employeeId = data;
					this.$http.post('/Employee/GetById', {
						Data: this.desc.employeeId
					}).then(res => {
						if(res.data.Code == '0') {
							this.employeeparams = {
								"UserID": res.data.Data.UserID,
								"EmployeeID": res.data.Data.ID,
								"EmployeeName": res.data.Data.Name,
								"DeptName": res.data.Data.OrganizationName,
								"EMail": res.data.Data.Email == null ? '' : res.data.Data.Email
							}
						} else {
							
						}
					})
				}
			},
			DrawAssetEmployeeShow(data) {
				this.employeeparams = {
					"UserID": data.UserID,
					"EmployeeID": data.ID,
					"EmployeeName": data.Name,
					"DeptName": data.OrganizationName,
					"EMail": data.Email == null ? '' : data.Email
				}
				this.desc.employeeId = data.ID
			},
			// 接收人员选择器数据
			optionShowq(data) {
				this.searchSolution.List[this.myIndex].value = data
			},
			DrawAssetEmployeeShowq(data) {
				if(!this.searchSolution.List[this.myIndex].value) {
					this.searchSolution.List[this.myIndex].value = []
				}
				data.forEach(employee => {
					this.searchSolution.List[this.myIndex].value.push(employee.ID);
				});
			},
			//增加条件
			addCondition() {
				if(this.editShow) {
					return
				}
				this.searchSolution.List.push({
					assetPropertyID: '',
					BillPropertyName: '',
					Operator: '',
					NameCN: '',
					NameEN: '',
					BillPropertyType: null,
					options: [{
						value: 1,
						label: R.ProcessEngine_list107
					}, {
						value: 2,
						label: R.ProcessEngine_list109
					}, {
						value: 3,
						label: R.ProcessEngine_list111
					}, {
						value: 4,
						label: R.ProcessEngine_list108
					}],
					value: '',
				});
			},
			selectfocus(index) {
				this.searchSolution.selectIndex = index;
			},
			//删除条件
			removeCondition(index) {
				if(this.editShow) {
					return
				}
				this.searchSolution.List.splice(index, 1);
			},
			// 延迟加载部门
			lazyLoadOrg({
				parentNode,
				callback
			}) {

				this.$http.post('system:/Organization/GetTree', {
					Data: {
						Delay: true,
						ParentId: parentNode ? parentNode.id : null,
						OnlyLoadPrivilegeOrg: true,
						LoadOrganizationType: 3,
						'onlyloadEnableOrg': false,
					}
				}).then((rsp) => {
					// 
					if(rsp.data.Code == 0) {
						if(parentNode) { //非根节点
							parentNode.children = rsp.data.Data;
						} else { //根节点
							this.searchSolution.OrgdataSource = rsp.data.Data;
						}
						callback();
					} else {
						this.$message.error(rsp.data.Message);
					}
				})
			},
			//上级部门懒加载
			uprogloadOptions({
				parentNode,
				callback
			}) {
				this.$http.post('system:/Organization/GetTree', {
					Data: {
						Delay: true,
						'onlyloadEnableOrg': true,
						ParentId: parentNode ? parentNode.id : null,
						OnlyLoadPrivilegeOrg: true,
						LoadOrganizationType: 3,
					}
				}).then((rsp) => {
					// 
					if(rsp.data.Code == 0) {
						if(parentNode) { //非根节点
							parentNode.children = rsp.data.Data;
						}
						callback();
					} else {
						this.$message.error(rsp.data.Message);
					}
				})
			},

			// 获取审批流详情
			resShow(ID, type) {
				if(!ID) {
					return
				}
				this.loading = true
				this.$http.post('system:/BillApproval/ApprovalFlow/GetByID', {
					Data: {
						"FlowId": ID,
						/*产品id*/
					}
				}).then((res) => {
					if(res.data.Code == 0) {
						this.CropName = res.data.Data.CropName
						this.ProductName = res.data.Data.ProductName
						this.productId = res.data.Data.ProductId;
						this.BillName = res.data.Data.BillName
						this.FlowName = res.data.Data.FlowName
						this.FlowVersion = res.data.Data.FlowVersion
						this.Remark = res.data.Data.Remark
						var title = `[${res.data.Data.ProductName}][${res.data.Data.BillName}][${res.data.Data.FlowVersion}][${res.data.Data.FlowStatusTxt}][${res.data.Data.FlowName}]`
						this.title = title
						this.IsEnabledTxt = res.data.Data.IsEnabled ? R.ProcessEngine_list126: R.ProcessEngine_list127
						this.editShow = res.data.Data.FlowStatus == 1 ? false : true;
						// if (this.$route.params.type=='new') {
						kmain.contentEditable = false;
						kmain.ObjVue = this
						processlist.pselectedProcessEntity = res.data.Data;
						if(type) {
							if(processlist.afterCreated) {
								slick.trigger(processlist.afterCreated, {
									"ProcessEntity": res.data.Data
								});
							}
						}
					
					this.$http.post('/ApprowFlow/BillConfig', {
						Data: {
							"ProductId": processlist.pselectedProcessEntity.ProductId,
								/*产品id*/
							"BillType": processlist.pselectedProcessEntity.BillType /*单据类型*/
						}
					}).then(res => {
						if(res.data.Code == '0') {
							this.billerOptions = res.data.Data
						} else {
							
						}
					})
					} else {
						
					}

					// kmain.contentEditable=true
					// }
					// if (this.$route.params.type=='edit') {
					// processlist.sure()
					// }
					this.loading = false
				})

			},

			// 保存
			processSave() {
				this.btndisabled = true;
				var a = kmain.mxGraphEditor.graph.getDefaultParent()
				if(a.children) {
					a.children.filter((val) => {
						kmain.createProperties(val)
					})
				}
				// mxGraph.prototype.getDefaultParent()
				// kmain.saveProcessFile()

				if(processlist.pselectedProcessEntity.FlowStatus == 1) {
					if(!this.FlowName.trim()) {
						return
					}
					var xmlContent = kloader.serialize2Xml(kmain.mxSelectedProcessEntity, kmain.mxSelectedParticipants);
					processlist.pselectedProcessEntity.FlowContent = xmlContent;
					var res = processlist.pselectedProcessEntity;
					this.loadingSave = true
					if(!res.FlowContent){
						this.$message.error(R.ProcessEngine_list128)
						this.loadingSave = false
						this.btndisabled = false;
						return
					}
					this.$http.post('system:/BillApproval/ApprovalFlow/Save', {
						Data: {
							"ID": res.FlowId,
							/*流程id*/
							"ProductId": res.ProductId,
							/*产品id*/
							"BillType": res.BillType,
							/*单据类型*/
							"FlowName": this.FlowName,
							/*流程名称*/
							"Remark": this.Remark,
							/*备注说明*/
							"FlowContent": res.FlowContent /*流程的xml内容*/
						}
					}).then((res) => {
						if(res.data.Code == 0) {
							this.maskhidden = false
							this.ApprovalForm = false
							this.$message.success(res.data.Message)
							this.resShow(res.data.Data.ID)
						} else {
							
						}
						this.loadingSave = false
						this.btndisabled = false;
					})
				} else {
					this.$message.error(R.ProcessEngine_list129)
					this.btndisabled = false;
				}

			},

			processSubmit() {
				if(processlist.pselectedProcessEntity.FlowStatus == 1) {
					this.SubElasticFrameMask = true;
				} else {
					this.$message.error(R.ProcessEngine_list130)
				}
			},
			SubclickCancel() {
				this.SubElasticFrameMask = false;
			},

			// 提交
			SubclickSure() {
				this.btndisabled = true;
				var a = kmain.mxGraphEditor.graph.getDefaultParent()
				if(a.children) {
					a.children.filter((val) => {
						kmain.createProperties(val)
					})
				}
				if(processlist.pselectedProcessEntity.FlowStatus == 1) {
					// kmain.saveProcessFile()
					var xmlContent = kloader.serialize2Xml(kmain.mxSelectedProcessEntity,
						kmain.mxSelectedParticipants);
					processlist.pselectedProcessEntity.FlowContent = xmlContent
					var res = processlist.pselectedProcessEntity
					// this.loading=true
					this.$http.post('system:/BillApproval/ApprovalFlow/Submit', {
						Data: {
							"ID": res.FlowId,
							/*流程id*/
							"ProductId": res.ProductId,
							/*产品id*/
							"BillType": res.BillType,
							/*单据类型*/
							"FlowName": res.FlowName,
							/*流程名称*/
							"FlowContent": res.FlowContent /*流程的xml内容*/
						}
					}).then((res) => {
						if(res.data.Code == 0) {
							this.$message.success(res.data.Message)
							this.resShow(res.data.Data.ID)
						} else {
							// this.loading=false
							
						}
						this.btndisabled = false;
						this.SubclickCancel()

					})
				} else {
					this.$message.error(R.ProcessEngine_list130)
					this.btndisabled = false;
				}
			},
			// 编辑
			EditShow() {
				if(processlist.pselectedProcessEntity.FlowStatus != 1) {
					this.$message.error(R.ProcessEngine_list131)
					return
				}
				this.urlInto = false
				this.maskhidden = true
				this.ApprovalForm = true
			},
			// 删除
			delShow() {
				if(processlist.pselectedProcessEntity.FlowStatus != 1) {
					this.$message.error(R.ProcessEngine_list132)
					return
				}
				this.urlInto = false
				this.DelElasticFrameMask = true
			},
			// 确定删除
			DelclickSure() {
				var res = processlist.pselectedProcessEntity
				this.$http.post('system:/BillApproval/ApprovalFlow/Delete', {
					Data: {
						"ID": res.FlowId,
						/*流程id*/
					}
				}).then((res) => {
					if(res.data.Code == 0) {
						this.$message.success(res.data.Message)
						this.goBack()
					} else {
						
					}
				})
				// var entity = {
				// 	"ProcessGUID": processlist.pselectedProcessEntity.ProcessGUID,
				// 	"Version": processlist.pselectedProcessEntity.Version
				// };
				// var that = this
				// //delete the selected row
				// jshelper.ajaxPost('http://192.168.0.215:1001/api/Wf2Xml/DeleteProcess', JSON.stringify(entity), function(result) {
				// 	if(result.Status == 1) {
				// 		that.$message.success('删除成功')
				// 		//refresh
				// 		// processlist.getProcessList();
				// 		that.DelElasticFrameMask = false
				// 		that.goBack()
				// 	} else {
				// 		that.$message.success('删除失败')
				// 	}
				// });
			},
			// 取消删除
			DelclickCancel() {
				this.DelElasticFrameMask = false
			},
			// 复制
			CopyFlowShow() {
				this.urlInto = false
				var res = processlist.pselectedProcessEntity
				this.$http.post('system:/BillApproval/ApprovalFlow/CopyFlow', {
					Data: {
						"ID": res.FlowId,
						/*流程id*/
					}
				}).then((res) => {
					if(res.data.Code == 0) {

						this.resShow(res.data.Data.ID)
						this.$message.success(res.data.Message)
					} else {
						
					}
				})
			},
			// 停用或启用
			ToggleEnableShow() {
				if(processlist.pselectedProcessEntity.FlowStatus != 2) {
					this.$message.error(R.ProcessEngine_list133)
					return
				}
				this.urlInto = false
				var res = processlist.pselectedProcessEntity
				this.$http.post('system:/BillApproval/ApprovalFlow/ToggleEnable', {
					Data: {
						"ID": res.FlowId,
						/*流程id*/
					}
				}).then((res) => {
					if(res.data.Code == 0) {
						this.$message.success(res.data.Message)
						this.resShow(res.data.Data.ID)
					} else {
						
					}
				})
			},
			// 作废
			CancelShow() {
				if(processlist.pselectedProcessEntity.FlowStatus != 2) {
					this.$message.error(R.ProcessEngine_list133)
					return
				}
				this.urlInto = false
				var res = processlist.pselectedProcessEntity
				this.$http.post('system:/BillApproval/ApprovalFlow/Cancel', {
					Data: {
						"ID": res.FlowId,
						/*流程id*/
					}
				}).then((res) => {
					if(res.data.Code == 0) {
						this.$message.success(res.data.Message)
						this.resShow(res.data.Data.ID)
					} else {
						
					}
				})
			},
			closemask() {
				this.maskhidden = false;
				this.addone = false;
				this.branch = false;
				this.Order = false;
				this.merage = false;
				this.huiqian = false;
				this.activeIndexs = 0;
				this.activeIndex = 0;
				this.mymenues = []
				this.mymenuesEmployee = []
				this.desc = {
					name: '',
					branchName: R.ProcessEngine_list89,
					orderName: R.ProcessEngine_list90,
					huiqianName: R.ProcessEngine_list91,
					merageName: R.ProcessEngine_list92,
					desc: '',
					openEmail: false,
					Employeeradio: 1,
					OrgId: '',
					OrgName:'',
					// 已选项
					mymenues: [],
					EmployeeOpenradio: 1,
					employeeOpen: 1,
					employeeOpenUp: 1,
					transUp: false,
					rangeRadio: 1,
					mymenuesEmployee: [],
					employeeId: '',
					unnormalRadio: 1,
					openEmaillater: false,
					addLine: false,
					DeLradio: 1,
					radio: 'AndSplit',
					typeValue: 1,
					percentage: "",
					SupportedStrategies: []
				};
				this.searchSolution.List = [{
					BillPropertyName: '',
					Operator: null,
					BillPropertyType: null,
					value: null,
					options: [{
						value: 1,
						label: R.ProcessEngine_list107
					}, {
						value: 2,
						label: R.ProcessEngine_list108
					}, {
						value: 3,
						label: R.ProcessEngine_list109
					}, {
						value: 4,
						label: R.ProcessEngine_list110
					}, {
						value: 5,
						label: R.ProcessEngine_list111
					}, {
						value: 6,
						label: R.ProcessEngine_list112
					}, {
						value: 7,
						label: R.ProcessEngine_list113
					}, {
						value: 8,
						label: R.ProcessEngine_list114
					}, {
						value: 9,
						label: R.ProcessEngine_list115
					}, {
						value: 10,
						label: R.ProcessEngine_list116
					}, {
						value: 11,
						label: R.ProcessEngine_list117
					}]
				}];
			},
		},
		beforeCreate(){
			R = this.getSelfLanguage('System', 'system_BillApproval_ProcessEngine')
		},
		mounted() {
			var permissionList = JSON.parse(this.$Store.get('SyetemKinds'))
			for(let i = 0; i < permissionList.length; i++) {
				if(permissionList[i].Children != null) {
					for(let j = 0; j < permissionList[i].Children.length; j++) {
						if(this.$route.params.status == 'StandardApproval') {
							if(permissionList[i].Children[j].Url == '/system/BillApproval/StandardApproval') {
								this.$emit('childByValue', permissionList[i].Children[j].Index)
								this.permissionCode = permissionList[i].Children[j].Permissions
							}
						} else if(this.$route.params.status == 'CompanyApprove') {
							if(permissionList[i].Children[j].Url == '/system/BillApproval/CompanyApprove') {
								this.permissionCode = permissionList[i].Children[j].Permissions
								this.$emit('childByValue', permissionList[i].Children[j].Index)
							}
						}

					}
				}
			}
			var that = this
			$(function() {
				//load lang
				kresource.localize();
				//initialize
				kmain.init();
				kmain.initializeMxGraphEditor(function(callback) {
					if(callback) {
						that.showTabsByActivityType(callback);
						// that.$emit('activity',callback)
					}
				});
				var processID = '';
				if(processID !== '') {
					//load process diagram from GC outer aplication
					processlist.openProcessDiagram(processID);
				}
				that.resShow(that.$route.params.ID, true)
			});

			this.$http.post('/Organization/GetTree', {
				Data: {
					LoadOrganizationType: 3,
					OnlyLoadPrivilegeOrg: true, //正式应该为true
					'ParentId': '',
					"Delay": true,
					'onlyloadEnableOrg': true,
				}
			}).then(res => {
				if(res.data.Code == '0') {
					this.Topapi = res.data.Data
					this.desc.OrgId = res.data.Data[0].id;
					this.oldOrgId = res.data.Data[0].id;
					this.oldOrgName = res.data.Data[0].label;
				} else {
					
				}
			})
			
			
			this.alllist = this.mymenus.slice(0);
			this.alllist1 = this.mymenues.slice(0);
			this.alllist2 = this.mymenueEmployee.slice(0);
			this.alllist3 = this.mymenuesEmployee.slice(0);
		}
	}
</script>

<style lang="scss">
	#ProcessEngine {
		position: relative;
		.condition{
			.vue-treeselect__multi-value-item-container{
				padding-top: 0!important;
			}
			.vue-treeselect__multi-value-item{
				padding:  0!important;
			}
		}
		
		.DelDocuments {
			padding-top: 50px;
			padding-left: 100px;
			>i {
				color: deepskyblue;
				margin-right: 10px;
				font-size: 16px;
			}
		}
		.mask .addone .table .one-table .main-table {
			.actived {
				color: #ff5402!important;
			}
			.activesd {
				color: #ff5402!important;
			}
			.actives{
				color: #ff5402!important;
			}
		}
		
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 3;
			.red {
				color: red;
			}
			.ApprovalForm {
				width: 420px;
				height: 380px;
				background: #ffffff;
				position: relative;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					>div {
						height: 30px;
						line-height: 30px;
						padding-left: 20px;
						margin-top: 10px;
						.leftName {
							display: inline-block;
							width: 71px;
							text-align: right;
						}
						.rightName {
							.el-input {
								width: 210px;
							}
							.textarea {
								margin-top: 10px;
								width: 210px;
								.el-textarea__inner {
									height: 60px;
								}
							}
						}
						.textarealeft {
							position: relative;
							top: -13px;
						}
					}
					.el-select {
						height: 30px;
						width: 210px;
					}
					.el-input__inner {
						height: 30px;
						line-height: 30px;
					}
				}
				.foots {
					position: absolute;
					right: 10px;
					bottom: 10px;
				}
			}
			.addone {
				width: 864px;
				height: 580px;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				margin: auto;
				background-color: white;
				.el-icon-question {
					font-size: 16px;
					color: #009966;
					margin-left: 5px;
					margin-right: 10px;
					cursor: pointer;
				}
				.listTable {
					position: absolute;
					top: 45px;
					bottom: 40px;
				}
				.foots {
					position: absolute;
					bottom: 10px;
					left: 0;
					right: 0;
					height: 30px;
					line-height: 30px;
					margin-right: 10px;
					.el-button {
						float: right;
						height: 30px!important;
						padding: 8px 16px;
					}
				}
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					top: 45px;
					left: 0px;
					right: 0px;
					bottom: 40px;
					position: absolute;
					overflow: auto;
					.el-input__inner {
						height: 30px !important;
						line-height: 30px;
					}
					.el-input__icon {
						height: 28px;
						line-height: 28px;
					}
					.commontree {
						top: 0;
					}
					>div {
						height: 40px;
						line-height: 40px;
						padding-left: 20px;
						.leftName {
							display: inline-block;
							width: 100px;
						}
						.leftNames {
							display: inline-block;
							width:61px;
						}
						.rightName {
							>.el-input {
								width: 250px;
							}
							.selectInput {
								width: 140px;
								.el-input {
									width: 100%;
								}
							}
							ul {
								.vue-treeselect__multi-value {
									height: 23px;
									line-height: 29px;
								}
								div {
									line-height: 29px;
								}
								;
								>.el-input {
									width: 130px;
									height: 30px;
									line-height: 30px;
								}
								.el-input__icon {
									line-height: 30px;
								}
								height:260px;
							}
							.vue-treeselect {
								width: 250px;
								.vue-treeselect__control {
									height: 30px;
									.vue-treeselect__placeholder,
									.vue-treeselect__single-value {
										line-height: 30px;
									}
								}
							}
							.vue-treeselect__input {
								height: 30px;
								line-height: 30px;
							}
							.textarea {
								margin-top: 10px;
								height: 60px;
								width: 250px;
							}
							li {
								margin-bottom: 5px;
							}
							.condition {
								>p {
									font-size: 14px;
									color: #5b5b5b;
								}
								>ul {
									.vue-treeselect__value-container {
										// overflow: auto;
									}
									overflow: auto;
									width: 100%;
									box-sizing: border-box;
									.conditioned {
										margin-bottom: 10px;
										display: flex;
										align-items: center;
										/*justify-content: space-between;*/
										.el-icon-remove {
											cursor: pointer;
											font-size: 15px;
											color: #fc4444;
										}
										>div {
											display: flex;
											margin-left: 10px;
											#Personnelselector {
												width: 250px;
												.searchselect {
													width: 235px;
													.el-input {
														width: 225px;
													}
													/*.el-select__tags{
												height: 30px;
												overflow: auto;
											}*/
													.Personnelsearch {
														height: 30px;
													}
													.el-input,
													.el-input--mini,
													.el-input--suffix {
														.el-input__inner {
															height: 30px;
														}
													}
												}
											}
											>.el-input {
												width: 130px;
												.el-input__inner {
													height: 30px;
													font-size: 12px
												}
											}
											>.el-select {
												width: 130px;
												.el-input__inner {
													height: 30px;
													font-size: 14px;
												}
											}
											>.input {
												width: 95px;
												.el-input__inner {
													height: 30px;
												}
											}
											.selectMY {
												.el-input {
													width: 250px;
												}
											}
											>.vue-treeselect {
												width: 130px;
												.vue-treeselect__control {
													height: 30px;
													.vue-treeselect__placeholder,
													.vue-treeselect__single-value {
														line-height: 30px;
													}
												}
											}
										}
									}
									.addfr {
										cursor: pointer;
										display: inline-block;
										i {
											color: #08bad5;
											font-size: 14px;
										}
										span {
											color: #08bad5;
											font-size: 13px;
										}
										.el-button.is-round {
											padding: 12px 22px;
										}
									}
								}
							}
						}
						.textarealeft {
							position: relative;
							top: -13px;
						}
					}
					>.el-select {
						height: 30px;
						width: 250px;
					}
				}
				.table {
					overflow: hidden;
					.main-tt {
						overflow: hidden;
						height: 280px;
					}
					.one-table {
						left: 2px;
						top: 2px;
						position: relative;
						float: left;
						height: 250px;
						width: 310px;
						box-sizing: border-box;
						/*border: 1px solid #EEEEEE;*/
						/*padding: 9px 15px;*/
						box-shadow: 0 0px 8px rgba(108, 115, 117, 0.3);
						.head {
							height: 30px;
							line-height: 30px;
							text-align: center;
							font-size: 14px;
							font-family: FZLTHK--GBK1-0;
							background-color: #EFF1F1;
						}
						.main-table {
							height: 220px;
							overflow: auto;
							padding-left: 14px;
							box-sizing: border-box;
							li {
								font-size: 14px;
								font-family: FZLTHK--GBK1-0;
								font-weight: 400;
								color: rgba(111, 115, 117, 1);
								margin-bottom: 5px;
								height: 15px;
								cursor: pointer;
								i {
									font-size: 14px;
									float: right;
									position: relative;
									top: -2px;
									right: 40px;
									height: 30px;
									width: 30px;
									border-radius: 4px;
									padding: 4px;
									box-sizing: border-box;
									border: 1px solid white;
								}
							}
							li:hover {
								color: #ff5402;
								i {
									border: 1px solid #FF5402;
									box-sizing: border-box;
								}
							}
						}
						.foot {
							position: absolute;
							bottom: 0px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							width: 100%;
							/*margin-left: -15px;*/
							border-radius: 4px;
							border: 1px dashed #FF5402;
							color: #FF5402;
							background-color: white;
							cursor: pointer;
							font-size: 14px;
							i {
								font-weight: 600;
							}
						}
					}
					.btns {
						margin-top: 50px;
						margin-left: 40px;
						margin-right: 56px;
						float: left;
						width: 60px;
						>.el-button {
							margin-bottom: 15px!important;
						}
						.el-button:focus {
							background-color: #dcdfe6!important;
						}
						.el-button+.el-button {
							margin-left: 0;
						}
						.orange {
							border-color: rgb(255, 84, 2);
							color: rgb(255, 84, 2);
							font-size: 14px;
							img {
								height: 12px;
								width: 20px;
								position: relative;
								top: 2px;
							}
						}
						.el-button:focus,
						.el-button:hover {
							background-color: white;
						}
					}
					.blue {
						font-size: 14px;
						font-family: FZLTHK--GBK1-0;
						font-weight: 400;
						color: rgba(7, 186, 212, 1);
						position: relative;
						left: 12px;
					}
				}
			}
			.branch {
				width: 420px;
				height: 230px;
			}
			.Order {
				width: 630px;
				height: 470px;
				.content {
					right: 8px;
				}
			}
			.merage {
				width: 420px;
				height: 230px;
			}
			.huiqian {
				height: 580px;
			}
		}
		.content {
			img {
				height: 100%;
				width: 100%;
			}
		}
	}
</style>