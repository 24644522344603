export default {
    init:{
        // 格式化字符串:string.format
        format(){
            String.prototype.format = function(args) {
                var result = this;
                if (arguments.length > 0) {
                    if (arguments.length == 1 && typeof (args) == "object") {
                        for (var key in args) {
                            if(args[key]!=undefined){
                                var reg = new RegExp("({" + key + "})", "g");
                                result = result.replace(reg, args[key]);
                            }
                        }
                    }
                    else {
                        for (var i = 0; i < arguments.length; i++) {
                            if (arguments[i] != undefined) {
                                var reg= new RegExp("({)" + i + "(})", "g");
                                result = result.replace(reg, arguments[i]);
                            }
                        }
                    }
                }
                return result;
            }
        },
        // 字符串以target为起始字符:string.startWith
        startWith(){
            String.prototype.startWith=function(target){
                if(target==null||target==''||this.length==0||target.length>this.length)
                    return false;
                if(this.substr(0,target.length)==target)
                return true;
                else
                return false;
            }
        },
        // 字符串以target为结尾字符:string.endWith
        endWith(){
            String.prototype.endWith=function(target){
                if(target==null||target==''||this.length==0||target.length>this.length)
                    return false;
                if(this.substring(this.length-target.length)==target)
                    return true;
                else
                    return false;
            }
        },
    }
}
