<template>
    <div class="Select">
      <el-select v-model="value" :multiple="multiple||false" collapse-tags :filterable="true" :filter-method='myfc' @change="handleClick(value)">
        <el-option
          v-for="item in options"
          :key="item.id"
          :value="item.data[0]"
          >
          <span v-for="(item1,index1) in item.data" :key="index1" :style="{width:item.width||'100px'}">{{item1}}</span>
        
        </el-option>
      </el-select>
    </div>
</template>
<script>
export default {
  
  name: "Select",
  data() {
    return {
      options:this.option,
      value:this.defoultValue,
    };
  },
  computed:{
    
  },
  watch:{
    
  },
  props:['option','defoultValue','multiple'],
   
  
  methods: {
      handleClick(evt) {
        this.$emit("change", evt); //事件曝光出去
      },
      myfc(query) {
//      // console.log(query)
        if(this.query==''){
//        // console.log(1)
        }
        if (query.trim()!== '') {
            // for (let item of this.options.data) {
            //   value.count = 0;
            // }
            
//          // console.log(this)
            this.options = this.options.filter(item => {
              return item.generalTerm.toLowerCase()
                .indexOf(query.trim().toLowerCase()) > -1;
            });
        } else {
          this.options = this.option;
        }
      }
  },
  mounted() {
  }
};
</script>
<style lang='scss'>
  .Select{
    
  }
  .el-select-dropdown{
    .el-scrollbar{
      .el-select-dropdown__wrap{
        >ul{
          >li{
            display: flex;
            span{
              width: 100px;
            }
            
          }
          .el-select-dropdown__item.selected{
            font-weight: 500;
          }
        }
      }
    }
  }
</style>
