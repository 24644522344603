<template>
	<div id="setting" v-loading="loading" v-if="IsPageDome">
		<p class="title">{{R.System_Setting_1}}</p>
		<Table stripe :columns="tableColumns2" :data="tableData2"></Table>
		<p class="title" style="margin-top:20px">{{R.System_Setting_7}}</p>
		<span style="margin-left:10px">{{R.System_Setting_6}}：</span>
		<div>
			<el-switch
			style="margin-left:10px"
			v-model="Excesspurchase"
			active-color="#13ce66"
			inactive-color="#ff4949">
			</el-switch>
			<span :style="Excesspurchase?'margin:0 10px;color:#13ce66':'margin:0 10px;color:#ff4949'">{{Excesspurchase?R.System_Setting_9:R.System_Setting_10}}</span>
		</div>
		<el-button v-if="getpermission('SaveSetting')" stripe class="orangebd " @click="saveSettings">{{R.Common_Button_Commit}}</el-button>
		
	</div>
</template>

<script>
	var R = {}
	export default {
		name: 'setting',
		data() {
			return {
				Excesspurchase:false,
				IsPageDome: true,
				permissionCode: [],
				loading: false,
				R: R,
				tableColumns2: [{
					align: 'center',
					width: 180,
					title: R.System_Setting_2,
					key: 'Code',
					tooltip: true,
				}, {
					align: 'center',
					width: 180,
					title: R.System_Setting_3,
					key: 'Value',
					tooltip: true,
					render: (h, params) => {
						return h('Checkbox', {
							props: {
								value: params.row.Value,
							},
							on: {
								'on-change': (event) => {
									this.tableData2[params.index].Value = event;
								}
							},
						});
					},
				}],
				tableData2: [{
					Code: R.System_Setting_4,
					Value: ''
				}, {
					Code: R.System_Setting_5,
					Value: ''
				}]
			}
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_Setting')
		},
		methods: {
			saveSettings() {
				var params = {
					Data: [{
						Key: 'AutoGenerateCode_Org',
						Value: this.tableData2[0].Value
					}, {
						Key: 'AutoGenerateCode_Employee',
						Value: this.tableData2[1].Value
					}, {
						Key: 'IsEnableApprovalSignPicture',
						Value: this.Excesspurchase
					}]
				};

				this.$http.post('/Setting/Save', params).then((rsp) => {
					if(rsp.data.Code == '0') {
						this.$message.success(rsp.data.Message)
						this.$Store.set('SystemDicSettings', JSON.stringify(params.Data));
					} else {
						this.$message.error(rsp.data.Message)
					}
				});
			},
			//判断权限是否存在
			getpermission(code) {
				// console.log(this.permissionCode)
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {

						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}
			},
		},
		mounted() {
			this.$http.post('/Setting/All').then((rsp) => {
				if(rsp.data.Code === 0) {
					let dic = rsp.data.Data;
					if(dic != null && dic.length > 0) {
						for(let i = 0; i < dic.length; i++) {
							var kv = dic[i];
							if(kv.Key === 'AutoGenerateCode_Org') {
								this.tableData2[0].Value = kv.Value === 'True' ? true : false;
							}
							if(kv.Key === 'AutoGenerateCode_Employee') {
								this.tableData2[1].Value = kv.Value === 'True' ? true : false;
							}
							if(kv.Key === 'IsEnableApprovalSignPicture') {
								this.Excesspurchase = kv.Value === 'True' ? true : false;
							}
						}
					}
				} else {
					alert(rsp.data.Message);
				}
				this.loading = false;
			});
			this.$PermissionUtil.getPermission('System/Setting').then((res)=>{
				this.permissionCode = res;
			});
		}
	}
</script>

<style lang="scss">
	#setting {
		.ivu-table-cell span {
			font-size: 12px!important;
		}
		.title {
			font-size: 16px;
			color: #626768;
			font-weight: bold;
		}
		.ivu-table-wrapper {
			margin-top: 15px;
			width: 362px;
		}
		.el-button {
			position: absolute;
			bottom: 1.9375rem;
			left: 1.9375rem;
		}
	}
</style>