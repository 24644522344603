<template>
	<div class="index" v-loading='languagelisoading'>
		<!-- 修改密码 -->
		<cj-ElasticFrame :title="R.Public_SystemIndex_17" v-if="PasswordElasticFrame" @clickSure="PasswordclickSure" @clickCancel="PasswordclickCancel" width="530px" height="250px">
			<div slot="content" :class="$i18n.locale=='zh'?'PasswordElasticFrame':'PasswordElasticFrame changepass' ">
				<el-form :model="page"  :rules="rules" ref="ruleForm" label-width="100px">
					<el-form-item  :label="R.Public_SystemIndex_17+'：'" prop='password'>
						<el-input   :placeholder="R.Public_SystemIndex_18+passType.PasswordPolicy_MinLength+R.Public_SystemIndex_88" type="password" maxlength='50' v-model="page.password"></el-input>&ensp;<span class="red">*</span>
					</el-form-item>
					<el-form-item  :label="R.Public_SystemIndex_19+'：'" prop='passwords'>
						<el-input :placeholder="R.Public_SystemIndex_18+passType.PasswordPolicy_MinLength+R.Public_SystemIndex_88" type="password"  maxlength='50' v-model="page.passwords"></el-input>&ensp;<span class="red">*</span>
					</el-form-item>
				</el-form>
			</div>
		</cj-ElasticFrame>
		<!-- 遮罩层 -->
		<div class="mask" v-if="maskhidden" @click="mask"></div>
		<!-- 消息弹出框 -->
		<div class="News" :style="{right:out}">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane name="first"><span slot="label">{{R.Public_SystemIndex_1}}
						<el-badge :value="business.length" class="item">
						</el-badge></span>
					<div class="Business_message" :key="index" v-for="(item,index) in business">
						<p class="time">{{item.time}}</p>
						<div class="content">
							<div>
								<P class="status" :class="{'class-e':item.state=='逾期','class-f':item.state=='到期','class-g':item.state=='归还',}">{{item.state}}</P>
								<P class="statuscontent">{{item.content}}</P>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane name="second"><span slot="label">{{R.Public_SystemIndex_2}}
					<el-badge :value="system.length" class="item">
						</el-badge></span>
					<div class="Business_message" :key="index" v-for="(item,index) in system">
						<p class="time">{{item.time}}</p>
						<div class="content">{{item.content}}</div>
					</div>
				</el-tab-pane>
			</el-tabs>
			<div class="back">
				<img src="/static/images/out.png" alt="" srcset="" @click="outNews">
			</div>
		</div>
		<!-- 内容区域 -->
		<el-container class="container" style="width:100%">
			<!-- 内容左侧 -->
			<el-aside width="210px" style="height:100%;">
				<!-- 左侧头部 -->
				<div class="logo">
					<img :src="logoPath?logoPath:(`static/images/${CustomizedSetting.logoPath?CustomizedSetting.logoPath:'bg_logo.png'}`)" :alt="R.Public_SystemIndex_3" srcset="">
				</div>
				<el-menu :default-active="emitActive" class="el-menu-vertical-demo" @select='select' router>
					<el-submenu :index="item.Index+''" v-for="item in kinds " :key="item.ID" v-if="item.Children">
						<template slot="title">
							<i :class="item.CssCls"></i>
							<span>{{item.Name}}</span>
						</template>
						<el-menu-item-group>
							<el-menu-item :index="item.Index+''" :route='item.Url' v-for="item in item.Children" :key="item.ID">
								{{item.Name}}
								<i class="item.CssCls" v-if="myroute==item.Index"></i>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
					<el-menu-item :index="item.Index+''" v-else :route='item.Url' :key="item.ID">
						<i :class="item.CssCls"></i>
						<span slot="title">{{item.Name}}</span>
						<i class="iconfont icon-zuosanjiao myicon" v-if="myroute==item.Index"></i>
					</el-menu-item>
				</el-menu>
			</el-aside>
			<!-- 内容右侧 -->
			<el-container>
				<!-- 左侧头部 -->
				<el-header>
					<div>
						<!-- 左侧头部左半边 -->
						<div class="headerLeft">
							<div class="Company_Name">
								<img src="static/images/zuhu.png" :alt="R.Public_SystemIndex_3" srcset="">
								<h3>{{comnpanyName}}</h3>
							</div>
						</div>
						<div class="headerCenter" v-if="false">
							<img src="static/images/wx.png" alt="">
							<h3>{{R.Public_SystemIndex_4}}</h3>
						</div>
						<!-- 左侧头部右半边 -->
						<div class="headerRight">
							<el-popover v-if="CustomizedSetting.IsOfficial" placement="bottom" width="176" trigger="click">
								<div class="wx">
									<img src="/static/images/xcx.jpg" alt="" srcset="">
								</div>
								<!-- <p class="escription">{{R.Public_SystemIndex_5}}</p> -->
								<div class="features" slot="reference">
									<img src="static/images/wx.png" :alt="R.Public_SystemIndex_3" class="img">
									<h3>{{R.Public_SystemIndex_15}}</h3>
								</div>
							</el-popover>
							<!-- <span>
								<div class="features" slot="reference">
								<img src="static/images/gouwu.png" :alt="R.Public_SystemIndex_3" class="img">
								<h3>{{R.Public_SystemIndex_6}}</h3> 
								<p class="counts"></p> 
								</div>
							</span>
							<el-popover placement="bottom" width="150" v-model="value" trigger="click">
								<div class="advisory hover" v-for="(item,index) in advisory" :key="index" :class="{'changeColor':item.count}" @click="changeadvisory(index)">
									{{item.name}}
								</div>
								<div class="features" slot="reference">
									<img src="static/images/zxbz.png" :alt="R.Public_SystemIndex_3" class="img">
									<h3>{{R.Public_SystemIndex_7}}</h3>
								</div>
							</el-popover>
							<span>  
							<div class="features" slot="reference" @click="xx">
								<img src="static/images/xx.png" :alt="R.Public_SystemIndex_3" class="img">
								<el-badge :value="system.length+business.length" class="item">
								 	<h3>{{R.Public_SystemIndex_8}}</h3>
								</el-badge>
							</div>
							</span> -->
							<div class="name" style="margin-left:15px">{{$Store.get('name')}}</div>
							<el-popover placement="bottom" width="148" v-model="values" trigger="click">
								<div class="tx">
									<p class="password"  @click="ChangePasswordShow" @blur="blurs">{{R.Public_SystemIndex_9}}</p>
									<ul @blur="blurs" class="Chinese_English" v-for="(item,index) in language" :key="index" :class="{'languageColor':language_category==(index+1)}" @click="enlanguage(index)">
										<div>
											<img v-show="language_category==(index+1)" src="static/images/ydj.png" alt="" srcset="">
										</div>
										<span>{{item.name}}</span>
									</ul>
									<p @blur="blurs" class="back" v-show="isShowLogOut">
										<img src="static/images/back.png" alt="" srcset="">
										<span @click='logout'>{{R.Public_SystemIndex_11}}</span>
									</p>
								</div>
								<div class="features touxiang" slot="reference" style="border:none;">
									<img src="static/images/touxiang.png" :alt="R.Public_SystemIndex_3">
								</div>
							</el-popover>
						</div>
					</div>
				</el-header>
				<!-- 左侧内容 -->
				<el-main>
					<router-view class="routerView" v-if="isRouterAlive" :mylanguage='mylanguage' v-on:languageloading="languageloading"  v-on:childByValue="childByValue"/>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	var R = {};
	export default {
		name: "index",
		provide() {
			return {
				reload: this.reload
			}
		},
		data() {
			var validatepassword = (rule, value, callback) => {
				if(this.page.password.trim() == '') {
					callback(new Error(R.Public_SystemIndex_21));
				} else if(this.page.password.length < this.passType.PasswordPolicy_MinLength ) {
					callback(new Error(R.Public_SystemIndex_18+this.passType.PasswordPolicy_MinLength+R.Public_SystemIndex_88));
				}else if(this.passType.PasswordPolicy_PasswordStrength){
						var  regnum = 0;
						var reg1 = /[a-z]|[A-Z]/;
						if(reg1.test(this.page.password)){
							regnum+=1
						}
						var reg2 = /\d/;
						if(reg2.test(this.page.password)){
							regnum+=1
						}
						var reg3 = /[\!\@\#\$\%\^\&\*]/
						if(reg3.test(this.page.password)){
							regnum+=1
						}
						if(regnum<2){
							callback(new Error(R.Public_SystemIndex_89));
						}else{
							callback();
						}
				}  else {
					callback();
				}
			};
			var validatetwopassword = (rule, value, callback) => {
				if(this.page.passwords.trim() == '') {
					callback(new Error(R.Public_SystemIndex_23));
				} else if(this.page.passwords!== this.page.password) {
					callback(new Error(R.Public_SystemIndex_24));
				} else {
					callback();
				}
			};
			return {
				isShowLogOut:true,
				logoPath:'',
				rules:{
					password: [{
						validator: validatepassword,
						trigger: 'blur'
					}],
					passwords: [{
						validator: validatetwopassword,
						trigger: 'blur'
					}],
				},
				page:{
					password:'',
					passwords:'',
				},
				passType:[],
				PasswordElasticFrame:false,
				R: R,
				isRouterAlive: true,
				languagelisoading: false,
				language_category: null,
				mylanguage: '',
				comnpanyName: '',
				emitActive: '1',
				myroute: '',
				kinds: [],
				myindex: 1,
				myclick: false,
				maskhidden: 0,
				value: false,
				values: false,
				values1: false,
				business: [],
				system: [],
				activeName: "first",
				pickerOptions1: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					}
				},

				value1: "",
				cond: "文本",
				conditions: [{
						val: "整数",
						arr: ["整数", "小数", "搜索框", "时间", "下拉列表"],
						val1: ""
					},
					{
						val: "整数",
						arr: ["整数", "小数", "搜索框", "时间", "下拉列表"],
						val1: ""
					},
					{
						val: "整数",
						arr: ["整数", "小数", "搜索框", "时间", "下拉列表"],
						val1: ""
					}
				],
				out: "-432px",
				advisory: [{
						name: R.Public_SystemIndex_12,
						count: 0
					},
					{
						name: R.Public_SystemIndex_13,
						count: 0
					}
				],
				language: [{
					name: "中文",
					count: 1
				},
				{
					name: "English",
					count: 0
				}
				],
				num: 0,
				common: [
					"在用资产",
					"闲置资产",
					"即将过保资产",
					"我管理的资产",
					"在用资产",
					"闲置资产",
					"即将过保资产",
					"我管理的资产",
					"在用资产",
					"闲置资产",
					"即将过保资产",
					"我管理的资产"
				],
				columns: [{
						name: "资产状态",
						dis: 1
					},
					{
						name: "资金编码",
						dis: 1
					},
					{
						name: "资产名称",
						dis: 1
					},
					{
						name: "资产分类",
						dis: 1
					},
					{
						name: "品牌",
						dis: 1
					},
					{
						name: "型号",
						dis: 1
					},
					{
						name: "设备型号",
						dis: 1
					}
				],
				inputs: [{
						content: ["资产编码", "资产名称"]
					},
					{
						content: ["品牌", "型号"]
					},
					{
						content: ["规格", "管理部门"]
					},
					{
						content: ["管理人", "使用人"]
					}
				],
				selects: [{
						conetnt: [{
								contens: ["资产分类", "使用情况"]
							},
							{
								contens: ["资产状态"]
							}
						]
					},
					{
						conetnt: [{
							contens: ["使用情况"]
						}, {
							contens: ["使用属性"]
						}]
					}
				]
			};
		},
		watch: {
			loading(val, olaval) {
				this.loading = this.$Store.get('loading');
			},
			languageloading(val, oldval) {
				this.languagelisoading = val;
			},
			childByValue(val, olaval) {
				this.emitActive = val
			},
			mylanguage(val, oldval) {}
		},
		methods: {
			childByValue: function(childValue) {
				// console.log(childValue,'hhh')
				this.emitActive = childValue
				this.myroute = childValue
			},
			ChangePasswordShow(){
				this.values=false;
				this.PasswordElasticFrame=true;
			},
			PasswordclickSure(){
				this.$refs.ruleForm.validate((valid) => {
					if(!valid){return}
					this.$http.post('system:/User/ModifyPassword', {
						data:{
							'ID':this.$Store.get('UserID'),
							"NewPassword": this.page.password,
							"ReNewPassword": this.page.passwords
						}
					}).then(data => {
						if(data.data.Code == '0') {
							this.$message({
								message: data.data.Message,
								type: 'success'
							});
							this.$Store.set('Password',this.page.password)
							
							this.PasswordclickCancel()
						} else {
							this.$message.error(data.data.Message)
						}
						this.page.password=''
						this.page.passwords=''
					});
				});
			},
			PasswordclickCancel(){
				this.PasswordElasticFrame=false;
				this.page.password=''
				this.page.passwords=''
			},
			getIndex() {
				
			},
			reload(index) {
				this.isRouterAlive = false;
				this.$nextTick(function() {
					this.$i18n.locale = this.$Store.get('lag')?this.$Store.get('lag'):'zh'
					R = this.getSelfLanguage('System', 'System_Index');
					this.$http.post('system:/User/GetUserInfo').then(res => {
						this.$Store.set('name', res.data.Data.User.Name, 1 / 12)
						this.$Store.set('Language', res.data.Data.User.Language)
						this.$Store.set('lag',res.data.Data.User.Language==1?'zh':'en')
						this.language_category = this.$Store.get('Language')
					}).then(()=>{
						Object.assign(this.$data,this.$options.data())
						this.$Store.set('Language',index + 1)
						this.language_category = this.$Store.get('Language')
						this.mountedShow()
						this.isRouterAlive = true
					})
					

				})
			},
			enlanguage(index) {
				this.$i18n.locale = index=='0'?'zh':'en';
				this.$Store.set('lag',this.$i18n.locale)
				this.$http.post('system:/User/ChangeLanguages', {
					Data: {
						Language: index + 1,
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.$message.success(res.data.Message)
						this.mylanguage = index + 1
						// console.log(this.mylanguage)
						this.$Store.set('Language', index + 1)
						this.reload(index)
					} else {
						
					}
				})
			},
			//切换语言
			languageloading: function(languageloading) {
				// childValue就是子组件传过来的值
				this.languagelisoading = languageloading
			},
			logout() {
				this.$http.post('system:/User/LogOut').then(res => {})
				window.opener = null;
				window.open('', '_self');
				window.close();
			},
			handleOpen(index, indexPath) {

			},
			handleClose(key, keyPath) {

			},
			select(index, indexPath) {
				this.emitActive = index
				this.myroute = index
				this.mylanguage = this.$Store.get('Language')
			},
			blurs() {
				this.value = false;
				this.values = false;
			},
			mask() {
				this.out = "-432px";
				this.maskhidden = 0;
			},
			xx() {
				this.out = "1%";
				this.maskhidden = 1;
			},
			outNews() {
				this.out = "-432px";
				this.maskhidden = 0;
			},
			handleClick(tab, event) {
				//				// console.log(tab, event);
			},
			changeadvisory(index) {
				// for (let value of this.advisory) {
				//   value.count = 0;
				// }
				// this.advisory[index].count = 1;
				this.value = false;
				this.values = false;
			},
			//改变图标
			changeicon(index) {},
			colshow(index) {
				this.columns[index].dis = !this.columns[index].dis;
			},
			gb() {},
			add() {
				this.conditions.push({
					val: "整数",
					arr: ["整数", "小数", "搜索框", "时间", "下拉列表"],
					val1: ""
				});
			},
			lower(index) {
				this.conditions.splice(index, 1);
			},
			mountedShow(){
			window.localStorage.setItem('mx-resource-local-lang', "zh");
			this.languagelisoading = true;
			this.language_category = this.$Store.get('Language')
			this.$http.post('system:/UserSetting/GetAll', {
				Data: {}
			}).then(res=>{
				if(res.data.Code=='0'){
				this.$Store.set('UserSetting', JSON.stringify(res.data.Data.UserSetting));
				this.$Store.set('SSOSetting', JSON.stringify(res.data.Data.SSOSetting));
				this.$http.post('system:/User/Menu/Mine', {
				Data: {
					Product: 1
				}
			}).then(res => {
				if(res.data.Code=='0'){
					this.logoPath =  res.data.Data.LogoPath?'/static/logo/'+res.data.Data.LogoPath:''
					this.kinds = res.data.Data.Products[0].Menus;
				if(this.kinds.length == '0') {
					this.$message.error(this.R.Public_SystemIndex_14);
					window.opener = null;
					window.open('', '_self');
					window.close();
					this.languagelisoading = false;
				} else {
					this.comnpanyName = res.data.Data.CorpName
					this.$Store.set('CorpName',this.comnpanyName );
					if(this.$Store.get('SyetemKinds')=='null'||this.$Store.get('SyetemKinds')==null||!this.$Store.get('SyetemKinds')){
						this.myroute = res.data.Data.Products[0].Menus.Index
						this.$router.push(res.data.Data.Products[0].Menus[0].Url)
						this.emitActive = res.data.Data.Products[0].Menus[0].Index
					}
					this.languagelisoading = false;
					this.$Store.set('SyetemKinds', JSON.stringify(res.data.Data.Products[0].Menus));
					this.languagelisoading = false;
					var  SyetemKinds = res.data.Data.Products[0].Menus
					for(var i = 0; i < SyetemKinds.length; i++) {
					if(SyetemKinds[i].Url.toLocaleLowerCase()==window.location.href.split('#')[1].toLocaleLowerCase()) {
						this.kinds = SyetemKinds;
						this.emitActive = SyetemKinds[i].Index
						this.myroute = SyetemKinds[i].Index
					}
					if (SyetemKinds[i].Children!=null) {
						for(let j=0;j<SyetemKinds[i].Children.length;j++){
							if (SyetemKinds[i].Children[j].Url.toLocaleLowerCase()==window.location.href.split('#')[1].toLocaleLowerCase()) {
								this.kinds = SyetemKinds;
								this.emitActive = SyetemKinds[i].Children[j].Index
								this.myroute = SyetemKinds[i].Children[j].Index
							}
							if(window.location.href.split('#')[1]=='/system/BillApproval/CompanyApproveDetail'&&SyetemKinds[i].Children[j].Url.toLocaleLowerCase()=='/system/billapproval/companyapprove'){
									this.emitActive = SyetemKinds[i].Children[j].Index
									this.myroute = SyetemKinds[i].Children[j].Index
									
							}
						}
					}
					
				}
				}

				if(this.kinds[0].Menus == []) {

				} else {
					if(location.href.indexOf('?') != '-1') {
						var token = decodeURIComponent(location.href).split('=')[1].split(';')[0];
						this.$Store.set('token', token)
						this.languagelisoading = false;
					}
				}

				}else{
					
				}
				this.languagelisoading = false;
			})
				}
			})
			this.advisory = [{
						name: R.Public_SystemIndex_12,
						count: 0
					},
					{
						name: R.Public_SystemIndex_13,
						count: 0
					}
			];
			this.$http.post('/Setting/All').then((rsp) => {
				if(rsp.data.Code === 0) {
					let dic = rsp.data.Data;
					if(dic != null && dic.length > 0) {
						this.$Store.set('SystemDicSettings', JSON.stringify(rsp.data.Data));
					} else {
						this.$Store.set('SystemDicSettings', JSON.stringify([]));
					}
				} else {
					this.$message.error(rsp.data.Message);
					this.$Store.set('SystemDicSettings', JSON.stringify([]));
				}
			});
			}

		},
		beforeCreate(){
			this.$http.post('system:/User/GetUserInfo').then(res=>{
				window.localStorage.setItem('mx-resource-local-lang', "zh");
				this.$Store.set('lag',res.data.Data.User.Language==1?'zh':'en');
				this.$i18n.locale = this.$Store.get('lag')?this.$Store.get('lag'):'zh'
				this.$Store.set('Language',res.data.Data.User.Language)
				this.language_category = this.$Store.get('Language')
			})
			R = this.getSelfLanguage('System', 'System_Index')
		},
		mounted() {
			if(location.href.indexOf('?') != '-1') {
				var token = decodeURIComponent(location.href).split('=')[1].split(';')[0];
				this.$Store.set('SyetemKinds', null)
				var name = decodeURIComponent(location.href).split('=')[3].split(';')[0];
				this.$Store.set('token', token)
				this.$Store.set('name', name)
				var CorpID = decodeURIComponent(location.href).split('=')[4].split(';')[0];
				this.$Store.set('CorpID', CorpID)
				var UserID = decodeURIComponent(location.href).split('=')[5].split(';')[0];
				this.$Store.set('UserID', UserID)
				this.passType = this.getPassword();
			    this.$Store.set('passType', JSON.stringify(this.passType));
			}
			this.mountedShow();
			
            const ssoSetting = JSON.parse(this.$Store.get("SSOSetting") || "{}");
			this.isShowLogOut = !(ssoSetting && ssoSetting.IsEnableSSO);
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss'>
	.vue-treeselect__menu-container{
		width:300px!important
	}
	.vue-treeselect__menu {
        width:300px!important
    }
	*{
		font-family: "微软雅黑";
	}
	th .ivu-table-cell{
		vertical-align: none!important;
	}
	.el-form>.el-form-item{
		display: flex;
		align-items: center;
	}
	.el-form-item__content{
		margin-left: 0!important;
	}
	.el-menu-item, .el-submenu__title{
		height: 42px!important;
		line-height: 42px!important;
		font-size: 15px!important;
		color: #CCCCCC;
		span{
			font-size: 15px!important;
		}
	}
	.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow{
		margin-top: -4px!important;
	}
	.el-submenu__icon-arrow{
		margin-top: -5px!important;
	}
	
		.el-menu-item,
	.el-submenu__title {
		font-size: 15px !important;
		height: 42px!important;
		line-height: 42px!important;
		color: #ccc!important;
		span {
			font-size: 15px !important;
		}
	}
	
	.el-submenu .el-menu-item {
		font-size: 14px !important;
	}
	
	.el-menu-item i {
		color: #ccc!important;
	}
	
	.el-menu-item.is-active i {
		color: white!important;
	}
	
	.el-menu-item.is-active span {
		color: white!important;
	}
	
	.el-submenu {
		color: #ffffff !important;
	}
	
	.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
		right: 6px !important;
	}
	
	.ivu-table-cell{
		height: 30px;
		line-height: 30px;
		color: #3f4450f7;
		.ivu-tooltip-rel{
			span{
				color: #3f4450f7;
			}
		}
		
	}
	.el-menu-item-group{
		.el-menu-item{
			height: 42px;
			line-height: 42px;
		}
	}
	.el-textarea.is-disabled .el-textarea__inner{
		color:#3f4450f7!important;
	}
	.el-input.is-disabled .el-input__inner{
		color:#3f4450f7!important;
	}
	.el-checkbox__input.is-disabled+span.el-checkbox__label{
		color:#3f4450f7!important;
	}
	.el-menu-item i{
		color: #CCCCCC !important;
	}
	.el-menu-item.is-active i{
		color: white!important;
	}
	.el-menu-item.is-active span{
		color: white!important;
	}
	.el-submenu{
		color: #CCCCCC !important;
	}
	
	.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after{
		right: 6px !important;
	}
	input::-webkit-input-placeholder,
	textarea::-webkit-input-placeholder {
		color: #BDBDBD !important;
	}
	.el-checkbox__label{
		font-size: 14px !important;
	}
	.el-radio__label{
		font-size: 14px !important;
	}
	input::-moz-input-placeholder,
	textarea::-moz-input-placeholder {
		color: #BDBDBD !important;
	}
	
	input:-moz-input-placeholder,
	textarea:-moz-input-placeholder {
		color: #BDBDBD !important;
	}
	
	input:-ms-input-placeholder,
	textarea:-ms-input-placeholder {
		color: #BDBDBD !important;
	}
	
	.el-select-dropdown__item.selected {
		color: #fa905d;
		font-weight: 700;
	}
	
	.el-select .el-input.is-focus .el-input__inner {
		border-color: #fa905d;
	}
	
	.el-select .el-input__inner:focus {
		border-color: #fa905d;
	}
	
	// loading层级全局设置
	.el-loading-mask {
		z-index: 3 !important;
	}
	
	// 分页器选中状态全局设置
	.el-pager li.active {
		background-color: #FF5402 !important;
		color: #fff !important;
		cursor: pointer!important;
	}
	
	.el-pager li {
		height: 28px!important;
		line-height: 28px!important;
		min-width: 28px !important;
		padding: 0 4px !important;
		margin: 0 5px!important;
		border-radius: 2px!important;
	}
	
	// .el-form-item.is-required .el-form-item__label:before {
	// 	display: none!important;
	// }
	// .el-form-item__content {
	// 	line-height: 30px!important;
	// }
	// .el-form-item__error {
	// 	top: 23%!important;
	// 	left: 260px!important;
	// 	padding-top: 0!important;
	// }
	// .el-form-item {
	// 	margin-bottom: 2px!important;
	// }
	// .el-form-item__label {
	// 	font-size: 14px!important;
	// }
	// 表单全局设置
	.el-form {
		>.el-form-item {
			margin-bottom: 8px;
			.el-form-item__label {
				line-height: 30px;
				font-size: 14px;
				padding: 0;
			}
			.el-form-item__content {
				line-height: 28px;
				font-size: 14px;
				.el-form-item__error {
					color: #f56c6c;
					font-size: 14px;
					line-height: 1;
					padding-top: 0px;
					position: absolute;
					top: 8px;
					left: 240px;
					min-width: 100px;
				}
				.vue-treeselect {
					width: 220px;
					.vue-treeselect__control {
						height: 28px;
						.vue-treeselect__placeholder,
						.vue-treeselect__single-value {
							line-height: 28px;
						}
					}
				}
				>.el-input {
					height: 30px;
					width: 220px;
				}
				.el-input__inner {
					height: 30px;
				}
				.el-textarea {
					width: 220px;
				}
			}
			.red {
				color: red;
				margin-left: 5px;
			}
		}
		.el-form-item:last-child {
			margin-bottom: 0;
		}
	}
	.changepass{
		.el-form-item{
			.el-form-item__label{
				line-height:15px
			}
		}
	}
	.roapeitem{
			.el-form-item__label {
				line-height: 15px!important;
			}
	}
	
	.el-dropdown-menu__item {
		font-size: 14px!important;
	}
	
	.routerView {
		position: absolute!important;
		top: 15px;
		left: 15px;
		right: 15px;
		bottom: 15px;
		background-color: white;
		.el-main {
			padding: 15px !important;
			position: relative;
		}
	}
	
	.ivu-table-wrapper {
		border: none!important;
	}
	
	.ivu-table-wrapper {
		border: none;
	}
	
	.vue-treeselect {
		width: 162px;
		display: inline-block;
		// margin-bottom: 10px;
		// height: 15px;
		padding: 0;
	}
	
	.ivu-input[disabled],
	fieldset[disabled] .ivu-input {
		background-color: initial!important;
		opacity: 1;
		cursor: not-allowed;
		color: #3f4450f7!important;
		border: none!important;
		input::-webkit-input-placeholder,
		textarea::-webkit-input-placeholder {
			color: #3f4450f7 !important;
		}
		
		input::-moz-input-placeholder,
		textarea::-moz-input-placeholder {
			color: #3f4450f7 !important;
		}
		
		input:-moz-input-placeholder,
		textarea:-moz-input-placeholder {
			color: #3f4450f7 !important;
		}
		
		input:-ms-input-placeholder,
		textarea:-ms-input-placeholder {
			color: #3f4450f7 !important;
		}
	}
	
	.icon-guanliyuan-copy,
	.icon-mima {
		position: relative!important;
		top: 2px!important;
	}
	
	.ivu-table-stripe .ivu-table-body tr.ivu-table-row-hover td,
	.ivu-table-stripe .ivu-table-fixed-body tr.ivu-table-row-hover td {
		background-color: #f5f7fa !important
	}
	
	.ivu-table-stripe .ivu-table-body tr:nth-child(2n) td,
	.ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td {
		.ivu-input[disabled],
		fieldset[disabled] .ivu-input {
			background-color: #ecf2f3!important;
		}
	}
	
	.ivu-table-row-hover {
		.ivu-input[disabled],
		fieldset[disabled] .ivu-input {
			background-color: #f5f7fa!important;
		}
	}
	
	.ivu-table-stripe .ivu-table-body tr:nth-child(2n) td,
	.ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td {
		background: #ecf2f3 !important;
	}
	
	.el-menu-item:hover {
		span{
			color: white!important;
		}
		i {
			color: white !important;
		}
	}
	
	.commontree {
		position: absolute!important;
		top: 40px;
		bottom: 40px;
		left: 0;
		right: 0;
		overflow: auto;
	}
	
	/*.el-tree {
		position: absolute!important;
	}
	*/
	.ivu-checkbox-checked .ivu-checkbox-inner {
		background-color: #FF5402!important;
		color: white!important;
		border: #FF5402!important;
	}
	
	.ivu-table th {
		background-color: #ecf2f3 !important;
	}
	
	.index{
		.ivu-table td,
		.ivu-table th {
			height: 36px;
		}
	}  
	
	.ivu-table-cell {
		overflow: visible;
		position: relative;
		padding-left: 2px!important;
		padding-right: 2px!important;
	}
	
	.purple {
		background-color: #645BD5;
		.el-progress-bar__inner {
			background-color: #5AC8FA!important;
		}
	}
	
	.el-progress__text {
		display: none;
	}
	
	.red {
		.el-progress-bar__inner {
			background-color: #FFC600!important;
		}
	}
	
	.ivu-icon-md-add-circle,
	.ivu-icon-ios-checkmark-circle-outline {
		margin-right: 10px!important;
		cursor: pointer!important;
	}
	
	.ivu-icon-ios-remove-circle-outline,
	.ios-checkmark-circle-outline {
		cursor: pointer;
	}
	
	.el-popover {
		.Printed:hover {
			background: #ff5402;
			color: #fff;
		}
		.Printed {
			margin-top: 5px;
			width: 100%;
			height: 44px;
			text-align: center;
			line-height: 44px;
			cursor: pointer;
			font-size: 14px;
			color: #7b8082;
		}
		.operatinged:hover {
			background: #ff5402;
			color: #fff;
		}
		.operatinged {
			width: 100%;
			height: 44px;
			text-align: center;
			line-height: 44px;
			cursor: pointer;
			font-size: 14px;
			color: #7b8082;
		}
		.Import_Export:hover {
			background: #ff5402;
			color: #fff;
		}
		.Import_Export {
			margin-top: 5px;
			width: 100%;
			height: 44px;
			text-align: center;
			line-height: 44px;
			cursor: pointer;
			font-size: 14px;
			color: #7b8082;
		}
		.Import_Export:nth-child(3) {
			border-top: 1px solid #eaedee;
		}
	}
	
	.el-button {
		>span {
			min-width: 50px!important;
			display: inline-block!important;
			font-size: 14px!important;
			text-align: center!important;
			>span {
				display: inline-block!important;
			}
		}
	}
	
	.el-badge__content.is-fixed {
		top: -5px;
		right: 16px;
	}
	
	.el-progress__text {
		display: none;
	}
	
	.el-input__inner {
		font-size: 14px!important;
	}
	
	.el-textarea__inner {
		font-size: 14px!important;
	}
	/*//搜索框背景色全局配置
	.el-input {
		.el-input__inner {
			color: #b3b9bc !important;
		}
	}
	
	.el-select .el-input {
		.el-input__inner {
			background: #f7fbfb !important;
			color: #b3b9bc !important;
		}
	}*/
	
	// 表格表头头全局设置
	.el-table th {
		background: #ecf2f3!important;
	}
	
	.el-menu-vertical-demo {
		background-color: #171e23!important;
	}
	
	.el-table--striped .el-table__body tr.el-table__row--striped td {
		background: #ecf2f3;
	}
	
	.el-aside,
	.el-main {
		overflow: visible;
	}
	
	// 按钮全局设置
	.el-button {
		height: 30px!important;
		font-size: 14px !important;
		padding: 0 10px !important;
		>span {
			min-width: 50px!important;
		}
	}
	
	.el-button:focus,
	.el-button:hover {
		color: #878787!important;
		background: #ecf1f2!important;
		border-color: #dcdfe6!important;
		>span {
			color: #878787!important;
			>span {
				color: #878787!important;
			}
		}
	}
	
	.orangebtn:focus,
	.orangebtn:hover {
		background: #FF5402!important;
		border-color: #FF5402!important;
		>span {
			color: white!important;
			>span {
				color: white!important;
			}
		}
	}
	
	.el-button:active {
		color: #878787 !important;
		border-color: #ff5402!important;
	}
	
	.orangebd {
		background: #ff5402!important;
		color: #fff!important;
		border: 1px solid #ff5402;
	}
	
	.orangebd:hover {
		background-color: #FF5402 !important;
		color: white !important;
		span {
			color: white!important;
		}
	}
	
	.orangebd:active {
		background: #FF5402!important;
		border-color: #FF5402 !important;
	}
	
	.orangebd:focus {
		background: #ff5402!important;
	}
	
	.orangebd:focus>span,
	.orangebd:hover>span {
		color: #fff !important;
	}
	/*.el-submenu.is-opened {*/
	
	// .el-menu--inline {
	// 	background-color: #FF5402!important;
	// }
	
	.vue-treeselect__control {
		padding-left: 10px !important;
	}
	.vue-treeselect__multi-value-item{
		color: #909399 !important;
		background: #F0F2F5;
	}
	.vue-treeselect__value-remove{
		color: #909399 !important;
	}
	.vue-treeselect--has-value .vue-treeselect__multi-value {
		height: 30px !important;
		overflow: auto;
		margin-bottom: 0!important;	
	}
	.Advanced_Search{
		.vue-treeselect--has-value .vue-treeselect__multi-value {
			height: auto !important;
		}
	}
	.vue-treeselect__multi-value-item-container{
		padding-top: 2.5px;
	}
	.vue-treeselect__menu-container,.vue-treeselect:focus{
		-webkit-focus-ring-color:#FF5402 !important;
		outline-color:#FF5402 !important;
	}
	.vue-treeselect__control{
		outline-color:#FF5402 !important;
	}
	.vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control{
		border-color: #FF5402 !important;
		box-shadow: 0 0 3px rgba(255, 84, 2, 0.1)
	}
	.vue-treeselect__checkbox-container:hover{
		border-color: #FF5402 !important;
	}
	.vue-treeselect__checkbox--checked{
		border-color: #FF5402 !important;
		background: #FF5402 !important;
	}
	.vue-treeselect__checkbox--checked:hover{
		border-color: #FF5402 !important;
		background: #FF5402 !important;
	}
	.vue-treeselect__option--selected:hover{
		.vue-treeselect__checkbox--checked{
			border-color: #FF5402 !important;
			background: #FF5402 !important;
		}
	}
	.vue-treeselect__option--highlight{
		.vue-treeselect__checkbox{
			border-color: #FF5402 !important;
		}
	}
	.el-tree-node__label {
		font-size: 14px !important;
	}
	
	.el-radio__input.is-checked .el-radio__inner {
		border-color: #FA905D!important;
		background: #FA905D!important;
	}
	
	.el-radio__inner:hover {
		border-color: #FA905D!important;
	}
	
	.el-radio__input.is-checked+.el-radio__label {
		color: #FA905D!important;
	}
	
	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #FA905D;
	}
	
	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #FA905D;
		border-color: #FA905D;
	}
	
	.el-checkbox__inner:hover {
		border-color: #FA905D;
	}
	
	.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
		.custom-tree-node>span:nth-of-type(1) {
			background-color: #FF5402!important;
		}
		.islocked {
			color: white;
		}
		.btns {
			color: #FF5402!important;
			;
		}
		color: white !important;
	}
	
	.el-tree-node__content {
		height: 34px !important;
		.custom-tree-node .mytreelistitem {
			padding: 5px 16px !important;
			box-sizing: border-box !important;
			max-width: 144px !important;
			overflow: hidden !important;
			white-space: nowrap !important;
			text-overflow: ellipsis !important;
			border-radius: 4px !important;
			.el-checkbox {
				margin-right: 10px !important;
			}
		}
	}
	/*定义滚动条轨道 内阴影+圆角*/
	
	.ivu-table-wrapper {
		border: none !important;
	}
	
	.ivu-table:after {
		width: 0 !important;
	}
	
	.ivu-table:before {
		height: 0 !important;
	}
	
	::-webkit-scrollbar {
	    width: 8px;
	    height: 8px;
	}
	/*定义滚动条轨道 内阴影+圆角*/
	
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
		background-color: #dfe9eb;
	}
	/*定义滑块 内阴影+圆角*/
	::-webkit-scrollbar-thumb {
	    height: 50px;
	    background-color: #aeafb0;
	    -webkit-border-radius: 4px;
	    outline-offset: -2px;
	    border: 2px solid #aeafb0;
	}
	 ::-webkit-scrollbar-thumb:hover {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px #d2dddf;
		background-color: #fff;
	}
	
	
	* {
		padding: 0;
		margin: 0;
		list-style: none;
		font-family: MicrosoftYaHei;
		font-size: 14px;
	}
	
	.vue-treeselect {
		width: 240px;
		.vue-treeselect__control {
			height: 30px;
			.vue-treeselect__placeholder,
			.vue-treeselect__single-value {
				line-height: 30px;
			}
		}
	}
	
	.ivu-input {
		height: 30px;
		font-size: 14px;
	}
	.ivu-table-cell{
		.ivu-input {
			height: 30px;
			font-size: 13px;
		}
		span{
			font-size:14px;
			white-space: nowrap;
		}
		.ivu-table-cell-tooltip-content{
			font-size:13px;
		}
		
	}
	.index {
		min-width: 1440px;
		min-height: 748px;
		height: 100%;
		overflow: hidden;
		// 安全模块标准格式
		.Standardformat{
			display: flex;
			flex-direction: column;
			.header{
				height: 30px;
				display: flex;
				align-items: center;
				.topTreeselect{
					>.el-button{
						margin-left: 5px;
					}
					display: flex;
					align-items: center;
					margin-right: 4px;
					>.el-checkbox{
						margin-left: 5px!important;
						>.el-checkbox__label{
							padding-left: 5px;
						}
					}
					
					>.text {
						font-size: 14px;
						font-family: FZLTCHK--GBK1-0;
						font-weight: 400;
						color: rgba(85, 85, 85, 1);
					}
					>.vue-treeselect {
						width:200px!important;
						.vue-treeselect__control {
							height: 30px;
							.vue-treeselect__placeholder,
							.vue-treeselect__single-value {
								line-height: 30px;
							}
						}
					}
					.vue-treeselect--has-value .vue-treeselect__multi-value {
							height: 23px;
							overflow: auto;
					}
					.vue-treeselect__control{
							height: 30px!important;
							overflow: auto;
					}
					.el-select__tags{
						max-width:225px;
					}
					.el-select__tags{
						max-width: 100%!important;
					}
					>.el-input{
						width: 200px;
					}
				}
			}
			.main{
				flex: auto;
				padding:10px 0;
				box-sizing: border-box; 
				>.listTable{
					height: 100%;
				}
			}
			.footer{
				height: 60px;
				position: relative;
				background: #ecf2f3;
				padding: 0 15px;
				>div {
					position: absolute;
					height: 30px;
					top: 0;
					bottom: 0;
					right: 15px;
					left: 15px;
					margin: auto;
					.paginarion {
						float: left;
						margin: auto;
						height: 30px;
						display: flex !important;
						align-items: center;
						.block {
							display: inline-block;
						}
						.selected {
							margin-left: 16px;
							color: #535f6b;
							span {
								color: #ff5402;
							}
						}
					}
					.totalCenter {
						font-weight: 400;
						color: #535f6b;
						height: 30px;
						float: right;
						line-height: 30px;
						display: flex;
						align-items: center;
						>div {
							margin-left: 5px;
							>span {
								/*color: #ff5402;*/
							}
						}
					}
				}
			}
		}
		
		.PasswordElasticFrame{
			padding-top:50px;
			padding-left: 42px;
			box-sizing: border-box;
		}
		.vue-treeselect__menu {width:300px!important;}
		.el-main {
			position: auto;
		}
		.mask {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 2;
			border-radius: 5px;
		}
		.el-aside,
		.el-main {
			overflow: visible;
		}
		.class-e {
			background: #fb3838 !important;
		}
		.class-f {
			background: #ff9600 !important;
		}
		.class-g {
			background: #06ba4e !important;
		}
		.el-aside {
			width: 240px;
			height: 100%;
			position: relative;
		}
		.productOption {
			margin-top: 5px;
			cursor: pointer;
		}
		.productOption:hover {
			background: #ff5402;
			color: #fff;
		}
		.languageColor {
			color: #ff5402 !important;
		}
		.changeColor {
			background: #ff5402;
			color: #fff !important;
		}
		.is-vertical {
			padding: 0 10px 10px 10px;
			box-sizing: border-box;
			.el-main {
				margin-top: 10px;
				padding: 15px !important;
				background: #fff;
				position: relative;
			}
		}
		/*插眼*/
		.container {
			/*// position: fixed;
			// left: 0;
			// top: 0;
			// right: 0;
			// bottom: 0;*/
			height: 100%;
			background: #eff7f8;
			.el-menu {
				overflow: auto;
				height: 92%;
			}
			.el-menu {
				/*position: absolute;*/
				/*top: 80px;
				bottom: 80px;*/
				/*width: 212px;*/
				/*height: 82%;*/
				overflow: auto;
				.el-menu-item-group {
					>ul {
						background: #0d1115;
					}
				}
				.el-menu-item {
					border-bottom: 1px solid #242E35;
					span{
						color: #CCCCCC;
					}
					font-size: 14px!important;
				}
				.iconfont {
					font-size: 20px;
					margin-right: 4px;
				}
				.el-aside,
				.el-main {
					overflow: visible;
				}
				.myicon {
					position: absolute;
					right: 0px;
					color: #fff;
					font-size: 16px;
					margin: 0;
				}
				.icontwo {
					font-size: 16px;
					position: absolute;
					right: 14px;
				}
			}
		}
		.colors {
			background: #169bd5;
			opacity: 1 !important;
		}
		.discoloration {
			background: #696976;
		}
		//消息弹出狂
		.News {
			z-index: 666;
			width: 400px;
			position: fixed;
			top: 9%;
			right: 12px;
			bottom: 1%;
			background: #fff;
			transition: 0.8s;
			padding: 18px;
			box-sizing: border-box;
			.el-tabs {
				position: absolute!important;
				left: 18px;
				right: 18px;
				top: 18px;
				bottom: 18px;
				.el-tabs__content {
					position: absolute!important;
					top: 48px;
					left: 0;
					bottom: 0;
					right: 0;
					overflow: auto;
					.el-tab-pane {
						/*max-height: 600px;*/
						overflow: auto;
						.Business_message {
							.time {
								height: 20px;
								border-radius: 14px;
								color: #7b8082;
								width: 140px;
								height: 20px;
								border: 1px solid #f4f7f8;
								background: #fff;
								font-size: 14px;
								line-height: 20px;
								text-align: center;
							}
							.content {
								border-left: 2px solid #F0F3F4;
								margin-left: 16px;
								padding: 14px 0 18px 5px;
								font-weight: 400;
								color: rgba(123, 128, 130, 1);
								font-size: 14px;
								div {
									display: flex;
									// align-items: center;
									height: 20px;
									.status {
										width: 32px;
										height: 19px;
										display: inline-block;
										border-radius: 4px;
										color: #fff;
										text-align: center;
										line-height: 19px;
										margin-left: 5px;
										font-size: 14px;
										background: #ff5402;
									}
									.statuscontent {
										width: 290px;
										font-size: 14px;
										margin-left: 10px;
										color: #7b8082;
										font-weight: 400;
										height: auto;
									}
								}
							}
						}
					}
				}
			}
			.el-tabs__item {
				height: 40px;
				line-height: 40px;
				span {
					font-size: 14px!important;
					i {
						font-style: normal;
						display: inline-block;
						width: 16px;
						height: 16px;
						border-radius: 50%;
						background: #f6292a;
						text-align: center;
						line-height: 16px;
						color: #fff;
						margin-left: 5px;
					}
				}
			}
			>p:hover {
				background: #ff5402;
				color: #fff;
			}
			>p {
				cursor: pointer;
				width: 104px;
				height: 44px;
				font-size: 14px;
				color: #5b5f61;
				font-weight: 400;
				line-height: 44px;
				text-align: center;
				box-sizing: border-box !important;
				display: inline-block;
				position: absolute;
				margin: auto;
				right: 0;
				left: 0;
				bottom: 34px;
				background: #fff;
				border: 1px solid #edf0f0;
				border-radius: 20px;
			}
			.back {
				position: absolute;
				right: -12px;
				top: -12px;
				img {
					width: 20px;
					height: 20px;
					cursor: pointer;
				}
			}
		}
		.enter {
			position: fixed !important;
			right: 0 !important;
			transition: 0.8s !important;
		}
		.select {
			background: #4189dd;
			color: #fff !important;
		}
		.logo {
			width: 100%;
			height: 80px;
			box-sizing: border-box;
			background: #0d1115;
			position: relative;
			img {
				max-height: 41px;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				margin: auto;
			}
		}
		// 右半边头部
		.el-header {
			border: 2px solid #e7eaeb;
			border-top: none;
			width: 100%;
			height: 80px!important;
			background-color: #fff;
			border-radius: 0 0 8px 8px;
			position: relative;
			padding: 0 0;
			>div {
				width: 100%;
				height: 20px;
				padding: 0 29px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				font-size: 14px;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				.headerLeft {
					display: flex;
					>span {
						display: block;
						.product {
							cursor: pointer;
							display: inline-block;
							height: 16px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							position: relative;
							margin-right: 20px;
							span {
								font-size: 14px;
								font-weight: 400;
								color: rgba(108, 115, 117, 1);
								line-height: 20px;
								margin: 0 16px 0 8px;
							}
							.popover {
								white-space: nowrap;
								z-index: 999;
								position: absolute;
								left: 0;
								top: 32px;
								border-radius: 4px;
								li:hover {
									background: rgb(91, 91, 101);
								}
								li {
									display: flex;
									align-items: center;
									background: #444;
									list-style: none;
									color: #fff;
									padding: 10px;
									cursor: pointer;
									i {
										margin-right: 5px;
										width: 14px;
										height: 14px;
										display: inline-block;
									}
								}
							}
							.yun {
								height: 20px;
							}
						}
					}
					.Company_Name {
						height: 16px;
						align-items: center;
						display: flex;
						h3 {
							margin-left: 8px;
							font-size: 16px;
							font-weight: 400;
							color: rgba(143, 144, 160, 1);
							line-height: 14px;
						}
						img {
							height: 22px;
						}
					}
				}
				.headerCenter {
					display: flex;
					align-items: center;
					margin-left: 100px;
					h3 {
						font-size: 16px;
						font-weight: normal;
						color: rgba(32, 33, 51, 1);
						margin-left: 14px;
					}
					img {
						height: 30px;
					}
				}
				.headerRight {
					.name {
						max-width: 80px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 14px;
					}
					display: flex;
					height: 20px;
					align-items: center;
					>span {
						display: block;
						.touxiang {
							>img {
								width: 35px;
							}
						}
						.features {
							.img {
								height: 24px;
							}
							cursor: pointer;
							display: flex;
							height: 24px;
							align-items: center;
							position: relative;
							border-right: 1px solid #878896;
							padding: 0 15px;
							h3 {
								font-size: 16px;
								font-family: FZLTHK--GBK1-0;
								font-weight: 400;
								color: rgba(135, 136, 150, 1);
								line-height: 20px;
								margin-left: 10px;
							}
							.count {
								margin-top: -20px;
								width: 16px;
								height: 16px;
								border-radius: 50%;
								background: #ff5402;
								text-align: center;
								line-height: 16px;
								font-size: 14px;
								font-weight: bold;
								color: rgba(255, 255, 255, 1);
							}
							.counts {
								margin-top: -20px;
								width: 8px;
								height: 8px;
								border-radius: 50%;
								background: #ff5402;
							}
							//微信公众号
							.wx {
								z-index: 999;
								padding: 18px;
								background: #444;
								display: flex;
								width: 100px;
								height: 100px;
								position: absolute;
								left: -18px;
								top: 32px;
								border-radius: 4px;
								img {
									width: 50px;
									height: 50px;
								}
								p {
									width: 60px;
									color: #fff;
									font-size: 14px;
								}
							}
						}
					}
					>span:last-child {
						border: none;
						>div {
							padding-right: 0;
						}
					}
				}
			}
		}
		//左侧菜单栏图标设置
		.tbkinds {
			margin-right: 10px;
		}
		.tbkinds2 {
			position: absolute;
			top: 0px;
			bottom: 0px;
			z-index: 999;
			margin: auto;
			right: 27px;
		}
	}
	
	.changeColor {
		background: #ff5402;
		color: #fff !important;
	}
	
	.el-popover {
		text-align: center !important;
		min-width: 130px !important;
		padding: 10px !important;
		.popper__arrow {
			top: -9999px;
		}
		.Printed {
			margin-top: 5px;
			width: 100%;
			height: 30px !important;
			text-align: center;
			line-height: 30px !important;
			cursor: pointer;
			font-size: 14px !important;
			color: #7b8082;
		}
		>div {
			height: 30px;
			text-align: center;
			line-height: 30px;
			font-size: 14px !important;
			color: #7b8082;
			cursor: pointer;
			font-weight: 400;
		}
		.hover {
			margin-top: 5px;
		}
		.hover:hover {
			background: #ff5402;
			color: #fff;
		}
		.wx {
			width: 100px;
			height: 100px;
			background: red;
			display: inline-block;
		}
		.escription {
			text-align: center;
			font-size: 14px;
			font-weight: 400;
			color: rgba(123, 128, 130, 1);
		}
		.tx {
			height: auto;
			.password {
				cursor: pointer;
				width: 100%;
				height: 30px;
				background: #ff5402;
				font-size: 14px;
				font-weight: 400;
				color: #fff;
				text-align: center;
				line-height: 30px;
			}
			.Chinese_English {
				text-align: left;
				padding-left: 18px;
				display: flex;
				align-items: center;
				box-sizing: border-box;
				cursor: pointer;
				width: 100%;
				height: 30px;
				color: #7b8082;
				margin-top: 20px;
				div {
					width: 20px;
					height: 20px;
					img {
						width: 16px;
						height: 16px;
						display: block;
					}
				}
				span {
					margin-left: 5px;
					font-size: 14px;
				}
			}
			.back {
				margin-top: 15px;
				width: 100%;
				padding: 0 15px;
				border-top: 1px solid #eaedee;
				font-size: 14px;
				color: #ff5402;
				align-items: center;
				display: flex;
				box-sizing: border-box;
				img {
					width: 16px;
					height: 16px;
				}
				span {
					font-size: 14px;
					margin-left: 5px;
					cursor: pointer;
				}
			}
		}
		.advisory {
			width: 100%;
			height: 34px;
			text-align: center;
			line-height: 35px;
			cursor: pointer;
			font-size: 14px;
			color: #7b8082;
			margin-top: 5px;
		}
		.advisory:hover {
			background: #ff5402;
			color: #fff;
		}
	}
</style>