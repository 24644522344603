<template>
	<div id="dataimport" v-if="IsPageDome">
		<p class="title">{{R.title}}</p>
		<ul>
			<li>
				<div class="left">
					<img class="step" v-bind:src="step1.iconUrl" alt="">
					<img class="down" src="static/images/bottom.png" alt="" srcset="">
				</div>
				<div class="right">
					<div>
						<img src="static/images/download.png" alt="" srcset="">
						<div>
							<p class="introduce">{{step1.decription.para1}}</p>
							<p class="button">
								<el-button round @click="downloadImportTemplate">{{step1.decription.para3}}</el-button>
							</p>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="left">
					<img class="step" v-bind:src="step2.iconUrl" alt="">
					<img class="down" src="static/images/bottom.png" alt="" srcset="">
				</div>
				<div class="right">
					<div>
						<img src="static/images/Template.png" alt="" srcset="">
						<div>
							<p class="introduce">{{step2.description.para1}}</p>
							<p class="Tips">{{step2.description.para2}}</p>
							<ul>
								<li>{{step2.description.para3}}</li>
								<li>{{step2.description.para4}}</li>
							</ul>
						</div>
					</div>
				</div>
			</li>
			<li>
				<div class="left">
					<img class="step" v-bind:src="step3.iconUrl" alt="">
				</div>
				<div class="right">
					<div>
						<img src="static/images/upload.png" alt="" srcset="">
						<div>
							<p class="introduce">{{step3.description.para1}}</p>
							<p class="Tips">{{step3.description.para2}}</p>
							<ul>
								<li>{{step3.description.para3}}</li>
								<li>{{step3.description.para4}}</li>
								<li>{{step3.description.para5}}</li>
							</ul>
							<div class="button">
								<p>
									<el-upload ref="upload" :file-list="list" :action="uploadUrl" :multiple="false" :show-file-list="true" :limit="1" :headers="token" :on-progress="importing" :on-success="imported" :auto-upload="false" :before-upload="uploadAttachment_BeforeUpload">
										<el-button slot="trigger">{{buttons.selectFile}}</el-button>
										<el-button v-if="getpermission('Import')" style="margin-left: 20px;" type="success" @click="uploadFile">{{buttons.import}}</el-button>
									</el-upload>
								</p>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
		<p v-if="isShowMsg" class="state">{{executinMsg}}</p>
		<ul>
			<li v-for="detail of importResult.details" :key="detail.SheetName">
				<span v-html="detail.html"></span>
			</li>
			<li v-if="importResult.downloadUrl">
				<a :href="importResult.downloadUrl">{{importResult.downloadUrlText}}</a>
			</li>
		</ul>
	</div>
</template>

<script>
	import service from "../../httpConfig/api.js"
	let R = {};
	// 多语言资源数据
	export default {
		name: 'dataimport',
		data() {
			return {
				IsPageDome: true,
				R: R,
				list: [],
				// 标题
				title: R.Asset_Data_Import_1,
				// 步骤一
				step1: {
					iconUrl: (this.$Store.get('lag') == 'zh' ? 'static/images/one.png' : 'static/images/oneEN.png'),
					decription: {
						para1: R.Asset_Data_Import_Step1_Para1,
						para2: R.Asset_Data_Import_Step1_Para2,
						para3: R.Asset_Data_Import_Step1_Para3,
					}
				},
				// 步骤二
				step2: {
					iconUrl: (this.$Store.get('lag') == 'zh' ? 'static/images/two.png' : 'static/images/twoEN.png'),
					description: {
						para1: R.Asset_Data_Import_Step2_Para1,
						para2: R.Asset_Data_Import_Step2_Para2,
						para3: R.Asset_Data_Import_Step2_Para3,
						para4: R.Asset_Data_Import_Step2_Para4,
					}
				},
				// 步骤三
				step3: {
					iconUrl: (this.$Store.get('lag') == 'zh' ? 'static/images/three.png' : 'static/images/threeEN.png'),
					description: {
						para1: R.Asset_Data_Import_Step3_Para1,
						para2: R.Asset_Data_Import_Step3_Para2,
						para3: R.Asset_Data_Import_Step3_Para3,
						para4: R.Asset_Data_Import_Step3_Para4,
						para5: R.Asset_Data_Import_Step3_Para5,
						para6: R.Asset_Data_Import_Step3_Para6,
					}
				},
				// 执行中信息
				executinMsg: R.Asset_Data_Import_4,
				executinMsgBak: R.Asset_Data_Import_4,
				// 按钮
				buttons: {
					selectFile: R.Common_Button_SelectFile,
					import: R.Asset_Data_Import_3,
				},
				//导入结果
				importResult: {
					success: {
						title: R.Asset_Data_Import_Success,
						template: R.Asset_Data_Import_Success_Template,
					},
					failed: {
						title: R.Asset_Data_Import_Failed,
						template: R.Asset_Data_Import_Failed_Template,
					},
					details: [],
					downloadUrl: null,
					downloadUrlText: R.Asset_Data_Import_5,
				},
				Data_Import_Msg_1: R.Asset_Data_Import_6,
				Data_Import_Msg_2: R.Asset_Data_Import_7,
				isShowMsg: false,
				permissionCode: [], //存储功能权限
				apiUrl: '',
				// 多语言资源对象

				// 上传Excel文件时，需要指定访问令牌
				token: {
					"X-Token": this.$Store.get('token'),
				},
				uploadUrl: '',
			}
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'Asset_BasicData_Import')
		},
		beforeUpdate() {
			const that = this
			window.onresize = () => {
				return(() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					that.screenWidth = window.screenWidth
					that.screenHeight = window.screenHeight
				})()
			}
			//			this.tableHeight = this.$refs.table.offsetHeight;
		},
		methods: {
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {
						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}

			},
			//重置多语言资源项
			resetLanguageResources(items) {
				let r = getResourceObject(items);
				this.R = r;
			},
			//下载导入模板
			downloadImportTemplate() {
				this.$http.post('/DataImport/DownloadTemplate', {

				}, {
					headers: {
						"Content-Disposition": "attachment;filename=template.xls",
						"Content-Type": "application/octet-stream",
					},
					responseType: 'blob'
				}).then((rsp) => {
					if(!rsp) {
						return;
					}
					if(rsp.data.type == 'application/json') {
						const blb = new Blob([rsp.data], {
							type: "text/plain"
						});
						const reader = new FileReader();
						// This fires after the blob has been read/loaded.
						reader.addEventListener('loadend', (e) => {
							const text = e.srcElement.result;
							this.$message.error(text.split(':')[2].split(',')[0])
						});
						// Start reading the blob as text.
						reader.readAsText(blb);
						return
					}
					let url = window.URL.createObjectURL(new Blob([rsp.data]));
					let link = document.createElement('a');
					if(window.navigator.msSaveBlob) {
						var blobObject = new Blob([rsp.data])
						window.navigator.msSaveBlob(blobObject, R.Asset_Data_Import_11 + '.xls'); // The user only has the option of clicking the Save button.
						return
					}
					link.style.display = 'none';
					link.href = url;
					link.setAttribute('download', R.Asset_Data_Import_11 + '.xls');
					//
					document.body.appendChild(link);
					link.click();
				}).catch((error) => {
					alert(error)
				});
			},
			//上传Excel数据文件
			uploadFile() {
				this.executinMsg = '';
				this.importResult.details = [];
				if(this.$refs.upload.uploadFiles.length == '0') {
					this.$message.error(R.Asset_Data_Import_9)
					return
				}
				this.$refs.upload.submit();
			},
			//执行导入中
			importing() {
				this.executinMsg = this.executinMsgBak;
				this.importResult.downloadUrl = '';
				this.isShowMsg = true;

				// this.R.importResult.downloadUrl='';
				// if(this.R.executinMsg==''){
				// 	this.R.executinMsg=this.R.executinMsgBak;
				// }else{
				// 	this.R.executinMsg+='.';
				// }
			},
			//导入结束
			imported(rsp, file, fileList) {
				this.isShowMsg = false;
				if(rsp.Code == 0) {
					//显示导入结果
					var isSuccess = rsp.Data.IsOk;
					if(isSuccess) {
						this.executinMsg = this.importResult.success.title;
						this.isShowMsg = true;
					} else {
						this.executinMsg = this.importResult.failed.title;
						this.isShowMsg = true;
						for(var i = 0; i < rsp.Data.Content.length; i++) {
							let c = rsp.Data.Content[i];
							if(c.IsOk) { //sheet导入成功
								c.html = this.importResult.success.template.format(c.SheetName);
							} else {
								c.html = this.importResult.failed.template.format(c.SheetName, c.ErrorCount);
							}

						}
						this.importResult.details = rsp.Data.Content;
						this.importResult.downloadUrl = service.getFileUrl(rsp.Data.FileUrl);
					}
				} else {
					//接口调用错误
					this.$message.error(rsp.Message);
				}
				//清除上传的文件
				this.$refs.upload.clearFiles();
			},
			uploadAttachment_BeforeUpload(file) {               
				this.isShowMsg = false;                
				this.executinMsg = '';                
				var fileName = new Array()
				fileName = file.name.split('.');
				const extension = fileName[fileName.length - 1] === 'xls'
				const extension2 = fileName[fileName.length - 1] === 'xlsx'
				const isMaxLength = file.size / 1024 / 1024 < 10
				if(!extension && !extension2) {                    
					this.$message.error(this.Data_Import_Msg_1);                    
					return false;                
				}                                
				if(!isMaxLength) {                    
					this.$message.error(this.Data_Import_Msg_2);                    
					return false;                
				}                
				return extension || extension2 && isMaxLength;            
			},
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted() {
			this.$PermissionUtil.getPermission('System/Import').then((res)=>{
				this.permissionCode = res;
			});
			this.uploadUrl = service.getDefaultServiceAPIUrl('/DataImport/Import');
			this.R.executinMsg = '';
		}
	}
</script>

<style lang="scss">
	#dataimport {
		background: #fff;
		overflow: auto;
		.title {
			color: #626768;
			font-size: 14px;
			font-weight: bold;
		}
		>ul {
			margin-top: 46px;
			margin-left: 42px;
			>li {
				display: flex;
				margin-bottom: 32px;
				.left {
					width: 73px;
					text-align: center;
					.step {
						width: 73px;
					}
					.down {
						width: 19px;
						margin-top: 40px;
					}
				}
				.right {
					flex: 1;
					margin-left: 54px;
					>div {
						display: flex;
						>img {
							width: 35px;
							height: 35px;
						}
						>div {
							margin-left: 21px;
							.introduce {
								color: #353839;
								font-size: 14px;
								font-weight: bold;
								width: 512px;
							}
							>ul>li,
							.Tips {
								font-size: 14px;
								color: #7B8082;
								font-weight: bold;
								margin-top: 5px;
							}
							.button {
								margin-top: 44px;
								display: flex;
								align-items: center;
								.el-button {
									background: #FFF6F2;
									border-color: #FF5402;
									color: #FF5402;
								}
								.el-button:focus,
								.el-button:hover {
									background: #FFF6F2 !important;
									border-color: #FF5402 !important;
									color: #FF5402 !important;
									span {
										color: #FF5402 !important;
									}
								}
								.el-button:active {
									background: #FFF6F2 !important;
									color: #FF5402 !important;
									span {
										color: #FF5402!important;
									}
								}
								>p {
									display: flex;
									align-items: center;
									/*margin-left: 4.5625rem;*/
									.el-input {
										width: 200px;
										.el-input__inner {
											height: 30px;
											font-size: 14px;
										}
									}
									.el-button {
										background: #FF5402;
										border-color: #FF5402;
										color: #fff;
										margin-left: 2px;
									}
									.el-button:focus,
									.el-button:hover {
										background: #FF5402 !important;
										border-color: #FF5402 !important;
										color: #fff !important;
										span {
											color: #fff !important;
										}
									}
									.el-button:active {
										background: #FF5402 !important;
										color: #fff !important;
										span {
											color: #fff!important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.state {
			font-size: 14px;
			font-weight: bold;
			color: #7B8082;
		}
	}
</style>