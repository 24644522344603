<template>
    <el-button 
    :style="{
      width:widths,
      height:height,
      borderRadius:borderRadius,
      backgroundColor:backgroundColor,
      border:border,
      fontFamily:fontFamily,
      marginLeft:marginLeft,
      marginTop:marginTop,
      marginRight:marginRight,
      marginBottom:marginBottom,
      margin:margin,
      paddingLeft:paddingLeft,
      paddingTop:paddingTop,
      paddingRight:paddingRight,
      paddingBottom:paddingBottom,
      padding:padding,
      boxSizing:boxSizing
      }"
    @click="handleClick"
    :disabled='disabled'
    :type='type' 
    class="cj-Button" 
    :class="[
        type?'cj-Button-'+type:'',
        ml?'two':'',
        qg?'cj-Button-customize':''
    ]"
    >
        <i :class="icon" :style="{fontSize:iconSize,backgroundColor:backgroundColor}" class="icons"></i>
        <slot v-if="$slots.default"></slot>
    </el-button>
</template>
<script>
export default {
  name: "Button",
  data() {
    return {
      ml:'',
      qg:'',
      widths:''
    };
  },
  computed:{
    
  },
  watch:{
    width:function (val, oldVal) {
      this.widths=val
    }
  },
  props: {
    type: {
      type: String,
      default: "default"
    },
    //禁止点击
    disabled: Boolean,
    //设置宽度
    width: {
      type: String,
      default: "80px"
    },
    //设置高度
    height: {
      type: String,
      default: "35px"
    },
    //设置圆角
    borderRadius: {
      type: String,
      default: "4px"
    },
    //加小图标
    icon: {
      type: String
    },
    //图标大小
    iconSize: {
      type: String,
      default: "12px"
    },
    //背影颜色
    backgroundColor: {
      type: String
    },
    //按钮边框
    border: {
      type: String
    },
    //字体样式
    fontFamily: {
      type: String
    },
    //左magin
    marginLeft: {
      type: String,
      default:'10px'
    },
    //右magin
    marginRight: {
      type: String
    },
    //上magin
    marginTop: {
      type: String
    },
    //下magin
    marginBottom: {
      type: String
    },
    //margin
    margin: {
      type: String
    },
    //左padding    
    paddingLeft: {
      type: String
    },
    //右padding
    paddingRight: {
      type: String
    },
    //上padding
    paddingTop: {
      type: String
    },
    //下padding
    paddingBottom: {
      type: String
    },
    //padding
    padding: {
      type: String
    },
    //padding属性
    boxSizing: {
      type: String
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt); //事件曝光出去
    }
  },
  mounted() {
    this.widths=this.width
    var a=this.$slots.default[0].text
    var re=/^[\u4e00-\u9fa5]{0,}$/;
    //两字样式
    if(a.length==2&&(!this.icon)){
      this.ml=true
    }
    //五到六字样式
    if(a.length>=5&&a.length<=6){
        this.widths='100px'
    }
    //七字及以上样式
    if(a.length>=7){
      this.qg=true
    }
  }
};
</script>
<style lang='scss' scoped>
.two>span{
  letter-spacing: 1em;
  margin-left: 1em;
}
.cj-Button {
  padding: 0;
}
.cj-Button-primary {
  background: rgba(22, 155, 213, 1);
  color: #ffffff;
}
.cj-Button-primary:focus,
.cj-Button-primary:hover {
  background: rgba(22, 155, 213, 0.8);
  color: #ffffff;
}
.cj-Button-warning {
  background: rgba(255, 0, 0, 1);
  color: #ffffff;
}
.cj-Button-warning:focus,
.cj-Button-warning:hover {
  background: rgba(255, 0, 0, 0.7);
  color: #ffffff;
}
.cj-Button-customize{
  padding: 0px 20px;
  width: auto !important;
  // height: auto !important;
}
.icons {
  font-weight: normal;
}
</style>
