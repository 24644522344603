<template>
	<div class="OperationLog Standardformat" v-loading="grid.loading" v-if="IsPageDome">
        <div class="header">
            <div v-if="getpermission('Query')" class="topTreeselect">
                <!--资产分类-->
                <span>{{R.System_SecurityModule_OperationLog_1}}：</span>
				<OrganizationSelect
					collapseTags
					:multiple="true" 
					:placeholder="top.crops.hint"
					v-model="top.crops.value" 
					:OnlyLoadEnableOrg="true"
				/> 
            </div>
            <div v-if="getpermission('Query')" class="topTreeselect">
                <!--操作类型-->
                <span>{{R.System_SecurityModule_OperationLog_3}}：</span>
                <el-input v-model="top.operationType.value" :placeholder="top.operationType.hint"></el-input>
            </div>
            <div v-if="getpermission('Query')" class="topTreeselect">
                <!--操作类型-->
                <span>{{R.System_SecurityModule_OperationLog_4}}：</span>
                <el-date-picker style="width:140px"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="top.operationDate.startvalue" type="date" :placeholder="top.operationDate.hint"></el-date-picker>
                -<el-date-picker style="width:140px"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="top.operationDate.endvalue"  :picker-options="pickerOptions"  type="date" :placeholder="top.operationDate.hint"></el-date-picker>
            </div>
            <el-button v-if="getpermission('Query')"  @click="Search" class="orangebd" style="margin-left:5px">{{R.Common_Button_Search}}</el-button>
            <el-button v-if="getpermission('Export')" @click="ExportToExcel">{{R.Common_Button_ExportToExcel}}</el-button>
        </div>
        <div class="main">
            <div class="listTable" ref="table" >
                <Table type='selection' stripe :columns="grid.columns"  :height="grid.height" :data="grid.data"></Table>
            </div>
        </div>
        <div class="footer">
            <div>
                <div class="paginarion">
                    <template>
                        <div class="block">
                            <el-pagination :background="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="grid.pagination.pageNO" :page-sizes="[10, 20, 50, 100]" :page-size="grid.pagination.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="grid.pagination.totalQty">
                            </el-pagination>
                        </div>
                    </template>
                    <p class="selected">
                    </p>
                </div>
                
            </div>
        </div>
	</div>
</template>

<script>
	import { rejects } from 'assert';

	var R = {};
	export default {
		data() {
			return{
				permissionCode: [],
                pickerOptions: { //日期判断
                    disabledDate: (time) => {
                        return time.getTime() <new Date(this.top.operationDate.startvalue).getTime()-8.64e7;
                    }
                },
                IsPageDome:true,
				R:R,
                top:{
                    crops:{
                        options:null,
                        value:null,
                        hint:R.System_SecurityModule_OperationLog_7
                    },
                    operationType:{
                        value:null,
                        hint:R.System_SecurityModule_OperationLog_8
                    },
                    operationDate:{
                        startvalue:'',
                        endvalue:'',
                        hint:R.System_SecurityModule_OperationLog_9
                    },
                },
                grid:{
					columns:[
                        { type: 'index', width: 50,tooltip:true, align: 'center' },		// 行号
                        { align: 'center',tooltip:true,width:160, title: R.System_SecurityModule_OperationLog_Column_1, key: 'CorpName', },//公司
                     	{ align: 'center',tooltip:true,width:140, title: R.System_SecurityModule_OperationLog_Column_2, key: 'OrgName',  },//部门
                        { align: 'center',tooltip:true,width:120, title: R.System_SecurityModule_OperationLog_Column_3, key: 'EmployeeName',  },//操作员
						{ align: 'center',tooltip:true,width:150, title: R.System_SecurityModule_OperationLog_Column_4, key: 'UserName',},//用户名
						{ align: 'left',tooltip:true,width:180, title: R.System_SecurityModule_OperationLog_Column_5, key: 'OperationDateTime',},//操作时间
						{ align: 'left',tooltip:true,width:140, title: R.System_SecurityModule_OperationLog_Column_6, key: 'IPAddress', },//IP地址
                     	{ align: 'left',tooltip:true,width:100, title: R.System_SecurityModule_OperationLog_Column_7, key: 'OperationType',  },//操作类型
                        { align: 'left',tooltip:true,minWidth:180,maxWidth:400, title: R.System_SecurityModule_OperationLog_Column_8, key: 'OperationSummary',},//简要信息
						{ title: ' '}
					],	//表格列
					data:[],			//表格数据
					pagination:{		//分页
						pageSize:20		//每页记录数
						,pageNO:1		//当前页码
						,totalQty:0		//总记录数
					},
					loading:false,		//是否加载数据中
					height:'',			//表格高度
				},
				// 屏幕
				screenWidth: document.body.clientWidth,
				screenHeight: document.body.clientHeight,
            };
        },
        methods:{
            Data(){
                let data={};
                data.PageNo= this.grid.pagination.pageNO;
                data.PageSize= this.grid.pagination.pageSize;
                data.OrgIds=this.top.crops.value;
                data.OperationType=this.top.operationType.value;
                data.StartDdate=this.top.operationDate.startvalue;
                data.EndDdate=this.top.operationDate.endvalue;
                data.IsSetNumber=true;
                return data
            },
            Search(){
                this.grid.pagination.pageNO=1;
				this.pageChange()
            },
            pageChange() {
				this.grid.loading = true;
				this.$http.post('system:/System/OperationLog/List', {
					Data: this.Data()
				}).then(res => {
					if(res.data.Code == '0') {
						this.grid.data = res.data.Data.Items == null ? [] : res.data.Data.Items;
						this.grid.pagination.totalQty = res.data.Data.TotalRecordQty;
						this.grid.pagination.PageNO = res.data.Data.PageNO;
						this.grid.pagination.pageSize = res.data.Data.PageSize;
						this.SetPageSize(this.grid.pagination.pageSize,'System/SecurityModule/OperationLog')
					} else {
						this.grid.data = [];
						this.grid.pagination.totalQty = 0;
						this.grid.pagination.PageNO = 1;
						
					}
					this.grid.loading = false;
				}).catch((error)=>{
					this.grid.loading = false;
				})
			},
            //分页尺码改变
			handleSizeChange(val) {
			    this.grid.pagination.pageSize = val
			    this.grid.pagination.pageNO = 1
				this.pageChange()
			},
			//分页当前页变化
			handleCurrentChange(val) {
				this.grid.pagination.pageNO = val
				this.pageChange()
			},
            ExportToExcel(){
                this.$http.post('system:/System/OperationLog/ExportToExcel', {
                    Data: this.Data()
                }, {
                    headers: {
                        "Content-Disposition": "attachment;filename=StockSummaryByCategory.xls",
                        "Content-Type": "application/octet-stream"
                    },
                    responseType: 'blob'
                }).then((rsp) => {
                    if(!rsp) {
                        return;
                    } else if(rsp.data.Code) {
                        alert(res.data.Message)
                        return;
                    }
                    if(rsp.data.type == 'application/json') {
                        const blb = new Blob([rsp.data], {
                            type: "text/plain"
                        });
                        const reader = new FileReader();
                        // This fires after the blob has been read/loaded.
                        reader.addEventListener('loadend', (e) => {
                            const text = e.srcElement.result;
                            this.$message.error(text.split(':')[2].split(',')[0].split('"')[1])
                        });
                        // Start reading the blob as text.
                        reader.readAsText(blb);
                        return
                    }
                    let url = window.URL.createObjectURL(new Blob([rsp.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', R.System_SecurityModule_OperationLog_5+'.xls');
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {});
            },
            //判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {
						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}
			},
			
        },
		watch: {
            screenWidth (val) {
				this.grid.height=this.$refs.table.offsetHeight;
			},
			screenHeight (val) {
				this.grid.height=this.$refs.table.offsetHeight;
			},
		},
		beforeUpdate() {
			// 屏幕自适应
            const me = this
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					me.screenWidth = window.screenWidth
					me.screenHeight = window.screenHeight
				})();
			}
			this.grid.height=this.$refs.table.offsetHeight;
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_SecurityModule_OperationLog')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted(){
			this.grid.pagination.pageSize=this.GetPageSize('System/SecurityModule/OperationLog')
			this.pageChange()
			//判断是否包含新增编辑等功能权限
			this.$PermissionUtil.getPermission('System/SecurityModule/OperationLog').then((res)=>{
				this.permissionCode = res;
			});
			const me = this
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					me.screenWidth = window.screenWidth
					me.screenHeight = window.screenHeight
				})();
			}
			this.grid.height=this.$refs.table.offsetHeight;
        },
		
	};
</script>

<style lang='scss'>
	
</style>