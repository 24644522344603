<template>
    <div class="aa">
        <div class="demo-input-suffix">
        <el-input
            :placeholder="placeholder"
            v-model="value"
            
        >
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
    </div>
    </div>

</template>
<script>
export default {
    name:"search",
    data() {
      return {
       input:'',
      };
    },
    mounted(){
//    // console.log(this)
    },
    props:{
        placeholder:{
            type:String,
            default:"按编码名称搜索"
        },
        value:{
           
        }
    }
}
</script>
<style lang='scss' >
    // 属性placeholder:控制input里默认的内容
    // .el-input:可控制input宽度等其他属性
    .aa{
        .el-input{
        width: 248px;
        }
        // .el-input__inner:可控制input高度,圆角,默认内容的字体大小、颜色、字体样式等其他属性
        .el-input__inner{
            height: 53px;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            font-size: 14px;
        }
        //.el-input__inner:hover可控制鼠标移入时input的边框等样式
        .el-input__inner:hover{
            border-color:#c0c4cc;
        }
        //.el-input.is-active .el-input__inner, .el-input__inner:focus
        //可控制鼠标点击获取焦点时的input的边框等样式
        .el-input.is-active .el-input__inner, .el-input__inner:focus{
            border-color: #ff5402;
        }
    }
</style>



