<template>
    <div class="ElasticFrameMask">
        <div :class="move? 'ElasticFrame move':'ElasticFrame'" v-loading="vloading" :style="{width:width,height:height,right:right}">
            <div class="ElasticFrameheader">
                <span class="title" >{{title}}</span>
                <span class="icon"  @click="cancelShow"><i class="el-icon-circle-close"></i></span>
            </div>
            <div class="ElasticFramecontent">
                <slot name="content">
                    <div></div>
                </slot>  
            </div>
            <div class="Elasticfoot">
                <el-button @click="sureShow" :disabled="sureDisable" class="orangetext add orangebd">{{sureText}}</el-button>
                <el-button @click="cancelShow">{{cancelText}}</el-button>
            </div>
        </div>
    </div>
    
</template>
<script>
	var R = {}
    export default {
    	data(){
    		return{
    			R:R,
    		}
    	},
        name:'ElasticFrame',
        props:{
            // 标题
            title:{
               type: String, 
            },
            //设置宽度
            width: {
                type: String,
                default: "400px"
            },
            //设置高度
            height: {
                type: String,
                default: "220px"
            },
            sureDisable:{
                type: Boolean,
                default: false
            },
            sureText:{
                type: String,
                default:()=>{
                    return R.Common_Button_Sure
                }
            },
            cancelText:{
                type: String,
                default:()=>{
                    return R.Common_Button_Cancel
                }
            },
            vloading:{
                type: Boolean,
                default: false
            },
            right:{
                type:String,
                default:''
            },
            move:{
                type: Boolean,
                default: false
            }
        },
        mounted(){
        	
        },
        beforeCreate(){
        	R =  this.getSelflag('selector')? this.getSelflag('selector'):this.getSelfLanguage('SystemControl', 'selector')
			this.setSelflag('selector',R)
		},
        methods:{
            sureShow(evt){
                this.$emit("clickSure", evt);
            },
            cancelShow(evt){
                this.$emit("clickCancel", evt);
            },
        }
    }
</script>
<style lang='scss'>
    .ElasticFrameMask{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        
        .ElasticFrame,.move{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 20;
            background: #fff;
            display: flex;
            flex-direction: column;
            .ElasticFrameheader {
                height: 35px;
                line-height: 35px;
                background-color: #fa905d;
                .title {
                    padding-left: 10px;
                    display: block;
                    width: 90%;
                    float: left;
                    font-weight: 600;
                    color: white;
                }
                .icon {
                    float: right;
                    margin-right: 10px;
                    text-align: center;
                    cursor: pointer;
                    color: white;
                    i {
                        font-size: 16px;
                    }
                }
            }
            .ElasticFramecontent{
                flex: auto;
                overflow: auto;
            }
            .Elasticfoot {
                height: 30px;
                line-height: 30px;
                float: right;
                position: absolute;
                right: 15px;
                bottom: 15px;
                .el-button {
                    height: 30px!important;
                }
            }
        }
        .move{
            position: fixed;
			top: 9%;
			right: 12px;
			bottom: 1%;
			background: #fff;
			transition: 0.8s;
            left: auto;
			box-sizing: border-box; 
            margin: 0;
        }
    }
    
</style>