<template>
	<div id="org" v-loading="loadingR" v-if="IsPageDome">
		<div class="mask" v-if="mask">
			<div :class="status=='del'||status=='lock'?'addone del':'addone'" v-if="addone" v-loading="CartridgeLoading">
				<div class="head">
					<span class="title" v-if="status=='add'">{{button.addtext}}</span>
					<span class="title" v-if="status=='edited'">{{button.edittext}}</span>
					<span class="title" v-if="status=='del'">{{button.deltext}}</span>
					<span class="title" v-if="status=='lock'">{{button.stoptext}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="contentdel" v-if="status=='del'">
					<i class="el-icon-info"></i>{{button.deltip}}
				</div>
				<div class="contentdel" v-if="status=='lock'">
					<i class="el-icon-info"></i>{{button.stoptip}}
				</div>
				<div class="foot" v-if="status=='del'">
					<el-button @click="suredel" :disabled="isDisabled" class="orangetext add orangebd">{{button.suredel}}</el-button>
					<el-button @click='closemask'>{{button.close}}</el-button>
				</div>
				<div class="foot" v-if="status=='lock'">
					<el-button @click="surelock" class="orangetext add orangebd">{{button.sureclose}}</el-button>
					<el-button @click='closemask'>{{button.close}}</el-button>
				</div>
				<div class="content" v-if="status!='del'&&status!='lock'">
					<el-form :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
						<el-form-item class='roapeitem' v-if="!myQSE" :label="plac.uporg+'：'" prop="ParentID" style="height:30px;">
							<OrganizationSelect
								:onlyloadEnableOrg="true"
								:placeholder="R.Org_plactexta_0"
								v-model="ruleForm2.ParentID" 
								:defaultValue = "Orgdefaultvalue"
							/>  
							<span class="red" style="position: relative;top: -8px;">*</span>
							<i style="color: #f56c6c;display: none; position: absolute; right: 160px; top: -4px;" class="myiconerror  el-input__icon el-input__validateIcon el-icon-circle-close"></i>
						</el-form-item>
						<el-form-item v-if="myQSE" class='roapeitem' :label="plac.uporg+'：'" style="height:30px;">
							<el-input :title="defaultvalue" disabled style="width:230px" v-model='defaultvalue'></el-input>
							<i style="color: #f56c6c;display: none; position: absolute; right: 160px; top: -4px;" class="myiconerror  el-input__icon el-input__validateIcon el-icon-circle-close"></i>
						</el-form-item>
						<el-form-item :label="plac.code+'：'" v-if="AutoGenerateCode_Org=='True'||AutoGenerateCode_Org==true">
							<el-input maxlength=20 :placeholder="R.Org_16" style="width:230px" v-model="ruleForm2.Code" :disabled="true"></el-input>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item :label="plac.code+'：'" prop="Code" v-if="AutoGenerateCode_Org=='False'||AutoGenerateCode_Org==false">
							<el-input maxlength=20 :placeholder="R.Org_8" style="width:230px" v-model="ruleForm2.Code"></el-input>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item class='roapeitem' :label="plac.unicode+'：'" prop="AbbrCode">
							<el-input :placeholder="R.Org_12" maxlength=10 style="width:230px" v-model="ruleForm2.AbbrCode"></el-input>
						</el-form-item>
						<!--<el-form-item class='roapeitem' :label="R.Org_25+'：'" prop="FinanceCode">
							<el-input :placeholder="R.Org_26" v-model="ruleForm2.FinanceCode"></el-input> <span class="red">*</span>
						</el-form-item>-->
						<el-form-item class='roapeitem' :label="plac.nameCn+'：'" prop="NameCN">
							<el-input maxlength=50 style="width:230px" :placeholder="R.Org_plactexta_3" v-model="ruleForm2.NameCN"></el-input> <span class="red">*</span>
						</el-form-item>

						<el-form-item class='roapeitem' :label="plac.nameEn+'：'" prop="NameEN">
							<el-input maxlength=50  style="width:230px":placeholder="R.Org_plactexta_4" v-model="ruleForm2.NameEN"></el-input>
						</el-form-item>

						<el-form-item :label="plac.type+'：'" prop="Type">
							<el-select :disabled="status=='edited'" style="width:230px" v-model="ruleForm2.Type" :placeholder="plac.text">
								<el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
							</el-select>
							<span class="red">*</span>
						</el-form-item>
						<!--<el-form-item :label="R.Org_23+'：'" v-if="ruleForm2.Type =='1'" >
							<el-select :disabled="status=='edited'" v-model="ruleForm2.NatureID" :placeholder="plac.Org_24">
								<el-option v-for="item in NatureOptions" :key="item.ID" :label="item.ItemText" :value="item.ID"> </el-option>
							</el-select>
							<span class="red">*</span>
						</el-form-item>-->
						<el-form-item class='roapeitem' :label="R.Org_17" prop="upID">
							<cj-Personnelselector :collapseTags="true" style="width:230px;display: inline-block;" :placeholder="R.Org_18" :PersonnelInfo="ruleForm2.PersonnelInfo" @optionShow="optionShow" @personnelShow="DrawAssetEmployeeShow" :DrawAssetEmployeeIDs="ruleForm2.upID" :multiple="true"></cj-Personnelselector>
						</el-form-item>
						<el-form-item class='roapeitem' :label="plac.phone+'：'">
							<el-input maxlength=50 :placeholder="R.Org_20" style="width:230px" v-model="ruleForm2.Telephone"></el-input>
						</el-form-item>
						<el-form-item :label="plac.address+'：'">
							<el-input type="textarea" maxlength="50" :rows="2" style="width:230px" :placeholder="R.Org_21" resize='none' v-model="ruleForm2.Address">
							</el-input>
						</el-form-item>
						<el-form-item :label="plac.remark+'：'" style="margin-top: 8px;">
							<el-input type="textarea" :placeholder='R.Org_plactexta_7' style="width:230px" maxlength="50" :rows="2" resize='none' v-model="ruleForm2.Remark">
							</el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="foot" v-if="status!='del'&&status!='lock'">
					<el-button @click="updateUser('ruleForm2')" :disabled="isDisabled" class="orangetext add orangebd">{{button.update}}</el-button>
					<el-button @click='resert'>{{button.resert}}</el-button>
					<el-button @click='closemask'>{{button.close}}</el-button>
				</div>
			</div>
		</div>
		<div class="left">
			<div class="titleall" v-if="title">{{title}}</div>
			<el-tree v-if="loadtree" :expand-on-click-node="false" node-key="id" :default-expanded-keys='expandkey' class="commontree" :load="loadNode" lazy ref="tree" highlight-current :props='defaultProps' @node-click="clicktree">
				<span class="custom-tree-node" slot-scope="{ node, data }">
		        	<span  class="mytreelistitem" :title=" node.label" >{{ node.label }}<span class="islocked" v-if="data.IsEnabled==false">&ensp;&ensp;({{data.IsEnabledText}})</span></span>
				</span>
				</span>
			</el-tree>
		</div>
		<div class="right">
			<div class="main">
				<div class="clickbtn">
					<el-button class="orangetext add orangebd" v-if="getpermission('Add')" @click="addClick"><i class="el-icon-plus"></i> {{R.Common_Button_Add}}</el-button>
					<el-popover placement="bottom" width="150" v-model="value" trigger="click">
						<div class="Printed" v-if="getpermission('Edit')" @click="editorClick">
							{{R.Common_Button_Edit}}
						</div>
						<div class="Printed" v-if="getpermission('Delete')" @click="delClick">
							{{R.Common_Button_Delete}}
						</div>
						<div class="Printed" v-if="getpermission('Enabled')" @click="lock">
							{{button.stop}}
						</div>
						<el-button slot="reference"> <span>{{button.action}}</span> <i style="font-size:12px;color:" class="el-icon-caret-bottom"></i></el-button>
					</el-popover>
					<el-button v-if="getpermission('PrintLabel')" @click="dataImport">{{button.logtip}}</el-button>
					<el-button v-if="getpermission('Export')" @click="dataexcel">{{R.Common_Button_ExportToExcel}}</el-button>
					<el-button v-if="getpermission('Query')" style="float: right; margin-left: 10px;" @click="searchMyList">{{R.Common_Button_Search}}</el-button>
					<el-input :placeholder="plac.texts" v-if="getpermission('Query')" v-model="search" @keyup.enter.native="searchMyList">
						<i slot="suffix" class="el-input__icon el-icon-search"></i>
					</el-input>
				</div>
				<div class="mytable" ref="table">
					<Table sortable='custom' @on-sort-change="sortableShow" :height='tableHeight' stripe @on-selection-change="selectionChange" :columns="tableColumns" :data="tableData"></Table>
				</div>
			</div>
			<div class="rightfoot">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { constants } from 'zlib';
	var R = {};
	export default {
		methods: {
			// 接收人员选择器数据
			optionShow(data) {

				// if(!data||data.length==0)
				// {
				// 	this.ruleForm2.upID = []
				// 	this.ruleForm2.PersonnelInfo=[]
				// 	return;
				// }
				this.ruleForm2.upID = data
				this.ruleForm2.PersonnelInfo = null
				// this.$http.post('system:/Employee/GetByIDs',{
				// 	Data:{
				// 		IDs:data
				// 	}
				// }).then(res=>{
				// 	var rsp=res.data.Data
				// 	rsp.filter((value,key,arr)=>{
				// 		value.label=value.Name;
				// 		value.value=value.ID;
				// 	})
				// 	this.ruleForm2.PersonnelInfo=rsp
				// 	this.ruleForm2.upID= data
				// }) 
			},
			DrawAssetEmployeeShow(data) {
				this.ruleForm2.PersonnelInfo = []
				for(let i = 0; i < data.length; i++) {
					this.ruleForm2.upID.push(data[i].ID)
					this.ruleForm2.PersonnelInfo.push({
						label: data[i].Name,
						value: data[i].ID,
						Code: data[i].Code
					})
				}
			},
			sortableShow(data) {
				if(data.order == "asc") {
					this.sort = 0
				} else if(data.order == "desc") {
					this.sort = 1
				} else {
					this.sort = 1
				}
			},
			getSystemDicSettings() {
				var systemDicSettings = JSON.parse(this.$Store.get('SystemDicSettings'));
				if(systemDicSettings != null) {
					this.AutoGenerateCode_Org = systemDicSettings[0].Value == 'False' || systemDicSettings[0].Value == false ? false : true
				}
			},
			showTreeNodeLabel(node, defaultValues) {

				if(node.raw.label) {    // 有label属性，则表示节点数据已经找到
					return node.raw.label;                
				} else {                     // 未找到节点，从defaultValues中查找
					if(defaultValues && defaultValues.length > 0) { 
						for(let i = 0; i < defaultValues.length; i++) {
							let dv = defaultValues[i];
							if(dv.id == node.id) {
								return dv.label;
							}
						}     
					}                     //没找到
					return '';                
				}                 //
			},
			dataexcel() {
				this.$http.post('/Organization/ExportToExcel', {
					Data: {
						QST: this.search,
						QSE: this.QSE,
					}
				}, {                    
					headers: {                        
						"Content-Disposition": "attachment;filename=users.xls",
						"Content-Type": "application/octet-stream"                    
					},
					   responseType: 'blob'                
				}).then(rsp => {
					if(!rsp) {                            
						return;                        
					}                        
					let url = window.URL.createObjectURL(new Blob([rsp.data]));                        
					let link = document.createElement('a');  
					if(window.navigator.msSaveBlob) {
						var blobObject = new Blob([rsp.data])
						window.navigator.msSaveBlob(blobObject, R.Org_3 + '.xls'); // The user only has the option of clicking the Save button.
						return
					}                       
					link.style.display = 'none';                        
					link.href = url;                        
					link.setAttribute('download', R.Org_3 + '.xls');                         //
					document.body.appendChild(link);                        
					link.click();
				}).catch((error) => {});

			},
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {

						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}

			},
			dataImport() {
				if(this.selection.length > 0) {
					var Ids = [];
					for(var i = 0; i < this.selection.length; i++) {
						Ids.push(this.selection[i].ID)
					}
					this.$http.post('/DataImport/PrintTag', {
						Data: {
							'Category': 2,
							'IDs': Ids
						}
					}, {                    
						headers: {                        
							"Content-Disposition": "attachment;filename=users.pdf",
							 "Content-Type": "application/octet-stream"                    
						},
						 responseType: 'blob'                
					}).then(rsp => {
						if(!rsp) {                            
							return;                        
						}                        
						let url = window.URL.createObjectURL(new Blob([rsp.data]));                        
						let link = document.createElement('a'); 
						if(window.navigator.msSaveBlob) {
							var blobObject = new Blob([rsp.data])
							window.navigator.msSaveBlob(blobObject, R.Org_4 + '.pdf'); // The user only has the option of clicking the Save button.
							return
						}                        
						link.style.display = 'none';                        
						link.href = url;                        
						link.setAttribute('download', R.Org_4 + '.pdf');                         //
						document.body.appendChild(link);                        
						link.click();
					}).catch((error) => {});
				} else {
					this.$message({
						message: R.Common_Msg_NoneBillSelected,
						type: 'error'
					});
					return;
				}              

			},
			//点击左侧树结构
			clicktree(node, data) {
				this.QSE = data.data.QSE;
				this.$http.post('/Organization/GetById', {
					Data: {
						ID: node.id,
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.Orgdefaultvalue = [{
							id: node.id,
							label: res.data.Data.Name
						}]
						this.defaultvalue = res.data.Data.Name == null ? '' : res.data.Data.Name
						this.ruleForm2.ParentID = node.id

					} else {
						
					}
				})
				this.searchMyList()
				this.editId = '';
				this.selection = [];
			},
			//左侧树懒加载
			loadNode(node, resolve) {
				if(node.level === 0) { //第一级
					this.getOrgByParentID(null, resolve);
				} else {
					this.getOrgByParentID(node.data.id, resolve);
				}
			},
			// 获取指定的父级公司/部门的ID获取其下级公司/部门
			getOrgByParentID(parentID, resolve) {
				this.loadingL = true
				this.$http.post('/Organization/GetTree', {
					Data: {
						'onlyloadEnableOrg': false,
						LoadOrganizationType: 3,
						OnlyLoadPrivilegeOrg: true, //正式应该为true
						Delay: true,
						ParentId: parentID
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) { //成功
						//
						let items = rsp.data.Data;
						if(parentID == null && rsp.data.Data.length > 0) {
							var arr = [];
							arr.push(rsp.data.Data[0].id)
							this.expandkey = arr
						}
						resolve(items);
					} else {
						this.$message.error(rsp.data.Message)
					}
					this.loadingL = false
				}).catch((error) => {});
			},
			//上级部门懒加载
			uprogloadOptions({
				parentNode,
				callback
			}) {
				this.$http.post('system:/Organization/GetTree', {
					Data: {
						Delay: true,
						'onlyloadEnableOrg': true,
						ParentId: parentNode ? parentNode.id : null,
						OnlyLoadPrivilegeOrg: true,
						LoadOrganizationType: 3,
					}
				}).then((rsp) => {
					// 
					if(rsp.data.Code == 0) {
						if(parentNode) { //非根节点
							parentNode.children = rsp.data.Data;
						}
						callback();
					} else {
						this.$message.error(rsp.data.Message);
					}
				}).catch((error) => {

					if(error) {
						this.$message.error(error.message);
					} else {
						this.$message.error(R.Org_5 + '.');
					}
				});
			},
			//pagechange 当前页和当前状态改变
			searchMyList() {
				this.currentPage = 1;
				this.pageChange();
			},
			//上级部门树渲染刷新
			pageChangeTopTree(id) {
				let param = {
					Data: {
						LoadOrganizationType: 3,
						OnlyLoadPrivilegeOrg: true, //正式应该为true
						'ParentId': id,
						"Delay": true,
						'onlyloadEnableOrg': true,
					}
				}
				this.$http.post('/Organization/GetTree', param).then(data => {
					if(data.data.Code == '0') {
						this.itemsleft = data.data.Data
					} else {
						this.$message.error(data.data.Message)
					}
				}).catch((error) => {});
			},
			//列表渲染刷新
			pageChange() {
				this.loadingR = true
				let params = {
					Data: {
						"QST": this.search,
						'QSE': this.QSE,
						'PageSize': this.pagesize,
						'PageNO': this.currentPage,
						sort: this.sort,
						'IsSetNumber': true,
					}
				}
				this.SetPageSize(this.pagesize, 'System/Org');
				this.$http.post('/Organization/List', params).then(data => {
					if(data.data.Code == 0) {
						this.total = data.data.Data.TotalRecordQty;
						this.currentPage = data.data.Data.PageNO;
						this.pagesize = data.data.Data.PageSize;
						this.tableData = data.data.Data.Items;
					} else {
						this.total = 0;
						this.currentPage = 1;
						this.tableData = [];
						this.$message.error(data.data.Message)
					}
					this.editId = '';
					this.Enabled = '';
					this.selection = [];
					this.loadingR = false
				}).catch((error) => {
					this.loadingR = false
				});;

			},
			//是否只选择一行
			isSelectedRowOnlyOne() {
				if(this.selection.length == 0) {
					this.$message({
						message: R.Common_Msg_NoneBillSelected,
						type: 'error'
					});
					return false;
				} else if(this.selection.length > 1) {
					this.$message({
						message: R.Common_Msg_OnlyOneCanBeSelected,
						type: 'error'
					});
					return false;
				} else {
					return true;
				}
			},
			//删除点击
			delClick() {
				if(!this.isSelectedRowOnlyOne()) {
					return;
				}
				this.value = false
				this.mask = true;
				this.addone = true;
				this.status = 'del';

			},

			//停用部门点击
			lock() {
				if(!this.isSelectedRowOnlyOne()) {
					return;
				}
				this.value = false
				this.mask = true;
				this.addone = true;
				this.status = 'lock';

			},
			//新增
			addClick() {
				this.mask = true;
				this.addone = true;
				this.status = 'add';
				this.CartridgeLoading = false;
				let param = {
					Data: {
						"ParentID": "",
						"Delay": false,
						"OnlyLoadPrivilegeLocation": false,
						'onlyloadEnableOrg': true,
					}
				}
				this.$http.post('/Organization/GetTree', param).then(data => {
					if(data.data.Code == '0') {
						this.api = data.data.Data.Items
					} else {
						this.$message.error(data.data.Message)
					}
				}).catch((error) => {

				});
			},
			//确认删除
			suredel() {
				this.isDisabled = true;
				var params = {
					data: {
						ID: this.editId,
					}
				}
				this.$http.post('/Organization/Delete', params).then(res => {
					if(res.data.Code == '0') {
						this.$message({
							message: res.data.Message,
							type: 'success'
						});
						this.isDisabled = false;
						this.loadtree = false;
						setTimeout(() => {
							this.loadtree = true;
						}, 10)
						this.currentPage = 1;
						this.pageChange();
						this.mask = false;
						this.addone = false;
					} else {
						this.mask = false;
						this.addone = false;
						this.isDisabled = false;
						
					}
				}).catch((error) => {
					this.mask = false;
					this.addone = false;
					this.isDisabled = false;
				});

			},
			//确认停用
			surelock() {
				var params = {
					data: {
						Enabled: !this.Enabled,
						QSE: this.clickQse,
					}
				}
				this.$http.post('/Organization/EnableOrDisable', params).then(res => {
					if(res.data.Code == '0') {
						this.$message({
							message: res.data.Message,
							type: 'success'
						});
					} else {
						
					}

					this.mask = false;
					this.addone = false;
					this.pageChange();
				}).catch((error) => {});
			},
			//关闭弹窗
			closemask() {
				this.mask = false;
				this.CartridgeLoading = false;
				this.addone = false;
				this.ruleForm2.NatureID  = '';
				this.ruleForm2.upID = []
				this.ruleForm2.PersonnelInfo = []
				this.ruleForm2.Code = ''
				this.ruleForm2.AbbrCode = ''
				this.ruleForm2.NameCN = ''
				this.ruleForm2.NameEN = ''
				this.ruleForm2.Telephone = ''
				this.ruleForm2.Address = ''
				this.ruleForm2.Remark = '';
				this.ruleForm2.Type = '2';
//				this.ruleForm2.FinanceCode = ''
				this.isDisabled = false;
				this.myQSE = false;
			},
			//重置
			resert() {
				this.ruleForm2.NatureID = '';
				this.ruleForm2.ParentID = null;
				this.ruleForm2.AbbrCode = ''
//				this.ruleForm2.FinanceCode = ''
				this.ruleForm2.NameCN = ''
				this.ruleForm2.NameEN = ''
				this.ruleForm2.Telephone = ''
				this.ruleForm2.Address = ''
				this.ruleForm2.Remark = ''
				this.ruleForm2.Code = ''
				this.ruleForm2.upID = []
				this.ruleForm2.PersonnelInfo = []
				if(this.status == 'add') {
					this.ruleForm2.Type = '2';
				}
			},
			//编辑公司部门
			editorClick() {
				if(!this.isSelectedRowOnlyOne()) {
					return;
				}
				this.value = false
				var params = {
					data: {
						ID: this.editId,
					}
				}
				this.CartridgeLoading = true;
				this.$http.post('/Organization/GetById', params).then(res => {
					if(res.data.Code == '0') {
						this.ruleForm2.ParentID = res.data.Data.ParentID,
							this.Orgdefaultvalue = [{
								id: res.data.Data.ParentID,
								label: res.data.Data.ParentName
							}]
						this.ruleForm2.Code = res.data.Data.Code,
							this.ruleForm2.AbbrCode = res.data.Data.AbbrCode,
//							this.ruleForm2.FinanceCode = res.data.Data.FinanceCode
							this.ruleForm2.NameCN = res.data.Data.NameCN,
							this.ruleForm2.NameEN = res.data.Data.NameEN,
							this.ruleForm2.NatureID = res.data.Data.NatureID,
							this.$http.post('system:/Employee/GetByIDs', {
								Data: {
									IDs: res.data.Data.ManagerIds
								}
							}).then(rsp => {
								var rsp = rsp.data.Data
								rsp.filter((value, key, arr) => {
									value.label = value.Name;
									value.value = value.ID;
								})
								this.ruleForm2.PersonnelInfo = rsp
								this.ruleForm2.upID = res.data.Data.ManagerIds
							})

						this.ruleForm2.Telephone = res.data.Data.Telephone
						this.ruleForm2.Address = res.data.Data.Address
						this.ruleForm2.Remark = res.data.Data.Remark;
						this.defaultvalue = res.data.Data.ParentName;
						this.ruleForm2.Type = res.data.Data.Type.toString();
						this.myQSE = res.data.Data.QSE.split('-').length <= 2 ? true : false;
						this.mask = true;
						this.addone = true;
						this.status = 'edited';
					} else {
						
					}
					this.CartridgeLoading = false;
				}).catch((error) => {
					this.CartridgeLoading = false;
				});

			},
			//table 取消选择
			selectcancel(selection, row) {
				if(selection.length >= 1) {
					this.editId = selection[selection.length - 1].ID;

				} else {
					this.editId = '';
				}
			},
			//table 选中
			selectionChange(selection) {
				this.selection = selection;
				if(selection.length > 0) {
					this.editId = selection[0].ID;
					this.Enabled = selection[0].IsEnabled;
					this.clickQse = selection[0].QSE;
				} else {
					this.editId = '';
					this.Enabled = '';
					this.clickQse = ''
				}
			},

			//当前页size重选
			handleSizeChange(val) {
				this.pagesize = val
				this.currentPage = 1;
				this.pageChange();
			},
			//当前页重选
			handleCurrentChange(val) {
				this.currentPage = val;
				this.pageChange();
			},
			//新增公司部门
			updateUser(formName) {
				this.$refs[formName].validate((valid) => {
					if(valid) {
						this.isDisabled = true;
						let params = {
							Data: {
								"NatureID":this.ruleForm2.NatureID,
								"ID": this.editId,
								"ParentID": this.ruleForm2.ParentID,
								"Code": this.ruleForm2.Code.trim(),
								"AbbrCode": this.ruleForm2.AbbrCode,
								"NameCN": this.ruleForm2.NameCN,
								'ManagerIds': this.ruleForm2.upID,
								"NameEN": this.ruleForm2.NameEN,
								"Type": this.ruleForm2.Type,
								"Telephone": this.ruleForm2.Telephone,
								"Address": this.ruleForm2.Address,
								"Remark": this.ruleForm2.Remark,
//								"FinanceCode":this.ruleForm2.FinanceCode
							}
						}
						this.CartridgeLoading = true;
						if(this.status == 'add') {
							this.$http.post('/Organization/Add', params).then(res => {
								if(res.data.Code == '0') {
									this.$message({
										message: res.data.Message,
										type: 'success'
									});
									this.loadtree = false;
									setTimeout(() => {
										this.loadtree = true;
									}, 10)
									this.resert()
									this.currentPage = 1;
									this.pageChange();
									this.isDisabled = false;
									let param = {
										Data: {
											LoadOrganizationType: 3,
											OnlyLoadPrivilegeOrg: true, //正式应该为true
											'ParentId': '',
											"Delay": true,
											'onlyloadEnableOrg': true,
										}
									}
									this.$http.post('/Organization/GetTree', param).then(data => {
										if(data.data.Code == '0') {
											this.Allapi = data.data.Data
										} else {
											this.$message.error(data.data.Message)
										}
									})
									this.closemask();
								} else {
									if(res.data.Code == '-399') {
										
										this.$http.post('/Setting/All').then((rsp) => {
											if(rsp.data.Code === 0) {
												let dic = rsp.data.Data;
												if(dic != null && dic.length > 0) {
													var SystemDicSettings = {}
													for(let i = 0; i < dic.length; i++) {
														SystemDicSettings[dic[i].Key] = dic[i].Value
													}
													this.$Store.set('SystemDicSettings', JSON.stringify(SystemDicSettings));
													this.getSystemDicSettings();
													this.isDisabled = false;
												} else {
													this.$Store.set('SystemDicSettings', JSON.stringify([]));
													this.getSystemDicSettings();
												}
												this.isDisabled = false;
											} else {
												this.isDisabled = false;
												this.$message.error(rsp.data.Message)

											}
										});
									} else {
										this.isDisabled = false;
										
									}
								}
								this.CartridgeLoading = false;
							}).catch((error) => {
								this.isDisabled = false;
								this.CartridgeLoading = false;
							});
						} else {
							this.$http.post('/Organization/Edit', params).then(res => {
								if(res.data.Code == '0') {
									this.$message({
										message: res.data.Message,
										type: 'success'
									});
									this.loadtree = false;
									setTimeout(() => {
										this.loadtree = true;
									}, 10)
									this.resert()
									this.pageChange();
									this.isDisabled = false;
									let param = {
										Data: {
											LoadOrganizationType: 3,
											OnlyLoadPrivilegeOrg: true, //正式应该为true
											'ParentId': '',
											"Delay": true,
											'onlyloadEnableOrg': true,
										}
									}
									this.$http.post('/Organization/GetTree', param).then(data => {
										this.Allapi = data.data.Data
									})
									this.closemask();
								} else {
									if(res.data.Code == '-399') {
										
										this.$http.post('/Setting/All').then((rsp) => {
											if(rsp.data.Code === 0) {
												let dic = rsp.data.Data;
												if(dic != null && dic.length > 0) {
													var SystemDicSettings = {}
													for(let i = 0; i < dic.length; i++) {
														SystemDicSettings[dic[i].Key] = dic[i].Value
													}
													this.$Store.set('SystemDicSettings', JSON.stringify(SystemDicSettings));
													this.getSystemDicSettings();
													this.isDisabled = false;
												} else {
													this.$Store.set('SystemDicSettings', JSON.stringify([]));
													this.getSystemDicSettings();
													this.isDisabled = false;
													this.$message.error(rsp.data.Message)
												}
											} else {
												this.isDisabled = false;
												this.$message.error(rsp.data.Message)
											}
										});
									} 
									this.CartridgeLoading = false;
									this.isDisabled = false;
								}
							}).catch((error) => {
								this.isDisabled = false;
								this.CartridgeLoading = false;
							});
						}
					} else {
						return false;
					}
				});
			},
			getAssetDicSettings() {
				var assetDicSettings = [];
				if(assetDicSettings != null) {
					assetDicSettings.forEach(element => {
						if(element.Key == 'AutoGenerateCode_Location') {
							this.isAutoGenerateCode = element.Value;
						}
					});
				}
			},
		},

		data() {
			var validateEmail = (rule, value, callback) => {
				var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				if(value != '' && !reg.test(value)) {
					callback(new Error(R.Org_5));
				} else {
					callback();
				}
			};
			var validateParentID = (rule, value, callback) => {
				if(this.ruleForm2.ParentID == '' || this.ruleForm2.ParentID == null || this.ruleForm2.ParentID == undefined) {
					callback(new Error(R.Org_6));
				} else {
					callback();
				}
			};
			var validateTelephone = (rule, value, callback) => {
				var reg = /^[1][3,4,5,7,8,9,6][0-9]{9}$/;
				if(value && !reg.test(value)) {
					callback(new Error(R.Org_7));
				} else {
					callback();
				}
			};
			var validateCode = (rule, value, callback) => {
				if(value.trim() == '') {
					callback(new Error(R.Org_8));
				} else {
					callback();
				}

			};
			var validateNameCN = (rule, value, callback) => {
				if(value.trim() == '') {
					callback(new Error(R.Org_9));
				} else {
					callback();
				}

			};
			var validateFinanceCode =  (rule, value, callback) => {
				if(!value || value.trim() == '') {
					callback(new Error(R.Org_26));
				} else if(value.length > 10) {
					callback(new Error(R.Org_27));
				} else {
					callback();
				}

			};
			// var validateAbbrCode = (rule, value, callback) => {
			// 	if(!value || value.trim() == '') {
			// 		callback(new Error(R.Org_12));
			// 	} else if(value.length > 10) {
			// 		callback(new Error(R.Org_13));
			// 	} else {
			// 		callback();
			// 	}

			// };
			return {
				IsPageDome: true,
				Orgdefaultvalue: [],
				CartridgeLoading: false,
				loadtree: true,
				sort: 0,
				expandkey: [1],
				myQSE: false,
				R: R,
				isDisabled: false,
				AutoGenerateCode_Org: 'False',
				defaultvalue: '',
				value: false,
				selection: [],
				permissionCode: [],
				ParentIDtext: false,
				ruleForm2: {
					PersonnelInfo: [],
					ParentID: null,
					Code: '',
					AbbrCode: '',
					NameCN: '',
					NameEN: '',
					Type: '2',
					Telephone: '',
					Remark: '',
					Address: '',
					upID: [],
					NatureID:'',
					FinanceCode:'',
				},
				rules2: {
					Code: [{
						validator: validateCode,
						trigger: 'blur'
					}],
					// AbbrCode: [{
					// 	validator: validateAbbrCode,
					// 	trigger: 'blur'
					// }],
					NameCN: [{
						validator: validateNameCN,
						trigger: 'blur'
					}],
					Type: [{
						message: R.Org_14,
						trigger: 'change'
					}],
					ParentID: [{
						validator: validateParentID,
						trigger: 'change'
					}],
					Telephone: [{
						validator: validateTelephone,
						trigger: 'blur'
					}],
//					FinanceCode: [{
//						validator: validateFinanceCode,
//						trigger: 'blur'
//					}],
				},
				// 表格高度适配
				screenWidth: document.body.clientWidth,
				screenHeight: document.body.clientHeight,
				// 表格高度
				tableHeight: '',
				button: {
					addtext: R.Org_addtext,
					edittext: R.Org_edittext,
					deltext: R.Org_deltext,
					stoptext: R.Org_stoptext,
					deltip: R.Org_deltip,
					stoptip: R.Org_stoptip,
					suredel: R.Org_suredel,
					close: R.Org_close,
					sureclose: R.Org_sureclose,
					update: R.Common_Button_Save,
					resert: R.Org_reset ? R.Org_reset : R.Common_Button_Reset,
					add: R.Common_Button_Add,
					action: R.Org_Action,
					edit: R.Common_Button_Edit,
					del: R.Common_Button_Delete,
					stop: R.Common_Button_Enable,
					logtip: R.Common_Button_PrintLabel,
					import: R.Common_Button_ExportToExcel,
					search: R.Common_Button_Query
				},
				plac: {
					uporg: R.Org_uporg,
					code: R.Org_code,
					text: R.Org_plodexa,
					unicode: R.Org_unicode,
					nameEn: R.Org_nameEn,
					nameCn: R.Org_nameCn,
					isenabled: R.Org_isenabled,
					phone: R.Org_phone,
					address: R.Org_Address,
					remark: R.Org_remark ? R.Org_remark : R.Org_real,
					type: R.Org_type,
					texts: R.Org_plodex,
				},
				typeOptions: [{
						label: R.Org_crop,
						value: '2'
					},
					{
						label: R.Org_dept,
						value: '1'
					}
				],
				NatureOptions:[],
				
				itemsleft: [],
				search: '',
				Allapi: [],
				Enabled: '',
				clickQse: '',
				QSE: '',
				editId: '',
				ParentID: null,
				Code: '',
				Type: '',
				AbbrCode: '',
//				FinanceCode:'',
				NameCN: '',
				NameEN: '',
				Telephone: '',
				Address: '',
				Remark: '',
				editId: '',
				total: 0,
				pagesize: 20,
				currentPage: 1,
				api: [],
				Remark: '',
				status: '',
				IsEnabled: true,
				loadingR: false,
				loadingL: false,
				mask: false,
				addone: false,
				defaultProps: {
					children: 'children',
					label: 'label',
					isLeaf: 'is_leaf'
				},
				tableColumns: [{
						type: 'selection',
						width: 50,
						align: 'center'
					},
					{
						type: 'index',
						width: 55,
						align: 'center',
						tooltip: true,
					},
					{
						align: 'left',
						width: 180,
						title: R.Org_code,
						tooltip: true,
						key: 'AbbrCode',
					},
					
					{
						title: R.Org_nameCn,
						key: 'NameCN',
						align: 'left',
						width: 260,
						tooltip: true,
						sortable: true,
					},
					{
						title: R.Org_nameEn,
						key: 'NameEN',
						width: 180,
						tooltip: true,
						align: 'left',
					},
					{
						title: R.Org_type,
						key: 'TypeText',
						align: 'left',
						width: 100,
						tooltip: true,
					}, {
						title: R.Org_22,
						key: 'ManagerName',
						align: 'left',
						width: 180,
						tooltip: true,
					},
					{
						title: R.Org_phone,
						key: 'Telephone',
						width: 120,
						align: 'left',
						tooltip: true,
					},
					{
						title: R.Org_address,
						key: 'Address',
						width: 160,
						tooltip: true,
						align: 'left',
					}, {
						title: R.Org_isenabled,
						key: 'IsEnabledText',
						width: 100,
						tooltip: true,
						align: 'center',
					},
					{
						title: R.Org_remark,
						key: 'Remark',
						width: 160,
						align: 'left',
						tooltip: true,
					},

				],
				title: R.Org_title,
				tableData: [],
				data2: [],

				defaultExpandKeys: [],
			};
		},
		watch: {
			screenWidth(val) {
				this.tableHeight = this.$refs.table.offsetHeight;
			},
			screenHeight(val) {
				this.tableHeight = this.$refs.table.offsetHeight;
			},
		},
		beforeUpdate() {
			const that = this
			window.onresize = () => {
				return(() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					that.screenWidth = window.screenWidth
					that.screenHeight = window.screenHeight
				})()
			}
			this.tableHeight = this.$refs.table.offsetHeight;
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_Org');
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted() {
			this.button = {
				addtext: R.Org_addtext,
				edittext: R.Org_edittext,
				deltext: R.Org_deltext,
				stoptext: R.Org_stoptext,
				deltip: R.Org_deltip,
				stoptip: R.Org_stoptip,
				suredel: R.Org_suredel,
				close: R.Org_close,
				sureclose: R.Org_sureclose,
				update: R.Common_Button_Save,
				resert: R.Org_reset ? R.Org_reset : R.Common_Button_Reset,
				add: R.Org_add,
				action: R.Common_Button_Operation,
				edit: R.Org_edit,
				del: R.Org_del,
				stop: R.Common_Button_Enable,
				logtip: R.Common_Button_PrintLabel,
				import: R.Org_import,
				search: R.Org_research
			};
			this.plac = {
				uporg: R.Org_uporg,
				code: R.Org_code,
				text: R.Org_plodexa,
				unicode: R.Org_unicode,
				nameEn: R.Org_nameEn,
				nameCn: R.Org_nameCn,
				isenabled: R.Org_isenabled,
				phone: R.Org_phone,
				address: R.Org_address,
				remark: R.Org_remark ? R.Org_remark : R.Org_real,
				type: R.Org_type,
				texts: R.Org_plodex,
			};
			this.typeOptions = [{
					label: R.Org_crop,
					value: '2'
				},
				{
					label: R.Org_dept,
					value: '1'
				}
			];
			this.rules2.Type = [{
				message: R.Org_14,
				trigger: 'change'
			}];
			this.tableColumns = [{
					type: 'selection',
					width: 30,
					align: 'center'
				},
				{
					type: 'index',
					width: 50,
					align: 'center',
					tooltip: true,
				},
				{
					align: 'center',
					width: 150,
					title: R.Org_code,
					key: 'Code',
					tooltip: true,
				}, {
					align: 'center',
					width: 150,
					title: R.Org_unicode,
					tooltip: true,
					key: 'AbbrCode',
				},
				{
					title: R.Org_nameCn,
					key: 'NameCN',
					align: 'center',
					width: 160,
					tooltip: true,
					sortable: true,
				},
				{
					title: R.Org_nameEn,
					key: 'NameEN',
					width: 160,
					tooltip: true,
					align: 'center',
				},
				{
					title: R.Org_22, //插眼
					key: 'ManagerName',
					align: 'center',
					width: 140,
					tooltip: true,
				},
				{
					title: R.Org_type,
					key: 'TypeText',
					align: 'center',
					width: 100,
					tooltip: true,
				},
//				{
//					title: R.Org_23,
//					key: 'ManagerName',
//					align: 'left',
//					width: 120,
//					tooltip: true,
//					
//				},
				{
					title: R.Org_phone,
					key: 'Telephone',
					width: 120,
					align: 'left',
					tooltip: true,
				},
				{
					title: R.Org_address,
					key: 'Address',
					width: 140,
					tooltip: true,
					align: 'left',
				}, {
					title: R.Org_isenabled,
					key: 'IsEnabledText',
					width: 90,
					tooltip: true,
					align: 'center',
				},
				{
					title: R.Org_remark,
					key: 'Remark',
					minWidth: 180,
					maxWidth: 400,
					align: 'left',
					tooltip: true,
				},
			];
			this.title = R.Org_title;
			this.pagesize = Number(this.GetPageSize('System/Org'))
			this.pageChange();
			//获取表头元数据
			// document.querySelectorAll('.ivu-checkbox-wrapper')[0].style.display = 'none';
			this.$PermissionUtil.getPermission('System/Org').then((res)=>{
				this.permissionCode = res;
			});
			this.getSystemDicSettings()
			this.getAssetDicSettings()
			this.$http.post('system:/Dictionary/GetByCode', {    
				Data: "D008"
			}).then(res => {
				if(res.data.Code == '0') {
					var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
					this.NatureOptions = opt
					this.ruleForm2.NatureID = opt[0].ID
				} else {
					
				}
			});
			
		}
	};
</script>

<style lang='scss'>
	#org {
		.islocked {
			color: red;
		}
		.el-button+.el-button {
			margin-left: 0px;
		}
		// .el-form-item__content{
		// 	line-height: 30px;
		// }
		.el-button {
			margin-right: 5px;
		}
		/*height: 100%;
		width: 100%;*/
		background-color: white;
		/*padding-top: 20px;*/
		/*padding-left: 20px;*/
		.titleall {
			height: 40px;
			line-height: 40px;
			background-color: #EFF1F1;
			text-align: center;
			margin-bottom: 10px;
			font-size: 14px;
			border-right: 1px solid #eee;
			font-family: FZLTHK--GBK1-0;
			font-weight: bold;
			color: rgba(123, 128, 130, 1);
		}
		.rightfoot {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 60px;
			padding-left: 15px;
			padding-top: 14px;
			background-color: #EFF1F1;
		}
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
			.addone {
				height: 585px;
				width: 530px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					padding: 15px 15px 0 55px;
					
					.el-input__inner {
						height: 30px;
					}
					.red {
						color: red;
					}
					.el-textarea {
						width: 230px
					}
				}
				.vue-treeselect {
					width: 230px;
				}
				.el-form>.el-form-item .el-form-item__content .el-form-item__error {
					left: 249px;
				}
				#Personnelselector>.searchselect {
					>.el-select {
						>.el-input {
							width: 200px;
							>.el-input__inner {
								height: 30px;
								line-height: 30px;
							}
						}
					}
					.Personnelsearch {
						height: 30px;
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					position: absolute;
					bottom: 10px;
					right: 15px;
					.el-button {
						height: 30px!important;
						margin-right: 0;
						margin-left: 5px;
					}
				}
			}
			.del {
				width: 400px;
				height: 200px;
				.contentdel {
					padding-top: 50px;
					padding-left: 100px;
					margin-bottom: 20px;
					i {
						color: deepskyblue;
						margin-right: 10px;
						font-size: 16px;
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 25px;
					float: right;
					margin-right: 10px;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
		}
		.left {
			width: 300px;
			float: left;
			background-color: white;
			height: 100%;
			padding: 0;
			position: relative;
			overflow: auto;
		}
		.right {
			padding: 15px;
			position: absolute;
			left: 315px;
			right: 0px;
			top: 0;
			bottom: 0;
			background-color: white;
			height: 100%;
			padding: 0px;
			.clickbtn {
				margin-bottom: 15px;
				height: 30px;
				.el-input {
					display: inline-block;
					float: right;
					width: 220px;
					i {
						position: relative;
						cursor: pointer;
					}
				}
				.el-input__inner {
					height: 30px;
					line-height: 30px;
					font-size: 14px;
					position: relative;
				}
			}
			.mytable {
				position: absolute;
				top: 45px;
				bottom: 78px;
				left: 0px;
				right: 0px;
				overflow: hidden;
			}
		}
	}
</style>