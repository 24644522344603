/**
 * ajax请求配置
 */
 import axios from 'axios'
 import service from './api.js'
 //import Qs from 'qs'
 //import Vue from 'vue'
 // import store from '../vuex/store.js';
 import store from 'storejs'
 import {
     Message
 } from 'element-ui';
 //var vue = new Vue;   // 实例化一个新的vue对象，用于绑定toast插件
 
 
 // axios默认配置
 axios.defaults.timeout = 1000 * 60 * 3; // 超时时间
 axios.defaults.baseURL = service.getServiceRoot(); // 获取默认服务的根URL
 //axios.defaults.withCredentials = true;
 //axios.defaults.headers.common['X-Token'] = cookie.get("token");
 //整理数据
 axios.defaults.transformRequest = function (data) {
     //	// console.log(data)
     //	if(data&&data.Data&&Object.prototype.toString.call(data.Data)=='[object Object]'){
     //		data.Data.IsSetNumber = true;
     //	}
     //	// console.log(data)
     data = JSON.stringify(data)
     return data;
 };
 
 // 路由请求拦截
 // http request 拦截器
 axios.interceptors.request.use(
     config => {
         // ;
         // 如果url以{serviceName}:{apiPath}的格式指定，则根据服务名设置根路径
         // 例如：system:/Resource/FindByPage，此表示当前调用system服务下的/Resource/FindByPage接口
         if (config.url) {
             let url = config.url;
             if (url.startWith('http://') == false && url.startWith('https://') == false) { //非指定完整url访问
                 let position = url.indexOf(':');
                 if (position > 0) {
                     let serviceName = url.substring(0, position);
                     url = url.substring(position + 1);
                     //重置请求的baseURL和url
                     config.baseURL = service.getServiceRoot(serviceName);
                     config.url = url;
                 }
             }
         }
         //如果未指定Content-Type,则默认为JSON
         if (config.headers['Content-Type'] == undefined)
             config.headers['Content-Type'] = 'application/json;charset=UTF-8';
         //判断是否存在Token，如果存在的话，则每个http header都加上Token
         if (store.get("token")) {
             //用户每次操作，都将cookie设置成2小时
             store.set("token", store.get("token"))
             config.headers['X-Token'] = store.get("token");
         }
         return config;
     },
     error => {
         return Promise.reject(error.response);
     });
 
 // 路由响应拦截
 // http response 拦截器
 axios.interceptors.response.use(
     response => {
        if(response.headers["content-type"] !== "application/json" || Object.getPrototypeOf(response.data) === Blob.prototype){
             return response;
         }
         if (response.data.Code == "-201" || response.data.Code == '202') {
             // 返回 错误代码-1 清除ticket信息并跳转到登录页面
             var ssoSetting = JSON.parse(store.get("SSOSetting") || "{}");
             if (ssoSetting && ssoSetting.IsEnableSSO) {
                 const { Message, Code } = response.data;
                 window.location.replace(`${ssoSetting.SsoRedirectUrl}?code=${encodeURIComponent(Code)}&msg=${encodeURIComponent(Message)}`);
             } else {
                 window.location.href = service.getAPIUrl('webInternet', '/#/login')
             }
             return response;
         }
         if(response.data && response.data.Code != 0){
             Message.error(response.data.Message);
         }
         return response;
     },
     error => {
         if (error && error.response) {
             switch (error.response.status) {
                 //  case 400: error.message = '请求错误(400)'; break;
                 //  case 403: error.message = '拒绝访问(403)'; break;
                 //  case 404: error.message = '请求出错(404)'; break;
                 //  case 408: error.message = '请求超时(408)'; break;
                 //  case 500: error.message = '服务器错误(500)'; break;
                 //  case 501: error.message = '服务未实现(501)'; break;
                 //  case 502: error.message = '网络错误(502)'; break;
                 //  case 503: error.message = '服务不可用(503)'; break;
                 //  case 504: error.message = '网络超时(504)'; break;
                 //  case 505: error.message = 'HTTP版本不受支持(505)'; break;
                 //  default: error.message = `连接出错(${err.response.status})!`;
             }
             Message.error(error.message)
             return;
         }
         console.error(error);
         error.message = "";
         //  return Promise.reject(error.response)   // 返回接口返回的错误信息
     });
 export default axios;