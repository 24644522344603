<template>
	<div id="user" v-loading="loadingR" v-if="IsPageDome">
		<div class="mask" v-if="mask">
			<div class="treeshow" v-if="rolepaytrue" v-loading="CartridgeLoading">
				<div class="head">
					<span class="title">{{page.title}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content" v-if="rolepaytrue" v-loading="innertreeloading">
					<div class="threetree" :key="index" v-for="(item,index) in permissionTrees">
						<div class="tree-head">
							<el-checkbox v-model="item.ruleWhole" @change="selectallselect(item,index)">{{item.name}}</el-checkbox>
						</div>
						<div :class="item.truemask?'notopentrue':'tree-content'">
							<div class="treemask" v-if='item.truemask'></div>
							<cjTree :checkbox="checkbox" :ref="item.ref" :expand-on-click-node='false' v-on:childByValue="childByValue" :defaultProps='defaultProps' :api="item.tree" :url='urla'></cjTree>
						</div>
						<div class="tree-foot" v-if="index=='0'">
							<el-checkbox v-model="permissionTrees[0].showlock" @change="showlocktree">{{page.list.module3.para6}}</el-checkbox>
						</div>
					</div>

				</div>
				<div class="foot" v-if="rolepaytrue">
					<!--新增-->
					<div style="margin-left: 10px;">
						{{R.User_Leave_12}}
						<el-radio-group v-model="AdditionalAuthorized">
							<el-radio :label="1">{{R.User_Leave_13}}</el-radio>
							<el-radio :label="2">{{R.User_Leave_14}}</el-radio>
							<el-radio :label="3">{{R.User_Leave_15}}</el-radio>
						</el-radio-group>
					</div>
					<div>
						<el-button @click="savePermission" class="orangetext add orangebd">{{page.list.module1.para9}}</el-button>
						<el-button @click='closemask'>{{page.list.module1.para11}}</el-button>
					</div>
				</div>

			</div>
			<div v-loading="CartridgeLoading" :class="status=='del'||status=='lock'?'addone del':(status=='modifyPassword'?'addoneModifyPassword':'addone')" v-if="addone">
				<div class="head">
					<span class="title" v-if="status=='add'">{{page.list.module3.para1}}</span>
					<span class="title" v-if="status=='edit'">{{page.list.module3.para2}}</span>
					<span class="title" v-if="status=='del'&&mytime">{{R.User_86}}</span>
					<span class="title" v-if="status=='del'&&!mytime">{{page.list.module3.para3}}</span>
					<span class="title" v-if="status=='lock'">{{page.list.module3.para4}}</span>
					<span class="title" v-if="status=='modifyPassword'">{{page.list.module3.para5}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="contentdel" v-if="status=='del'&&!mytime">
					<i class="el-icon-info"></i>{{page.list.module3.para7}}
				</div>
				<div class="contentdel" v-if="status=='lock'">
					<i class="el-icon-info"></i>{{page.list.module3.para8}}
				</div>
				<div class="contentdels" v-if="status=='del'&&mytime">
					{{R.User_84}}：<el-date-picker :editable=false :picker-options="purchasePickerOptions"  v-model="page.input.user.validityTime" type="date"  format="yyyy-MM-dd" value-format="yyyy-MM-dd"  :placeholder="R.User_85">
					</el-date-picker>
				</div>
				<div class="foot" v-if="status=='del'&&!mytime">
					<el-button @click="confirmDel" :disabled="isDisabled" class="orangetext add orangebd">{{page.list.module3.para9}}</el-button>
					<el-button @click='closemask'>{{page.list.module1.para11}}</el-button>
				</div>
					<!--设置有效期-->
				<div class="foot" v-if="status=='del'&&mytime" >
					<el-button @click="suresetTime" :disabled="isDisabled" class="orangetext add orangebd">{{R.UserEmployee_89}}</el-button>
					<el-button @click='closemask'>{{page.list.module1.para11}}</el-button>
				</div>
				<div class="foot" v-if="status=='lock'">
					<el-button @click="confirmLock" :disabled="isDisabled" class="orangetext add orangebd">{{page.list.module3.para10}}</el-button>
					<el-button @click='closemask'>{{page.list.module1.para11}}</el-button>
				</div>
				<div class="content" v-if="status!='del'&&status!='lock'">
					<el-form :model="page" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-form-item v-if="status!='edit'&&status!='modifyPassword'" :label="page.list.module3.para11" prop="UAEID">
							<div style="display: flex;">
								<cj-Personnelselector style="width:220px" :placeholder="R.User_Leave_3" @optionShow="optionShow" @personnelShow="DrawAssetEmployeeShow" :DrawAssetEmployeeIDs="page.input.employee.id" :multiple="false" :IsFilterUser="true" :EnableEmployeeAccount="false"></cj-Personnelselector>
								<i style="color:red;font-style:normal;font-size:14px;margin-left:5px;">*</i>
							</div>
						</el-form-item>
						<el-form-item v-if="status!='add'" :label="page.list.module3.para11">
							<div style="width:220px">
								<span>{{page.input.employee.name}}</span>
							</div>
						</el-form-item>
						<el-form-item class='roapeitem' :label="page.list.module3.para12" v-if="status!='modifyPassword'">
							<el-input v-model="page.input.employee.code" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item :label="page.list.module3.para13" v-if="status!='modifyPassword'">
							<el-input v-model="page.input.employee.org" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item class='roapeitem' :label="page.list.module3.para14" v-if="status!='modifyPassword'">
							<el-input v-model="page.input.employee.telephone" :disabled="true"></el-input>

						</el-form-item>
						<el-form-item :label="page.list.module3.para15" prop='username'>
							<el-input v-if="status=='add'" :placeholder="page.list.module3.para16" maxlength='50' v-model="page.input.user.name"></el-input>
							<el-input v-else :placeholder="page.list.module3.para16" maxlength='50' v-model="page.input.user.name" :disabled="true"></el-input>&ensp;<span class="red">*</span>
						</el-form-item>

						<el-form-item v-if="status=='add'" :label="page.list.module3.para17" prop='password'>
							<el-input :placeholder="page.list.module3.para19" type="password" maxlength='50' v-model="page.input.user.password"></el-input>&ensp;<span class="red">*</span>
						</el-form-item>
						<el-form-item v-else-if="status=='modifyPassword'" :label="page.list.module3.para18" prop='password'>
							<el-input :placeholder="page.list.module3.para19" type="password" maxlength='50' v-model="page.input.user.password"></el-input>&ensp;<span class="red">*</span>
						</el-form-item>
						<el-form-item class='roapeitem' v-if="status!='edit'" :label="page.list.module3.para20" prop="passtwoword">
							<el-input :placeholder="page.list.module3.para21" maxlength='50' type="password" v-model="page.input.user.passwordConfirm"></el-input>&ensp;<span class="red">*</span>
						</el-form-item>
						<!--插眼-->
						<el-form-item v-if="status!='modifyPassword'"  class='roapeitem' :label="R.User_84+'：'" >
							<el-date-picker  :disabled="status=='edit'&&(selection[0].IsAdmin||!selection[0].IsEnabled)" :editable=false :picker-options="purchasePickerOptions"  v-model="page.input.user.validityTime" type="date"  format="yyyy-MM-dd" value-format="yyyy-MM-dd"  :placeholder="R.User_85">
							</el-date-picker>
						</el-form-item>
						
						<el-form-item v-if="status!='add'&&status!='modifyPassword'" :label="page.list.module3.para22">
							{{page.input.user.isEnabledText}}
						</el-form-item>
						<!--所属角色-->
						<el-form-item v-if="status!='modifyPassword'" :label="page.list.module3.para23">
							<el-select v-model="page.input.roles.selectedIDs" multiple :collapse-tags="true" :placeholder="page.list.module3.para24">
								<el-option v-for="item in page.input.roles.data" :key="item.Value" :label="item.Name" :value="item.Value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item class='roapeitem' v-if="status!='modifyPassword'" :label="page.list.module3.para25">
							<el-select v-model="page.input.user.defaultLanguage" :placeholder="page.list.module3.para24">
								<el-option v-for="item in page.dropdownList.language" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item v-if="status!='modifyPassword'" :label="page.list.module3.para26">
							<el-input type="textarea" :rows="2" maxlength='200' :placeholder="page.list.module3.para27" resize='none' v-model="page.input.remark">
							</el-input>
						</el-form-item>

					</el-form>
				</div>
				<div class="foot" v-if="status!='del'&&status!='lock'">
					<el-button v-if="status=='edit'" :disabled="isDisabled" @click="lock">{{page.list.module1.para4}}</el-button>
					<el-button @click="save" :disabled="isDisabled" class="orangetext add orangebd">{{page.list.module1.para9}}</el-button>
					<el-button v-if="status=='add'" :disabled="isDisabled" @click='clearInput'>{{page.list.module1.para10}}</el-button>
					<el-button @click='closemask'>{{page.list.module1.para11}}</el-button>
				</div>
			</div>
		</div>
		<div class="left">
			<!-- <cjTree v-on:childByValue="childByValue" :title="page.tree.title" :api="page.tree.data" :url='urla'></cjTree> -->
			<div class="titleall">{{page.tree.title}}</div>
			<el-tree v-if="loadtree" class="commontree" node-key="id" :load="loadNode" lazy ref="tree" :default-expanded-keys='expandkey' highlight-current :props='page.tree.defaultProps' @node-click="clicktree">
				<span class="custom-tree-node" slot-scope="{ node, data }">
		        	<span  class="mytreelistitem" :title=" node.label">{{ node.label }}
		        	</span>
				</span>
			</el-tree>
		</div>
		<div class="right">
			<div class="clickbtn">
				<el-button v-if="getpermission('Add')" class="orangetext add orangebd" @click="add"><i class="el-icon-plus"></i> {{page.list.module1.para1}}</el-button>
				<el-popover placement="bottom" width="150" v-model="value" trigger="click">
					<div class="Printed" v-if="getpermission('Edit')" @click="edit">
						{{page.list.module1.para3}}
					</div>
					<div class="Printed" v-if="getpermission('Enabled')" @click="lock">
						{{page.list.module1.para4}}
					</div>
					<div class="Printed" v-if="getpermission('ChangePWD')" @click="modifyPassword">
						{{page.list.module1.para5}}
					</div>
					<div class="Printed" v-if="getpermission('Delete')" @click="del">
						{{page.list.module1.para6}}
					</div>
					<el-button slot="reference"> <span>{{page.list.module1.para2}}</span> <i style="font-size:12px;color:" class="el-icon-caret-bottom"></i></el-button>
				</el-popover>
				<el-button v-if="getpermission('AsginDataPermission')" @click="rolepay()">{{page.list.module1.para7}}</el-button>
				<el-button v-if="getpermission('Export')" @click="exportToExcel()">{{page.list.module1.para8}}</el-button>
				<!--插眼 设置有效期-->
				<el-button v-if="getpermission('Export')" @click='openTime'>{{R.User_86}}</el-button>
				<el-button v-if="getpermission('Query')" style="float: right; margin-left: 10px;" @click="searchmylist">{{R.Common_Button_Search}}</el-button>
				<el-input :placeholder="page.list.module2.para1" v-if="getpermission('Query')" v-model="page.list.QST" @keyup.enter.native="searchmylist">
					<i slot="suffix" class="el-input__icon el-icon-search"></i>
				</el-input>
				<!--<li style="float: right;margin-right: 10px;height: 30px;line-height: 30px;display: flex;">
						<span>{{R.User_Leave_11}}：</span>
						<el-select class="myselecti" style="width: 150px;"  v-model="EnableEmployeeAccountNumber" :collapse-tags="true" :placeholder="R.Employee_60">
							<el-option v-for="item in accountOaptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
				</li>-->
			</div>
			<div class="mytable" ref="table">
				<Table stripe sortable='custom' @on-sort-change="sortableShow" :height='tableHeight' @on-select="selectionChange" @on-selection-change="selectShow" @on-select-cancel="selectionCancel" :columns="page.list.columnNames" :data="page.list.data"></Table>
			</div>
			<div class="rightfoot">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.list.currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="page.list.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.list.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	var R = {};
	export default {
		data() {
			var validatepassword = (rule, value, callback) => {
				if(this.page.input.user.password.trim() == '') {
					callback(new Error(R.User_Leave_1));
				} else if(this.page.input.user.password.length < this.passType.PasswordPolicy_MinLength) {
					callback(new Error(R.User_Edit_19+this.passType.PasswordPolicy_MinLength+R.User_Edit_33));
				}  else if(this.passType.PasswordPolicy_PasswordStrength){
						var  regnum = 0;
						var reg1 = /[a-z]|[A-Z]/;
						if(reg1.test(this.page.input.user.password)){
							regnum+=1
						}
						var reg2 = /\d/;
						if(reg2.test(this.page.input.user.password)){
							regnum+=1
						}
						var reg3 = /[\!\@\#\$\%\^\&\*]/
						if(reg3.test(this.page.input.user.password)){
							regnum+=1
						}
						if(regnum<2){
							callback(new Error(R.User_Edit_34));
						}else{
							callback();
						}
				}else {
					callback();
				}
			};
			var validateUAEID = (rule, value, callback) => {
				if(this.page.input.employee.id == '') {
					callback(new Error(R.User_Leave_3));
				} else {
					callback();
				}
			};
			var validatetwopassword = (rule, value, callback) => {
				if(this.page.input.user.passwordConfirm.trim() == '') {
					callback(new Error(R.User_Leave_4));
				} else if(this.page.input.user.password !== this.page.input.user.passwordConfirm) {
					callback(new Error(R.User_Leave_5));
				} else {
					callback();
				}
			};
			var validateusername = (rule, value, callback) => {
				//				var isMob = /^[1][3,4,5,7,8,9,6][0-9]{9}$/;
				//				var isEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				//				if(this.page.input.user.name.trim() == '' || (!isMob.test(this.page.input.user.name) && !isEmail.test(this.page.input.user.name))) {
				//					callback(new Error(R.User_Error_2));
				//					return;
				//				} else {
				//					callback();
				//				};
				if(this.page.input.user.name.trim() == '') {
					callback(new Error(R.User_Error_2));
					return;
				} else {
					callback();
				};
			};
			return {
				purchasePickerOptions: { //日期判断
					disabledDate: (time) => {
						return time.getTime() < Date.now();
					}
				},
				mytime:false,
				passType:[],
				AdditionalAuthorized: 1,
				EnableEmployeeAccountNumber: '2',
				accountOaptions: [{
					value: '2',
					label: R.User_Leave_10
				}, {
					value: '1',
					label: R.Common_Button_Yes
				}, {
					value: '0',
					label: R.Common_Button_No
				}],
				IsPageDome: true,
				CartridgeLoading: false,
				R: R,
				loadtree: true,
				isDisabled: false,
				innertreeloading: false,
				expandkey: [1],
				rules: {
					password: [{
						validator: validatepassword,
						trigger: 'blur'
					}],
					username: [{
						validator: validateusername,
						trigger: 'blur'
					}],
					passtwoword: [{
						validator: validatetwopassword,
						trigger: 'blur'
					}],
					Type: [{
						required: true,
						message: R.User_Leave_8,
						trigger: 'change'
					}],
					UAEID: [{
						validator: validateUAEID,
						trigger: 'blur'
					}],
					// Telephone:[{
					// 		validator: validateTelephone,
					// 		trigger: 'blur'
					// 	}]
				},

				permissionCode: [],
				tableHeight: '',
				page: {
					title: R.User_Title_1,
					//树
					tree: {
						title: R.User_Tree_Title_1,
						defaultExpandKeys: [],
						data: [],
						defaultProps: {
							children: 'children',
							label: 'label',
							isLeaf: 'is_leaf'
						},
					},
					//列表
					list: {
						//
						module1: {
							para1: R.Common_Button_Add,
							para2: R.Common_Button_Operation,
							para3: R.Common_Button_Edit,
							para4: R.Common_Button_Enable,
							para5: R.User_List_Button_3,
							para6: R.Common_Button_Delete,
							para7: R.User_List_Button_4,
							para8: R.Common_Button_ExportToExcel,
							para9: R.Common_Button_Save,
							para10: R.Common_Button_Reset,
							para11: R.User_List_Button_5,
							search: R.search,
						},
						//
						module2: {
							para1: R.User_List_Select_1
						},
						//
						module3: {
							para1: R.User_Edit_1,
							para2: R.User_Edit_2,
							para3: R.User_Edit_3,
							para4: R.User_Edit_4,
							para5: R.User_Edit_5,
							para6: R.User_Edit_6,
							para7: R.User_Edit_7,
							para8: R.User_Edit_8,
							para9: R.User_Edit_9,
							para10: R.User_Edit_10,
							para11: R.User_Edit_11,
							para12: R.User_Edit_12,
							para13: R.User_Edit_13,
							para14: R.User_Edit_14,
							para15: R.User_Edit_15,
							para16: R.User_Edit_16,
							para17: R.User_Edit_17,
							para18: R.User_Edit_18,
							para19: R.User_Edit_19,
							para20: R.User_Edit_20,
							para21: R.User_Edit_21,
							para22: R.User_Edit_22,
							para23: R.User_Edit_23,
							para24: R.User_Edit_24,
							para25: R.User_Edit_25,
							para26: R.User_Edit_26,
							para27: R.User_Edit_27,
							para28: R.User_Edit_28,
							para29: R.User_Edit_29,
							para30: R.User_Edit_30,
							para31: R.User_Edit_31,
							para32: R.User_Edit_32
						},
						//
						columnNames: [{
								type: 'selection',
								width: 50,
								align: 'center'
							},
							{
								type: 'index',
								align: 'center',
								width: 55,
								tooltip: true,
							},
							{
								align: 'center',
								width: 140,
								title: R.User_ColumnName_1,
								key: 'Code',
								tooltip: true,

							}, {
								align: 'center',
								width: 100,
								title: R.User_ColumnName_2,
								key: 'Name',
								tooltip: true,
							},
							{
								align: 'center',
								width: 160,
								title: R.User_ColumnName_3,
								key: 'OrgName',
								tooltip: true,
							},
							{
								align: 'center',
								width: 120,
								title: R.User_ColumnName_4,
								key: 'MobileNo',
								tooltip: true,
							},
							{
								align: 'center',
								width: 160,
								title: R.User_ColumnName_5,
								key: 'UserName',
								tooltip: true,
							},
							{
								align: 'center',
								width: 160,
								title: R.User_ColumnName_6,
								key: 'RoleNames',
								tooltip: true,
							},
							{
								align: 'center',
								width: 140,
								title: R.User_ColumnName_7,
								key: 'IsAdminText',
								tooltip: true,
							},
							{
								align: 'center',
								title: R.User_ColumnName_8,
								key: 'DefaultLanguageText',
								width: 100,
								tooltip: true,
							},
							{
								align: 'center',
								title: R.User_ColumnName_9,
								key: 'IsEnabledText',
								width: 100,
								tooltip: true,
							},
							{
								align: 'left',
								width: 160,
								title: R.User_ColumnName_10,
								key: 'Remark',
								tooltip: true,
							},
							{
								title: ' '
							}
						],
						//
						//快速检索表达式
						QSE: "",
						//快速搜索文本
						QST: "",
						//排序
						sortDirection: 1,
						//列表及分页
						data: [],
						//总页数
						total: 0,
						//当前页码
						currentPage: 1,
						//每页记录数
						pageSize: 10,
						//列表选中目标
						selectedId: "",
						//列表选中目标状态
						enabled: "",
						isAdmin: false,
					},
					input: {
						//员工 - 来自人员选择器//待修改
						employee: {
							id: "",
							name: "",
							code: "",
							org: "",
							telephone: ""
						},
						//用户
						user: {
							name: "", //默认员工手机号
							password: "",
							passwordConfirm: "",
							defaultLanguage: 1, //默认中文
							enabled: "",
							isEnabledText: "",
							validityTime:'',
						},
						//角色
						roles: {
							//被选中的ID集合
							selectedIDs: [],
							//所有角色信息
							data: []
						},
						//备注
						remark: ""
					},
					//下拉列表
					dropdownList: {
						//语言
						language: [{
								value: 1,
								label: R.User_Edit_31
							},
							{
								value: 2,
								label: R.User_Edit_32
							}
						]
					}
				},

				highcurrent: true,
				//权限分配树
				permissionTrees: [{
						name: R.User_Edit_28, //树名
						ref: "dept", //
						ruleWhole: '', //管辖所有
						truemask: false, //子节点状态 是否可选择
						allDeptTree: [], //所有部门树（启用+停用）（方便切换显示停用部门时直接读取）
						excludeLockDeptTree: [], //排除停用部门树（启用）（方便切换显示停用部门时直接读取）
						tree: [], //第一次加载所显示的数据（启用）（默认不加载停用部门，如果用户切换了显示状态，则将两种都保存，方便切换显示停用部门时直接读取）
						showlock: false //是否显示停用部门
					},
					{
						name: R.User_Edit_29,
						ref: "assetCategory",
						ruleWhole: '',
						truemask: false,
						tree: []
					},
					{
						name: R.User_Edit_30,
						ref: "location",
						ruleWhole: '',
						truemask: false,
						tree: []
					},
				],
				// 表格高度适配
				screenWidth: document.body.clientWidth,
				screenHeight: document.body.clientHeight,
				//------
				truemask: false,
				selectalllist: false,
				selectallposition: false,
				selectall: false,
				rolepaytrue: false,
				checkbox: true,
				value: false,
				urla: '',
				status: '',
				loadingR: false,
				loadingL: false,
				mask: false,
				addone: false,
				selection: '',
				defaultProps: {
					children: 'Children',
					label: 'Name'
				}
			};
		},
		created() {
			//加载树
			this.getTree();
			//加载列表

		},
		watch: {
			screenWidth(val) {
				this.tableHeight = this.$refs.table.offsetHeight;
			},
			screenHeight(val) {
				this.tableHeight = this.$refs.table.offsetHeight;
			},
		},
		beforeUpdate() {
			const that = this
			window.onresize = () => {
				return(() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					that.screenWidth = window.screenWidth
					that.screenHeight = window.screenHeight
				})()
			}
			this.tableHeight = this.$refs.table.offsetHeight;
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_User')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted() {
			this.rules.Type = [{
				required: true,
				message: R.User_Leave_8,
				trigger: 'change'
			}];

			this.page = {
				title: R.User_Title_1,
				//树
				tree: {
					title: R.User_Tree_Title_1,
					defaultExpandKeys: [],
					data: [],
					defaultProps: {
						children: 'children',
						label: 'label',
						isLeaf: 'is_leaf'
					},
				},
				//列表
				list: {
					//
					module1: {
						para1: R.Common_Button_Add,
						para2: R.Common_Button_Operation,
						para3: R.Common_Button_Edit,
						para4: R.Common_Button_Enable,
						para5: R.User_List_Button_3,
						para6: R.Common_Button_Delete,
						para7: R.User_List_Button_4,
						para8: R.Common_Button_ExportToExcel,
						para9: R.Common_Button_Save,
						para10: R.Common_Button_Reset,
						para11: R.User_List_Button_5,
						search: R.search,
					},
					//
					module2: {
						para1: R.User_List_Select_1
					},
					//
					module3: {
						para1: R.User_Edit_1,
						para2: R.User_Edit_2,
						para3: R.User_Edit_3,
						para4: R.User_Edit_4,
						para5: R.User_Edit_5,
						para6: R.User_Edit_6,
						para7: R.User_Edit_7,
						para8: R.User_Edit_8,
						para9: R.User_Edit_9,
						para10: R.User_Edit_10,
						para11: R.User_Edit_11,
						para12: R.User_Edit_12,
						para13: R.User_Edit_13,
						para14: R.User_Edit_14,
						para15: R.User_Edit_15,
						para16: R.User_Edit_16,
						para17: R.User_Edit_17,
						para18: R.User_Edit_18,
						para19: R.User_Edit_19,
						para20: R.User_Edit_20,
						para21: R.User_Edit_21,
						para22: R.User_Edit_22,
						para23: R.User_Edit_23,
						para24: R.User_Edit_24,
						para25: R.User_Edit_25,
						para26: R.User_Edit_26,
						para27: R.User_Edit_27,
						para28: R.User_Edit_28,
						para29: R.User_Edit_29,
						para30: R.User_Edit_30,
						para31: R.User_Edit_31,
						para32: R.User_Edit_32
					},
					//
					columnNames: [{
							type: 'selection',
							width: 30,
							align: 'center'
						},
						{
							type: 'index',
							align: 'center',
							width:50,
							tooltip: true,
						},
						{
							align: 'center',
							width: 150,
							title: R.User_ColumnName_1,
							key: 'Code',
							tooltip: true,
							sortable: true,
						}, {
							align: 'center',
							width: 120,
							title: R.User_ColumnName_2,
							key: 'Name',
							tooltip: true,
						},
						{
							align: 'center',
							width: 160,
							title: R.User_ColumnName_3,
							key: 'OrgName',
							tooltip: true,
						},
						{
							align: 'left',
							width: 120,
							title: R.User_ColumnName_4,
							key: 'MobileNo',
							tooltip: true,
						},
						{
							align: 'center',
							width: 120,
							title: R.User_ColumnName_5,
							key: 'UserName',
							tooltip: true,
						},
						{
							align: 'center',
							width: 140,
							title: R.User_ColumnName_6,
							key: 'RoleNames',
							tooltip: true,
						},
						{
							align: 'center',
							width: 120,
							title: R.User_ColumnName_7,
							key: 'IsAdminText',
							tooltip: true,
						},
						//有效期
						{
							align: 'left',
							width: 140,
							title: R.User_84,
							key: 'EffectiveTime',
							tooltip: true,
						},
						{
							align: 'center',
							title: R.User_ColumnName_8,
							key: 'DefaultLanguageText',
							width: 100,
							tooltip: true,
						},
						{
							align: 'center',
							title: R.User_ColumnName_9,
							key: 'IsEnabledText',
							width: 100,
							tooltip: true,
						},
						{
							align: 'left',
							minWidth: 180,
							maxWidth:400,
							title: R.User_ColumnName_10,
							key: 'Remark',
							tooltip: true,
						},
					],
					//
					//快速检索表达式
					QSE: "",
					//快速搜索文本
					QST: "",
					//排序
					sortDirection: 1,
					//列表及分页
					data: [],
					//总页数
					total: 0,
					//当前页码
					currentPage: 1,
					//每页记录数
					pageSize: 10,
					//列表选中目标
					selectedId: "",
					//列表选中目标状态
					enabled: "",
					isAdmin: false,
				},
				input: {
					//员工 - 来自人员选择器//待修改
					employee: {
						id: "",
						name: "",
						code: "",
						org: "",
						telephone: ""
					},
					//用户
					user: {
						name: "", //默认员工手机号
						password: "",
						passwordConfirm: "",
						defaultLanguage: 1, //默认中文
						enabled: "",
						isEnabledText: "",
						validityTime:'',
					},
					//角色
					roles: {
						//被选中的ID集合
						selectedIDs: [],
						//所有角色信息
						data: []
					},
					//备注
					remark: ""
				},
				//下拉列表
				dropdownList: {
					//语言
					language: [{
							value: 1,
							label: R.User_Edit_31
						},
						{
							value: 2,
							label: R.User_Edit_32
						}
					]
				}
			};
			this.permissionTrees = [{
					name: R.User_Edit_28, //树名
					ref: "dept", //
					ruleWhole: '', //管辖所有
					truemask: false, //子节点状态 是否可选择
					allDeptTree: [], //所有部门树（启用+停用）（方便切换显示停用部门时直接读取）
					excludeLockDeptTree: [], //排除停用部门树（启用）（方便切换显示停用部门时直接读取）
					tree: [], //第一次加载所显示的数据（启用）（默认不加载停用部门，如果用户切换了显示状态，则将两种都保存，方便切换显示停用部门时直接读取）
					showlock: false //是否显示停用部门
				},
				{
					name: R.User_Edit_29,
					ref: "assetCategory",
					ruleWhole: '',
					truemask: false,
					tree: []
				},
				{
					name: R.User_Edit_30,
					ref: "location",
					ruleWhole: '',
					truemask: false,
					tree: []
				},
			];
			//获取表头元数据
			this.page.list.pageSize = Number(this.GetPageSize('System/User'))
			this.getList();
			document.querySelectorAll('.ivu-checkbox-wrapper')[0].style.display = 'none';
			this.$PermissionUtil.getPermission('System/User').then((res)=>{
				this.permissionCode = res;
			});
			this.passType = JSON.parse(this.$Store.get('passType'));
			this.page.list.module3.para19 = R.User_Edit_19+this.passType.PasswordPolicy_MinLength+R.User_Edit_33
		},
		methods: {
			//插眼保存有效期
			suresetTime(){
				this.isDisabled = true;
				this.$http.post('/User/SetEffectiveTime', {
					Data: {
						"Id": this.selection[0].ID, //员工ID
						'EffectiveTime':this.page.input.user.validityTime,
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.$message.success(res.data.Message)
						this.isDisabled = false;
						this.searchmylist()
						this.closemask()
					} else {
						this.isDisabled = false;
						this.closemask()
					}
				})
			},
			openTime(){
				if(this.selection.length == 0) {
					this.$message({
						message: R.Common_Msg_NoneBillSelected,
						type: 'error'
					});
					return;
				}
				if(this.selection.length > 1) {
					this.$message({
						message: R.Common_Msg_OnlyOneCanBeSelected,
						type: 'error'
					});
					return;
				}
				if(this.selection[0].IsAdmin == true){
					this.$message.error(R.UserEmployee_87)
					return
				}
				
				if(this.selection[0].IsEnabled == false ){
					this.$message.error(R.UserEmployee_88)
					return
				}
				this.mask = true;
				this.addone = true;
				this.status = 'del';
				this.mytime = true;
			},
			sortableShow(data) {
				if(data.order == "asc") {
					this.page.list.sortDirection = 0
				} else if(data.order == "desc") {
					this.page.list.sortDirection = 1
				} else {
					this.page.list.sortDirection = 1
				}
				this.page.list.currentPage = 1;
				this.getList();
			},
			aa() {
				this.value = false;
			},
			selectShow(selection, row) {
				this.selection = selection
			},
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {

						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}

			},
			// 接收人员选择器数据
			optionShow(data) {
				if(data == '') {
					this.page.input.employee.id = null;
					this.page.input.employee.name = '';
					this.page.input.employee.code = '';
					this.page.input.employee.org = '';
					this.page.input.employee.telephone = '';
				}
				this.$http.post("system:/Employee/GetByID", {

					Data: data
				}).then(data => {
					if(data.data.Code == '0') {
						var data = data.data.Data
						this.page.input.employee.id = data.ID;
						this.page.input.employee.name = data.Name;
						this.page.input.employee.code = data.Code;
						this.page.input.employee.org = data.OrganizationName;
						this.page.input.employee.telephone = data.MobileNo;
					} else {
						this.$message.error(data.data.Message)
					}
				})
			},
			DrawAssetEmployeeShow(data) {
				this.page.input.employee.id = data.ID;
				this.page.input.employee.name = data.Name;
				this.page.input.employee.code = data.Code;
				this.page.input.employee.org = data.OrgName;
				this.page.input.employee.telephone = data.MobileNo;
			},
			//////Get Start
			//部门树
			getTree() {
				let params = {

					Data: {
						'onlyloadEnableOrg': true,
						"LoadOrganizationType": 3,
						'OnlyLoadPrivilegeOrg': true,
						// 'OnlyLoadEnableOrg':true,
						'Delay': false,
						'ParentId': ""
					}
				}
				this.$http.post("/Organization/GetTree", params).then(data => {
					if(data.data.Code == '0') {
						this.page.tree.data = data.data.Data;
					} else {
						this.$message.error(data.data.Message)
					}
				})
			},
			//用户列表
			getList() {
				this.loadingR = true;
				let params = {

					Data: {
						"QST": this.page.list.QST,
						'QSE': this.page.list.QSE,
						'SortDirection': this.page.list.sortDirection,
						'PageSize': this.page.list.pageSize,
						'PageNO': this.page.list.currentPage,
						'IsSetNumber': true,
					}
				}
				this.SetPageSize(this.page.list.pageSize, 'System/User')
				this.$http.post('/User/List', params).then(data => {
					var rp = data.data.Data;
					if(data.data.Code == 0) {
						if(data.data.Data == null) {
							this.page.list.data = [];
						} else {
							this.page.list.data = rp.Items;
						}

						this.page.list.total = rp.TotalRecordQty;
						this.page.list.currentPage = rp.PageNO;
						this.page.list.pageSize = rp.PageSize
					} else {
						this.$message.error(data.data.Message)
					}
					this.loadingR = false;
					this.selection = []
					this.page.list.selectedId = '';
					this.page.list.enabled = '';
				})
			},
			//角色
			getRoleList() {
				let params = {

					Data: true
				}
				this.$http.post('/User/AllRoles', params).then(data => {
					var rp = data.data.Data;
					if(data.data.Code == '0') {
						this.page.input.roles.data = rp;
					} else {
						this.$message.error(data.data.Message)
					}
				})
			},
			//获取用户信息
			getUser() {
				var params = {
					token: this.$Store.get('token'),
					data: this.page.list.selectedId
				}
				this.CartridgeLoading = true;
				this.$http.post('/User/GetById', params).then(data => {
					var rp = data.data.Data;
					if(data.data.Code == '0') {
						//员工信息
						this.page.input.employee.name = rp.Name
						this.page.input.employee.code = rp.Code
						this.page.input.employee.org = rp.OrganizationName
						this.page.input.employee.telephone = rp.MobileNo
						this.page.input.user.validityTime = rp.EffectiveTime
							//用户信息 
						this.page.input.user.name = rp.UserName
						this.page.input.user.enabled = rp.IsEnabled;
						this.page.input.user.isEnabledText = rp.IsEnabledText;
						this.page.input.roles.selectedIDs = rp.Roles;
						this.page.input.user.defaultLanguage = rp.DefaultLanguage;

						this.page.input.remark = rp.Remark;
					} else {
						this.$message.error(data.data.Message)
					}
					this.CartridgeLoading = false;
				}).catch((error) => {
					this.CartridgeLoading = false;
				})
			},
			//获取权限信息
			getPermission() {
				this.innertreeloading = true;
				var params = {
					token: this.$Store.get('token'),
					data: this.page.list.selectedId
				}
				this.$http.post('/User/Permission/Get', params).then(data => {
					var rp = data.data.Data;
					if(data.data.Code == '0') {
						//
						//部门
						this.permissionTrees[0].ruleWhole = rp.IsRuleWholeCorp; //是否管辖全公司
						this.permissionTrees[0].truemask = rp.IsRuleWholeCorp; //树节点是否可选
						this.permissionTrees[0].tree = rp.TreeData_Organizations; //树结构信息
						this.permissionTrees[0].showlock = rp.IsShowUnableOrg; //是否显示停用部门
						this.$refs.dept[0].$refs.tree.setCheckedKeys(rp.SelectedIDs4Organizations); //给选中的节点赋值
						//是否显示停用部门
						if(rp.IsShowUnableOrg == true) {
							//如果当前用户为显示停用部门，则切换显示的数据并将两种都保存，方便切换数据
							this.showlocktree(rp.IsShowUnableOrg);
						}

						//资产分类
						this.permissionTrees[1].ruleWhole = rp.IsOwnerAllAssetCategory;
						this.permissionTrees[1].truemask = rp.IsOwnerAllAssetCategory;
						this.permissionTrees[1].tree = rp.TreeData_AssetCategories;
						this.$refs.assetCategory[0].$refs.tree.setCheckedKeys(rp.SelectedIDs4AssetCategories);

						//存放位置
						this.permissionTrees[2].ruleWhole = rp.IsRuleWholeLocation;
						this.permissionTrees[2].truemask = rp.IsRuleWholeLocation;
						this.permissionTrees[2].tree = rp.TreeData_Locations;
						this.$refs.location[0].$refs.tree.setCheckedKeys(rp.SelectedIDs4Locations);
						this.AdditionalAuthorized = rp.AdditionalAuthorized;

					} else {
						this.$message.error(data.data.Message)
					}
					this.innertreeloading = false;
				})
			},

			//新增
			add() {
				this.mask = true;
				this.addone = true;
				this.status = 'add';
				//加载角色（下拉菜单）
				this.getRoleList();
			},
			//编辑
			edit() {
				this.value = false
				if(this.selection.length == 0) {
					this.$message({
						message: R.Common_Msg_NoneBillSelected,
						type: 'error'
					});
					return;
				}
				if(this.selection.length > 1) {
					this.$message({
						message: R.Common_Msg_OnlyOneCanBeSelected,
						type: 'error'
					});
					return;
				}
				this.getUser();
				//加载角色（下拉菜单）
				this.getRoleList();
				this.mask = true;
				this.addone = true;
				this.status = 'edit';
			},
			//保存
			save() {
				this.$refs.ruleForm.validate((valid) => {
					if(valid) {
						this.isDisabled = true;
						//新增
						this.CartridgeLoading = true;
						if(this.status == "add") {
							//员工校验
							if(!this.page.input.employee.id) {
								this.$message.error(R.User_Error_5);
								return;
							}
							//密码校验
							if(this.page.input.user.password.length < this.passType.PasswordPolicy_MinLength) {
								this.$message.error(R.User_Edit_19+this.passType.PasswordPolicy_MinLength+R.User_Edit_33);
								return;
							}
							if(this.page.input.user.password.length > 50) {
								this.$message.error(R.User_Error_7);
								return;
							}
							if(this.page.input.user.password != this.page.input.user.passwordConfirm) {
								this.$message.error(R.User_Error_8);
								return;
							}
							//默认中文
							if(!(this.page.input.user.defaultLanguage)) {
								this.page.input.user.defaultLanguage = "1";
							}
							let params = {

								Data: {
									"EmployeeID": this.page.input.employee.id,
									"UserName": this.page.input.user.name,
									"UserPassword": this.page.input.user.password,
									"UserPasswordConfirm": this.page.input.user.passwordConfirm,
									"DefaultLanguage": this.page.input.user.defaultLanguage,
									"RoleIDs": this.page.input.roles.selectedIDs,
									"Remark": this.page.input.remark,
									"EffectiveTime":this.page.input.user.validityTime
								}
							}

							this.$http.post('/User/Add', params).then(data => {
								if(data.data.Code == '0') {
									this.$message({
										message: data.data.Message,
										type: 'success'
									});
									//									this.loadtree = false;
									//										setTimeout(() => {
									//											this.loadtree = true;
									//}, 10)
									this.page.list.currentPage = 1
									this.getList(); //重新加载列表
									this.clearInput(); //清空文本框
									this.mask = false;
									this.addone = false;
								} else {
									this.$message.error(data.data.Message)
								}
								this.isDisabled = false;
								this.CartridgeLoading = false;
							}).catch(() => {
								this.isDisabled = false;
								this.CartridgeLoading = false;
							});
						}
						//编辑
						else if(this.status == "edit") {
							//默认中文
							if(!(this.page.input.user.defaultLanguage)) {
								this.page.input.user.defaultLanguage = "1";
							}
							let params = {

								Data: {
									"ID": this.page.list.selectedId,
									"DefaultLanguage": this.page.input.user.defaultLanguage,
									"RoleIDs": this.page.input.roles.selectedIDs,
									"Remark": this.page.input.remark,
									"EffectiveTime":this.page.input.user.validityTime
								}
							}
							this.$http.post('/User/Edit', params).then(data => {
								if(data.data.Code == '0') {
									this.$message({
										message: data.data.Message,
										type: 'success'
									});
									//									this.loadtree = false;
									//										setTimeout(() => {
									//											this.loadtree = true;
									//									}, 10)
									this.getList(); //重新加载列表
									this.clearInput(); //清空文本框
									this.mask = false;
									this.addone = false;
								} else {
									this.$message.error(data.data.Message)
								}
								this.isDisabled = false;
								this.CartridgeLoading = false;
							}).catch(() => {
								this.isDisabled = false;
								this.CartridgeLoading = false;
							});
						}
						//修改密码
						else if(this.status == "modifyPassword") {
							//密码校验
							if(this.page.input.user.password.length < this.passType.PasswordPolicy_MinLength) {
								this.$message.error(R.User_Edit_19+this.passType.PasswordPolicy_MinLength+R.User_Edit_33);
								return;
							}
							if(this.page.input.user.password.length > 50) {
								this.$message.error(R.User_Error_7);
								return;
							}
							if(this.page.input.user.password != this.page.input.user.passwordConfirm) {
								this.$message.error(R.User_Error_8);
								return;
							}

							let params = {
								Data: {
									"ID": this.page.list.selectedId,
									"NewPassword": this.page.input.user.password,
									"ReNewPassword": this.page.input.user.passwordConfirm
								}
							}
							this.$http.post('/User/ModifyPassword', params).then(data => {
								if(data.data.Code == '0') {
									this.$message({
										message: data.data.Message,
										type: 'success'
									});
									this.clearInput(); //清空文本框
									this.getList(); //重新加载列表
									this.mask = false;
									this.addone = false;
								} else {
									this.isDisabled = false;
									this.$message.error(data.data.Message)
								}
								this.CartridgeLoading = false;
								this.isDisabled = false;
							}).catch((err) => {
								this.isDisabled = false;
								this.CartridgeLoading = false;
							});
						}
					}

				})

			},
			//是否删除
			del() {
				this.CartridgeLoading = false;
				if(this.page.list.isAdmin == true) {
					this.$message({
						message: R.User_Leave_6,
						type: 'error'
					});
					this.mask = false;
					this.addone = false;
					return;
				}

				this.value = false
				if(this.selection.length == 0) {
					this.$message({
						message: R.User_Error_9,
						type: 'error'
					});
					return;
				}
				if(this.selection.length > 1) {
					this.$message({
						message: R.Common_Msg_OnlyOneCanBeSelected,
						type: 'error'
					});
					return;
				}
				this.mask = true;
				this.addone = true;
				this.status = 'del';
			},
			//删除
			confirmDel() {
				this.isDisabled = true;
				var params = {
					token: this.$Store.get('token'),
					data: this.page.list.selectedId
				}
				this.$http.post('/User/Delete', params).then(data => {
					if(data.data.Code == '0') {
						this.$message({
							message: data.data.Message,
							type: 'success'
						});
						this.page.list.currentPage = 1
						this.getList(); //重新加载列表
						this.mask = false;
						this.addone = false;
					} else {
						this.$message.error(data.data.Message)
					}
					this.isDisabled = false;
				})
			},
			//是否停用/启用
			lock() {
				if(this.page.list.isAdmin == true) {
					this.$message({
						message: R.User_Leave_6,
						type: 'error'
					});
					this.mask = false;
					this.addone = false;
					return;
				}

				this.value = false
				if(this.selection.length == 0) {
					this.$message({
						message: R.User_Error_10,
						type: 'error'
					});
					return;
				}
				if(this.selection.length > 1) {
					this.$message({
						message: R.Common_Msg_OnlyOneCanBeSelected,
						type: 'error'
					});
					return;
				}

				this.mask = true;
				this.addone = true;
				this.status = 'lock';
			},
			//停用/启用
			confirmLock() {
				this.isDisabled = true;
				let params = {

					Data: {
						"ID": this.page.list.selectedId,
						"Enabled": this.page.list.enabled == "启用" || this.page.list.enabled == "Enabled" ? false : true
					}
				}
				this.$http.post('/User/EnableOrDisable', params).then(data => {
					if(data.data.Code == '0') {
						this.$message({
							message: data.data.Message,
							type: 'success'
						});
					} else {
						this.$message.error(data.data.Message)
					}
					this.getList(); //重新加载列表
					this.clearInput(); //清空文本框
					this.mask = false;
					this.addone = false;
					this.isDisabled = false;
				})
			},
			//修改密码
			modifyPassword() {
				this.value = false
				if(this.selection.length == 0) {
					this.$message({
						message: R.User_Error_11,
						type: 'error'
					});
					return;
				}
				if(this.selection.length > 1) {
					this.$message({
						message: R.Common_Msg_OnlyOneCanBeSelected,
						type: 'error'
					});
					return;
				}

				this.getUser();
				this.mask = true;
				this.addone = true;
				this.status = 'modifyPassword';
			},
			//数据权限分配
			rolepay() {
				if(this.page.list.isAdmin == true) {
					this.$message({
						message: R.User_Leave_6,
						type: 'error'
					});
					this.mask = false;
					this.addone = false;
					return;
				}

				if(this.page.list.enabled && this.page.list.enabled != "启用" && this.page.list.enabled != "Enabled") {
					this.$message({
						message: R.User_Leave_9,
						type: 'error'
					});
					this.mask = false;
					this.addone = false;
					return;
				}

				if(this.selection.length == 0) {
					this.$message({
						message: R.User_Error_12,
						type: 'error'
					});
					return;
				}
				if(this.selection.length > 1) {
					this.$message({
						message: R.Common_Msg_OnlyOneCanBeSelected,
						type: 'error'
					});
					return;
				}

				this.getPermission();
				this.mask = true;
				this.rolepaytrue = true;
			},
			//保存数据权限分配
			savePermission() {
				this.isDisabled = true;
				this.CartridgeLoading = true;
				let params = {
					Data: {
						"ID": this.page.list.selectedId,
						"IsRuleWholeCorp": this.permissionTrees[0].ruleWhole,
						"IsOwnerAllAssetCategory": this.permissionTrees[1].ruleWhole,
						"IsRuleWholeLocation": this.permissionTrees[2].ruleWhole,
						"PrivilegeOrganizationIds": this.$refs.dept[0].$refs.tree.getCheckedKeys(),
						"PrivilegeAssetCategoryIds": this.$refs.assetCategory[0].$refs.tree.getCheckedKeys(),
						"PrivilegeLocationIds": this.$refs.location[0].$refs.tree.getCheckedKeys(),
						"IsShowUnableOrg": this.permissionTrees[0].showlock,
						'AdditionalAuthorized': this.AdditionalAuthorized
					}
				}
				this.$http.post('/User/Permission/Save', params).then(data => {
					if(data.data.Code == '0') {
						this.$message({
							message: data.data.Message,
							type: 'success'
						});
						this.getTree(); //重新加载树
						this.getList(); //重新加载列表
						this.mask = false;
						this.rolepaytrue = false;
					} else {
						this.$message.error(data.data.Message)
					}
					this.isDisabled = false;
					this.CartridgeLoading = false;
				}).catch(() => {
					this.CartridgeLoading = false;
				})
			},
			//导出excel
			exportToExcel() {
				let params = {

					Data: {
						"QST": this.page.list.QST,
						'QSE': this.page.list.QSE,
						'SortDirection': this.page.list.sortDirection,
						'EnableEmployeeAccount': this.EnableEmployeeAccountNumber == '2' ? '' : this.EnableEmployeeAccountNumber   
					}
				}
				this.$http.post('/User/ExportToExcel', params, {
					headers: {
						"Content-Disposition": "attachment;filename=users.xls",
						"Content-Type": "application/octet-stream"
					},
					responseType: 'blob'
				}).then((rsp) => {
					if(!rsp) {
						return;
					}
					//
					let url = window.URL.createObjectURL(new Blob([rsp.data]));
					let link = document.createElement('a');
					if(window.navigator.msSaveBlob) {
						var blobObject = new Blob([rsp.data])
						window.navigator.msSaveBlob(blobObject, R.User_Leave_7 + '.xls'); // The user only has the option of clicking the Save button.
						return
					}  
					link.style.display = 'none';
					link.href = url;
					link.setAttribute('download', R.User_Leave_7 + '.xls');
					//
					document.body.appendChild(link);
					link.click();
				}).catch((error) => {
					alert(error)
				});
			},
			//是否显示停用部门
			showlocktree(val) {
				if(val == true) {
					//先判断是否已经存储所有（启用+停用）的部门信息
					if(this.permissionTrees[0].allDeptTree != '') {
						//有则直接取出
						this.permissionTrees[0].tree = this.permissionTrees[0].allDeptTree;
					} else {
						//如果没有则获取
						var params = {
							token: this.$Store.get('token'),
							data: {
								"ID": this.page.list.selectedId,
								"HiddenStopOrganization": false
							}
						}
						this.$http.post('/User/HiddenStopOrganization', params).then(data => {
							var rp = data.data.Data;
							if(data.data.Code == '0') {
								//部门
								//1、获取后将初始化的树存储（排除停用部门树）
								this.permissionTrees[0].excludeLockDeptTree = this.permissionTrees[0].tree;
								//2、获取所有部门信息存储并渲染数据
								this.permissionTrees[0].allDeptTree = rp;
								this.permissionTrees[0].tree = rp;
							} else {
								this.$message.error(data.data.Message)
							}
						})
					}
				} else {
					//直接获取存储的数据
					this.permissionTrees[0].tree = this.permissionTrees[0].excludeLockDeptTree;
				}
			},
			//点击左侧树结构
			clicktree(node, data) {
				this.page.list.QSE = data.data.QSE;
				this.getList(); //重新加载列表
				this.page.list.selectedId = data.data.id;
				this.page.list.isAdmin = false;
				this.selection = [];
			},
			//左侧树懒加载
			loadNode(node, resolve) {
				if(node.level === 0) { //第一级
					this.getOrgByParentID(null, resolve);
				} else {
					this.getOrgByParentID(node.data.id, resolve);
				}
			},
			// 获取指定的父级公司/部门的ID获取其下级公司/部门
			getOrgByParentID(parentID, resolve) {
				this.loadingL = true
				this.$http.post('/Organization/GetTree', {
					Data: {
						'onlyloadEnableOrg': true,
						LoadOrganizationType: 3,
						OnlyLoadPrivilegeOrg: true, //正式应该为true
						Delay: true,
						ParentId: parentID
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) { //成功
						//
						let items = rsp.data.Data;
						if(parentID == null && rsp.data.Data.length > 0) {
							var arr = [];
							arr.push(rsp.data.Data[0].id)
							this.expandkey = arr
						}
						resolve(items);
					} else {
						this.$message.error(rsp.data.Message)
					}
					this.loadingL = false
				}).catch((error) => {
					alert(error)
				});
			},
			//上级部门懒加载
			uprogloadOptions({
				action,
				parentNode,
				callback
			}) {
				// Typically, do the AJAX stuff here.
				// Once the server has responded,
				// assign children options to the parent node & call the callback.
				if(action === 'LOAD_CHILDREN_OPTIONS') {
					this.pageChangeTopTree(parentNode.id)
					simulateAsyncOperation(() => {
						parentNode.children = this.itemsleft
						callback()
					})
					callback()
				}
			},
			//////Operating End

			//////Verify Start
			//选择框 变更选中
			selectionChange(selects, row) {
				this.page.list.selectedId = row.ID;
				this.page.list.enabled = row.IsEnabledText;
				this.page.list.isAdmin = row.IsAdmin;
			},
			//选择框 取消选中
			selectionCancel(selection, row) {
				if(selection.length >= 1) {
					this.page.list.selectedId = selection[selection.length - 1].ID;
					this.page.list.enabled = selection[selection.length - 1].IsEnabledText;
				} else {
					this.page.list.selectedId = '';
					this.page.list.enabled = '';
					this.page.list.isAdmin = false;
				}
			},
			//关闭弹窗并清空
			closemask() {
				this.mask = false;
				this.showlock = false;
				this.addone = false;
				this.rolepaytrue = false;
				this.isDisabled = false;
				this.clearInput();
				this.CartridgeLoading = false;
			},
			//清空文本框
			clearInput() {
				this.page.input.employee.id = "";
				this.page.input.employee.name = "";
				this.page.input.employee.code = "";
				this.page.input.employee.org = "";
				this.page.input.employee.telephone = "";
				this.AdditionalAuthorized = '1';
				this.page.input.user.name = "";
				this.page.input.user.password = "";
				this.page.input.user.passwordConfirm = "";
				this.page.input.user.defaultLanguage = 1;
				this.page.input.roles.selectedIDs = [];
				this.page.input.remark = "";
				this.page.input.user.validityTime=''
			},
			//////Verify End

			selectallselect(item, index) {
				if(item.ruleWhole) {
					this.permissionTrees[index].truemask = true;
				} else {
					this.permissionTrees[index].truemask = false;
				}
				this.$forceUpdate();
			},
			searchmylist() {
				this.page.list.currentPage = 1;
				this.getList();
			},
			childByValue: function(childValue) {
				// childValue就是子组件传过来的值
				// this.page.list.QSE = childValue
				// this.getList();
			},
			handleSizeChange(val) {
				this.page.list.pageSize = val
				this.page.list.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page.list.currentPage = val;
				this.getList();
			}
		}
	};
</script>
<style lang='scss'>
	#user {
		overflow: auto;
		.el-button+.el-button {
			margin-left: 0px;
		}
		.el-button {
			margin-right: 5px;
		}
		.left {
			width: 300px;
			float: left;
			background-color: white;
			height: 100%;
			padding: 0;
			position: relative;
			.titleall {
				height: 40px;
				line-height: 40px;
				background-color: #EFF1F1;
				text-align: center;
				margin-bottom: 10px;
				font-size: 14px;
				border-right: 1px solid #eee;
				font-family: FZLTHK--GBK1-0;
				font-weight: bold;
				color: rgba(123, 128, 130, 1);
			}
		}
		.right {
			table {
				width: 100%!important;
			}
			.mytable {
				position: absolute;
				top: 45px;
				bottom: 78px;
				left: 0px;
				right: 0px;
				overflow: hidden;
			}
			padding: 15px;
			position: absolute;
			left: 315px;
			right: 0px;
			top: 0;
			bottom: 0;
			background-color: white;
			height: 100%;
			padding: 0px;
			.clickbtn {
				margin-bottom: 15px;
				height: 30px;
				.el-input {
					display: inline-block;
					float: right;
					width: 220px;
					i {
						position: relative;
						cursor: pointer;
					}
				}
				.el-input__inner {
					height: 30px;
					line-height: 30px;
					font-size: 14px;
					position: relative;
				}
				.myselecti {
					.el-input__inner {
						height: 30px!important;
						line-height: 30px
					}
					.el-input {
						width: 150px;
					}
				}
			}
		}
		.rightfoot {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 60px;
			padding-top: 14px;
			padding-left: 15px;
			background-color: #EFF1F1;
			box-sizing: border-box;
		}
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
			.del {
				width: 400px!important;
				height: 200px!important;
				.contentdel {
					padding-top: 50px;
					padding-left: 100px;
					margin-bottom: 20px;
					i {
						color: deepskyblue;
						margin-right: 10px;
						font-size: 16px;
					}
				}
				.contentdels {
					padding-top: 40px;
					padding-left: 50px;
					margin-bottom: 20px;
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 25px;
					float: right;
					margin-right: 10px;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
			.treeshow {
				height: 510px;
				width: 850px;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					padding-top: 10px;
					padding-right: 10px;
					display: flex;
					.threetree {
						flex: 1;
						margin-left: 10px;
						.commontree {
							top: 10px!important;
						}
						.tree-head {
							margin-bottom: 10px;
						}
						.tree-content {
							position: relative;
							border: 1px solid #eee;
							height: 350px;
							margin-bottom: 10px;
							overflow: auto;
							.el-tree {
								background-color: white;
							}
						}
						.notopentrue {
							position: relative;
							border: 1px solid #eee;
							height: 350px;
							margin-bottom: 10px;
							overflow: auto;
							.el-checkbox__inner {
								background-color: #EEEEEE!important;
								border: #EEEEEE!important;
							}
							.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
								.custom-tree-node>span:nth-of-type(1) {
									background-color: #f0f7ff!important;
								}
								.islocked {
									color: #FF5402;
								}
								.btns {
									color: #515a6e!important;
								}
								color: #515a6e;
							}
						}
					}
					.treemask {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						z-index: 2;
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					/*float: right;*/
					margin-right: 10px;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
			.addone {
				height: 582px;
				width: 520px;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					padding-left: 45px;
					padding-right: 15px;
					padding-top: 15px;
					box-sizing: border-box;
					.el-form-item__content {
						>.el-input {
							height: 30px;
							width: 220px;
							.el-input__inner {
								height: 30px;
							}
						}
						>.el-select {
							width: 220px;
							.el-tag {
								margin: 0 !important;
								margin-left: 5px !important;
							}
							.el-input__inner {
								height: 30px;
								font-size: 14px;
							}
						}
						#Personnelselector>.searchselect {
							>.el-select {
								>.el-input {
									>.el-input__inner {
										height: 30px;
										line-height: 30px;
									}
								}
							}
							.Personnelsearch {
								height: 30px;
							}
						}
						.red {
							color: red;
							position: relative;
							top: 2px;
						}
						.el-textarea {
							width: 220px;
							margin-top: 5px;
						}
						.el-form-item {
							margin-bottom: 0;
						}
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					position: absolute;
					right: 15px;
					bottom: 15px;
					.el-button {
						height: 30px!important;
						margin-left: 5px;
						margin-right: 0;
					}
				}
			}
			.addoneModifyPassword {
				height: 300px;
				width: 500px;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					padding-left: 45px;
					padding-right: 15px;
					padding-top: 15px;
					box-sizing: border-box;
					.el-form-item__content {
						>.el-input {
							height: 30px;
							width: 220px;
							.el-input__inner {
								height: 30px;
							}
						}
						>.el-select {
							width: 220px;
							.el-tag {
								margin: 0 !important;
								margin-left: 5px !important;
							}
							.el-input__inner {
								height: 30px;
								font-size: 14px;
							}
						}
						#Personnelselector>.searchselect {
							>.el-select {
								>.el-input {
									>.el-input__inner {
										height: 30px;
										line-height: 30px;
									}
								}
							}
							.Personnelsearch {
								height: 30px;
							}
						}
						.red {
							color: red;
							position: relative;
							top: 2px;
						}
						.el-textarea {
							width: 220px;
							margin-top: 5px;
						}
						.el-form-item {
							margin-bottom: 0;
						}
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					position: absolute;
					right: 15px;
					bottom: 15px;
					.el-button {
						height: 30px!important;
						margin-left: 5px;
						margin-right: 0;
					}
				}
			}
		}
	}
</style>