<template>
    <div>
        <div class="container" id="barTopMenu">
            <div class="row" style="margin-left:60px;">
                <!-- <div style="float:left;">
                    <a href="#" class="btn btn-info" onclick="kmain.createProcess();"><span
                            class="glyphicon glyphicon-file lang" as="new"> </span> </a>
                    <a href="#" class="btn btn-info" onclick="kmain.openProcess();"><span
                            class="glyphicon glyphicon-share lang" as="open"> </span></a>
                    <a href="#" class="btn btn-info" onclick="kmain.saveProcessFile();"><span
                            class="glyphicon glyphicon-ok lang" as="save"> </span></a>
                </div> -->
                <!-- <div class="dropdown" style="float:left;margin-left: 5px;color:#fff">
                    <a class="btn btn-info dropdown-toggle" data-toggle="dropdown"><span class="glyphicon glyphicon-th lang"
                            as="options"> </span></a>
                    <ul class="dropdown-menu" role="menu" aria-labelledby="dropdownMenu1">
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="#" class="lang" as="content"
                                onclick="kmain.previewXml();"></a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="#" class="lang" as="importxml"
                                onclick="kmain.importDiagram();"></a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="#" class="lang" as="simutest"
                                onclick="kmain.simuTest();"></a></li>
                        <li class="divider">&nbsp</li>
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="#" class="lang" as="en"
                                onclick="kmain.changeLang('en');"></a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="#" class="lang" as="zh"
                                onclick="kmain.changeLang('zh');"></a></li>
                    </ul>
                </div> -->
            </div>
        </div>

        <div id="kgraphContainer" style="overflow: scroll;" class="base">
            <!-- Graph Here -->
        </div>

        <!-- <div id="status" class="base" align="right" style="white-space:nowrap;"> -->
            <!-- Status Here -->
        <!-- </div> -->

        <!--Progress Bar-->
        <!-- <div class="modal js-loading-bar">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" data-transitiongoal="75"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>

export default {
    name:'process',
    data(){
        return{
        }
    }
}
</script>
<style>
div.base {
    position: absolute;
    overflow: hidden;
    font-family: Arial;
    font-size: 8pt;
    left: 62px !important;
}

div.base#kgraphContainer {
    border-style: solid;
    border-color: #F2F2F2;
    border-width: 1px;
    background: url('/static/sfd/Scripts/mxGraph/src/editor/images/grid.gif');
}

hr {
    border-top: 1px solid #000000 !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
}
</style>
<script type="text/javascript">
    mxBasePath = "/static/sfd/Scripts/mxGraph/src";
</script>
<script type="text/javascript">
    mxGraph.prototype.htmlLabels = true;
    mxGraph.prototype.allowLoops = true;

    mxGraph.prototype.isWrapping = function (cell) {
        return true;
    };

    mxConstants.DEFAULT_HOTSPOT = 1;

    // Enables guides
    mxGraphHandler.prototype.guidesEnabled = true;

    // Alt disables guides
    mxGuide.prototype.isEnabledForEvent = function (evt) {
        return !mxEvent.isAltDown(evt);
    };

    // Enables snapping waypoints to terminals
    mxEdgeHandler.prototype.snapToTerminals = true;

    window.onbeforeunload = function () { return mxResources.get('changesLost'); };
</script>

<script type="text/javascript">
    export default {
        name:'process',
        mounted(){
           
        },
    }
    
</script>