<template>
	<div class="Onlineusers Standardformat" v-loading="grid.loading" v-if="IsPageDome">
		<cj-ElasticFrame :title="R.System_SecurityModule_Onlineusers_11"  v-if="ElasticFrame" :sureDisable="DelsureDisable"   @clickSure="clickSure"  @clickCancel="ElasticFrame=false" width="430px" height="200px">
			<div slot="content" class="contentDocuments">
				<div class="Offline" >
                    <i class="el-icon-info"></i>{{R.System_SecurityModule_Onlineusers_12}}
                </div>
			</div>
		</cj-ElasticFrame>
        <div class="header">
            <div v-if="getpermission('Query')" class="topTreeselect">
                <!--资产分类-->
                <span>{{R.System_SecurityModule_Onlineusers_1}}：</span>
				<OrganizationSelect
					collapseTags
					:multiple="true" 
					:placeholder="top.crops.hint"
					v-model="top.crops.value" 
					:OnlyLoadEnableOrg="true"
				/> 
            </div>
            <div v-if="getpermission('Query')" class="topTreeselect">
                <!--操作类型-->
                <span>{{R.System_SecurityModule_Onlineusers_3}}：</span>
                <el-select clearable v-model="top.UserType.value" :placeholder="top.UserType.hint">
                    <el-option v-for="item in top.UserType.options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </div>
            <el-button v-if="getpermission('Query')"  @click="Search" class="orangebd" style="margin-left:5px">{{R.Common_Button_Search}}</el-button>
            <el-button v-if="getpermission('Refresh')" @click="Refresh">{{R.System_SecurityModule_Onlineusers_4}}</el-button>
            <div style="margin-left:8px;">{{R.System_SecurityModule_Onlineusers_5}}：{{top.TotalOnlineUser}}</div>
        </div>
        <div class="main">
            <div class="listTable" ref="table" >
                <Table type='selection' stripe :columns="grid.columns"  :height="grid.height" :data="grid.data"></Table>
            </div>
        </div>
        <div class="footer">
            <div>
                <div class="paginarion">
                    <template>
                        <div class="block">
                            <el-pagination :background="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="grid.pagination.pageNO" :page-sizes="[10, 20, 50, 100]" :page-size="grid.pagination.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="grid.pagination.totalQty">
                            </el-pagination>
                        </div>
                    </template>
                    <p class="selected">
                    </p>
                </div>
                
            </div>
        </div>
	</div>
</template>

<script>
	var R = {};
	export default {
		data() {
			return{
				ElasticFrame:false,
				DelsureDisable:false,
				permissionCode: [],
                IsPageDome:true,
				R:R,
                top:{
                    crops:{
                        options:null,
                        value:null,
                        hint:R.System_SecurityModule_Onlineusers_7
                    },
                    UserType:{
                        value:'',
                        options:[
                            {label:R.System_SecurityModule_Onlineusers_6,value:1},
                            {label:R.System_SecurityModule_Onlineusers_10,value:2},
                        ],
                        hint:R.System_SecurityModule_Onlineusers_7
                    },
                    TotalOnlineUser:0,
                },
                grid:{
					columns:[
                        { type: 'index', width: 50,tooltip:true, align: 'center' },		// 行号
                        { align: 'center',tooltip:true,width:160, title: R.System_SecurityModule_Onlineusers_Column_1, key: 'CorpName', },//公司
                     	{ align: 'center',tooltip:true,width:140, title: R.System_SecurityModule_Onlineusers_Column_2, key: 'OrgName',  },//部门
                        { align: 'center',tooltip:true,width:120, title: R.System_SecurityModule_Onlineusers_Column_3, key: 'EmployeeName',  },//员工姓名
						{ align: 'center',tooltip:true,width:120, title: R.System_SecurityModule_Onlineusers_Column_4, key: 'UserName',},//用户名
						{ align: 'left',tooltip:true,width:180, title: R.System_SecurityModule_Onlineusers_Column_5, key: 'LoginDateTime', },//登录时间
                     	{ align: 'left',tooltip:true,width:140, title: R.System_SecurityModule_Onlineusers_Column_6, key: 'LoginDurations',  },//登录时长
                        { align: 'left',tooltip:true,width:140, title: R.System_SecurityModule_Onlineusers_Column_7, key: 'IPAddress',},//IP地址
                        { align: 'left',tooltip:true,width:140, title: R.System_SecurityModule_Onlineusers_Column_8, 
                            render:(h, params)=>{
                                return h('div',{
                                    style:{
                                        fontSize: '14px',
										color: '#07BAD4',
										textDecoration: 'underline',
										cursor: 'pointer',
										textAlign:'left',
                                    },
                                    on:{
                                        click:()=>{
											this.ElasticFrame=true;
											this.ID=params.row.ID;
                                        }
                                    }
                                },R.System_SecurityModule_Onlineusers_11)
                            }
                        },//操作
						{ title: ' '}
					],	//表格列
					data:[],			//表格数据
					pagination:{		//分页
						pageSize:20		//每页记录数
						,pageNO:1		//当前页码
						,totalQty:0		//总记录数
					},
					loading:false,		//是否加载数据中
					height:'',			//表格高度
				},
				ID:null,
				// 屏幕
				screenWidth: document.body.clientWidth,
				screenHeight: document.body.clientHeight,
            };
        },
        methods:{
			clickSure(){
				this.DelsureDisable=true;
				this.$http.post('system:/System/OnLineUser/LogOut', {
					Data:this.ID
				}).then(res => {
					if (res.data.Code==0) {
						this.pageChange()
						this.ElasticFrame=false;
					}
					this.DelsureDisable=false;
				}).catch((error)=>{
					this.grid.loading = false;
					this.DelsureDisable=false;
				})
			},
            Data(){
                let data={};
                data.PageNo= this.grid.pagination.pageNO;
                data.PageSize= this.grid.pagination.pageSize;
                data.OrgIds=this.top.crops.value;
                data.UserType=this.top.UserType.value;
                data.IsSetNumber=true;
                return data
            },
            Search(){
                this.grid.pagination.pageNO=1;
				this.pageChange()
			},
			Refresh(){
				this.grid.loading = true;
				this.$http.post('system:System/OnLineUser/Refresh', {
					Data: this.Data()
				}).then(res => {
					this.grid.pagination.pageNO=1;
					this.pageChange()
				}).catch((error)=>{
					this.grid.loading = false;
				})
			},
            pageChange() {
				this.grid.loading = true;
				this.$http.post('system:/System/OnLineUser/List', {
					Data: this.Data()
				}).then(res => {
					if(res.data.Code == '0') {
						this.grid.data = res.data.Data.Items == null ? [] : res.data.Data.Items;
						this.top.TotalOnlineUser=res.data.Data.TotalOnlineUser
						this.grid.pagination.totalQty = res.data.Data.TotalRecordQty;
						this.grid.pagination.PageNO = res.data.Data.PageNO;
						this.grid.pagination.pageSize = res.data.Data.PageSize;
						this.SetPageSize(this.grid.pagination.pageSize,'System/SecurityModule/Onlineusers')
					} else {
						this.grid.data = [];
						this.grid.pagination.totalQty = 0;
						this.grid.pagination.PageNO = 1;
						
					}
					this.grid.loading = false;
				}).catch((error)=>{
					this.grid.loading = false;
				})
			},
            //分页尺码改变
			handleSizeChange(val) {
			    this.grid.pagination.pageSize = val
			    this.grid.pagination.pageNO = 1
				this.pageChange()
			},
			//分页当前页变化
			handleCurrentChange(val) {
				this.grid.pagination.pageNO = val
				this.pageChange()
			},
            ExportToExcel(){
            },
            //判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {
						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}
			},
        },
		watch: {
            screenWidth (val) {
				this.grid.height=this.$refs.table.offsetHeight;
			},
			screenHeight (val) {
				this.grid.height=this.$refs.table.offsetHeight;
			},
		},
		beforeUpdate() {
			// 屏幕自适应
            const me = this
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					me.screenWidth = window.screenWidth
					me.screenHeight = window.screenHeight
				})();
			}
			this.grid.height=this.$refs.table.offsetHeight;
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_SecurityModule_Onlineusers')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted(){
			this.grid.pagination.pageSize=this.GetPageSize('System/SecurityModule/Onlineusers')
			this.pageChange()
			//判断是否包含新增编辑等功能权限
			this.$PermissionUtil.getPermission('System/SecurityModule/Onlineusers').then((res)=>{
				this.permissionCode = res;
			});
			const me = this
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					me.screenWidth = window.screenWidth
					me.screenHeight = window.screenHeight
				})();
			}
			this.grid.height=this.$refs.table.offsetHeight;
        },
		
	};
</script>

<style lang='scss'>
	.contentDocuments {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 80px);
        .Offline{
            display: flex;
            align-items: center;
            >i {
                color: deepskyblue;
                font-size: 16px;
                margin-right: 10px;
            }
        }
    }
</style>