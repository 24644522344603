import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/components/index'
import home from '@/components/home/home'
import dataimport from '@/components/system/dataimport'
import dictionary from '@/components/system/dictionary'
import setting from '@/components/system/setting'
import org from '@/components/system/org.vue'
import employee from '@/components/system/employee'
import user from '@/components/system/user'
import role from '@/components/system/role'
import CompanyApprove from '@/components/system/BillApproval/CompanyApprove'
import EnableApproval from '@/components/system/BillApproval/EnableApproval.vue'
import CompanyApproveDetail from '@/components/system/BillApproval/CompanyApproveDetail'
import ProcessEngine from '@/components/system/BillApproval/ProcessEngine'
import StandardApproval from '@/components/system/BillApproval/StandardApproval.vue'
import OperationLog from '@/components/system/SecurityModule/OperationLog.vue'
import Onlineusers from '@/components/system/SecurityModule/Onlineusers.vue'
import AbnormalUser from '@/components/system/SecurityModule/AbnormalUser.vue'
import PasswordPolicy from '@/components/system/SecurityModule/PasswordPolicy.vue'
import addnotice from '@/components/system/addnotice.vue'
import menuManagement from '@/components/system/menuManagement.vue'
Vue.use(Router)

var routes = [{
    path: '/',
    name: 'index',
    component: Index,
    children: [{
        path: '/System/SecurityModule/PasswordPolicy',
        name: 'PasswordPolicy',
        component: PasswordPolicy,
    }, {
        path: '/System/NotificationManagement',
        name: 'addnotice',
        component: addnotice,
    },
    {
        path: '/System/SecurityModule/AbnormalUser',
        name: 'AbnormalUser',
        component: AbnormalUser,
    },
    {
        path: '/System/SecurityModule/Onlineusers',
        name: 'Onlineusers',
        component: Onlineusers,
    },
    {
        path: '/System/SecurityModule/OperationLog',
        name: 'OperationLog',
        component: OperationLog,
    },
    {
        path: '/system/BillApproval/StandardApproval',
        name: 'StandardApproval',
        component: StandardApproval,
    },
    {
        path: '/system/BillApproval/EnableApproval',
        name: 'EnableApproval',
        component: EnableApproval,
    },
    {
        path: '/home',
        name: 'home',
        component: home,
    },
    {
        path: '/system/import',
        name: 'dataimport',
        component: dataimport,
    },
    {
        path: '/system/dictionary',
        name: 'dictionary',
        component: dictionary,
    },
    {
        path: '/system/setting',
        name: 'setting',
        component: setting,
    },
    {
        path: '/System/Org',
        name: 'org',
        component: org
    }, {
        path: '/system/user',
        name: 'user',
        component: user
    }, {
        path: '/system/role',
        name: 'role',
        component: role
    }, {
        path: '/system/employee',
        name: 'employee',
        component: employee
    },
    {
        path: '/system/BillApproval/CompanyApprove',
        name: 'CompanyApprove',
        component: CompanyApprove,
    },
    {
        path: '/system/BillApproval/CompanyApproveDetail',
        name: 'CompanyApproveDetail',
        component: CompanyApproveDetail,
        meta: {
            NoOrder: true
        },
    },
    {
        path: '/system/BillApproval/ProcessEngine',
        name: 'ProcessEngine',
        component: ProcessEngine
    },
    {
        path: '/system/MenuManagement',
        name: 'MenuManagement',
        component: menuManagement
    }
    ]
}]
const router = new Router({
    mode: 'hash',
    routes
});
router.beforeEach((to, from, next) => {
    if (from.name == 'ProcessEngine') {
        var mxWindow = $('.mxWindow')
        for (let i = 0; i < mxWindow.length; i++) {
            $(mxWindow[i]).remove()
        }
    }
    next();
})


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


export default router;