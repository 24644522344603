// 默认服务
let config = window.g;
const defaultService = config.defaultService;
const fileServiceRoot = config.fileServiceRoot;
const services = {
	// 固定资产服务
	asset: {
		url: config.services.asset.url,
	},
	// 系统管理服务
	system: {
		url: config.services.system.url,
	},
	imgurl: {
		//文件服务
		url: config.services.imgurl.url
	},
	consumables: {
		//易耗品服务
		url: config.services.consumables.url
	},
	myWork: {
		//我的工作服务
		url: config.services.myWork.url
	},
	systemInternet: {
		//system 跳转
		//      url: 'http://sys.houqinstar.com/',
		url: config.services.systemInternet.url
	},
	webInternet: {
		//c8 跳转
		//     url: 'http://bp.houqinstar.com/'
		url: config.services.webInternet.url
	},
	frameInternet: {
		//流程引擎 跳转
		url: config.services.frameInternet.url
	},
};
export default {
	// 获取指定服务的根URL
	getServiceRoot(service) {
		let s = service || defaultService;
		let url = services[s].url;
		if(url === undefined) {}
		//
		return url;
	},
	// 获取指定服务下的API的URL
	getAPIUrl(service, apiPath) {
		let root = this.getServiceRoot(service);
		return root + apiPath;
	},
	// 获取默认服务下的API的URL
	getDefaultServiceAPIUrl(apiPath) {
		return this.getAPIUrl(defaultService, apiPath);
	},
	// 获取文件的完整URL
	getFileUrl(absolutePath) {
		if(absolutePath) {
			if(absolutePath.startWith('/')) {
				return fileServiceRoot + absolutePath;
			} else {
				return fileServiceRoot + '/' + absolutePath;
			}
		}
	},
}