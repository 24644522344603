<template>
	<div id="dictionary" v-loading="loadingR" v-if="IsPageDome">
		<div>
		</div>
		<div class="left">
			<div class="titleall" v-if="title">{{title}}</div>
			<cjdirTree v-on:childByValue="childByValue" v-on:clickparentKey="clickparentKey" :title="title" :openName="openName" :defaultProps='defaultProps' :defaultExpandKeys='defaultExpandKeys' :api="Allapi" :url='urla'></cjdirTree>
		</div>
		<div class="right">
			<div class="main">
				<div class="btns">
					<!--<el-button class="orangetext add orangebd" @click="append()"><i class="el-icon-plus"></i>新增字典</el-button>-->
					<el-button v-if="getpermission('Save')" @click='update'>{{R.Dictionary_1}}</el-button>
				</div>
				<div class="table" ref="table">
					<Table :height='tableHeight' stripe @on-select-cancel="selectcancel" @on-select="selectionChange" ref="mytable" :columns="tableColumns" sortable='custom' @on-sort-change="sortableShow" :data="tableData"></Table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var R = {};
	export default {
		methods: {
			sortableShow(data) {
				if(data.order == "asc") {
					this.sort = 1
				} else if(data.order == "desc") {
					this.sort = 2
				} else {
					this.sort = 2
				}
			},
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {
						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}

			},
			update() {
				var index = 0;
				for(var i = 0; i < this.tableData.length; i++) {
					if(this.tableData[i].ItemTextCN == '' || this.tableData[i].ItemTextEN == '' || this.tableData[i].ItemCode == '') {
						this.$message.error(R.Dictionary_2 + R.Dictionary_3)
						return
					}
					if(this.tableData[i].IsDefaultItem) {
						index = index + 1;
					}
				}
				if(index > 1) {
					this.$message.error(R.Dictionary_4)
					return
				}
				if(index == 0) {
					this.$message.error(R.Dictionary_5)
					return
				}
				var DictID = (this.QSE == '' || this.QSE == null) ? this.Allapi[0].Children[0].ID : this.QSE
				var params = {
					Data: {
						DictionaryItems: this.tableData,
						//要看
						"DictID": DictID,
					}
				}

				this.$http.post('/Dictionary/Item/Save', params).then(res => {
					if(res.data.Code == '0') {
						this.$message.success(res.data.Message)
						this.pageChange(DictID);
					} else {
						
					}
				})
			},
			//列表渲染刷新
			pageChange(defaultId) {
				this.loadingR = true
				let params = {
					Data: {
						//页面默认进入第一项
						"DictionaryID": defaultId ? defaultId : (this.QSE == '' || this.QSE == null) ? 1 : this.QSE,
						"DictionaryType": defaultId ? 'System' : (this.DictionaryType == '' || this.DictionaryType == null) ? 1 : this.DictionaryType,
						'SortDirection': this.sort,
						'IsSetNumber': true,
					}
				}
				this.$http.post('/Dictionary/Item/List', params).then(data => {
					if(data.data.Code == '0') {
						if(!data.data.Data || data.data.Data.length == 0) {
							this.tableData = [{
								"ItemTextCN": '',
								"ItemTextEN": "",
								"ItemCode": '',
								"IsBuildIn": false,
								"IsDefaultItem": false,
								"Remark": '',
								'checked': false,
							}]
						} else {
							this.tableData = data.data.Data

						}
					} else {
						
					}
					this.loadingR = false
				})
			},
			//table 取消选择
			selectcancel(selection, row) {
				if(selection.length >= 1) {
					this.editId = selection[selection.length - 1].ID;

				} else {
					this.editId = '';
				}
			},
			//table 选中
			selectionChange(select, row) {
				this.editId = row.ID;
				this.Enabled = row.IsEnabled;
				this.clickQse = row.QSE;
				//var index = this.data1.findIndex(d => d.ID === row.ID)
			},
			expand(index) {
				this.clicked = true;
				if(this.myindex.indexOf(index) == '-1') {
					this.myindex += index + ',';
				}

			},
			childByValue: function(childValue) {
				// childValue就是子组件传过来的值
				this.QSE = childValue
				// console.log(this.QSE)
			},
			clickparentKey: function(clickparentKey) {
				// childValue就是子组件传过来的值
				this.DictionaryType = clickparentKey
				if(this.DictionaryType == 'System') {
					this.disabled = true;
				} else {
					this.disabled = false;
				}
				this.pageChange();
			},
			show(index) {
				var icon = this.myindex.split(',');
				this.myindex = '';
				for(var i = 0; i < icon.length - 1; i++) {
					if(icon[i] != index) {
						this.myindex += icon[i] + ','
					}
				}
			},
			addmenu(index) {
				this.tableData.push({
					"ItemTextCN": '',
					"ItemTextEN": "",
					"ItemCode": '',
					"IsBuildIn": false,
					"IsDefaultItem": false,
					"Remark": '',
					'checked': false,
				})
			},
			cutmenu(index) {
				if(this.tableData.length == 1) {
					this.$message.error(R.Dictionary_6)
					return;
				} else {
					this.tableData.splice(index, 1)
				}

			},
			closemask() {
				this.mask = false;
				this.addone = false;
				this.addlist = false;
			},
			append() {
				this.mask = true;
				this.addlist = true;
				this.liststatus = "add";
				//				// console.log(this.ParentID)
			},
			remove() {
				this.mask = true;
				this.addlist = true;
				this.liststatus = "del";
				//				this.addlistid = data.ID
			},
			eddit() {
				this.mask = true;
				this.addlist = true;
				this.liststatus = "edited";

			},
			suredel() {
				this.mask = false;
				this.addlist = false;
				this.listname = '';
				this.listcode = '';

			},
			sureupdate() {
				this.mask = false;
				this.addlist = false;
				this.listname = '';
				this.listcode = '';

			},
			treepage() {
				this.loadingL = true
				this.$http.post('/Dictionary/GetTree').then(res => {
					if(res.data.Code == '0') {
						this.Allapi = res.data.Data
						var myid = this.Allapi[0].Children[0] ? this.Allapi[0].Children[0].ID : this.Allapi[1].Children[0].ID
						this.openName =  this.Allapi[0].Children[0] ? this.Allapi[0].Children[0].Name : this.Allapi[1].Children[0].Name
						// console.log(myid)
						this.pageChange(myid);
					} else {
						
					}
					this.loadingL = false
				}).catch((error) => {
					
				});
			}
		},
		watch: {
			screenWidth(val) {
				this.tableHeight = this.$refs.table.offsetHeight;
			},
			screenHeight(val) {
				this.tableHeight = this.$refs.table.offsetHeight;
			},
		},
		data() {
			return {
				openName:'',
				IsPageDome: true,
				sort: 0,
				R: R,
				QSE: '',
				permissionCode: [],
				//系统字典不可编辑增加
				disabled: true,
				DictionaryType: '',
				loadingL: false,
				loadingR: false,
				// 表格高度适配
				screenWidth: document.body.clientWidth,
				screenHeight: document.body.clientHeight,
				// 表格高度
				tableHeight: '',
				total: 0,
				pagesize: 10,
				currentPage: 1,
				search: '',
				refsa: "dictonarytree",
				urla: 'Dictionary',
				title: R.Dictionary_7,
				defaultExpandKeys: [1],
				defaultProps: {
					children: 'Children',
					label: 'Name'
				},
				checked: false,
				listname: '',
				liststatus: '',
				mask: false,
				addlist: false,
				tableColumns: [{
						title: ' ',
						key: 'action',
						width: 90,
						render: (h, params) => {
							if(params.row.IsBuildIn) {
								return h('div', [
									h('Icon', {
										props: {
											type: 'md-add-circle',
											size: '18',
											color: '#909399'
										},
										on: {
											click: () => {
												this.addmenu(params.index)
											}
										}

									}),
								]);
							} else {
								return h('div', [
									h('Icon', {
										props: {
											type: 'md-add-circle',
											size: '18',
											color: '#909399'
										},
										on: {
											click: () => {
												this.addmenu(params.index)
											}
										}

									}),
									h('Icon', {
										props: {
											type: 'ios-remove-circle-outline',
											size: '18',
											color: '#909399'
										},
										on: {
											click: () => {
												this.cutmenu(params.index)
											}
										}
									}),
								]);
							}

						},
					}, {
						type: 'index',
						align: 'center',
						width: 100,
						tooltip: true,
					}, {
						title: R.Dictionary_8,
						key: 'ItemTextCN',
						align: 'center',
						width: 150,
						render: (h, params) => {
							return h('div', [
								h('Input', {
									props: {
										value: params.row.ItemTextCN,
										disabled: params.row.IsBuildIn,
										maxlength: 50,
									},
									on: {
										'on-blur': (event) => {
											this.tableData[params.index].ItemTextCN = event.target.value
										}
									},
								}),
							]);
						},
					}, {
						title: R.Dictionary_9,
						key: 'ItemTextEN',
						align: 'center',
						width: 150,
						render: (h, params) => {
							return h('div', [
								h('Input', {
									props: {
										value: params.row.ItemTextEN,
										disabled: params.row.IsBuildIn,
										maxlength: 50,

									},
									on: {
										'on-blur': (event) => {
											this.tableData[params.index].ItemTextEN = event.target.value
										}
									},
								}),
							]);
						},
					},
					{

						title: R.Dictionary_10,
						key: 'ItemCode',
						align: 'center',
						width: 140,
						render: (h, params) => {
							return h('div', [
								h('Input', {
									props: {
										value: params.row.ItemCode,
										disabled: params.row.IsBuildIn,
										maxlength: 10,
									},
									on: {
										'on-blur': (event) => {
											this.tableData[params.index].ItemCode = event.target.value.trim()
										}
									},
								}),
							]);
						},
					},
					{
						title: R.Dictionary_11,
						key: 'IsDefaultItem',
						align: 'center',
						width: 100,
						render: (h, params) => {
							var _this = this
							return h('div', [
								h('Checkbox', {
									props: {
										value: params.row.IsDefaultItem
									},
									on: {
										'on-change' (checkbox) {
											if(checkbox) {
												for(let i = 0; i < _this.tableData.length; i++) {
													_this.tableData[i].IsDefaultItem = false;
												}
											}
											_this.tableData[params.index].IsDefaultItem = checkbox

										}
									},
								}),

							]);
						},
					}, {
						title: R.Dictionary_12,
						key: 'IsBuildIn',
						align: 'center',
						width: 100,
						render: (h, params) => {
							var _this = this
							return h('div', [
								h('Checkbox', {
									props: {
										disabled: true,
										value: params.row.IsBuildIn,
									},
									on: {
										'on-change' (checkbox) {
											_this.tableData[params.index].IsBuildIn = checkbox

										}
									},
								}),

							]);
						},
					}, {
						title: R.Dictionary_13,
						key: 'Remark',
						align: 'left',
						width: 160,
						render: (h, params) => {
							return h('div', [
								h('Input', {
									props: {
										value: params.row.Remark,
										disabled: params.row.IsBuildIn,
										maxlength: 200,
									},
									on: {
										'on-blur': (event) => {
											this.tableData[params.index].Remark = event.target.value
										}
									},
								}),
							]);
						},
					},
					{
						title: ' '
					}
				],
				tableData: [],
				myindex: '999999999,',
				clicked: false,
				Iindex: false,
				value: '',
				Allapi: [],
			};
		},
		beforeUpdate() {
			const that = this
			window.onresize = () => {
				return(() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					that.screenWidth = window.screenWidth
					that.screenHeight = window.screenHeight
				})()
			}
			this.tableHeight = this.$refs.table.offsetHeight;
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_Dictionary')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted() {
			this.title = R.Dictionary_7;
			this.tableColumns = [{
					title: ' ',
					key: 'action',
					width: 90,
					render: (h, params) => {
						if(params.row.IsBuildIn) {
							return h('div', [
								h('Icon', {
									props: {
										type: 'md-add-circle',
										size: '18',
										color: '#909399'
									},
									on: {
										click: () => {
											this.addmenu(params.index)
										}
									}

								}),
							]);
						} else {
							return h('div', [
								h('Icon', {
									props: {
										type: 'md-add-circle',
										size: '18',
										color: '#909399'
									},
									on: {
										click: () => {
											this.addmenu(params.index)
										}
									}

								}),
								h('Icon', {
									props: {
										type: 'ios-remove-circle-outline',
										size: '18',
										color: '#909399'
									},
									on: {
										click: () => {
											this.cutmenu(params.index)
										}
									}
								}),
							]);
						}

					},
				}, {
					type: 'index',
					align: 'center',
					width: 50,
					tooltip: true,
				}, {

					title: R.Dictionary_8,
					key: 'ItemTextCN',
					align: 'center',
					width: 150,
					render: (h, params) => {
						return h('div', [
							h('Input', {
								props: {
									value: params.row.ItemTextCN,
									disabled: params.row.IsBuildIn,
									maxlength: 50,
								},
								on: {
									'on-blur': (event) => {
										this.tableData[params.index].ItemTextCN = event.target.value
									}
								},
							}),
						]);
					},

				}, {

					title: R.Dictionary_9,
					key: 'ItemTextEN',
					align: 'center',
					width: 150,
					render: (h, params) => {
						return h('div', [
							h('Input', {
								props: {
									value: params.row.ItemTextEN,
									disabled: params.row.IsBuildIn,
									maxlength: 50,
								},
								on: {
									'on-blur': (event) => {
										this.tableData[params.index].ItemTextEN = event.target.value
									}
								},
							}),
						]);
					},
				},
				{

					title: R.Dictionary_10,
					key: 'ItemCode',
					align: 'center',
					sortable: true,
					width: 140,
					render: (h, params) => {
						return h('div', [
							h('Input', {
								props: {
									value: params.row.ItemCode,
									disabled: params.row.IsBuildIn,
									maxlength: 10,
								},
								on: {
									'on-blur': (event) => {
										this.tableData[params.index].ItemCode = event.target.value.trim()
									}
								},
							}),
						]);
					},
				},
				{
					title: R.Dictionary_11,
					key: 'IsDefaultItem',
					align: 'center',
					width: 100,
					render: (h, params) => {
						var _this = this
						return h('div', [
							h('Checkbox', {
								props: {
									value: params.row.IsDefaultItem
								},
								on: {
									'on-change' (checkbox) {
										if(checkbox) {
											for(let i = 0; i < _this.tableData.length; i++) {
												_this.tableData[i].IsDefaultItem = false;
											}
										}
										_this.tableData[params.index].IsDefaultItem = checkbox

									}
								},
							}),

						]);
					},
				}, {
					title: R.Dictionary_12,
					key: 'IsBuildIn',
					align: 'center',
					width: 100,
					render: (h, params) => {
						var _this = this
						return h('div', [
							h('Checkbox', {
								props: {
									disabled: true,
									value: params.row.IsBuildIn,
								},
								on: {
									'on-change' (checkbox) {
										_this.tableData[params.index].IsBuildIn = checkbox

									}
								},
							}),

						]);
					},
				}, {
					title: R.Dictionary_13,
					key: 'Remark',
					align: 'left',
					minWidth: 180,
					maxWidth: 400,
					render: (h, params) => {
						return h('div', [
							h('Input', {
								props: {
									value: params.row.Remark,
									disabled: params.row.IsBuildIn,
									maxlength: 200,
								},
								on: {
									'on-blur': (event) => {
										this.tableData[params.index].Remark = event.target.value
									}
								},
							}),
						]);
					},
				},
				{
					title: ' '
				}
			];
			this.treepage()
			//	document.querySelectorAll('.ivu-checkbox-wrapper')[0].style.display = 'none';
			this.$PermissionUtil.getPermission('System/Dictionary').then((res)=>{
				this.permissionCode = res;
			});

		}
	};
</script>

<style lang='scss'>
	#dictionary {
		.table {
			position: absolute;
			top: 45px;
			left: 0px;
			right: 0px;
			bottom: 0;
		}
		.rightfoot {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 60px;
			padding-left: 15px;
			background-color: #EFF1F1;
			padding-top: 14px;
		}
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
			.addone {
				height: 400px;
				width: 500px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					padding-left: 20px;
					li {
						margin-top: 20px;
						.red {
							color: red;
						}
						.el-input {
							height: 30px;
							width: 160px;
						}
						.el-input__inner {
							height: 30px;
						}
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 25px;
					float: right;
					margin-right: 10px;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
				.contentdel {
					padding-top: 50px;
					padding-left: 100px;
					margin-bottom: 20px;
					i {
						color: deepskyblue;
						margin-right: 10px;
						font-size: 16px;
					}
				}
			}
			.deldel {
				height: 200px;
			}
			.addone {
				height: 546px;
				width: 500px;
				position: fixed;
				margin: auto;
				background-color: white;
				.head {
					border: 1px solid #eee;
					height: 35px;
					line-height: 35px;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						border-right: 1px solid #eee;
						font-weight: 600;
					}
					.icon {
						float: left;
						width: 10%;
						text-align: center;
						cursor: pointer;
					}
				}
				.content {
					padding-left: 20px;
					li {
						margin-top: 8px;
						height: 35px;
						line-height: 35px;
						.el-input {
							height: 30px;
							width: 160px;
						}
						.el-input__inner {
							height: 30px;
						}
						.red {
							color: red;
						}
						.el-textarea {
							width: 60%;
						}
						.beizhu {}
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 35px;
					float: right;
					margin-right: 10px;
					.el-button {
						height: 26px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
		}
		.left {
			width: 300px;
			float: left;
			background-color: white;
			height: 100%;
			padding: 0;
			position: relative;
			.titleall {
				height: 40px;
				line-height: 40px;
				background-color: #EFF1F1;
				text-align: center;
				font-size: 14px;
				border-right: 1px solid #eee;
				font-family: FZLTHK--GBK1-0;
				font-weight: bold;
				color: rgba(123, 128, 130, 1);
			}
		}
		.right {
			padding: 15px;
			position: absolute;
			left: 315px;
			right: 0px;
			top: 0;
			bottom: 0;
			background-color: white;
			height: 100%;
			padding: 0px;
			.btns {
				margin-bottom: 15px
			}
		}
	}
</style>