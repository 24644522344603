<template>
  <div class="selectMask">
    <div class="isShow">
      <div class="head">
        <span class="title">{{title}}</span>
        <span class="icon" @click="closeThis"
          ><i class="el-icon-circle-close"></i
        ></span>
      </div>
      <div>
        <el-row>
          <el-col :span="8">
            <p>{{R.User_Select_List_1}}</p>
            <div class="tree" v-loading="utility.tree.loading">
              <el-tree
                node-key="id"
                :default-expanded-keys="utility.tree.expandkeys"
                :load="loadNode"
                lazy
                ref="SelectTree"
                :default-checked-keys="utility.tree.defaultExpandKeys"
                highlight-current
                :props="utility.tree.defaultProps"
                @node-click="clicktree"
              >
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span class="mytreelistitem" :title="node.label"
                    >{{ node.label }}
                  </span>
                </span>
              </el-tree>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="header">
              <el-input
                v-model="utility.qst"
                :placeholder="R.User_Select_List_2"
                @keyup.enter.native="search"
              >
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
              </el-input>
              <el-button @click="search">{{R.User_Select_List_3}}</el-button>
            </div>
            <el-table
              v-loading="utility.loading"
              :row-style="{ height: '40px' }"
              :cell-style="{ padding: '0px' }"
              :data="utility.list"
              height="370px"
              @selection-change="changeSelectedTableIds"
              style="width: 100%"
            >
              <el-table-column
                v-for="(item, index) in utility.columns"
                :key="index"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                :align="item.align"
                :type="item.type"
              >
              </el-table-column>
            </el-table>
            <div class="page">
              <el-pagination
                @size-change="changeSize"
                @current-change="changeCurrent"
                :current-page="utility.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="utility.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="utility.total"
              >
              </el-pagination>
            </div>
            <div class="footer">
              <el-button class="orangebd" @click="okSelect">{{R.User_Select_List_4}}</el-button>
              <el-button @click="closeThis">{{R.User_Select_List_5}}</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
var R = {};
export default {
  name: "UserSelect",
  props: {
    roleId: {
      type: [Number, undefined, null],
      default: null,
    },
    title:{
      type:String,
      default:R.User_Select_List_6
    }
  },
  data() {
    return {
      R:R,
      utility: {
        columns: [
          { label: "#", align: "center", width: "55", type: "selection" },
          { label: "#", align: "center", width: "55", type: "index" },
          { label:R.User_Select_List_7, align: "center", width: "160", prop: "Code" },
          { label: R.User_Select_List_8, align: "center", width: "160", prop: "UserName" },
          { label: R.User_Select_List_9, align: "center", width: "160", prop: "Name" },
          { label: R.User_Select_List_10, align: "center", width: "160", prop: "OrgName" },
          { label: R.User_Select_List_11, align: "center", width: "160", prop: "Phone" },
          {
            label: R.User_Select_List_12,
            align: "center",
            width: "160",
            prop: "EffectiveTime",
          },
        ],
        list: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        selection: [],
        tree: {
          expandkeys: [],
          defaultExpandKeys: [],
          defaultProps: {
            children: "children",
            label: "label",
            isLeaf: "is_leaf",
          },
          loading: false,
          id: null,
        },
        loading: false,
        qst: null,
      },
    };
  },
  computed: {},
  beforeCreate(){
    R = this.getSelfLanguage('System', 'User_Select');
  },
  mounted(){
    this.search();
  },
  methods: {
    closeThis() {
      this.$emit("close");
    },
    okSelect() {
      if(this.utility.selection.length<1){
        this.$message.error(R.User_Select_List_14);
        return;
      }
      this.$http
        .post("system:/Role/Users/Save", {
          Data: {
            ID: this.roleId,
            UserIDs: this.utility.selection,
          },
        })
        .then((rsp) => {
          if (rsp.data.Code === 0) {
            this.$emit("ok");
            this.closeThis();
          }
        });
    },
    changeCurrent(val) {
      this.utility.currentPage = val;
      this.search();
    },
    changeSize(val) {
      this.utility.currentPage = 1;
      this.utility.pageSize = val;
      this.search();
    },
    changeSelectedTableIds(val) {
      this.utility.selection = val.map((item) => item.ID);
    },
    search() {
      this.utility.loading=true;
      this.$http
        .post("system:/Role/Users/Query", {
          Data: {
            ID: this.roleId,
            OrgID: this.utility.tree.id,
            QST: this.utility.qst,
            PageNO: this.utility.currentPage,
            PageSize: this.utility.pageSize,
          },
        })
        .then((rsp) => {
          if (rsp.data.Code === 0) {
            this.utility.list = rsp.data.Data.Items;
            this.utility.total = rsp.data.Data.TotalRecordQty;
          }
          this.utility.loading=false;
        });
    },
    clicktree(node, data) {
      this.utility.tree.QSE = data.data.QSE;
      this.utility.tree.id = data.data.id;
      this.search();
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        if (this.ParentId !== null && this.ParentId !== "") {
          this.getOrgByParentID(this.ParentId, resolve, this.IsIncludeSelf);
        } else {
          this.getOrgByParentID(null, resolve);
        }
      } else {
        this.getOrgByParentID(node.data.id, resolve);
      }
    },
    getOrgByParentID(parentID, resolve, IsIncludeSelf) {
      this.utility.tree.loading = true;
      this.$http
        .post("system:/Organization/GetTree", {
          Data: {
            LoadOrganizationType: 3,
            OnlyLoadPrivilegeOrg: true,
            OnlyLoadOrganization: this.OnlyLoadOrganization,
            OnlyLoadEnableOrg: true,
            Delay: true,
            ParentId: parentID,
            IsIncludeSelf: IsIncludeSelf,
          },
        })
        .then((rsp) => {
          if (rsp.data.Code === 0) {
            if (parentID == null) {
              this.utility.tree.expandkeys = [rsp.data.Data[0].id];
            }
            resolve(rsp.data.Data);
          } else {
            this.$message.error(rsp.data.Message);
          }
          this.utility.tree.loading = false;
        });
    },
  }, 
};
</script>
<style lang="scss" scoped>
.selectMask {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .isShow {
    width: 1000px !important;
    height: 550px;
    border-radius: 5px;
    background-size: 100%;
    background: #fff;
    overflow: hidden;
    .head {
      height: 35px;
      line-height: 35px;
      background-color: #fa905d;
      .title {
        padding-left: 10px;
        display: block;
        width: 90%;
        float: left;
        font-weight: 600;
        color: white;
      }
      .icon {
        float: right;
        margin-right: 10px;
        text-align: center;
        cursor: pointer;
        color: white;
        i {
          font-size: 16px;
        }
      }
    }
    .el-row {
      .el-col {
        &:nth-child(1) {
          width: 250px;
          p {
            height: 48px;
            line-height: 48px;
            background-color: #eff1f1;
            text-align: center;
            margin-bottom: 10px;
            font-size: 14px;
            border-right: 1px solid #eee;
            font-family: FZLTHK--GBK1-0;
            font-weight: bold;
            color: #7b8082;
          }
        }
        &:nth-child(2) {
          width: 750px;
          padding: 10px;
          .header {
            &:nth-child(1) {
              padding: 10px 0;
              text-align: right;
              .el-input {
                display: inline-block;
                width: 266px;
                margin: 0 5px;
                i {
                  position: relative;
                  cursor: pointer;
                }
              }
            }
          }
          .page {
            padding: 5px 0;
          }
          .footer {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
