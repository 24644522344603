<template>
	<div class="AbnormalUser Standardformat" v-loading="grid.loading" v-if="IsPageDome">
        <cj-ElasticFrame :title="title"  v-if="ElasticFrame" :sureDisable="DelsureDisable"   @clickSure="clickSure"  @clickCancel="ElasticFrame=false" width="430px" height="200px">
			<div slot="content" class="contentDocuments">
				<div class="Unlock"  v-if="title==R.System_SecurityModule_AbnormalUser_1">
                    <i class="el-icon-info"></i>{{R.System_SecurityModule_AbnormalUser_3}}
                </div>
				<div class="Extensionofvalidity"  v-if="title==R.System_SecurityModule_AbnormalUser_2">
                    <span>{{R.System_SecurityModule_AbnormalUser_5}}：</span>
                    <el-date-picker :picker-options="pickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="top.termofvalidity.value" type="date" :placeholder="top.termofvalidity.hint"></el-date-picker>
                </div>
			</div>
		</cj-ElasticFrame>
        <div class="header">
            <el-button v-if="getpermission('Unlock')"  @click="Unlock(true)" class="orangebd" style="margin-left:5px">{{R.System_SecurityModule_AbnormalUser_1}}</el-button>
            <el-button v-if="getpermission('ExtendValidTime')" @click="Unlock(false)">{{R.System_SecurityModule_AbnormalUser_2}}</el-button>
        </div>
        <div class="main">
            <div class="listTable" ref="table" >
                <Table type='selection' stripe  @on-selection-change="handleGridSelectionChange" :columns="grid.columns"  :height="grid.height" :data="grid.data"></Table>
            </div>
        </div>
        <div class="footer">
            <div>
                <div class="paginarion">
                    <template>
                        <div class="block">
                            <el-pagination :background="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="grid.pagination.pageNO" :page-sizes="[10, 20, 50, 100]" :page-size="grid.pagination.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="grid.pagination.totalQty">
                            </el-pagination>
                        </div>
                    </template>
                    <p class="selected">
                    </p>
                </div>
                
            </div>
        </div>
	</div>
</template>

<script>
	var R = {};
	export default {
		data() {
			return{
                permissionCode: [],
                pickerOptions:{
                    disabledDate: (time) => {
						return time.getTime() < Date.now();
					}
                },
                title:'',
                DelsureDisable:false,
                ElasticFrame:false,
                IsPageDome:true,
                Loading:false,
				R:R,
                top:{
                    termofvalidity:{
                        value:"",
                        hint:R.System_SecurityModule_AbnormalUser_6
                    }
                },
                grid:{
                    selectedRows:[],
					columns:[
                        { type: 'selection', width: 30,tooltip:true, align: 'center' },		// 复选框
                        { type: 'index', width: 50,tooltip:true, align: 'center' },		// 行号
                        { align: 'center',tooltip:true,width:140, title: R.System_SecurityModule_AbnormalUser_Column_1, key: 'StatusText', },//状态
                     	{ align: 'center',tooltip:true,width:150, title: R.System_SecurityModule_AbnormalUser_Column_2, key: 'EmployeeCode',  },//员工编号
                        { align: 'center',tooltip:true,width:120, title: R.System_SecurityModule_AbnormalUser_Column_3, key: 'EmployeeName',  },//员工姓名
						{ align: 'center',tooltip:true,width:160, title: R.System_SecurityModule_AbnormalUser_Column_4, key: 'OrgName',},//所属部门
						{ align: 'center',tooltip:true,width:120, title: R.System_SecurityModule_AbnormalUser_Column_5, key: 'UserName',},//用户名
						{ align: 'center',tooltip:true,width:140, title: R.System_SecurityModule_AbnormalUser_Column_6, key: 'RoleNames', },//所属角色
                     	{ align: 'left',tooltip:true,width:140, title: R.System_SecurityModule_AbnormalUser_Column_7, key: 'ExpiryDateText',  },//有效期
                        { align: 'left',tooltip:true,width:180, title: R.System_SecurityModule_AbnormalUser_Column_8, key: 'LastLoginDate',},//最后登录时间
                        { align: 'left',tooltip:true,minWidth:180,maxWidth:400, title: R.System_SecurityModule_AbnormalUser_Column_9, key: 'Remarks',},//备注
						{ title: ' '}
					],	//表格列
					data:[],			//表格数据
					pagination:{		//分页
						pageSize:20		//每页记录数
						,pageNO:1		//当前页码
						,totalQty:0		//总记录数
					},
					loading:false,		//是否加载数据中
					height:'',			//表格高度
				},
				// 屏幕
				screenWidth: document.body.clientWidth,
				screenHeight: document.body.clientHeight,
            };
        },
        methods:{
            clickSure(){
				this.DelsureDisable=true;
				let IDs=this.grid.selectedRows.map((item)=>{
					return item.ID
				})
                if (this.title==R.System_SecurityModule_AbnormalUser_1) {
                   this.$http.post('system:System/ExceptionUser/Unlock', {
                        Data: {
                            IDs:IDs
                        }
                    }).then(res => {
                        if(res.data.Code == '0') {
							this.ElasticFrame=false;
							this.pageChange()
							this.$message.success(res.data.Message)
							this.grid.selectedRows=[];
                        } 
                        this.DelsureDisable = false;
                    }).catch((error)=>{
                        this.DelsureDisable = false;
                    }) 
                }else{
                    this.$http.post('system:/System/ExceptionUser/Delay', {
                        Data: {
							IDs:IDs,
							Date:this.top.termofvalidity.value
                        }
                    }).then(res => {
                        if(res.data.Code == '0') {
							this.ElasticFrame=false;
							this.pageChange()
							this.$message.success(res.data.Message)
							this.grid.selectedRows=[];
                        } 
                        this.DelsureDisable = false;
                    }).catch((error)=>{
                        this.DelsureDisable = false;
                    })
                }
            },
            Unlock(type){
				if (!this.isSelectedOnlyOneRow())return
				let Status= this.grid.selectedRows.every((item)=>{
					return item.Status==1
				})
				let Status2= this.grid.selectedRows.every((item)=>{
					return item.Status==2
				})
				if (type&&!Status) {
					this.$message.error(R.System_SecurityModule_AbnormalUser_7)
					return
				}
				if (!type&&!Status2) {
					this.$message.error(R.System_SecurityModule_AbnormalUser_8)
					return
				}
                this.title=type?R.System_SecurityModule_AbnormalUser_1:R.System_SecurityModule_AbnormalUser_2;
                this.ElasticFrame=true;
            },
            Extensionofvalidity(){

            },
			isSelectedOnlyOneRow(){
				let len =this.grid.selectedRows.length;
				if(len==0){
					this.$message({
						message:R.Common_Msg_NoneBillSelected,
						type:'error',
					});
				}else{
					return true;
				}
				//
				return false;
			},
            handleGridSelectionChange(selection){
                this.grid.selectedRows =selection;
            },
            Data(){
                let data={};
                data.PageNo= this.grid.pagination.pageNO;
                data.PageSize= this.grid.pagination.pageSize;
                data.IsSetNumber=true;
                return data
            },
            pageChange() {
				this.grid.loading = true;
				this.$http.post('system:/System/ExceptionUser/List', {
					Data: this.Data()
				}).then(res => {
					if(res.data.Code == '0') {
						this.grid.data = res.data.Data.Items == null ? [] : res.data.Data.Items;
						this.grid.pagination.totalQty = res.data.Data.TotalRecordQty;
						this.grid.pagination.PageNO = res.data.Data.PageNO;
						this.grid.pagination.pageSize = res.data.Data.PageSize;
						this.SetPageSize(this.grid.pagination.pageSize,'System/SecurityModule/AbnormalUser')
					} else {
						this.grid.data = [];
						this.grid.pagination.totalQty = 0;
						this.grid.pagination.PageNO = 1;
						
					}
					this.grid.loading = false;
				}).catch((error)=>{
					this.grid.loading = false;
				})
			},
            //分页尺码改变
			handleSizeChange(val) {
			    this.grid.pagination.pageSize = val
			    this.grid.pagination.pageNO = 1
				this.pageChange()
			},
			//分页当前页变化
			handleCurrentChange(val) {
				this.grid.pagination.pageNO = val
				this.pageChange()
			},
            ExportToExcel(){
            },
            //判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {
						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}
			},
           
        },
		watch: {
            screenWidth (val) {
				this.grid.height=this.$refs.table.offsetHeight;
			},
			screenHeight (val) {
				this.grid.height=this.$refs.table.offsetHeight;
			},
		},
		beforeUpdate() {
			// 屏幕自适应
            const me = this
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					me.screenWidth = window.screenWidth
					me.screenHeight = window.screenHeight
				})();
			}
			this.grid.height=this.$refs.table.offsetHeight;
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_SecurityModule_AbnormalUser')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted(){
			this.grid.pagination.pageSize=this.GetPageSize('System/SecurityModule/AbnormalUser')
			this.pageChange()
			//判断是否包含新增编辑等功能权限
			this.$PermissionUtil.getPermission('System/SecurityModule/AbnormalUser').then((res)=>{
				this.permissionCode = res;
			});
			const me = this
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					me.screenWidth = window.screenWidth
					me.screenHeight = window.screenHeight
				})();
			}
			this.grid.height=this.$refs.table.offsetHeight;
        },
		
	};
</script>

<style lang='scss'>
	.contentDocuments {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 80px);
        .Unlock{
            display: flex;
            align-items: center;
            >i {
                color: deepskyblue;
                font-size: 16px;
                margin-right: 10px;
            }
        }
    }
</style>