<template>
	<div id="Personnelselector">
		<template>
			<div class="mask" v-if="mask">
			</div>
			<div class="Personnelselectorinformation" v-if="dataModel.ProjectileFrame">
				<p class="Personneltitle">
					<span>{{dataModel.selectionStaff}}</span>
					<img src="static/images/backout.png" alt="" srcset="" @click="backProjectileFrame">
				</p>
				<div class="Personnelcontent" v-loading="loadingTable">
					<div class="PersonnelcontentLeft">
						<div class="titleall" v-if="title">{{title}}</div>
						<el-tree node-key="id" :default-expanded-keys='expandkey' :load="loadNode" lazy ref="tree" :default-checked-keys='defaultExpandKeys' highlight-current :props='defaultProps' @node-click="clicktree">
							<span class="custom-tree-node" slot-scope="{ node, data }">
                                <span  class="mytreelistitem" :title="node.label">{{ node.label }}
                                </span>
							</span>
						</el-tree>
					</div>

					<div class="PersonnelcontentRight">
						<div class="topfunction">
							<div>
								<el-input :placeholder="dataModel.topToolbarModel.search.placeholder" v-model="dataModel.topToolbarModel.search.defaultValue" @keyup.enter.native="tableList(1)">
								</el-input>
								<el-button @click="tableList(1)">{{R.Common_Button_Search}}</el-button>
							</div>
						</div>
						<div class="table">
							<Table @on-selection-change="selectionShow" type='selection' :columns="dataModel.tableModel.columns" :data="dataModel.tableModel.data" stripe height="360"></Table>
						</div>
						<div class="pagination" style="margin-top:10px">
							<el-pagination :pager-count="5" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="dataModel.pagingModel.currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="dataModel.pagingModel.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="dataModel.pagingModel.total">
							</el-pagination>
						</div>

					</div>
				</div>

				<div class="Personnelselectorinformationbottom">
					<p>
						<el-button class="orangebd" @click="sureShow">{{dataModel.buttons.sure}}</el-button>
						<el-button @click="backProjectileFrame">{{dataModel.buttons.cancel}}</el-button>
					</p>
				</div>
			</div>
			<div class="searchselect">
				<el-select :title="titleName" clearable :disabled='disabled' size="mini" @change="optionsChange" v-model="AssetEmployeeIDs" :multiple='multiple' filterable remote :collapse-tags="collapseTags" reserve-keyword :placeholder="placeholder||R.selector_Setting_2" :remote-method="remoteMethod" :loading="loading">
					<el-option v-for="item in options4" :key="item.value" :label="code==false? item.Code: item.label" :value="item.value">
						<span style="float:left">{{ item.label }}</span>
						<span style="float:right; color: #8492a6;font-size: 13px;">{{ item.Code }}</span>
					</el-option>
				</el-select>
				<div class="Personnelsearch"><i @click="maskshow" slot="suffix" class="el-input__icon el-icon-search"></i></div>
			</div>
			<div>
			</div>
		</template>

	</div>
</template>
<script>
	let R = {}
	export default {
		name: 'Personnelselector',
		data() {
			return {
				R: R,
				titleName: '',
				expandkey: [],
				loadingTable: false,
				options4: [],
				AssetEmployeeIDs: [],
				list: [],
				loading: false,
				selectoptions: [],
				optionsChangeArr: [],
				// 资产选择器
				// 选择器
				searchselect: {
					options: [],
					defaultValue: '',
					placeholder: R.selector_Setting_3
				},
				// 弹框模块
				dataModel: {
					ProjectileFrame: false,
					selectionStaff: R.selector_Setting_4,
					// 顶部工具栏模型
					topToolbarModel: {
						search: {
							defaultValue: "",
							placeholder: R.selector_Setting_5
						},
					},
					// 表格模型
					tableModel: {
						// 表头
						columns: [{
								type: 'selection',
								width: 30,
								align: 'center'
							}, {
								type: 'index',
								width: 50,
								align: 'center',
								tooltip: true,
							},
							{
								title: R.selector_Button_Add,
								key: 'Code',
								width: 150,
								align: 'center',
								tooltip: true,
							},
							{
								title: R.selector_Button_Edit,
								key: 'Name',
								width: 140,
								align: 'center',
								tooltip: true,
							},
							{
								title: R.selector_Button_Enable,
								key: 'OrgName',
								width: 140,
								align: 'center',
								tooltip: true,
							},
							{
								title: R.selector_Button_Delete,
								key: 'Position',
								width: 140,
								align: 'left',
								tooltip: true,

							},
							{
								title: R.selector_Button_PrintLabel,
								key: 'MobileNo',
								width: 120,
								align: 'left',
								tooltip: true,
							},
							{
								title: R.selector_Button_ExportToExcel,
								key: 'Telephone',
								width: 140,
								align: 'left',
								tooltip: true,
							}, {
								title: R.selector_Button_AdvanceSearch,
								key: 'Email',
								width: 140,
								align: 'left',
								tooltip: true,
							}, {
								title: R.selector_Button_ColumnSelection,
								key: 'Address',
								width: 140,
								align: 'left',
								tooltip: true,
							},
							{
								title: R.selector_Button_SaveDraft,
								key: 'Remark',
								minWidth: 180,
								maxWidth:400,
								align: 'left',
								tooltip: true,
							},
						],
						// 数据
						data: [],
					},
					// 分页器模型
					pagingModel: {
						// 当前页
						currentPage: 1,
						// 一页数据条数
						pagesize: 10,
						// 总条数
						total: 0,
					},
					// 底部按钮模块
					buttons: {
						sure: R.Common_Button_Sure,
						cancel: R.Common_Button_Cancel
					},
				},
				// 其他
				mask: false,
				QSE: '',
				list: [],
				loading: false,
				// 表格选中值
				selection: [],

				defaultProps: {
					children: 'children',
					label: 'label',
					isLeaf: 'is_leaf'
				},
				defaultExpandKeys: [],
				Allapi: [],
				urla: "",
				title: R.selector_Button_Print,
				defaultExpandKeys: [],
				True: true
			}
		},
		props: {
			'IsFilterUser': {},
			'IsContainSubordinateCompany': {
				type: Boolean,
				default: true
			},
			'OnlyLoadOrganization': {
				type: Boolean,
				default: false
			},
			"EnableEmployeeAccount":{
				type: Boolean,
				default: null
			},
			'changeClaer': {},
			'collarWorkers': {},
			'collapseTags': {},
			'placeholder': {},
			'PersonnelInfo': {},
			'multiple': {
				// validator(v){               //验证也可以是函数
				//     
				//     if (v) {
				//         this.PersonnelInfo=[]
				//     }else{
				//         this.PersonnelInfo=null
				//     }
				// }
			},
			'DrawAssetEmployeeIDs': {
				default: ''
			},
			'options': {},
			'code': {},
			'disabled': {
				type: Boolean,
				default: false
			},
			'ParentId': {
				default: null
			},
			'ParentQSE': {
				default: null
			},
			'disabledPlaceholder': {},
			'IsIncludeSelf': {},
		},
		computed: {
			DrawAssetEmployeeIDsWatch() {
				if((this.DrawAssetEmployeeIDs == null || this.DrawAssetEmployeeIDs.length == 0) && this.AssetEmployeeIDs != null) {
					if(this.multiple == false) {
						this.AssetEmployeeIDs = null;
					} else {
						this.AssetEmployeeIDs = [];
					}
					this.options4 = []
					return
				}
				return this.DrawAssetEmployeeIDs;
			}
		},
		watch: {
			DrawAssetEmployeeIDsWatch(a) {
				const _this = this;
				const setAssetEmployeeIDs = (v)=>{
					_this.$nextTick(()=>{_this.AssetEmployeeIDs = v;});
				};
				if(_this.multiple == false) {
					if(a == null || a == '' || a == undefined || a.length == 0) {
						setAssetEmployeeIDs(null);
						_this.selectoptions = []
						return
					}

					if(_this.PersonnelInfo) {
						_this.options4 = _this.PersonnelInfo
						setAssetEmployeeIDs(a);

					} else {
						_this.$http.post('system:/Employee/GetByID', {
							"Data": (a + '')
						}).then(res => {
							if(res.data.Code == '0') {
								_this.options4 = [{
									label: res.data.Data.Name,
									value: res.data.Data.ID,
									Code: res.data.Data.Code
								}]
								setAssetEmployeeIDs(a);
							} else {
								_
							}
						})
					}
				} else {

					if(a == null || a == '' || a == undefined || a.length == 0) {
						setAssetEmployeeIDs([]);
						_this.options4 = []
						_this.selectoptions = []
						return
					}

					if(_this.PersonnelInfo && _this.True) {
						_this.PersonnelInfo.filter((item) => {
							item.ID = item.value
						})
						_this.selectoptions = _this.PersonnelInfo
						_this.options4 = _this.PersonnelInfo
						_this.True = false
					} else {
						_this.selectoptions = []
					}
					setAssetEmployeeIDs(Array.from(new Set(a)));
				}

			},
			disabled(a, b) {
				this.disabled = a
				/*  if (!b) {
				      if (this.multiple) {
				          this.AssetEmployeeIDs=[]
				      }
				      else{
				          this.AssetEmployeeIDs=''
				      }
				      this.options4=[]
				 } */
			},
			ParentQSE(a, b) {
				this.QSE = a
			},
			ParentId(a, b) {
				// 
				// if (b) {
				//      if (this.multiple) {
				//         this.AssetEmployeeIDs=[]
				//     }else{
				//         this.AssetEmployeeIDs=''
				//     }
				//     this.options4=[]
				// }

			},
			changeClaer(a, b) {
				if(a == true) {
					if(this.multiple) {
						this.AssetEmployeeIDs = []
					} else {
						this.AssetEmployeeIDs = ''
					}
					this.options4 = []
					this.selectoptions = []
				}
			}
		},
		methods: {
			optionsChange(event) {
				// console.log(this.AssetEmployeeIDs)

				if(this.multiple) {
					var arr = []
					event.filter((item) => {
						this.options4.filter((item1) => {
							if(item == item1.ID) {
								arr.push(item1)
							}
						})

					})
					this.optionsChangeArr = arr;
					var title = [];
					this.AssetEmployeeIDs.filter((item) => {
						this.options4.filter((item1) => {
							if(item == item1.value) {
								title.push(item1.label);
							}
						});
					});
					this.titleName = title.join(',');
				}
				this.$emit("optionShow", this.AssetEmployeeIDs);
				// this.$emit("personnelShow",arr)
			},
			// 模糊搜索
			remoteMethod(query) {
				if(query.trim() !== '') {
					this.loading = true;
					this.$http.post('system:/Employee/FuzzyQuery', {
						"Data": {        
							"QST": query,
							"CorpID": this.ParentId,
							"IsFilterUser": this.IsFilterUser,
							'IsContainSubordinateCompany': this.IsContainSubordinateCompany,
							"IsEnableDataPermission": this.OnlyLoadPrivilegeOrg,
							"EnableEmployeeAccount":this.EnableEmployeeAccount
						}
					}).then(res => {
						if(res.data.Code == 0) {
							this.loading = false;
							this.options4 = res.data.Data
						} else {
							
						}
					})

				} else {
					if(this.multiple) {
						this.options4 = [];
					} else {
						this.options4 = '';
					}

				}

			},

			// 弹框显示
			maskshow() {
				if(this.disabled == true) {

					if(this.disabledPlaceholder)
						this.$message({
							message: this.disabledPlaceholder || R.selector_Button_Query,
							type: 'error',
						});
					return
				}
				this.mask = true
				this.dataModel.ProjectileFrame = true
				this.tableList()
				this.selection = []
			},
			// 弹框隐藏
			backProjectileFrame() {
				this.mask = false
				this.dataModel.ProjectileFrame = false
				this.dataModel.topToolbarModel.search.defaultValue = ''
				this.QSE = ''
			},
			// 快捷搜索方法
			inputShow(val) {
				this.tableList(val)
			},
			// 确定按钮
			sureShow() {
				if(this.selection.length == 0) {
					this.$message.error(R.selector_Button_Reset)
					return
				}
				if(this.multiple) {

					// if (!(this.DrawAssetEmployeeIDs)) {
					//     this.AssetEmployeeIDs=[]
					//     for(let i=0;i<this.selection.length;i++){
					//         this.AssetEmployeeIDs.push(this.selection[i].ID)
					//     }
					// }

					var arr = (this.options4.concat(this.selection)).concat(this.optionsChangeArr)
					let hash = {}
					const newArr = arr.reduceRight((item, next) => {
						hash[next.ID] ? '' : hash[next.ID] = true && item.push(next);
						return item
					}, []);
					var arr1 = (this.selectoptions.concat(this.selection)).concat(this.optionsChangeArr)
					let hash1 = {}
					const newArr1 = arr1.reduceRight((item, next) => {
						hash1[next.ID] ? '' : hash1[next.ID] = true && item.push(next);
						return item
					}, []);
					this.options4 = newArr
					// console.log(this.AssetEmployeeIDs)
					if(this.AssetEmployeeIDs.length == 0) {
						this.$emit("personnelShow", newArr1)
					} else {
						// console.log(this.AssetEmployeeIDs)
						var index = null;
						var data = []
						for(let i = 0; i < newArr1.length; i++) {
							var flag = false;
							for(let j = 0; j < this.AssetEmployeeIDs.length; j++) {
								if(newArr1[i].ID == this.AssetEmployeeIDs[j]) {
									flag = true
								}

							}
							if(!flag) {
								var reg = data.find((value, index, arr) => {
									return value.ID == newArr1[i].ID
								})
								// console.log(reg)
								if(reg == undefined) {
									data.push(newArr1[i])
								}

							}

						}

						// console.log(data)
						this.$emit("personnelShow", data)
					}
					// this.$emit("optionShow",this.AssetEmployeeIDs)
					this.backProjectileFrame()
				} else {
					if(this.selection.length > 1) {
						this.$message.error(R.selector_Button_Save)
						return
					}
					if(this.selection.length == 0) {
						this.selection = [{}]
					}

					if(this.selection.length !== 0) {
						this.options4 = this.selection[0]
					} else {
						this.options4 = '';
					}
					this.AssetEmployeeIDs = this.selection[0].ID
					this.options4 = this.selection
					this.$emit("personnelShow", this.selection[0])
					this.$emit("optionShow", this.AssetEmployeeIDs)
					this.backProjectileFrame()
				}
			},
			// 分页方法
			handleSizeChange(val) {
				this.dataModel.pagingModel.pagesize = val;
				this.dataModel.pagingModel.currentPage = 1;
				this.tableList()

			},
			handleCurrentChange(val) {
				this.dataModel.pagingModel.currentPage = val;
				this.tableList()

			},
			// 表格方法
			selectionShow(selection) {

				this.selection = selection

			},
			// 表格数据
			tableList(val) {
				this.loadingTable = true
				this.$http.post('system:/Employee/List', {
					"data": {
						"PageNO": val ? val : this.dataModel.pagingModel.currentPage,
				        "PageSize": this.dataModel.pagingModel.pagesize,
				        "SortDirection": 1,
				        "QST": this.dataModel.topToolbarModel.search.defaultValue,
				        "QSE": this.QSE ? this.QSE : this.ParentQSE,
						"CorpID": this.ParentId,
						'IsContainSubordinateCompany': this.IsContainSubordinateCompany,
						"IsFilterUser": this.IsFilterUser,
						"IsEnableDataPermission": this.OnlyLoadPrivilegeOrg,
						"EnableEmployeeAccount":this.EnableEmployeeAccount,
					}
				}).then(res => {
					if(res.data.Code == 0) {
						if(res.data.Data.Items == null) {
							this.dataModel.tableModel.data = []
						} else {
							this.dataModel.tableModel.data = res.data.Data.Items;
						}
						this.dataModel.pagingModel.total = res.data.Data.TotalRecordQty;
						//    this.selectShow()
					} else {
						
					}
					this.loadingTable = false
				})
			},
			//点击左侧树结构
			clicktree(node, data) {
				this.QSE = data.data.QSE;
				this.tableList()
				//				this.$emit('childByValue', this.QSE)
				//				this.clickID = data.data.id;
			},
			//左侧树懒加载
			loadNode(node, resolve) {
				if(node.level === 0) { //第一级
					if(this.ParentId !== null && this.ParentId !== '') {
						this.getOrgByParentID(this.ParentId, resolve, this.IsIncludeSelf);
					} else {
						this.getOrgByParentID(null, resolve);
					}

				} else {
					this.getOrgByParentID(node.data.id, resolve);
				}
			},
			// 获取指定的父级公司/部门的ID获取其下级公司/部门
			getOrgByParentID(parentID, resolve, IsIncludeSelf) {
				// 
				this.$http.post('system:/Organization/GetTree', {
					Data: {
						LoadOrganizationType: 3,
						OnlyLoadPrivilegeOrg: true, //正式应该为true
						OnlyLoadOrganization: this.OnlyLoadOrganization,
						OnlyLoadEnableOrg: true,
						Delay: true,
						ParentId: parentID,
						"IsIncludeSelf": IsIncludeSelf
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) { //成功
						//
						if(parentID == null) {
							this.expandkey = [rsp.data.Data[0].id]
						}
						let items = rsp.data.Data;
						resolve(items);
					} else {
						this.$message.error(rsp.data.Message)
					}
				}).catch((error) => {});
			},
			// 其他
			childByValue: function(childValue) {
				// childValue就是子组件传过来的值

			},
		},
		beforeCreate() {
			R =  this.getSelflag('selector')? this.getSelflag('selector'):this.getSelfLanguage('SystemControl', 'selector')
			this.setSelflag('selector',R)
		},
		mounted() {
			this.QSE = this.ParentQSE
			if(this.DrawAssetEmployeeIDs != null && this.DrawAssetEmployeeIDs != '' && this.DrawAssetEmployeeIDs.length != 0) {
				if(this.multiple == false) {
					if(this.DrawAssetEmployeeIDs == '') {
						this.DrawAssetEmployeeIDs = null
						return
					}
					if(this.PersonnelInfo) {
						this.options4 = this.PersonnelInfo
						this.AssetEmployeeIDs = this.DrawAssetEmployeeIDs
					} else {
						this.$http.post('system:/Employee/GetByID', {
							"Data": (this.DrawAssetEmployeeIDs + '')
						}).then(res => {
							if(res.data.Code == '0') {
								this.options4 = [{
									label: res.data.Data.Name,
									value: res.data.Data.ID,
									Code: res.data.Data.Code
								}]
								this.AssetEmployeeIDs = this.DrawAssetEmployeeIDs
							} else {
								
							}
						})
					}
				} else {
					if(this.PersonnelInfo) {
						this.options4 = this.PersonnelInfo
						this.AssetEmployeeIDs = Array.from(new Set(this.DrawAssetEmployeeIDs))
					} else {
						this.options4 = []
						this.selectoptions = []

						this.$http.post('system:/Employee/GetByIDs', {
							"Data": {
								IDs: Array.from(new Set(this.DrawAssetEmployeeIDs))
							}
						}).then(res => {
							if(res.data.Code == '0') {
								var data = res.data.Data
								data.filter((value, key, arr) => {
									value.label = value.Name;
									value.value = value.ID;
								})
								this.options4 = data
								this.AssetEmployeeIDs = Array.from(new Set(this.DrawAssetEmployeeIDs)).map(item=>item*1)
								var title = [];
								this.AssetEmployeeIDs.filter((item) => {
									this.options4.filter((item1) => {
										if(item == item1.value) {
											title.push(item1.label)
										}
									})
								})
								this.titleName = title.join(',')
							} else {
								
							}
						})
					}

				}
			}

		}

	}
</script>
<style lang="scss">
	#Personnelselector {
		.el-tree {
			position: relative !important;
		}
		.Personnelselectorinformation {
			width: 1000px;
			height: 550px;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			border-radius: 5px;
			background-size: 100%;
			z-index: 3;
			background: #fff;
			// 解决表单样式影响
			.el-input__inner {
				border-color: #dcdfe6 !important;
			}
			.el-input__inner:hover {
				border-color: #c0c4cc !important;
			}
			.el-input__suffix {
				display: none !important;
			}
			.el-pagination__sizes .el-input .el-input__inner:hover {
				border-color: #FF5402 !important;
			}
			.el-form-item.is-error .el-input__validateIcon {
				display: none !important;
			}
			.Personneltitle {
				height: 37px;
				background: #fa905d;
				width: 100%;
				border-radius: 5px 5px 0 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 15px;
				>span {
					font-size: 14px;
					color: #fff;
					font-weight: bold;
					line-height: 37px;
				}
				>img {
					width: 16px;
					height: 16px;
					cursor: pointer;
				}
			}
			.Personnelcontent {
				display: flex;
				height: 445px;
				.PersonnelcontentLeft {
					width: 250px;
					overflow: auto;
					.el-tree {
						display: inline-block;
						min-width: 100%!important;
						min-height: calc(100% - 63px) ;
					}
					.titleall {
						height: 48px;
						line-height: 48px;
						background-color: #EFF1F1;
						text-align: center;
						margin-bottom: 10px;
						font-size: 14px;
						border-right: 1px solid #eee;
						font-family: FZLTHK--GBK1-0;
						font-weight: bold;
						color: rgba(123, 128, 130, 1);
					}
				}
				.PersonnelcontentRight {
					width: 750px;
					height: 100%;
					.topfunction {
						display: flex;
						padding: 10px;
						box-sizing: border-box;
						flex-direction: row-reverse;
						.el-button {
							height: 30px!important;
						}
						.el-input {
							width: 266px!important;
							.el-input__inner {
								height: 30px;
								line-height: 30px;
							}
						}
					}
					.table {
						width: 100%;
						padding: 0 10px;
						box-sizing: border-box;
					}
					.pagination {
						padding: 10px;
						padding-bottom: 0;
						padding-top: 0;
						box-sizing: border-box;
						.el-pagination {
							width: 100%;
							overflow: auto;
						}
						.el-select .el-input__inner {
							padding-right: auto !important;
						}
					}
				}
			}
			.Personnelselectorinformationbottom {
				text-align: right;
				padding-right: 10px;
				box-sizing: border-box;
				position: absolute;
				right: 5px;
				bottom: 10px;
				>p {
					display: inline-block;
					.el-button {
						height: 30px !important;
						padding: 0 16px !important;
					}
					.orangebd {
						background: #ff5402;
						color: #fff;
						border: none;
					}
					.orangebd:hover {
						background-color: #FF5402 !important;
						color: white !important;
						span {
							color: white!important;
						}
					}
					.orangebd:active {
						background: #FF5402!important;
						border-color: #FF5402 !important;
					}
					.orangebd:focus {
						background: #ff5402!important;
					}
					.orangebd:focus>span,
					.orangebd:hover>span {
						color: #fff !important;
					}
				}
			}
		}
		.searchselect {
			width: 100%;
			display: flex;
			.el-select {
				flex: 1;
				.el-input{
					width: 100% !important;
				}
			}
			.el-input{
				input{
					border-radius: 4px 0 0 4px;
				}
			}
			.Personnelsearch {
				width: 28px;
				background: #f7fbfb;
				border: 0 4px 4px 0;
				border: 1px solid #dcdfe6;
				border-left: none;
				box-sizing: border-box;
				cursor: pointer;
				border-radius: 0 4px 4px 0;
			}
			.disabled {
				pointer-events: none
			}
		}
	}
	
	.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
		// position: static;
		// float: right;
		Personnelcontent: ''
	}
</style>