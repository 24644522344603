var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-button',{staticClass:"cj-Button",class:[
    _vm.type?'cj-Button-'+_vm.type:'',
    _vm.ml?'two':'',
    _vm.qg?'cj-Button-customize':''
],style:({
  width:_vm.widths,
  height:_vm.height,
  borderRadius:_vm.borderRadius,
  backgroundColor:_vm.backgroundColor,
  border:_vm.border,
  fontFamily:_vm.fontFamily,
  marginLeft:_vm.marginLeft,
  marginTop:_vm.marginTop,
  marginRight:_vm.marginRight,
  marginBottom:_vm.marginBottom,
  margin:_vm.margin,
  paddingLeft:_vm.paddingLeft,
  paddingTop:_vm.paddingTop,
  paddingRight:_vm.paddingRight,
  paddingBottom:_vm.paddingBottom,
  padding:_vm.padding,
  boxSizing:_vm.boxSizing
  }),attrs:{"disabled":_vm.disabled,"type":_vm.type},on:{"click":_vm.handleClick}},[_c('i',{staticClass:"icons",class:_vm.icon,style:({fontSize:_vm.iconSize,backgroundColor:_vm.backgroundColor})}),(_vm.$slots.default)?_vm._t("default"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }