<template>
	<div id="TreeOrListSelect" class="vue-treeselect">
		<template>
			<div class="mask" v-if="mask"></div>
			<div class="TreeOrListSelectinformation" v-if="dataModel.ProjectileFrame">
				<p class="Personneltitle">
					<span>{{dataModel.selectionStaff}}</span>
					<img src="static/images/backout.png" alt="" srcset="" @click="backProjectileFrame">
				</p>
				<div v-loading="loadingTable" class="TreeOrListSelectConent">
					<div class="TreeOrListSelectLeft">
						<div class="titleall" v-if="title">{{title}}</div>
						<el-tree v-if="dataModel.ProjectileFrame&&Delay" node-key="id" :default-expanded-keys="expandedKeys" :load="loadNode" lazy ref="tree"  highlight-current :props='defaultProps' @node-click="clicktree">
							<span class="custom-tree-node" slot-scope="{ node}">
                                <span  class="mytreelistitem" :title=" node.label">{{ node.label }}
                                </span>
							</span>
						</el-tree>
						<el-tree v-if="dataModel.ProjectileFrame&&!Delay" node-key="id" :default-expanded-keys="expandedKeys" :data="treeDate"  highlight-current :props='defaultProps' @node-click="clicktree">
							<span class="custom-tree-node" slot-scope="{ node}">
                                <span  class="mytreelistitem" :title=" node.label">{{ node.label }}
                                </span>
							</span>
						</el-tree>
					</div>
					<div class="TreeOrListSelectRight">
						<div class="topfunction">
							<div>
								<el-input @keyup.enter.native="tableList(1)" :placeholder="dataModel.topToolbarModel.search.placeholder" v-model="dataModel.topToolbarModel.search.value">
								</el-input>
								<el-button @click="tableList(1)">{{R.Common_Button_Search}}</el-button>
							</div>
						</div>
						<div class="table">
							<Table @on-selection-change="selectionShow" type='selection' :columns="dataModel.tableModel.columns" :data="dataModel.tableModel.data" stripe height="360"></Table>
						</div>
						<div class="pagination" style="margin-top:10px">
							<el-pagination :pager-count="5" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="dataModel.pagingModel.currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="dataModel.pagingModel.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="dataModel.pagingModel.total">
							</el-pagination>
						</div>
					</div>

				</div>

				<div class="TreeOrListSelectinformationbottom">
					<div>
						<el-button class="orangebd" @click="sureShow">{{dataModel.buttons.sure}}</el-button>
						<el-button @click="backProjectileFrame">{{dataModel.buttons.cancel}}</el-button>
					</div>
				</div>
			</div>
			<div class="TreeOrListSelectsearchselect">
				<el-select :class="collapseTags?'collapseTags':''" :clearable="clearable" :disabled='disabled' @remove-tag="removeTag" :collapse-tags="collapseTags" :multiple="multiple" size="mini" @change="optionsChange" v-model="currentValue" filterable remote reserve-keyword :placeholder="placeholder||R.TreeOrListSelect_1" :remote-method="remoteMethod" :loading="loading">
					<el-option v-for="item in options" :key="item.ID" :label="item.Name" :value="item.ID">
					</el-option>
				</el-select>
				<div class="TreeOrListSelectsearch"><i slot="suffix" class="el-input__icon el-icon-search" @click="maskshow"></i></div>
			</div>
		</template>

	</div>
</template>
<style lang="scss" ></style>
<script>
	import './TreeOrListSelect.scss'
	let R = {}
	export default {
		name: 'TreeOrListSelect',
		data() {
			return {
				defaultValues:this.defaultValue,
				currentValue:this.value,
				options: [],
				R: R,
				title: R.TreeOrListSelect_2,
				defaultProps: {
					children: 'children',
					label: 'label',
					isLeaf: 'is_leaf'
				},
				expandedKeys: [],
				loadingTable: false,
				list: [],
				loading: false,
				treeDate:[],
				// 资产选择器
				// 弹框模块
				dataModel: {
					ProjectileFrame: false,
					selectionStaff: R.TreeOrListSelect_2,
					// 顶部工具栏模型
					topToolbarModel: {
						search: {
							defaultValue: "",
							placeholder: R.TreeOrListSelect_1
						},
					},
					// 表格模型
					tableModel: {
						// 表头
						columns: [{
								type: 'selection',
								width: 30,
								align: 'center'
							}, {
								type: 'index',
								width: 50,
								align: 'center',
								tooltip: true,
							},
							{
								title: R.TreeOrListSelect_3,
								key: 'Code',
								width: 150,
								align: 'center',
								tooltip: true,
							},
							{
								title: R.TreeOrListSelect_4,
								key: 'AbbrCode',
								width: 140,
								align: 'center',
								tooltip: true,
							},
							{
								title: R.TreeOrListSelect_5,
								key: 'NameCN',
								width: 140,
								align: 'center',
								tooltip: true,

							},
							{
								title: R.TreeOrListSelect_6,
								key: 'NameEN',
								width: 140,
								align: 'center',
								tooltip: true,

							},
							{
								title: R.TreeOrListSelect_7,
								key: 'ManagerName',
								width: 140,
								align: 'center',
								tooltip: true,
							},
							{
								title: R.TreeOrListSelect_8,
								key: 'TypeText',
								width: 90,
								align: 'center',
								tooltip: true,
							}, {
								title: R.TreeOrListSelect_9,
								key: 'Telephone',
								width: 140,
								align: 'left',
								tooltip: true,
							}, {
								title: R.TreeOrListSelect_10,
								key: 'Address',
								width: 140,
								align: 'left',
								tooltip: true,
							},
							{
								title: R.TreeOrListSelect_11,
								key: 'IsEnabledText',
								width: 90,
								align: 'center',
								tooltip: true,
							},
							{
								title: R.TreeOrListSelect_12,
								key: 'Remark',
								minWidth: 180,
								maxWidth:400,
								align: 'left',
								tooltip: true,
							},
						],
						// 数据
						data: [],
					},
					// 分页器模型
					pagingModel: {
						// 当前页
						currentPage: 1,
						// 一页数据条数
						pagesize: 20,
						// 总条数
						total: 0,
					},
					// 底部按钮模块
					buttons: {
						sure: R.Common_Button_Sure,
						cancel: R.Common_Button_Cancel
					},
				},
				// 其他
				mask: false,
				// 列表QSE
				QSE: '',
				list: [],
				// 表格选中值
				selection: [],

			}
		},
		model:{
			event: 'on-input'
		},
		props: {
			//索引
			Index:{
				type:[String,Number],
				default:null,
			},
            placeholder:{
                type:String,
            },
            multiple:{
                type:Boolean,
                default: false,
			},
			clearable:{
                type:Boolean,
                default: true,
            },
            collapseTags:{
                type:Boolean,
                default: false,
            },
            disabled:{
                type:Boolean,
                default: false,
            },
            value:{
                type:[String,Number,Array],
                default:()=>{
					this.multiple?[]:null
				}
            },
            defaultValue:{
                type:Array,
                default:null
            },
            // 非必填 加载公司/部门数据
            // 2.仅加载公司数据 3：加载公司+部门数据
            // 默认显示公司+部门数据
            LoadOrganizationType:{
                type:[String,Number],
                default:3
            },
            //是否只显示部门
            OnlyLoadOrganization:{
                type:Boolean,
                default: false,
            },
            // 是否开启权限过滤
            OnlyLoadPrivilegeOrg:{
                type:Boolean,
                default: true,
            },
            //仅加载启用的公司/部门
            OnlyLoadEnableOrg:{
                type:Boolean,
                default: true,
			},
			//仅加载未启用的公司/部门
			OnlyLoadDisableOrg:{
				type:Boolean,
                default: false,
			},
            // 延迟加载 默认延迟加载
            Delay:{
                type:Boolean,
                default: true,
            },
            // 父级公司/部门Id 非延迟加载忽略此属性
            ParentId:{
                type:[String,Number],
                default:null
			},
			SelectorType:{
				type:String,
				default:'CompanyDepartment'
			},
			// 上级的qse数组
			ParentCompanyQSEs:{
				type:Array,
				default:null
			},
			// 是否包含下级公司
			ContainOffspringCompany:{
				type:Boolean,
				default:false
			}
        },
		watch: {
			defaultValue(){
				this.defaultValueShow()
			},
			value(newValue,oldValue){
				this.optionsShow(newValue)
			},
			disabled(newValue,oldValue) {
				this.disabled = newValue
			},
		},
		methods: {
			removeTag(data){
				let deldata=this.options.filter((item)=>item.ID==data)
				this.$emit("deselect", deldata[0],this.Index)
			},
			clicktree(node, data) {
				this.QSE=node.QSE
				this.loadingTable = true
				this.tableList(1)
			},
			treeDateShow(){
				let posturl
				let postdata
				if (this.SelectorType=='Inventory') {
					posturl='system:/Inventory/Organization/GetTree';
					postdata={
						ContainOffspringCompany: this.ContainOffspringCompany,
						Delay:this.Delay,
						IsIncludeSelf: true,
						ParentCompanyQSEs:this.ParentCompanyQSEs
					};
				}else{
					posturl='system:/Organization/GetTree';
					postdata={
						LoadOrganizationType:this.LoadOrganizationType==1?3:this.LoadOrganizationType,
                        OnlyLoadPrivilegeOrg:this.OnlyLoadPrivilegeOrg,
                        OnlyLoadOrganization:this.OnlyLoadOrganization,
                        OnlyLoadEnableOrg:this.OnlyLoadDisableOrg?false:this.OnlyLoadEnableOrg,
                        Delay:this.Delay,
						ParentId:this.ParentId,
						IsIncludeSelf: true,
						OnlyLoadDisableOrg:this.OnlyLoadDisableOrg
					}
				}
				this.$http.post(posturl, {
					Data: postdata
				}).then((rsp) => {
					if(rsp.data.Code === 0) { //成功
						let items = this.SelectorType=='Inventory'?rsp.data.Data.treeData:rsp.data.Data;
						if(items.length > 0) {
							this.treeDate=items
							this.expandedKeys=[items[0].id]
							if (this.ParentId||this.SelectorType=='Inventory') {
								this.QSE=items[0].QSE
								this.tableList(1)
							}
						}
					} else {
						this.$message.error(rsp.data.Message);
					}
				}).catch((error) => {});
			},
			//左侧树懒加载
			loadNode(node, resolve) {
				if(node.level === 0) { //第一级
					this.getCategoryByParentID(null, resolve,true);
				} else {
					//bug
					this.getCategoryByParentID(node.data.id, resolve,false,node);
				}
			},

			// 获取指定的父级公司/部门的ID获取其下级公司/部门
			getCategoryByParentID(parentID, resolve,IsIncludeSelf,node) {
				let posturl
				let postdata
				if (this.SelectorType=='Inventory') {
					posturl='system:/Inventory/Organization/GetTree';
					postdata={
						ContainOffspringCompany: this.ContainOffspringCompany,
						Delay:this.Delay,
						ParentCompanyQSEs:parentID?[node.data.QSE]:this.ParentCompanyQSEs,
						IsIncludeSelf: IsIncludeSelf,
					};
				}else{
					posturl='system:/Organization/GetTree';
					postdata={
						LoadOrganizationType:this.LoadOrganizationType==1?3:this.LoadOrganizationType,
                        OnlyLoadPrivilegeOrg:this.OnlyLoadPrivilegeOrg,
                        OnlyLoadOrganization:this.OnlyLoadOrganization,
                        OnlyLoadEnableOrg:this.OnlyLoadDisableOrg?false:this.OnlyLoadEnableOrg,
                        Delay:this.Delay,
						ParentId:parentID?parentID:this.ParentId,
						IsIncludeSelf: IsIncludeSelf,
						OnlyLoadDisableOrg:this.OnlyLoadDisableOrg
					}
				}
				this.$http.post(posturl, {
					Data: postdata
				}).then((rsp) => {
					if(rsp.data.Code === 0) { //成功
						let items = (this.SelectorType=='Inventory')?rsp.data.Data.treeData:rsp.data.Data;
						if(parentID == null && items.length > 0) {
							this.expandedKeys=[items[0].id]
							if (this.ParentId||(this.SelectorType=='Inventory')) {
								this.QSE=items[0].QSE
								this.tableList(1)
							}
						}
						if (!parentID&&!items.length) {
							this.mask = false;
							this.loadingTable = false;
						}
						resolve(items);
					} else {
						this.$message.error(rsp.data.Message);
					}
				}).catch((error) => {});
			},
			// 单选框查询
			optionsChange(event) {
				if (this.defaultValues) {
					this.clearOption()
				}
				this.$emit("on-input", this.currentValue)
				if (this.multiple) {
					let obj = {}
					let arr=this.selection.concat(this.options)
					arr = arr.reduce (function(item,next){
						obj[next.ID] ? '' : obj[next.ID]  = true && item.push(next)
						return item;
					},[])
					let data=arr.filter(item=>this.currentValue.filter((item1)=> item1==item.ID).length>0)
					this.options=this.SelectorType=='Inventory'?data:arr
					this.$emit("select", data,this.Index)
					this.$emit("input", this.currentValue,this.Index)
					
				}else{
					let data=this.options.filter(item=>item.ID==this.currentValue)
					this.$emit("select", this.currentValue?data[0]:{},this.Index)
					this.$emit("input", this.currentValue,this.Index)
				}
			},
			clearOption(){
				this.defaultValues=null;
				let arr=this.currentValue instanceof Array?this.currentValue:[this.currentValue]
				this.options=this.options.filter(item=>arr.filter((item1)=> item1==item.ID).length>0)
			},
			// 模糊搜索
			remoteMethod(query) {
				if (this.defaultValues) {
					this.clearOption()
				}
				if(query) {
					this.loading = true;
					this.options=[]
					this.$http.post('system:/Organization/List', {
						"Data": {        
							QST: query,
                            PageNO: 1,
							PageSize: 10,
							QSE:this.QSE,
							LoadOrganizationType:this.LoadOrganizationType,
							OnlyLoadPrivilegeOrg:this.OnlyLoadPrivilegeOrg,
							OnlyLoadEnableOrg:this.OnlyLoadDisableOrg?false:this.OnlyLoadEnableOrg,
							OnlyLoadDisableOrg:this.OnlyLoadDisableOrg,
							ParentCompanyQSEs:this.ParentCompanyQSEs,
							ContainOffspringCompany:this.SelectorType=='Inventory'?this.ContainOffspringCompany:null,
						}
					}).then(res => {
						if(res.data.Code == 0) {
							this.loading = false;
							let obj = {}
							if (!this.options) this.options=[]
							let arr=this.options.concat(res.data.Data.Items?res.data.Data.Items:[])
							arr = arr.reduce (function(item,next){
								obj[next.ID] ? '' : obj[next.ID]  = true && item.push(next)
								return item;
							},[])
							if (!this.currentValue) {
								this.currentValue=this.multiple?[]:null;
							}
							this.options = arr
						} else {
							this.options=[]
							
						}
					})

				} else {
					this.options=[]
				}

			},
			// 弹框显示
			maskshow() {
				if(this.disabled) return
				this.dataModel.pagingModel.currentPage=1;
				this.mask = true
				this.dataModel.ProjectileFrame = true
				this.loadingTable = true
				if (!this.ParentId&&this.SelectorType!='Inventory') {
					this.tableList(1)
				}
				this.selection = []
			},
			// 弹框隐藏
			backProjectileFrame() {
				this.QSE=''
				this.mask = false
				this.dataModel.ProjectileFrame = false
				this.dataModel.topToolbarModel.search.value = ''
			},
			// 确定按钮
			sureShow() {
				if(this.selection.length == 0) {
					this.$message.error(R.Common_Msg_NoneBillSelected)
					return
				}
				if (this.defaultValues) {
					this.clearOption()
				}
				if(this.multiple) {
					if (!this.currentValue) {
						this.currentValue=[]
					}
					this.currentValue=Array.from(new Set(this.currentValue.concat(this.selection.map((item)=>item.ID)))) 
					let obj = {}
					if (!this.options) this.options=[]
					let arr=this.options.concat(this.selection)
					arr = arr.reduce (function(item,next){
						obj[next.ID] ? '' : obj[next.ID]  = true && item.push(next)
						return item;
					},[])
					let data=arr.filter(item=>this.currentValue.filter((item1)=> item1==item.ID).length>0)
					this.options = data
					this.$emit("on-input", this.currentValue)
					this.$emit("on-change", this.currentValue)
					this.$emit("input", this.currentValue,this.Index)
					this.$emit("select", data,this.Index)
					this.backProjectileFrame()
				} else {
					if(this.selection.length > 1) {
						this.$message.error(R.Common_Msg_OnlyOneCanBeSelected)
						return
					}
					this.options = this.selection
					this.currentValue=this.selection[0].ID
					this.$emit("on-input", this.currentValue)
					this.$emit("on-change", this.currentValue)
					this.$emit("input", this.currentValue,this.Index)
					this.$emit("select", this.selection[0],this.Index)
					this.backProjectileFrame()
				}
			},
			// 分页方法
			handleSizeChange(val) {
				this.dataModel.pagingModel.pagesize = val;
				this.dataModel.pagingModel.currentPage = 1;
				this.tableList()

			},
			handleCurrentChange(val) {
				this.dataModel.pagingModel.currentPage = val;
				this.tableList()

			},
			// 表格方法
			selectionShow(selection) {
				this.selection = selection
			},
			// 表格数据
			tableList(val) {
				this.loadingTable = true
				this.$http.post('system:/Organization/List', {
					"data": {
						PageNO: val ? val : this.dataModel.pagingModel.currentPage,
                        PageSize: this.dataModel.pagingModel.pagesize,
				        QST: this.dataModel.topToolbarModel.search.value,
						QSE: this.QSE,
						LoadOrganizationType:this.LoadOrganizationType,
						OnlyLoadPrivilegeOrg:this.OnlyLoadPrivilegeOrg,
						OnlyLoadEnableOrg:this.OnlyLoadDisableOrg?false:this.OnlyLoadEnableOrg,
						OnlyLoadDisableOrg:this.OnlyLoadDisableOrg,
						ParentCompanyQSEs:this.ParentCompanyQSEs,
						ContainOffspringCompany:this.SelectorType=='Inventory'?this.ContainOffspringCompany:null,
					}
				}).then(res => {
					if(res.data.Code == 0) {
						if(!res.data.Data.Items||!res.data.Data.Items.length) {
							this.dataModel.tableModel.data = []
							this.dataModel.pagingModel.total = res.data.Data.TotalRecordQty;
							this.loadingTable = false
							return
						}
						this.dataModel.tableModel.data = res.data.Data.Items;
						this.dataModel.pagingModel.total = res.data.Data.TotalRecordQty;
					} else {
						this.dataModel.tableModel.data = []
						
					}
					this.loadingTable = false
				})
			},
			optionsShow(newValue){
				let Is
				if (newValue instanceof Array) {
					let newValues=newValue.map(item=>item*1);
					let IDs=(newValues.filter(item=>this.options.filter((item1)=> item==item1.ID).length>0));
					Is=JSON.stringify(newValues.sort()) === JSON.stringify(IDs.sort())
				}else{
					Is=this.options.some((item)=>item.ID==newValue)
				}
				if (!this.multiple&&newValue&&!Is) {
					this.$http.post('system:/Organization/GetByID', {
						Data: {
							ID:newValue
						}
					}).then((rsp) => {
						if(rsp.data.Code === 0) { //成功
							let item=rsp.data.Data
							this.options=[item]
							this.currentValue=newValue;
						} else {
							this.$message.error(rsp.data.Message);
						}
					}).catch((error) => {});
				}else if(this.multiple&&(newValue&&newValue.length)&&!Is){
					this.$http.post('system:/Organization/GetByIDs', {
						Data: {
							IDs:newValue
						}
					}).then((rsp) => {
						if(rsp.data.Code === 0) { //成功
							let item=rsp.data.Data
							this.options=item
							this.currentValue=newValue.map(item=>item*1);
						} else {
							this.$message.error(rsp.data.Message);
						}
					}).catch((error) => {});
				}else{
					if (this.multiple) {
						this.currentValue=newValue?newValue:[];
					}else{
						this.currentValue=newValue?newValue*1:newValue;
					}
				}
			},
			defaultValueShow(){
				if (this.defaultValue&&this.defaultValue.length) {
					if(this.defaultValue[0].hasOwnProperty("id")){
						this.options=this.defaultValue.map(item=>{
							if (item.id) {
								return {ID:item.id,Name:item.label}
							}
						});
					}else{
						this.options=this.defaultValue
					}
					if(this.value){
						this.currentValue=this.value
					}else{
						this.currentValue=this.multiple?[]:null;
						this.options=[];
					}
				}
				this.defaultValues=this.defaultValue
			}
        },
		beforeCreate() {
			R =  this.getSelflag('TreeOrListSelect')? this.getSelflag('TreeOrListSelect'):this.getSelfLanguage('SystemControl', 'TreeOrListSelect')
			this.setSelflag('TreeOrListSelect',R)
			
		},
		mounted() {
			this.currentValue=this.multiple?[]:null;
			if (!this.Delay) {
				this.treeDateShow()
			}
			if (this.defaultValue&&this.defaultValue.length) {
				this.defaultValueShow()
			}
			this.optionsShow(this.value)
		}

	}
</script>
