<template>
	<div class="expand">
		<div class="titleall" v-if="title">{{title}}</div>
		<div class="mask" v-if="mask">
			<div class="addlists" v-if="addlist">
				<div class="head">
					<span class="title" v-if="liststatus=='add'">新增{{title}}</span>
					<span class="title" v-if="liststatus=='edited'">编辑{{title}}</span>
					<span class="title" v-if="liststatus=='del'">删除{{title}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-close"></i></span>
				</div>

				<div class="content" v-if="liststatus!='del'">
					<ul>
						<li>
							<span>名称：</span>
							<el-input v-model="listname"></el-input>
							<span class="red">*</span>
						</li>
						<li>
							<span>编码：</span>
							<el-input v-model="listcode"></el-input>
							<span class="red">*</span>
						</li>
					</ul>
				</div>
				<div class="foot" v-if="liststatus!='del'">
					<el-button @click="sureupdate" class="orangebd">保存</el-button>
					<el-button @click='closemask'>关闭</el-button>
				</div>
				<div class="contentdel" v-if="liststatus=='del'">
					<i class="el-icon-info"></i>删除后不可恢复
				</div>
				<div class="foot" v-if="liststatus=='del'">
					<el-button @click="suredel" class="orangebd">确认删除</el-button>
					<el-button @click='closemask'>关闭</el-button>
				</div>

			</div>
		</div>

		<el-tree :default-expanded-keys='expandkey' :check-strictly="true"  :auto-expand-parent="false" class="commontree" ref="tree"  :show-checkbox="checkbox" :highlight-current="!highcurrent" show :data="api" node-key="id" :props='defaultProps'  @node-click="clicktree">
			<span class="custom-tree-node" slot-scope="{ node, data }">
		        <span  class="mytreelistitem" :title=" node.label">{{  node.label }}<span class="islocked" v-if="data.IsEnabled==false">&ensp;&ensp;({{data.IsEnabledText}})</span></span>
			<span class="myrole">
				
				<!--<el-checkbox :indeterminate='data.isIndeterminate' v-model="data.checkAll" @change="handleCheckAllChange(data,val)">全选</el-checkbox>
			  	<el-checkbox-group v-model="data.checkedCities" @change="handleCheckedCitiesChange(data,val)">
			 	 <el-checkbox v-for="(item,index) in data.role" >{{item.name}}</el-checkbox>
			  </el-checkbox-group>-->
					<!--<el-checkbox class="allselect" @change='checkchange(data,node)'>全选</el-checkbox>
					<span v-for="(item,index) in data.role"  class="roleitem"  v-if="data.role">
					<el-checkbox :checked="item.checked">{{item.name}}</el-checkbox>
					</span>-->

			</span>
			<!-- <span class="btns">
		            <i class="el-icon-plus"  @click="() => append(node,data)"></i>
		            <i class="el-icon-edit-outline"  @click="() => eddit(node, data)"></i>
		            <i class="el-icon-delete" @click="() => remove(node, data)"></i>
		    </span> -->
			</span>
		</el-tree>
		<div>

			<div class="addnew" @click="append('data')" v-if="addnew">新建{{title}}</div>

		</div>
	</div>
</template>
<!-- VUE饿了么树形控件添加增删改功能按钮 -->
<script>
	export default {
		props: [
			//			'defaultcheckedkeys',
			'highcurrent',
			'checkbox',
			'refsa',
			'title',
			'url',
			//			'defaultExpandKeys',
			'api',
			'addnew',
			
		],
		name: 'cjtree',
		data() {
			return {
				expandkey:[],
				liststatus: '',
				listcode: '',
				listname: '',
				addlistid: '',
				mask: false,
				addlist: false,
				ParentID: '',
				QSE: '',
				clickID: '',
				defaultExpandKeys: [],
				checkedCities:[],
				defaultProps: {
					children: 'children',
					label: 'label'
				},
			}
		},
		created() {
//			this.$nextTick(() => {
//				this.$refs.tree.setCurrentKey('22'); // treeBox 元素的ref   value 绑定的node-key
//			});
		},
		mounted(){
			this.expandkey = [this.api[0].id]
			// console.log(this.expandkey)
		},
		methods: {
			  handleCheckAllChange(val,data) {
		        data.checkedCities = val ? data.role : [];
		        data.isIndeterminate = false;
		      },
		      handleCheckedCitiesChange(data,value) {
//		      	// console.log(value)
		        let checkedCount = value.length;
		        data.checkAll = checkedCount === data.role.length;
		        data.isIndeterminate = checkedCount > 0 && checkedCount < data.role.length;
		      },
			checkchange(data, node) {
				//				for(var i =0;i<data.role.length;i++){
				//					data.role[i].checked =true
				//				}

			},
			clicktree(node, data) {
				this.QSE = data.data.QSE;
				this.$emit('childByValue', this.QSE)
				this.clickID = data.data.id;
			},
			closemask() {
				this.mask = false;
				this.addone = false;
				this.addlist = false;
			},
			append(data, node) {
				this.mask = true;
				this.addlist = true;
				this.liststatus = "add";
				this.addlistid = '';
				this.ParentID = data.parent_id ? data.parent_id : this.clickID;
//				// console.log(this.ParentID)
			},
			remove(node, data) {
				this.mask = true;
				this.addlist = true;
				this.liststatus = "del";
				this.addlistid = data.id
			},
			eddit(node, data) {
				;
				this.addlistid = data.id
				var params = {
					token: this.$Store.get('token'),
					data: {
						ID: this.addlistid,
					}
				}
				this.$http.post('/SupplierCategory/GetById', params).then(res => {
					if(res.data.Code=='0'){
						this.listcode = res.data.Data.code;
						this.listname = res.data.Data.label;
					}else{
						
					}
				})
				this.mask = true;
				this.addlist = true;
				this.liststatus = "edited";
				this.ParentID = data.parent_id
			},
			suredel() {
				var params = {
					token: this.$Store.get('token'),
					data: {
						ID: this.addlistid,
					}
				}
				this.$http.post('/SupplierCategory/Delete', params).then(res => {
					if(res.data.Code == '0') {
						this.mask = false;
						this.addlist = false;
						this.listname = '';
						this.listcode = '';
						this.$parent.pagechangetree();
						this.$message({
							message: '删除成功',
							type: 'success'
						});
					} else {
						
					}
				})
				
			},
			sureupdate() {

				if(this.listname.trim() == '') {
					this.$message.error('请输入名称');
					return
				}
				if(this.listcode.trim() == '') {
					this.$message.error('请输入编码')
					return
				}
				var params = {
					'token': this.$Store.get('token'),
					data: {
						ID: this.addlistid,
						Name: this.listname,
						Code: this.listcode,
						ParentID: this.ParentID,
					}

				}
				this.$http.post('/SupplierCategory/AddOrEdit', params).then(res => {
					if(this.liststatus == 'add') {
						if(res.data.Code == '0') {
							this.mask = false;
							this.addlist = false;
							this.listname = '';
							this.listcode = '';
							this.$parent.pagechangetree();
							this.$message({
								message: '新建成功',
								type: 'success'
							});
						} else {
							
						}
					} else {
						if(res.data.Code == '0') {
							this.mask = false;
							this.addlist = false;
							this.listname = '';
							this.listcode = '';
							this.$parent.pagechangetree();
							this.$message({
								message: '编辑成功',
								type: 'success'
							});
						} else {
							
						}
					}

				})
				
			},
		}

	}
</script>

<style lang="scss">
	.expand {
		.islocked {
			color: #FF5402;
		}
		.myrole {
			position: absolute;
			left: 13rem;
			.label.el-checkbox {
				width: 80px;
			}
			.roleitem {
				display: -moz-inline-box;
				display: inline-block;
				width: 115px;
			}
			.allselect {
				margin-right: 40px;
			}
		}
		
		width: 100%;
		/*height: 499px;*/
		height: 99%;
		overflow: auto;
		background-color: white;
		.mask {
			/*position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(0, 0, 0, 0.5);*/
			.addlists {
				width: 400px;
				height: 200px;
				position: fixed;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					padding-left: 20px;
					li {
						margin-top: 14px;
						.red {
							color: red;
						}
						.el-input {
							height: 30px;
							width: 160px;
						}
						.el-input__inner {
							height: 30px;
						}
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 25px;
					float: right;
					margin-right: 10px;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
				.contentdel {
					padding-top: 50px;
					padding-left: 100px;
					margin-bottom: 20px;
					i {
						color: deepskyblue;
						margin-right: 10px;
						font-size: 16px;
					}
				}
			}
			.addone {
				height: 546px;
				width: 500px;
				position: fixed;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					padding-left: 20px;
					li {
						margin-top: 8px;
						height: 35px;
						line-height: 35px;
						.el-input {
							height: 30px;
							width: 160px;
						}
						.el-input__inner {
							height: 30px;
						}
						.red {
							color: red;
						}
						.el-textarea {
							width: 60%;
						}
						.beizhu {}
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 35px;
					float: right;
					margin-right: 10px;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
		}
		.custom-tree-node {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			padding-right: 8px;
		}
		.titleall {
			height: 40px;
			line-height: 40px;
			background-color: #EFF1F1;
			text-align: center;
			margin-bottom: 10px;
			font-size: 14px;
			border-right: 1px solid #eee;
			font-family: FZLTHK--GBK1-0;
			font-weight: bold;
			color: rgba(123, 128, 130, 1);
		}
		.yy {
			margin-top: 20px;
		}
		.myinput .el-input {
			width: 150px;
		}
		.myinput .el-input__inner {
			margin-top: 15px;
			height: 30px;
			line-height: 30px;
			width: 150px;
			margin-bottom: 15px;
		}
		.addnew {
			text-align: center;
			margin-top: 25px;
			height: 30px;
			line-height: 30px;
			border: 1px dashed #FF5402;
			color: #FF5402;
			cursor: pointer;
		}
		.expand-tree {
			border: none;
			margin-top: 10px;
		}
		.el-tree {
			width: 100%;
			/*height: 400px;*/
			overflow: auto;
		}
		.expand-tree .el-tree-node.is-current,
		.expand-tree .el-tree-node:hover {
			overflow: hidden;
		}
		.expand-tree .is-current>.el-tree-node__content .tree-label {
			font-weight: 600;
		}
	}
</style>