<template>
    <div class="Test">
        <!-- <cj-Button type='' disabled>禁止按钮</cj-Button>
        <cj-Button type='' @click="aa($event)">默认按钮</cj-Button>
        <cj-Button type='primary'>高亮按钮</cj-Button>
        <cj-Button type='warning'>危险按钮</cj-Button>
        <cj-Button type='warning' >哈</cj-Button>
        <cj-Button type='warning' >哈哈</cj-Button>
        <cj-Button type='warning' >哈哈哈</cj-Button>
        <cj-Button type='warning' >哈哈哈哈</cj-Button>
        <cj-Button type='warning' >哈哈哈哈哈</cj-Button>
        <cj-Button type='warning' >哈哈哈哈哈哈</cj-Button>
        <cj-Button type='warning' height='35px !important'>哈哈哈哈哈哈哈</cj-Button>
        <cj-Button type='warning' icon='el-icon-delete' iconSiz='12px' @click="aa($event)">危险</cj-Button>
        <cj-Button type='warning'>呵呵呵呵呵</cj-Button>
        <cj-Button type='warning'>hhhhh</cj-Button>
        <cj-Button type='warning'>12345</cj-Button>
        <cj-Button type='warning'>123</cj-Button>
        <cj-Button type='customize' marginLeft='100px'>啊哈</cj-Button>
        <cj-Search placeholder='按编码、名称搜索' v-model="value"></cj-Search> -->
        <!-- <template>
          <el-popover
            placement="bottom"
            width="246"
            trigger="click">
            <div>123</div>
            <el-button slot="reference">click 激活</el-button>
          </el-popover>
        </template> -->
       <!-- <Tree :title="title" :defaultProps='defaultProps' :defaultExpandKeys='defaultExpandKeys' :api="api"></Tree> -->
       <!-- <cj-select :option='option' :defoultValue='defoultValue' @change="aa" :multiple="false" ></cj-select> -->
	    <!-- <Table :columns="columns1" :data="data1"></Table> -->
		<cj-Dropdowntree :defaultProps='defaultProps' :defaultExpandKeys='defaultExpandKeys' :api="api"></cj-Dropdowntree>
		<!-- <cj-Button type='customize' marginLeft='100px'>啊哈</cj-Button> -->
		<!-- <div @click="ww">21</div> -->
    </div>
</template>
<script>
export default {
	
  name: "Test",
  props:[
  	
  ],
  data() {
    return {
		  columns1: [
                    {
                        title: 'Name',
                        key: 'name'
                    },
                    {
                        title: 'Age',
                        key: 'age'
                    },
                    {
                        title: 'Address',
                        key: 'address'
                    }
                ],
                data1: [
                    {
                        name: 'John Brown',
                        age: 18,
                        address: 'New York No. 1 Lake Park',
                        date: '2016-10-03'
                    },
                    {
                        name: 'Jim Green',
                        age: 24,
                        address: 'London No. 1 Lake Park',
                        date: '2016-10-01'
                    },
                    {
                        name: 'Joe Black',
                        age: 30,
                        address: 'Sydney No. 1 Lake Park',
                        date: '2016-10-02'
                    },
                    {
                        name: 'Jon Snow',
                        age: 26,
                        address: 'Ottawa No. 2 Lake Park',
                        date: '2016-10-04'
                    }
                ],
	   defoultValue:[],
	   option:[
		   		{id:'1',data:['软件部'],generalTerm:'软件部樊少华咕嘿嘿',},
		   		{id:'2',data:['销售部'],generalTerm:'销售部樊华咕嘿嘿',},
		   		{id:'3',data:['财务部'],generalTerm:'财务部樊少咕嘿嘿',},
		   		{id:'4',data:['哈哈哈'],generalTerm:'哈哈哈樊咕嘿嘿',},
	   		  ],
       title:'畅捷公司',
       defaultProps: {
					children: 'children',
					label: 'name',
					isLeaf: 'leaf'
        },
        api:{

			Items: [{  
				ID: 1,  
				name: "北京市",  
				ProSort: 1,  
				remark: "直辖市",
				pid: '',
				isEdit: false,
				edited:false,
				children: [{
					ID: 35,
					name: "朝阳区",
					pid: 1,
					remark: '',
					edited:true,
					isEdit: false,
					children: []
				},{
					ID: 36,
					name: "海淀区",
					pid: 1,
					remark: '',
					isEdit: false,
					leaf:true,
					children: []
				}],}]  
		},
						
		defaultExpandKeys: [], 
    };
  },
  methods: {
	  ww(){
		  alert(1)
		  // console.log(this.defoultValue)
	  },
    aa(evt) {
      // console.log(evt)
    },
    loadNode1(node, resolve) {
        if (node.level === 0) {
          return resolve([{ name: 'region' }]);
        }
        if (node.level > 1) return resolve([]);

        setTimeout(() => {
          const data = [{
            name: 'leaf',
            zones:[{
              name:'a'
            },{
              name:'b'
            }],
          }, {
            name: 'zone'
          },{
            name:'a',
          }];

          resolve(data);
        }, 500);
      }
  },
  mounted() {
		
		// 公司或部门树数据
		// this.$http.post('/Organization/GetTree',{
		// 	"Token":cookie.get('token'),
		//  	"Data":{
		// 		"LoadOrganizationType":3,
		// 		"OnlyLoadPrivilegeOrg":false,
		// 		"OnlyLoadEnableOrg":false,
		// 		"Delay":false,
		// 		"ParentId":null
		// 	}

		// }).then(res => {
		// 	// console.log(res)
		// 	this.api.treelist=[]
		// 	for(let i=0;i<res.data.Data.length;i++){
		// 		this.api.treelist.push({
		// 		id:res.data.Data[i].ID,
		// 		name:res.data.Data[i].NameCN,
		// 		ProSort: 1,  
		// 		remark: "",
		// 		pid: '',
		// 		isEdit: false,
		// 		edited:false,
		// 		children:[]})
		// 	}
		// 	// console.log(this.api.treelist)
		// })
  }
};

</script>
<style lang='scss' scoped>
.yz {
  margin-top: 20px;
}
.aa > span {
  letter-spacing: 1em;
  transform: translateX(2em);
}
.box {
  width: 80px;
  height: 35px;
  border: 1px solid #999;
  text-align: center;
  line-height: 35px;
  margin-left: 20px;
  font-size: 13px;
  -webkit-appearance: none;

  span {
    word-spacing: 8px;
    letter-spacing: 1px;
  }
}
</style>
