<template>
	<div id="employee" v-loading="loadingR" v-if="IsPageDome">
		<div class="mask" v-if="mask">
			<div class="addone account" v-loading="accountLoading" v-if="account">
				<div class="head">
					<span class="title" v-if="selectTwo">{{R.Employee_68}}</span>
					<span class="title" v-else>{{R.Employee_78}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="contentdel">
					<p v-show="selectTwo">{{R.Employee_70}}{{selection.length}}{{R.Employee_71}}</p>
					<el-form class="form changepass" :model="page" :rules="rules" ref="ruleForm" label-width="90px">
						<el-form-item :label="R.Employee_72+'：'" prop='password'>
							<el-input :placeholder="R.Employee_74" autocomplete="new-password" type="password" maxlength='50' v-model="page.password"></el-input>&ensp;<span class="red">*</span>
						</el-form-item>
						<el-form-item :label="R.Employee_73+'：'" prop='passwords'>
							<el-input :placeholder="R.Employee_75" autocomplete="new-password" type="password" maxlength='50' v-model="page.passwords"></el-input>&ensp;<span class="red">*</span>
						</el-form-item>
						<el-form-item :label="R.Employee_84+'：'" v-if="sccountpass">
							<el-date-picker   v-model="page.validityTime" type="date" :editable=false :picker-options="purchasePickerOptions"   format="yyyy-MM-dd" value-format="yyyy-MM-dd"  :placeholder="R.Employee_85">
							</el-date-picker>
						</el-form-item>
					</el-form>
				</div>
				<div class="foot">
					<el-button v-if="selectTwo" @click="sureEnable" :disabled="isDisabled" class="orangetext add orangebd">{{R.Employee_69}}</el-button>
					<el-button v-else @click="surechange" :disabled="isDisabled" class="orangetext add orangebd">{{R.Employee_69}}</el-button>
					<el-button @click='closemask'>{{R.Employee_close}}</el-button>
				</div>
			</div>
			<div class="addone del" v-if="openBindMask" :style="{height:myheight}">
				<div class="head">
					<span class="title" >{{R.Employee_92}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="contentdelsa" >
					<div>
						 <el-radio-group v-model="checked">
						    <el-radio :label="1" :disabled="wxdis">{{R.Employee_88}}</el-radio>
						    <el-radio :label="2" :disabled="dddis" >{{R.Employee_89}}</el-radio>
						 </el-radio-group>
					</div>
					
					<i class="el-icon-info" style="margin-top:10px"></i>{{R.Employee_93}}
				</div>
				<div class="foot">
					<el-button @click="SureUnbind" :disabled="isDisabled" class="orangetext add orangebd">{{R.Common_Button_Save}}</el-button>
					<el-button @click='closemask'>{{R.Employee_close}}</el-button>
				</div>
			</div>
			<div :class="status=='del'||status=='lock'?'addone del':'addone'" v-loading="CartridgeLoading" v-if="addone" :style="{height:myheight}">
				<div class="head">
					<span class="title" v-if="status=='add'">{{R.Employee_addtext}}</span>
					<span class="title" v-if="status=='edited'">{{R.Employee_edittext}}</span>
					<span class="title" v-if="status=='del'&&!mytime">{{R.Employee_deltext}}</span>
					<span class="title" v-if="status=='del'&&mytime">{{R.Employee_86}}</span>
					<span class="title" v-if="status=='lock'">{{R.Employee_stoptext}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="contentdel" v-if="status=='del'&&!mytime">
					<i class="el-icon-info"></i>{{R.Employee_deltip}}
				</div>
				<div class="contentdels" v-if="status=='del'&&mytime">
					{{R.Employee_84}}：<el-date-picker  v-model="page.validityTime" type="date"  format="yyyy-MM-dd" value-format="yyyy-MM-dd"  :editable=false :picker-options="purchasePickerOptions" :placeholder="R.Employee_85">
					</el-date-picker>
				</div>
				<div class="contentdel" v-if="status=='lock'">
					<i class="el-icon-info"></i>{{R.Employee_IsTrueLeave}}
				</div>
				<div class="foot" v-if="status=='del'&&!mytime" >
					<el-button @click="suredel" :disabled="isDisabled" class="orangetext add orangebd">{{R.Employee_suredel}}</el-button>
					<el-button @click='closemask'>{{R.Employee_close}}</el-button>
				</div>
				<!--设置有效期-->
				<div class="foot" v-if="status=='del'&&mytime" >
					<el-button @click="suresetTime" :disabled="isDisabled" class="orangetext add orangebd">{{R.Employee_69}}</el-button>
					<el-button @click='closemask'>{{R.Employee_close}}</el-button>
				</div>
				<div class="foot" v-if="status=='lock'">
					<el-button @click="surelock" :disabled="isDisabled" class="orangetext add orangebd">{{R.Employee_TrueLeave}}</el-button>
					<el-button @click='closemask'>{{R.Employee_close}}</el-button>
				</div>
				<div class="content" :style="{height:mycontengheight}" v-if="status!='del'&& status!='lock'">
					<el-form :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" label-width="100px" class=" changepass demo-ruleForm">
						<el-form-item :label="R.Employee_code+'：'" prop="Code" v-if="!AutoGenerateCode_Employee||AutoGenerateCode_Employee=='False'">
							<el-input maxlength=50 :placeholder="R.Employee_45" @change="changeCode" v-model="ruleForm2.Code"></el-input>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item :label="R.Employee_code+'：'" v-if="AutoGenerateCode_Employee||AutoGenerateCode_Employee=='True'">
							<el-input maxlength=50 :placeholder="R.Employee_46" :disabled="true" v-model="ruleForm2.Code"></el-input>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item :label="R.Employee_name+'：'" prop="Name">
							<el-input maxlength=50 :placeholder="R.Employee_47" :label="R.Employee_code+'：'" v-model="ruleForm2.Name"></el-input>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item :label="R.Employee_sex+'：'" prop="sex">
							<el-select v-model="ruleForm2.sex" :placeholder="R.Employee_48">
								<el-option key="1" :label="R.Employee_2" value="1">
								</el-option>
								<el-option key="0" :label="R.Employee_3" value="0">
								</el-option>
							</el-select>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item class='roapeitem' :label="R.Employee_bumen+'：'" prop="OrgID" v-if="status=='add'" style="height:30px;">
							<OrganizationSelect
								:LoadOrganizationType="1"
								:placeholder="R.Employee_50"
								v-model="ruleForm2.OrgID" 
								:defaultValue ="defaultvalue"
							/> 
							<span class="red" style="position: relative;top: -6px;">*</span>
							<i style="color: #f56c6c;display: none; position: absolute; right: 160px; top: -4px;" class="myiconerror  el-input__icon el-input__validateIcon el-icon-circle-close"></i>
						</el-form-item>
						<el-form-item class='roapeitem' :label="R.Employee_bumen+'：'" v-if="status=='edited'" style="height:30px;">
							<span style="margin-top: 10px;">{{OrganizationName}}</span>
						</el-form-item>
						<!--新职位-->
						<el-form-item :label="R.Employee_right+'：'" prop="Position">
							<el-select class="myselecti" style="height: 30px;line-height: 30px;" multiple v-model="ruleForm2.Position" :collapse-tags="true" :placeholder="R.Employee_51">
								<!--插眼-->
								<el-option v-for="item in typeOptions" :key="item.ID" :label="item.ItemText" :value="item.ID"> </el-option>
							</el-select>
						</el-form-item>
						<!--旧职位-->
						<!--<el-form-item :label="R.Employee_right+'：'" prop="Position">
							<el-input  v-model="ruleForm2.Position" placeholder="请输入职位"></el-input>
						</el-form-item>-->
						<el-form-item class='roapeitem' :label="R.Employee_phone+'：'" prop="MobileNo">
							<el-input maxlength=50 :placeholder="R.Employee_52" v-model="ruleForm2.MobileNo"></el-input>
						</el-form-item>
						<el-form-item class='roapeitem' :label="R.Employee_95+'：'" prop="IDCard" >
							<el-input maxlength=18  :placeholder="R.Employee_96" v-model="ruleForm2.IDCard"></el-input>
						</el-form-item>
						<!--新内容-->
						<el-form-item :label="R.Employee_60+'：'" prop="EnableEmployeeAccount">
							<el-select class="myselecti" style="height: 30px;line-height: 30px;" @change="changeNew" v-model="ruleForm2.EnableEmployeeAccount" :collapse-tags="true" :placeholder="R.Employee_60">
								<!--插眼-->
								<el-option v-for="item in accountOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
							</el-select>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item v-show="ruleForm2.EnableEmployeeAccount=='1'" class='roapeitem' :label="R.Employee_61+'：'" prop="EmployeeAccount">
							<el-input maxlength=50 :placeholder="R.Employee_61" :disabled="status=='edited'&&editpass == '1'" v-model="ruleForm2.EmployeeAccount"></el-input>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item v-show="(editpass == '0'&&ruleForm2.EnableEmployeeAccount=='1')||(status=='add'&&ruleForm2.EnableEmployeeAccount=='1')" class='roapeitem' :label="R.Employee_62+'：'" prop="password">
							<el-input maxlength=50 :placeholder="R.Employee_62" type='password' autocomplete="new-password" v-model="ruleForm2.EmployeePwd"></el-input>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item v-show="(editpass == '0'&&ruleForm2.EnableEmployeeAccount=='1')||(status=='add'&&ruleForm2.EnableEmployeeAccount=='1')" class='roapeitem' :label="R.Employee_63+'：'" prop="passwords">
							<el-input maxlength=50 :placeholder="R.Employee_63" type='password' autocomplete="new-password" v-model="ruleForm2.EmployeePwds"></el-input>
							<span class="red">*</span>
						</el-form-item>
						<!--有效期-->
						<el-form-item  class='roapeitem'  :label="R.Employee_84+'：'" >
							<el-date-picker :disabled="ruleForm2.EnableEmployeeAccount!='1'" :editable=false :picker-options="purchasePickerOptions"  v-model="ruleForm2.validityTime" type="date"  format="yyyy-MM-dd" value-format="yyyy-MM-dd"  :placeholder="R.Employee_85">
							</el-date-picker>
						</el-form-item>
						<el-form-item v-show="ruleForm2.EnableEmployeeAccount=='1'" prop="DefaultLanguage" :label="R.Employee_64+'：'">
							<el-select class="myselecti" style="height: 30px;line-height: 30px;" v-model="ruleForm2.DefaultLanguage" :collapse-tags="true" :placeholder="R.Employee_64">
								<!--插眼-->
								<el-option v-for="item in languageOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
							</el-select>
							<span class="red">*</span>
						</el-form-item>
						<!--新内容-->
						<el-form-item class='roapeitem' :label="R.Employee_fax+'：'">
							<el-input maxlength=50 :placeholder="R.Employee_53" v-model="ruleForm2.Telephone"></el-input>
						</el-form-item>
						<el-form-item :label="R.Employee_Email+'：'">
							<el-input maxlength=50 :placeholder="R.Employee_54" v-model="ruleForm2.Email"></el-input>
						</el-form-item>
						<el-form-item :label="R.Employee_address+'：'" prop="Address">
							<el-input type="textarea" maxlength="200" :rows="2" :placeholder="R.Employee_55" resize='none' v-model="ruleForm2.Address">
							</el-input>
						</el-form-item>
						<el-form-item :label="R.Employee_remark+'：'" prop="Remark">
							<el-input type="textarea" maxlength="200" :rows="2" :placeholder="R.Employee_56" resize='none' v-model="ruleForm2.Remark">
							</el-input>
						</el-form-item>

					</el-form>
				</div>
				<div class="foot" v-if="status!='del'&&status!='lock'">
					<el-button @click="updateUser('ruleForm2')" :disabled="isDisabled" class="orangetext add orangebd">{{R.Common_Button_Save}}</el-button>
					<el-button @click="resert('ruleForm2')">{{R.Common_Button_Reset}}</el-button>
					<el-button @click='closemask'>{{R.Employee_close}}</el-button>
				</div>
			</div>
			<div class="changeAsset" v-if="change" v-loading="CartridgeLoading">
				<div class="head">
					<span class="title">{{R.Employee_5}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content">
					<p>{{R.Employee_6}}<span class="orangetext" style="color: blue;">&ensp;&ensp;{{Employee}}&ensp;&ensp;-&ensp;&ensp;{{OrganizationName}}&ensp;&ensp;</span>{{R.Employee_7}}</p>
					<p>{{R.Employee_8}}：&ensp;&ensp;{{OrganizationName}}</p>
					<p style="margin-bottom: 15px;">{{R.Employee_9}}：
						<OrganizationSelect
							:LoadOrganizationType="1"
							@select="typecancel"
							v-model="TargetOrgID" 
							@input="typecancelClear"
							:placeholder="R.Employee_57"
						/> 
						<span style="color: red;">*</span>
					</p>
					<p>{{R.Employee_10}}&ensp;&ensp;{{Employee}}&ensp;&ensp;{{R.Employee_11}}</p>
					<Table sortable='custom' @on-sort-change="sortableShow" height='320' stripe :columns="EmployeetableColumns" :data="EmployeetableData"></Table>
				</div>
				<div class="foot">
					<el-button @click="uodateChangeEmployee" class="orangetext add orangebd">{{R.Common_Button_Save}}</el-button>
					<el-button @click='closemask'>{{R.Employee_close}}</el-button>
				</div>
			</div>
		</div>
		<div class="left">
			<div class="titleall" v-if="title">{{R.Employee_title}}</div>
			<el-tree v-if="loadtree" :expand-on-click-node="false" node-key="id" :default-expanded-keys='expandkey' class="commontree" :load="loadNode" lazy ref="tree" highlight-current :props='defaultProps' @node-click="clicktree">
				<span :loading='loadingL' class="custom-tree-node" slot-scope="{ node, data }">
		        	<span  class="mytreelistitem" :title=" node.label">{{ node.label }}
		        	</span>
				</span>
			</el-tree>
		</div>
		<div class="right" style="overflow: hidden;">
			<div class="main" style="min-width: 1122px;overflow:hidden;">
				<div class="clickbtn">
					<el-row>
						<el-col :span="12" style="display:flex;justify-content: start;flex-wrap: nowrap;">
							<el-button v-if="getpermission('Add')" class="orangetext add orangebd" @click="addnessupiler"><i class="el-icon-plus"></i> {{R.Common_Button_Add}}</el-button>
							<el-popover placement="bottom" v-model="value" trigger="click">
								<div class="Printed" v-if="getpermission('Edit')" @click="editorClick">
									{{R.Common_Button_Edit}}
								</div>
								<div class="Printed" v-if="getpermission('ChangeDept')" @click="openaccount('true')">
									{{R.Employee_78}}
								</div>
								<div class="Printed" v-if="getpermission('Delete')" @click="delnessupiler">
									{{R.Common_Button_Delete}}
								</div>
								<div class="Printed" v-if="getpermission('LeaveOffice')" @click="lock">
									{{R.Employee_stoptext}}
								</div>
								<div class="Printed" v-if="getpermission('ChangeDept')" @click="changeEmployee">
									{{R.Employee_13}}
								</div>
								<div class="Printed" v-if="getpermission('ChangeDept')" @click="changeWX">
									{{R.Employee_97}}
								</div>
								<el-button slot="reference"> <span>{{R.Common_Button_Operation}}</span> <i style="font-size:12px;color:" class="el-icon-caret-bottom"></i></el-button>
							</el-popover>
							<el-button v-if="getpermission('PrintLabel')" @click='print'>{{R.Common_Button_PrintLabel}}</el-button>
							<el-button v-if="getpermission('Export')" @click='dataImport'>{{R.Common_Button_ExportToExcel}}</el-button>
						
							<el-button v-if="getpermission('Export')" @click='openaccount'>{{R.Employee_68}}</el-button>
							<!--插眼 设置有效期-->
							<el-button v-if="getpermission('Export')" @click='openTime'>{{R.Employee_86}}</el-button>
						</el-col>
						<el-col :span="12" style="display:flex;justify-content:flex-end;flex-wrap: nowrap;">
							<li style="margin-right: 10px;height: 30px;line-height: 30px;display: flex;flex-wrap: nowrap;white-space: nowrap;">
									<span>{{R.Employee_60}}：</span>
									<el-select class="myselecti" style="width: 150px;" v-model="EnableEmployeeAccountNumber" :collapse-tags="true" :placeholder="R.Employee_60">
										<!--插眼-->
										<el-option v-for="item in accountOaptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
									</el-select>
							</li>
							<div style="white-space: nowrap;">
								<el-input v-if="getpermission('Query')" :placeholder="R.Employee_14" v-model="search" @keyup.enter.native="searchmylist(1)">
									<i slot="suffix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<el-button v-if="getpermission('Query')" style=" margin-left: 10px;" @click="searchmylist(1)">{{R.Common_Button_Search}}</el-button>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="mytable" ref="table">
					<Table :height='tableHeight' stripe @on-selection-change="selectionChange" :columns="tableColumns" :data="tableData"></Table>
				</div>
			</div>
			<div class="rightfoot">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	const simulateAsyncOperation = fn => {
		setTimeout(fn, 2000)
	}
	var R = {};
	export default {
		data() {
			var validateEnableEmployeeAccount = (rule, value, callback) => {
				console.log(this.ruleForm2.EnableEmployeeAccount)
				if(this.ruleForm2.EnableEmployeeAccount == '1' &&( !value || value == '')) {
					callback(new Error(R.Employee_81));
				} else {
					callback();
				}
			};
			var validatepassword = (rule, value, callback) => {
				if(this.page.password.trim() == '') {
					callback(new Error(R.Employee_74));
				} else if(this.page.password.length < this.passType.PasswordPolicy_MinLength) {
					callback(new Error(R.Employee_76+this.passType.PasswordPolicy_MinLength+R.Employee_99));
				} else if(this.passType.PasswordPolicy_PasswordStrength){
						var  regnum = 0;
						var reg1 = /[a-z]|[A-Z]/;
						if(reg1.test(this.page.password)){
							regnum+=1
						}
						var reg2 = /\d/;
						if(reg2.test(this.page.password)){
							regnum+=1
						}
						var reg3 = /[\!\@\#\$\%\^\&\*]/
						if(reg3.test(this.page.password)){
							regnum+=1
						}
						if(regnum<2){
							callback(new Error(R.Employee_100));
						}else{
							callback();
						}
				} else {
					callback();
				}
			};
			var validatetwopassword = (rule, value, callback) => {
				if(this.page.passwords.trim() == '') {
					callback(new Error(R.Employee_75));
				} else if(this.page.passwords !== this.page.password) {
					callback(new Error(R.Employee_77));
				} else {
					callback();
				}
			};
			var validatepasswords = (rule, value, callback) => {
				if((this.editpass == '0'&&this.ruleForm2.EnableEmployeeAccount=='1')||(this.status=='add'&&this.ruleForm2.EnableEmployeeAccount=='1')){
					if(this.ruleForm2.EmployeePwd.trim() == '') {
					callback(new Error(R.Employee_74));
				} else if(this.ruleForm2.EmployeePwd.length < this.passType.PasswordPolicy_MinLength) {
					callback(new Error(R.Employee_76+this.passType.PasswordPolicy_MinLength+R.Employee_99));
				} else if(this.passType.PasswordPolicy_PasswordStrength){
						var  regnum = 0;
						var reg1 = /[a-z]|[A-Z]/;
						if(reg1.test(this.ruleForm2.EmployeePwd)){
							regnum+=1
						}
						var reg2 = /\d/;
						if(reg2.test(this.ruleForm2.EmployeePwd)){
							regnum+=1
						}
						var reg3 = /[\!\@\#\$\%\^\&\*]/
						if(reg3.test(this.ruleForm2.EmployeePwd)){
							regnum+=1
						}
						if(regnum<2){
							callback(new Error(R.Employee_100));
						}else{
							callback();
						}
				}else {
					callback();
				}
				}else{
					
					callback();
				}
				
			};
			var validatetwopasswords = (rule, value, callback) => {
				if((this.editpass == '0'&&this.ruleForm2.EnableEmployeeAccount=='1')||(this.status=='add'&&this.ruleForm2.EnableEmployeeAccount=='1')){
					if(this.ruleForm2.EmployeePwd.trim() == '') {
					callback(new Error(R.Employee_75));
				} else if(this.ruleForm2.EmployeePwd !== this.ruleForm2.EmployeePwds) {
					callback(new Error(R.Employee_77));
				} else {
					callback();
				}
				}else{
					callback();
				}
				
			};
			var validateEmail = (rule, value, callback) => {
				var reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
				if(value != '' && !reg.test(value)) {
					callback(new Error(R.Employee_19));
				} else {
					callback();
				}
			};
			var validateTelephone = (rule, value, callback) => {
				var reg = /^[1][3,4,5,7,8,9,6][0-9]{9}$/;
				if(value != '' && !reg.test(value)) {
					callback(new Error(R.Employee_18));
				} else {
					if(this.ruleForm2.EnableEmployeeAccount == '1' && this.ruleForm2.EmployeeAccount == '') {
						this.ruleForm2.EmployeeAccount = value
						this.$refs.ruleForm2.clearValidate('EmployeeAccount')
						callback();
					}
					callback();

				}
			};
			var validateCode = (rule, value, callback) => {
				if(value.trim() == '') {
					callback(new Error(R.Employee_20));
				} else {
					callback();
				}

			};
			var validateName = (rule, value, callback) => {
				if(value.trim() == '') {
					callback(new Error(R.Employee_21));
				} else {
					callback();
				}

			};
			var validateParentID = (rule, value, callback) => {
				if(this.ruleForm2.OrgID == '' || this.ruleForm2.OrgID == null || this.ruleForm2.OrgID == undefined) {
					callback(new Error(R.Employee_22));
				} else {
					callback();
				}
			};
			var validateTelephonea = (rule, value, callback) => {
				var reg = /\d{3}-\d{8}|\d{4}-\d{7}/;
				if(value != '' && !reg.test(value)) {
					callback(new Error(R.Employee_23));
				} else {
					callback();
				}
			};
			var validateIDCard = (rule, value, callback) => {
				var reg = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;
				if(value != '' && value != null && !reg.test(value)) {
					callback(new Error(R.Employee_105));
				} else {
					callback();
				}
			};
			return {
				purchasePickerOptions: { //日期判断
					disabledDate: (time) => {
						return time.getTime() < Date.now();
					}
				},
				checked:'',
				wxdis:false,
				dddis:false,
				clickId :'',
				wechat:'',
				openBindMask:false,
				mytime:false,
				Topapis: [],
				editpass: null,
				EnableEmployeeAccountNumber: '2',
				selectTwo: false,
				accountLoading: false,
				account: false,
				languageOptions: [{
					value: '1',
					label: R.Employee_65
				}, {
					value: '2',
					label: R.Employee_66
				}],
				accountOptions: [{
					value: '1',
					label: R.Common_Button_Yes
				}, {
					value: '0',
					label: R.Common_Button_No
				}],
				accountOaptions: [{
					value: '2',
					label: R.Employee_67
				}, {
					value: '1',
					label: R.Common_Button_Yes
				}, {
					value: '0',
					label: R.Common_Button_No
				}],
				IsPageDome: true,
				CartridgeLoading: false,
				loadtree: true,
				expandkey: [1],
				sort: 1,
				R: R,
				defaultvalue: [],
				myheight: '580px',
				mycontengheight: '500px',
				isDisabled: false,
				AutoGenerateCode_Employee: false,
				value: false,
				permissionCode: [],
				selection: [],
				OrgIDtext: false,
				rules: {
					password: [{
						validator: validatepassword,
						trigger: 'blur'
					}],
					passwords: [{
						validator: validatetwopassword,
						trigger: 'blur'
					}],
				},
				page: {
					password: '',
					passwords: '',
					validityTime:'',
				},
				ruleForm2: {
					OrgID: null,
					Name: '',
					Code: '',
					sex: '1',
					Email: '',
					Address: '',
					Position: '',
					MobileNo: '',
					IDCard:'',
					Telephone: '',
					Remark: '',
					EnableEmployeeAccount: '1', //是否启用员工账号
					EmployeeAccount: "", //员工账号
					EmployeePwd: "", //员工密码
					EmployeePwds: '',
					DefaultLanguage: '1' ,//默认语言]
					validityTime:'',
				},
				rules2: {
					EmployeeAccount: [{
						trigger: 'blur',
						validator: validateEnableEmployeeAccount,
					}],
					password: [{
						validator: validatepasswords,
						trigger: 'blur'
					}],
					passwords: [{
						validator: validatetwopasswords,
						trigger: 'blur'
					}],
					Code: [{
						validator: validateCode,
						trigger: 'blur'
					}],
					Name: [{
						validator: validateName,
						trigger: 'blur'
					}],
					MobileNo: [{
						required: false,
						validator: validateTelephone,
						trigger: 'blur'
					}],
					IDCard:[{
						required: false,
						validator: validateIDCard,
						trigger: 'blur'
					}],
					Email: [{
						validator: validateEmail,
						trigger: 'blur'
					}],
					sex: [{
						message: R.Employee_24,
						trigger: 'change'
					}],
					OrgID: [{
						validator: validateParentID,
						trigger: 'change'
					}],
					Telephone: [{
						validator: validateTelephonea,
						trigger: 'blur'
					}]
				},
				OrganizationName: '',
				TargetOrgID: null, //变更部门id
				needChange: false,
				OrganizationName: '', //部门
				Employee: '', //人
				change: false,
				EmployeetableColumns: [{
					align: 'center',
					width: 158,
					title: R.Employee_25,
					render: (h, params) => {
						return h('Select', {
							props: {
								value: this.EmployeetableData[params.index].ProcessingMethod,
							},
							on: {
								'on-change': (event) => {
									this.EmployeetableData[params.index].ProcessingMethod = event;
								}
							},
						}, [
							h('Option', {
								props: {
									value: '0'
								}
							}, R.Employee_26),
							h('Option', {
								props: {
									value: '1'
								}
							}, R.Employee_27)
						]);
					},
				}, {
					align: 'center',
					width: 140,
					title: R.Employee_28,
					key: 'Code',
					tooltip: true,

				}, {
					align: 'center',
					width: 140,
					title: R.Employee_29,
					key: 'OriginalCode',
					tooltip: true,
				}, {
					align: 'center',
					width: 160,
					title: R.Employee_30,
					key: 'Name',
					tooltip: true,
				}, {
					align: 'center',
					width: 160,
					tooltip: true,
					title: R.Employee_31,
					key: 'Brand',
				}, {
					align: 'center',
					width: 160,
					tooltip: true,
					title: R.Employee_32,
					key: 'Spec',
				}, {
					align: 'center',
					width: 160,
					tooltip: true,
					title: R.Employee_33,
					key: 'Model',
				}],
				EmployeetableData: [],
				EmployeetableOptions: [{
					value: '0',
					name: ''
				}, {
					value: '1',
					name: ''
				}],
				Topapi: [],
				Allapi: [],
				// 表格高度适配
				screenWidth: document.body.clientWidth,
				screenHeight: document.body.clientHeight,
				// 表格高度
				tableHeight: '',

				QSE: '',
				editId: '',
				search: '',
				urla: '',
				total: 0,
				pagesize: 10,
				passType:[],
				currentPage: 1,
				api: [],
				status: '',
				IsEnabled: true,
				loadingR: false,
				loadingL: false,
				mask: false,
				sccountpass:true,
				addone: false,
				defaultProps: {
					children: 'children',
					label: 'label',
					isLeaf: 'is_leaf'
				},
				tableColumns: [{
						type: 'selection',
						width: 50,
						align: 'center'
					},
					{
						type: 'index',
						width: 55,
						align: 'center',
						tooltip: true,
					},
					{
						align: 'center',
						width: 180,
						title: R.Employee_34,
						key: 'Code',
						tooltip: true,

					}, {
						align: 'center',
						width: 120,
						title: R.Employee_35,
						key: 'Name',
						tooltip: true,
					},
					{
						title: R.Employee_36,
						key: 'OrgName',
						width: 180,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.Employee_37,
						key: 'Position',
						width: 180,
						tooltip: true,
						align: 'center'
					},

					{
						title: R.Employee_38,
						key: 'MobileNo',
						width: 120,
						align: 'center',
						tooltip: true,
					},{
						title: R.Employee_95,
						key: 'IDCard',
						width: 120,
						align: 'center',
						tooltip: true,
					},
					{
						title: R.Employee_39,
						key: 'Telephone',
						width: 120,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.Employee_40,
						key: 'Email',
						width: 160,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.Employee_42,
						key: 'StatusText',
						width: 100,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.Employee_41,
						key: 'Address',
						width: 180,
						tooltip: true,
						align: 'left'
					},
					{
						title: R.Employee_43,
						key: 'Remark',
						width: 160,
						align: 'left',
						tooltip: true,
					},
					{
						title: ' '
					}
				],
				tableData: [],
				data2: [],
				typeOptions: [{
						label: '职位1',
						value: '2'
					},
					{
						label: '职位2',
						value: '1'
					}
				],
				title: R.Employee_44,
				defaultExpandKeys: [],

			};
		},

		methods: {
			changeWX(){
				this.value = false;
				if(!this.isSelectedRowOnlyOne()) {
					return;
				}
				this.clickId = this.selection[0].ID;
				this.wxdis = this.selection[0].WeixinAppOpenID?false:true;
				this.dddis = this.selection[0].DingAppUserID?false:true;
				if(this.wxdis&&this.dddis){
					this.$message.error(R.Employee_98)
					return
				}
				this.mask = true;
				this.openBindMask = true;
			},
			SureUnbind(){
				this.isDisabled = true;
				if(this.checked == '1'){
					this.$http.post('/Employee/UnBind/WeChat', {
					Data: this.clickId
				}).then(res => {
					if(res.data.Code == '0') {
						this.$message.success(res.data.Message)
						this.isDisabled = false;
						this.searchmylist()
						this.closemask()
					} else {
						this.isDisabled = false;
						this.closemask()
					}
				})
				}else if(this.checked == '2'){
					this.$http.post('/Employee/UnBind/DingTalk', {
					Data: this.clickId
				}).then(res => {
					if(res.data.Code == '0') {
						this.$message.success(res.data.Message)
						this.isDisabled = false;
						this.searchmylist()
						this.closemask()
					} else {
						this.isDisabled = false;
						this.closemask()
					}
				})
				}
				
			},
			//插眼保存有效期
			suresetTime(){
				this.isDisabled = true;
				this.$http.post('/Employee/SetEffectiveTime', {
					Data: {
						"Id": this.selection[0].ID, //员工ID
						'EffectiveTime':this.page.validityTime,
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.$message.success(res.data.Message)
						this.isDisabled = false;
						this.searchmylist()
						this.closemask()
					} else {
						this.isDisabled = false;
						this.closemask()
					}
				})
			},
			openTime(){
				if(!this.isSelectedRowOnlyOne()) {
					return;
				}
				if(this.selection[0].EnableEmployeeAccount == false){
					this.$message.error(R.Employee_87)
					return
				}
				
				if(this.selection[0].Status == '2'){
					this.$message.error(R.Employee_83)
					return
				}
				this.CartridgeLoading = false;
				this.value = false
				this.mask = true;
				this.addone = true;
				this.status = 'del';
				this.mytime = true;
			},
			typecancelClear(node, instanceId) {
				if(this.needChange) {
					this.TargetOrgID = null;
					this.$message.error(R.Employee_90)
				}
			},
			typecancel(node) {
				if(node.Type == '2') {
					this.needChange = true;
				} else {
					this.needChange = false;
				}
			},
			changeCode() {
				if(this.status == 'add') {
					if(this.ruleForm2.EnableEmployeeAccount == '1' && this.ruleForm2.Code && (!this.AutoGenerateCode_Employee || this.AutoGenerateCode_Employee == 'False')) {
						if(!this.ruleForm2.EmployeeAccount) {
							this.ruleForm2.EmployeeAccount = this.ruleForm2.Code
						}
					}
				}

			},
			changeNew() {
				if(this.status == 'add') {
					if(this.ruleForm2.EnableEmployeeAccount == '1' && this.ruleForm2.Code && (!this.AutoGenerateCode_Employee || this.AutoGenerateCode_Employee == 'False')) {
						if(!this.ruleForm2.EmployeeAccount) {
							this.ruleForm2.EmployeeAccount = this.ruleForm2.Code
							this.$refs.ruleForm2.clearValidate('EmployeeAccount')
							return
						}
					}
					if(this.ruleForm2.EnableEmployeeAccount == '1' && this.ruleForm2.MobileNo) {
						if(!this.ruleForm2.EmployeeAccount) {
							this.ruleForm2.EmployeeAccount = this.ruleForm2.MobileNo
						}
					}
				}

			},
			sureEnable() {
				this.$refs.ruleForm.validate((valid) => {
					if(!valid) {
						return
					}
					var ids = [];
					this.selection.filter((item) => {
						ids.push(item.ID)
					})
					this.isDisabled = true;
					this.$http.post('/Employee/BatchEnable', {
						Data: {
							"Ids": ids, //员工ID
							"NewPassword": this.page.password, //新密码
							"ConfrimPassword": this.page.passwords, //重复密码	
							'EffectiveTime':this.page.validityTime,
						}
					}).then(res => {
						if(res.data.Code == '0') {
							this.$message.success(res.data.Message)
							this.isDisabled = false;
							this.searchmylist()
							this.closemask()
						} else {
							this.isDisabled = false;
						}
					})
				});
			},
			surechange() {
				this.$refs.ruleForm.validate((valid) => {
					if(!valid) {
						return
					}
					this.isDisabled = true;
					this.$http.post('/Employee/ChangePassword', {
						Data: {
							"ID": this.selection[0].ID, //员工ID
							"NewPassword": this.page.password, //新密码
							"ConfrimPassword": this.page.passwords, //重复密码	
							'EffectiveTime':this.page.validityTime,
						}
					}).then(res => {
						if(res.data.Code == '0') {
							this.$message.success(res.data.Message)
							this.isDisabled = false;
							this.searchmylist()
							this.closemask()
							
						} else {
							this.isDisabled = false;
						}
					})
				});
			},
			openaccount(aa) {
				this.value = false
				this.sccountpass = true;
				if(aa == 'true') {
					this.sccountpass = false;
					if(!this.isSelectedRowOnlyOne()) {
						return;
					}
					this.mask = true;
					this.account = true;
					return;
				}
				if(this.selection.length == 0) {
					this.$message.error(R.Common_Msg_NoneBillSelected)
					return
				}
				var flag = false;
				this.selection.filter((item) => {
					if(item.EnableEmployeeAccount == true ) {
						flag = true;
					}
				})
				if(flag) {
					this.$message.error(R.Employee_80)
					return
				}
				var newflag = false;
				this.selection.filter((item) => {
					if(item.Status == '2') {
						newflag = true;
					}
				})
				if(newflag) {
					this.$message.error(R.Employee_83)
					return
				}
				this.mask = true;
				this.account = true;
				this.selectTwo = true;
			},
			sortableShow(data) {
				if(data.order == "asc") {
					this.sort = 0
				} else if(data.order == "desc") {
					this.sort = 1
				} else {
					this.sort = 1
				}
				this.pageChange()
			},
			showTreeNodeLabel(node, defaultValues) {
				if(node.raw.label) {    // 有label属性，则表示节点数据已经找到
					return node.raw.label;                
				} else {                     // 未找到节点，从defaultValues中查找
					if(defaultValues && defaultValues.length > 0) {      
						return defaultValues;                    
					}                     //没找到
					return '';                
				}                 //
				return node.raw.label;
			},
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {
						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}

			},
			print() {
				if(this.selection.length > 0) {
					var Ids = [];
					for(var i = 0; i < this.selection.length; i++) {
						Ids.push(this.selection[i].ID)
					}
					this.$http.post('DataImport/PrintTag', {
						Data: {
							'Category': 3,
							'IDs': Ids,
						}
					}, {                    
						headers: {                        
							"Content-Disposition": "attachment;filename=users.pdf",
							"Content-Type": "application/octet-stream"                    
						},
						    responseType: 'blob'                
					}).then(rsp => {
						if(!rsp) {                            
							return;                        
						}                        
						let url = window.URL.createObjectURL(new Blob([rsp.data]));                        
						let link = document.createElement('a');    
						if(window.navigator.msSaveBlob) {
							var blobObject = new Blob([rsp.data])
							window.navigator.msSaveBlob(blobObject, R.Employee_15 + '.pdf'); // The user only has the option of clicking the Save button.
							return
						}                     
						link.style.display = 'none';                        
						link.href = url;                        
						link.setAttribute('download', R.Employee_15 + '.pdf');                         //
						document.body.appendChild(link);                        
						link.click();
					}).catch((error) => {
						alert(error)
					});
				} else {
					this.$message({
						message: R.Common_Msg_NoneBillSelected,
						type: 'error'
					});
					return;
				}

			},
			dataImport() {
				let params = {                                        
					Data: {  
						"SortDirection": this.sort,   
						"QST": this.search,
						'QSE': this.QSE,
						'IsShowLeaveJobEmployee': true,
						"EnableEmployeeAccount": this.EnableEmployeeAccountNumber == '2' ? '' : (this.EnableEmployeeAccountNumber == '1' ? true : false)                    
					}                
				}                
				this.$http.post('/Employee/ExportToExcel', params, {                    
					headers: {                        
						"Content-Disposition": "attachment;filename=users.xls",
						"Content-Type": "application/octet-stream"                    
					},
				    responseType: 'blob'                
				}).then((rsp) => {                    
					if(!rsp) {                        
						return;                    
					}                     //
					                    
					let url = window.URL.createObjectURL(new Blob([rsp.data]));                    
					let link = document.createElement('a');   
					if(window.navigator.msSaveBlob) {
						var blobObject = new Blob([rsp.data])
						window.navigator.msSaveBlob(blobObject, R.Employee_16 + '.xls'); // The user only has the option of clicking the Save button.
						return
					}                    
					link.style.display = 'none';                    
					link.href = url;                    
					link.setAttribute('download', R.Employee_16 + '.xls');                     //
					document.body.appendChild(link);                    
					link.click();                
				}).catch((error) => {                    
					alert(error)                
				});

			},
			//离职员工
			surelock() {
				this.isDisabled = true;
				this.$http.post('/Employee/LeaveOffice', {
					Data: this.editId
				}).then(res => {
					if(res.data.Code == '0') {
						this.$message({
							message: res.data.Message,
							type: 'success'
						});
						this.isDisabled = false;
						this.closemask();
						this.pageChange();
					} else {
						this.isDisabled = false;
						
					}
				}).catch((error) => {
					this.isDisabled = false;
				});
			},
			//左侧树懒加载
			loadNode(node, resolve) {
				if(node.level === 0) { //第一级
					this.getOrgByParentID(null, resolve);
				} else {
					this.getOrgByParentID(node.data.id, resolve);
				}
			},
			//所属部门懒加载
			uprogloadOptions2({
				parentNode,
				callback
			}) {
				this.$http.post('system:/Organization/GetTree', {
					Data: {
						Delay: true,
						'onlyloadEnableOrg': true,
						ParentId: parentNode ? parentNode.id : null,
						LoadOrganizationType: 3,
					}
				}).then((rsp) => {
					// 
					if(rsp.data.Code == 0) {
						if(parentNode) { //非根节点
							for(var i = 0; i < rsp.data.Data.length; i++) {
								if(rsp.data.Data[i].Type == '2') {
									rsp.data.Data[i].isDisabled = true;
									rsp.data.Data[i].disabled = true;
								}
							}
							parentNode.children = rsp.data.Data;
						}
						callback();
					} else {
						this.$message.error(rsp.data.Message);
					}
				})
			},
			//所属部门懒加载
			uprogloadOptions({
				parentNode,
				callback
			}) {
				this.$http.post('system:/Organization/GetTree', {
					Data: {
						Delay: true,
						'onlyloadEnableOrg': true,
						ParentId: parentNode ? parentNode.id : null,
						OnlyLoadPrivilegeOrg: false,
						LoadOrganizationType: 2,
					}
				}).then((rsp) => {
					// 
					if(rsp.data.Code == 0) {
						if(parentNode) { //非根节点
							parentNode.children = rsp.data.Data;
						}
						callback();
					} else {
						this.$message.error(rsp.data.Message);
					}
				})
			},
			pageChangeTopTree(id) {
				let param = {
					Data: {
						'ParentId': id,
						"Delay": true,
						'onlyloadEnableOrg': true,
						LoadOrganizationType: 3,
						OnlyLoadPrivilegeOrg: true, //正式应该为true
					}
				}
				this.$http.post('/Organization/GetTree', param).then(data => {
					if(data.data.Code == '0') {
						this.itemsleft = data.data.Data
					} else {
						this.$message.error(data.data.Message)
					}
				})
			},
			// 获取指定的父级公司/部门的ID获取其下级公司/部门
			getOrgByParentID(parentID, resolve) {
				this.loadingL = true
				this.$http.post('/Organization/GetTree', {
					Data: {
						LoadOrganizationType: 3,
						'onlyloadEnableOrg': true,
						OnlyLoadPrivilegeOrg: true, //正式应该为true
						Delay: true,
						ParentId: parentID
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) { //成功
						//
						let items = rsp.data.Data;
						if(parentID == null && rsp.data.Data.length > 0) {
							var arr = [];
							arr.push(rsp.data.Data[0].id)
							this.expandkey = arr
						}
						resolve(items);
					} else {
						this.$message.error(rsp.data.Message)
					}
					this.loadingL = false
				}).catch((error) => {
					this.loadingL = false
				});
			},
			clicktree(node, data) {
				this.$http.post('/Organization/GetById', {
					data: {
						ID: node.id,
					}
				}).then(res => {

					if(res.data.Data.Type == '1') {
						this.ruleForm2.OrgID = node.id;
						this.defaultvalue = res.data.Data.Name == null ? '' : [res.data.Data.Name]
					} else {
						this.defaultvalue = null
						this.ruleForm2.OrgID = null
					}

				})
				this.QSE = data.data.QSE;
				this.searchmylist();
				this.editId = '';
				this.selection = [];
				//				this.$emit('childByValue', this.QSE)
				//				this.clickID = data.data.id;
			},
			searchmylist() {
				this.currentPage = 1;
				this.pageChange();
			},

			pageChange() {
				this.SetPageSize(this.pagesize, 'System/Employee')
				this.loadingR = true
				let params = {
					Data: {
						"QST": this.search,
						'QSE': this.QSE,
						'PageSize': this.pagesize,
						'PageNO': this.currentPage,
						'IsShowLeaveJobEmployee': true,
						SortDirection: this.sort,
						'IsSetNumber': true,
						EnableEmployeeAccount: this.EnableEmployeeAccountNumber == '2' ? '' : (this.EnableEmployeeAccountNumber == '1' ? true : false)   
					}
				}
				this.$http.post('/Employee/List', params).then(data => {
					if(data.data.Code == 0 && data.data.Data.Items != null) {
						this.tableData = data.data.Data.Items;
						this.total = data.data.Data.TotalRecordQty;
						this.currentPage = data.data.Data.PageNO;
						this.pagesize = data.data.Data.PageSize
					} else {
						this.tableData = [];
						this.total = 0;
						this.currentPage = 1;
						this.$message.error(data.data.Message)
					}
					this.editId = '';
					this.Enabled = '';
					this.selection = [];
					this.loadingR = false
				}).catch((error) => {
					this.loadingR = false
				});
			},
			delnessupiler() {
				if(!this.isSelectedRowOnlyOne()) {
					return;
				}
				this.CartridgeLoading = false;
				this.value = false
				this.mask = true;
				this.addone = true;
				this.status = 'del';

			},
			//是否只选择一行
			isSelectedRowOnlyOne() {
				if(this.selection.length == 0) {
					this.$message({
						message: R.Common_Msg_NoneBillSelected,
						type: 'error'
					});
					return false;
				} else if(this.selection.length > 1) {
					this.$message({
						message: R.Common_Msg_OnlyOneCanBeSelected,
						type: 'error'
					});
					return false;
				} else {
					return true;
				}
			},
			//编辑公司部门
			editorClick() {
				if(!this.isSelectedRowOnlyOne()) {
					return;
				}
				this.value = false
				var params = {
					token: this.$Store.get('token'),
					Data: this.editId
				}
				this.CartridgeLoading = true;
				this.mask = true;
				this.addone = true;
				this.status = 'edited';
				this.$http.post('/Employee/GetById', params).then(res => {
					if(res.data.Code == '0') {
						this.ruleForm2.Name = res.data.Data.Name,
						this.ruleForm2.sex = res.data.Data.Sex == null ? '1' : res.data.Data.Sex.toString();
						this.ruleForm2.OrgID = res.data.Data.OrgID;
						this.ruleForm2.IDCard = res.data.Data.IDCard;
						this.ruleForm2.Position = res.data.Data.JobIds;
						this.ruleForm2.MobileNo = res.data.Data.MobileNo == null ? '' : res.data.Data.MobileNo;
						this.ruleForm2.Telephone = res.data.Data.Telephone == null ? '' : res.data.Data.Telephone;
						this.ruleForm2.Email = res.data.Data.Email == null ? '' : res.data.Data.Email;
						this.ruleForm2.Code = res.data.Data.Code;
						this.ruleForm2.Address = res.data.Data.Address;
						this.OrganizationName = res.data.Data.OrganizationName;
						this.ruleForm2.Remark = res.data.Data.Remark;
						this.ruleForm2.EnableEmployeeAccount = res.data.Data.EnableEmployeeAccount ? '1' : '0';
						this.editpass = res.data.Data.EnableEmployeeAccount ? '1' : '0';
						this.ruleForm2.EmployeeAccount = res.data.Data.EmployeeAccount;
						this.ruleForm2.DefaultLanguage = res.data.Data.DefaultLanguage == null ? '1' : res.data.Data.DefaultLanguage.toString();
						this.ruleForm2.validityTime = res.data.Data.EffectiveTime
						this.CartridgeLoading = false;
					} else {
						
					}
					this.CartridgeLoading = false;
				}).catch((error) => {
					this.CartridgeLoading = false;
				});

			},

			changeEmployee() {
				this.CartridgeLoading = true;
				if(!this.isSelectedRowOnlyOne()) {
					return;
				}
				this.value = false
				var params = {
					Data: this.editId
				}
				this.mask = true;
				this.change = true;
				this.$http.post('/Employee/ChangeDept/Query', params).then(res => {

					if(res.data.Code == '0') {
						this.OrganizationName = res.data.Data.OrganizationName;
						this.Employee = res.data.Data.Name;
						this.EmployeetableData = res.data.Data.Assets
						this.EmployeetableData.filter((val) => {
							val.ProcessingMethod = null
						})

					} else {
						
					}
					this.CartridgeLoading = false;
				}).catch((error) => {
					this.CartridgeLoading = false;
				});
			},
			lock() {
				if(!this.isSelectedRowOnlyOne()) {
					return;
				}
				this.value = false
				this.mask = true;
				this.addone = true;
				this.status = 'lock';
			},
			addnessupiler() {
				this.mask = true;
				this.addone = true;
				this.status = 'add';
				this.CartridgeLoading = false;
			},
			suredel() {
				this.isDisabled = true;
				var params = {
					data: this.editId,
				}
				this.$http.post('/Employee/Delete', params).then(res => {
					if(res.data.Code == '0') {
						this.$message({
							message: res.data.Message,
							type: 'success'
						});
						this.searchmylist();
						this.mask = false;
						this.addone = false;
						this.isDisabled = false;
					} else {
						this.isDisabled = false;
						this.mask = false;
						this.addone = false;
						
					}
				}).catch((error) => {
					this.isDisabled = false;
					this.mask = false;
					this.addone = false;
				});

			},
			closemask(ruleForm2) {
				this.mask = false;
				this.account = false;
				this.selectTwo = false;
				this.accountLoading = false;
				this.addone = false;
				this.CartridgeLoading = false;
				this.ruleForm2.OrgID = null;
				this.change = false;
				this.TargetOrgID = null;
				this.ruleForm2.Name = ''
				this.ruleForm2.sex = '1';
				this.ruleForm2.OrgID = null;
				this.ruleForm2.Position = ''
				this.ruleForm2.MobileNo = ''
				this.ruleForm2.IDCard = ''
				this.ruleForm2.Telephone = ''
				this.ruleForm2.Email = ''
				this.ruleForm2.Code = ''
				this.ruleForm2.Address = ''
				this.ruleForm2.validityTime = ''
				this.OrganizationName = ''
				this.ruleForm2.Remark = ''
				this.page.password = '';
				this.page.passwords = '';
				this.page.validityTime = ''
				this.isDisabled = false;
				this.ruleForm2.EnableEmployeeAccount = '1';
				this.ruleForm2.EmployeeAccount = "";
				this.ruleForm2.EmployeePwd = "";
				this.ruleForm2.EmployeePwds = '';
				this.editpass = null;
				this.ruleForm2.DefaultLanguage = '1';
				this.mytime = false;
				this.clickId = false;
				this.wechat = false;
				this.checked = '';
			},
			uodateChangeEmployee() {
				if(!this.TargetOrgID) {
					this.$message.error(R.Employee_58)
					return
				}
				var Assets = [];
				for(var i = 0; i < this.EmployeetableData.length; i++) {
					if(this.EmployeetableData[i].ProcessingMethod == null) {
						this.$message.error(R.Employee_59)
						return false;
					}
					Assets.push({
						"ID": this.EmployeetableData[i].ID,
						"ProcessingMethod": this.EmployeetableData[i].ProcessingMethod,
					})
				}
				var params = {
					Data: {
						"ID": this.editId,
						"TargetOrgID": this.TargetOrgID,
						"Assets": Assets
					}
				};
				this.CartridgeLoading = true;
				this.$http.post('/Employee/ChangeDept/Save', params).then(res => {
					if(res.data.Code == '0') {
						this.$message({
							message: res.data.Message,
							type: 'success'
						});
						this.closemask();
						this.searchmylist();
					} else {
						
					}
					this.CartridgeLoading = false;
				}).catch((error) => {
					this.CartridgeLoading = false;
				});
			},
			resert(formName) {
				this.$refs[formName].resetFields();
				this.ruleForm2.OrgID = null;
				this.ruleForm2.Name = '';
				this.ruleForm2.sex = '1';
				this.ruleForm2.Code = '';
				this.ruleForm2.validityTime =''
			},
			//table 取消选择
			selectcancel(selection, row) {
				if(selection.length >= 1) {
					this.editId = selection[selection.length - 1].ID;

				} else {
					this.editId = '';
				}
			},
			//table 选中
			selectionChange(selection) {
				this.selection = selection;
				if(selection.length > 0) {
					this.editId = selection[0].ID;
					this.Enabled = selection[0].IsEnabled;
					this.clickQse = selection[0].QSE;
				} else {
					this.editId = '';
					this.Enabled = '';
					this.clickQse = ''
				}

			},
			getAssetDicSettings() {
				var systemDicSettings = JSON.parse(this.$Store.get('SystemDicSettings'));
				if(systemDicSettings != null) {
					this.AutoGenerateCode_Employee = systemDicSettings[1].Value == 'False' || systemDicSettings[1].Value == false ? false : true
				}
			},
			handleSizeChange(val) {
				this.pagesize = val
				this.currentPage = 1;
				this.pageChange();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.pageChange();
			},
			updateUser(formName) {
				this.$refs[formName].validate((valid) => {		
					var reg = /^[1][3,4,5,7,8,9,6][0-9]{9}$/;
					if(this.ruleForm2.MobileNo && !reg.test(this.ruleForm2.MobileNo)) {
						this.$message.error(R.Employee_18);
						return;
					}

					var reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
					if(this.ruleForm2.Email && !reg.test(this.ruleForm2.Email)) {
						this.$message.error(R.Employee_19);
						return;
					}

					if(valid && this.ruleForm2.OrgID) {
						this.isDisabled = true;
						let params = {
							Data: {
								ID: this.editId,
								"Code": this.ruleForm2.Code.trim(),
								"Name": this.ruleForm2.Name,
								"Sex": this.ruleForm2.sex,
								"OrgID": this.ruleForm2.OrgID,
								"Position": this.ruleForm2.Position,
								"MobileNo": this.ruleForm2.MobileNo,
								"Telephone": this.ruleForm2.Telephone,
								"Email": this.ruleForm2.Email,
								"Address": this.ruleForm2.Address,
								"Remark": this.ruleForm2.Remark,
								"EnableEmployeeAccount": this.ruleForm2.EnableEmployeeAccount == '1' ? true : false, //是否启用员工账号
								"EmployeeAccount": this.ruleForm2.EmployeeAccount, //员工账号
								"EmployeePwd": this.ruleForm2.EmployeePwd, //员工密码
								"DefaultLanguage": this.ruleForm2.DefaultLanguage ,//默认语言
								'EffectiveTime':this.ruleForm2.validityTime,
								'IDCard': this.ruleForm2.IDCard
							}
						}
						this.CartridgeLoading = true;
						if(this.status == 'add') {
							this.$http.post('/Employee/Add', params).then(res => {
								if(res.data.Code == '0') {
									this.$message({
										message: res.data.Message,
										type: 'success'
									});
									//										this.loadtree = false;
									//										setTimeout(() => {
									//											this.loadtree = true;
									//										}, 10)
									this.closemask();
									this.searchmylist();
									this.mask = false;
									this.addone = false;
									this.isDisabled = false;
									this.ruleForm2.OrgID = null;
								} else {
									if(res.data.Code == '-399') {
										
										this.$http.post('/Setting/All').then((rsp) => {
											if(rsp.data.Code === 0) {
												let dic = rsp.data.Data;
												if(dic != null && dic.length > 0) {
													var SystemDicSettings = {}
													for(let i = 0; i < dic.length; i++) {
														SystemDicSettings[dic[i].Key] = dic[i].Value
													}
													this.$Store.set('SystemDicSettings', JSON.stringify(SystemDicSettings));
													this.getAssetDicSettings();
												} else {
													this.$Store.set('SystemDicSettings', JSON.stringify([]));
													this.getAssetDicSettings();
												}
												this.isDisabled = false;
											} else {
												this.isDisabled = false;
											}
										});
									} else {
										this.isDisabled = false;
										
									}

								}
								this.CartridgeLoading = false;
							}).catch((error) => {
								this.isDisabled = false;
								this.CartridgeLoading = false;
							});

						} else {
							this.$http.post('/Employee/Edit', params).then(res => {
								if(res.data.Code == '0') {
									this.$message({
										message: res.data.Message,
										type: 'success'
									});
									//										this.loadtree = false;
									//										setTimeout(() => {
									//											this.loadtree = true;
									//										}, 10)
									this.closemask();
									this.pageChange();
									this.mask = false;
									this.addone = false;
									this.isDisabled = false;
									this.ruleForm2.OrgID = null;
								} else {
									if(res.data.Code == '-399') {
										
										this.$http.post('/Setting/All').then((rsp) => {
											if(rsp.data.Code === 0) {
												let dic = rsp.data.Data;
												if(dic != null && dic.length > 0) {
													var SystemDicSettings = {}
													for(let i = 0; i < dic.length; i++) {
														SystemDicSettings[dic[i].Key] = dic[i].Value
													}
													this.$Store.set('SystemDicSettings', JSON.stringify(SystemDicSettings));
													this.getAssetDicSettings();
												} else {
													this.isDisabled = false;
													this.$Store.set('SystemDicSettings', JSON.stringify([]));
													this.getAssetDicSettings();
												}
												this.isDisabled = false;
											} else {
												
												this.isDisabled = false;
											}
										});
									} else {
										this.$message({
											message: res.data.Message,
											type: 'error'
										});
										this.isDisabled = false;
									}
								}
								this.CartridgeLoading = false;
							}).catch((error) => {
								this.isDisabled = false;
								this.CartridgeLoading = false;
							});
						}

					} else {
						return false;
					}
				});

			}
		},

		watch: {
			screenWidth(val) {
				this.tableHeight = this.$refs.table.offsetHeight;

			},
			screenHeight(val) {
				this.tableHeight = this.$refs.table.offsetHeight;
			},
		},
		beforeUpdate() {
			const that = this
			window.onresize = () => {
				return(() => {
					window.screenWidth = document.body.clientWidth
					window.screenHeight = document.body.clientHeight
					that.screenWidth = window.screenWidth
					that.screenHeight = window.screenHeight
				})()
			}
			if(document.body.clientWidth < 1300) {
				this.myheight = '520px';
				this.mycontengheight = '430px'
			} else if(document.body.clientWidth < 1500 && document.body.clientWidth > 1300) {
				this.myheight = '530px'
				this.mycontengheight = '440px'
			} else {
				this.myheight = '580px'
				this.mycontengheight = '490px'
			}
			this.tableHeight = this.$refs.table.offsetHeight;
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_Employee')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted() {
			this.EmployeetableOptions = [{
				value: 0,
				name: R.Employee_26
			}, {
				value: 1,
				name: R.Employee_27
			}]
			this.EmployeetableColumns = [{
				align: 'center',
				width: 158,
				title: R.Employee_25,
				render: (h, params) => {
					return h('el-select', {
						props: {
							value: params.row.ProcessingMethod,
							placeholder: R.Employee_25,
						},
						on: {
							'change': (event) => {
								this.EmployeetableData[params.index].ProcessingMethod = event;
								params.row.ProcessingMethod = event;
							}
						}
					}, this.EmployeetableOptions.map(function(item) {
						// console.log(item)
						return h('el-option', {
							props: {
								value: item.value,
								label: item.name,
							}
						}, item);
					}));
				},
			}, {
				align: 'center',
				width: 180,
				title: R.Employee_28,
				key: 'Code',
				tooltip: true,
			}, {
				align: 'center',
				width: 180,
				title: R.Employee_29,
				key: 'OriginalCode',
				tooltip: true,
			}, {
				align: 'center',
				width: 160,
				title: R.Employee_30,
				key: 'Name',
				tooltip: true,
			}, {
				align: 'left',
				width: 140,
				tooltip: true,
				title: R.Employee_31,
				key: 'Brand',
			}, {
				align: 'left',
				width: 140,
				tooltip: true,
				title: R.Employee_32,
				key: 'Spec',
			}, {
				align: 'left',
				width: 140,
				tooltip: true,
				title: R.Employee_33,
				key: 'Model',
			}];

			tableColumns: [{
					type: 'selection',
					width: 30,
					align: 'center'
				},
				{
					type: 'index',
					width: 50,
					align: 'center',
					tooltip: true,
				},
				{
					align: 'left',
					width: 150,
					title: R.Employee_34,
					key: 'Code',
					tooltip: true,
					sortable: true,							
				}, {
					align: 'left',
					width: 110,
					title: R.Employee_35,
					key: 'Name',
					tooltip: true,
				},
				{
					title: R.Employee_36,
					key: 'OrgName',
					width: 160,
					tooltip: true,
					align: 'left'
				},
				{
					title: R.Employee_37,
					key: 'Position',
					width: 180,
					tooltip: true,
					align: 'left'
				},

				{
					title: R.Employee_38,
					key: 'MobileNo',
					width: 120,
					align: 'left',
					tooltip: true,
				},
				{
					title: R.Employee_95,
					key: 'IDCard',
					width: 120,
					align: 'left',
					tooltip: true,
				},
				{
					title: R.Employee_39,
					key: 'Telephone',
					width: 120,
					tooltip: true,
					align: 'left'
				},
				{
					title: R.Employee_40,
					key: 'Email',
					width: 120,
					tooltip: true,
					align: 'left'
				},
				{
					title: R.Employee_42,
					key: 'StatusText',
					width: 100,
					tooltip: true,
					align: 'left'
				},
				{
					title: R.Employee_41,
					key: 'Address',
					width: 140,
					tooltip: true,
					align: 'left'
				},
				{
					title: R.Employee_43,
					key: 'Remark',
					minWidth: 180,
					maxWidth:400,
					align: 'left',
					tooltip: true,
				},
			];
			this.tableColumns = [{
					type: 'selection',
					width: 30,
					align: 'center'
				},
				{
					type: 'index',
					width: 50,
					align: 'center',
					tooltip: true,
				},
				{
					align: 'left',
					width: 150,
					title: R.Employee_34,
					key: 'Code',
					tooltip: true,
					sortable: true,
				}, {
					align: 'left',
					width: 120,
					title: R.Employee_35,
					key: 'Name',
					tooltip: true,
				},
				{
					title: R.Employee_36,
					key: 'OrgName',
					width: 160,
					tooltip: true,
					align: 'left',
				},
				{
					title: R.Employee_37,
					key: 'Position',
					width: 180,
					tooltip: true,
					align: 'left',
				},
				{
					title: R.Employee_42,
					key: 'StatusText',
					width: 100,
					tooltip: true,
					align: 'center',
				},
				{
					title: R.Employee_38,
					key: 'MobileNo',
					width: 120,
					align: 'left',
					tooltip: true,
				},{
					title: R.Employee_95,
					key: 'IDCard',
					width: 120,
					align: 'left',
					tooltip: true,
				},
				{
					title: R.Employee_60,
					key: 'EnableEmployeeAccountText',
					width: 120,
					align: 'center',
					tooltip: true,
				},
				{
					title: R.Employee_61,
					key: 'EmployeeAccount',
					width: 140,
					align: 'left',
					tooltip: true,
				},
				{
					title: R.Employee_88,
					key: 'WeixinAppOpenID',
					width: 100,
					align: 'left',
					tooltip: true,
					render: (h, params) => {
						if(params.row.WeixinAppOpenID) {
								return h('div', [
									h('span', {
										class:'iconfont icon-weixin-dis',
										style:{
											color:'#07b906'
										}

									}),
								]);
							} else {
								return h('div', [
									h('span', {
										class:'iconfont icon-weixin-dis',
										style:{
											color:'#666666'
										}
									})
								]);
							}

						},
				},
				{
					title: R.Employee_89,
					key: 'DingAppUserID',
					width: 100,
					align: 'left',
					tooltip: true,
					render: (h, params) => {
						if(params.row.DingAppUserID) {
								return h('div', [
									h('Icon', {
										class:'icon iconfont icon-dingding',
										style:{
											color:'#3296fa'
										}
									}),
								]);
							} else {
								return h('div', [
									h('Icon', {
										class:'icon iconfont icon-dingding1',
										style:{
											color:'#666666'
										}
									})
								]);
							}

						},
				},
				//插眼 有效期
				{
					title: R.Employee_84,
					key: 'EffectiveTime',
					width: 120,
					align: 'left',
					tooltip: true,
				},
				{
					title: R.Employee_39,
					key: 'Telephone',
					width: 120,
					tooltip: true,
					align: 'left',
				},
				{
					title: R.Employee_40,
					key: 'Email',
					width: 140,
					tooltip: true,
					align: 'left',
				},

				{
					title: R.Employee_41,
					key: 'Address',
					width: 140,
					tooltip: true,
					align: 'left',
				},
				{
					title: R.Employee_43,
					key: 'Remark',
					minWidth: 180,
					maxWidth:400,
					align: 'left',
					tooltip: true,
				},
			];
			this.rules2.sex = [{
				message: R.Employee_24,
				trigger: 'change'
			}]

			this.getAssetDicSettings()
			let param = {
				Data: {
					LoadOrganizationType: 3,
					OnlyLoadPrivilegeOrg: true, //正式应该为true
					'ParentId': '',
					"Delay": true,
					'onlyloadEnableOrg': true,
					OnlyLoadOrganization:true,
				}
			}
			this.$http.post('/Organization/GetTree', param).then(data => {
				if(data.data.Code == '0') {
					this.Topapi = data.data.Data
				} else {
					this.$message.error(data.data.Message)
				}
			}).catch((err) => {

			})
			this.pagesize = Number(this.GetPageSize('System/Employee'))
			this.pageChange();
			//获取表头元数据
			document.querySelectorAll('.ivu-checkbox-wrapper')[0].style.display = 'none';
			this.$PermissionUtil.getPermission('System/Employee').then((res)=>{
				this.permissionCode = res;
			});
			this.$http.post('system:/Dictionary/GetByCode', {    
				Data: "D007"
			}).then(res => {
				if(res.data.Code == '0') {
					var opt = res.data.Data.Items == null ? [] : res.data.Data.Items;
					this.typeOptions = opt
				} else {
					
				}
			});
			this.passType = JSON.parse(this.$Store.get('passType'));
			
		}
	};
</script>

<style lang='scss'>
	#employee {
		// .el-form-item.is-required .el-form-item__label:before{
		// 	display: none;
		// }
		.el-form-item{
			.vue-treeselect{
				width: 220px!important;
			}
		}
		.myselecti {
			.el-input__inner {
				height: 30px!important;
				line-height: 30px
			}
		}
		.el-button+.el-button {
			margin-left: 0px;
		}
		.el-button {
			margin-right: 5px;
		}
		.titleall {
			height: 40px;
			line-height: 40px;
			background-color: #EFF1F1;
			text-align: center;
			margin-bottom: 10px;
			font-size: 14px;
			border-right: 1px solid #eee;
			font-family: FZLTHK--GBK1-0;
			font-weight: bold;
			color: rgba(123, 128, 130, 1);
		}
		.rightfoot {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 60px;
			padding-left: 15px;
			padding-top: 14px;
			background-color: #EFF1F1;
		}
		.mytable {
			position: absolute;
			top: 45px;
			bottom: 78px;
			left: 0px;
			right: 0px;
			overflow: hidden;
		}
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
			.addone {
				height: 595px;
				width: 500px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					padding-top: 15px;
					padding-left: 20px;
					height: 465px;
					padding-right: 20px;
					overflow: auto;
					.el-form-item__content{
						>.el-input {
							height: 30px;
							width: 220px;
						}	
						>.el-select {
							width: 220px;
						}
					}
					.orangetext {
						color: #fa905d;
						margin-left: 5px;
						margin-right: 5px;
					}
					>p {
						margin-bottom: 15px;
						display: flex;
						align-items: center;
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 18px;
					float: right;
					margin-right: 10px;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
			.changeAsset {
				height: 540px;
				width: 800px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background-color: white;
				.head {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					z-index: 2;
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					position: absolute;
					top: 40px;
					left: 0;
					right: 0;
					bottom: 55px;
					overflow-y: auto;
					padding-top: 5px;
					padding-left: 15px;
					padding-right: 15px;
					box-sizing: border-box;
					.el-form-item__content{
						>.el-input {
							height: 30px;
							width: 220px;
						}	
						>.el-select {
							width: 220px;
						}
					}
					
					.ivu-table-cell {
						overflow: visible;
						.el-input {
							height: 30px;
							width: 160px;
							line-height: 30px;
						}
					}
					.el-input__inner {
						height: 30px;
						line-height: 30px;
					}
					.red {
						color: red;
					}
					.el-textarea {
						width: 220px
					}
					p {
						margin-bottom: 5px;
					}
				}
				.vue-treeselect {
					width: 220px;
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 15px;
					position: absolute;
					bottom: 10px;
					right: 15px;
					.el-button {
						height: 30px!important;
						margin-right: 0;
						margin-left: 5px;
					}
				}
			}
			.del {
				width: 400px;
				height: 190px!important;
				.contentdel {
					padding-top: 40px;
					padding-left: 80px;
					margin-bottom: 20px;
					i {
						color: deepskyblue;
						margin-right: 10px;
						font-size: 16px;
					}
				}
				.contentdels {
					padding-top: 40px;
					padding-left: 50px;
					margin-bottom: 20px;
				}
				.contentdelsa {
					padding-top: 20px;
					padding-left: 50px;
					margin-bottom: 20px;
					i {
						color: deepskyblue;
						margin-right: 10px;
						font-size: 16px;
					}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					margin-top: 25px;
					float: right;
					margin-right: 10px;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
			.account {
				width: 455px;
				height: 280px!important;
				.contentdel {
					p {
						text-align: center;
						margin-bottom: 10px;
					}
					.form {
						margin-top: 20px;
					}
					padding-top: 20px;
					margin-bottom: 10px;
				}
				.foot {
					position: absolute;
					right: 0px;
					bottom: 10px;
					.el-button {
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
			}
		}
		.left {
			width: 300px;
			float: left;
			background-color: white;
			height: 100%;
			padding: 0;
			position: relative;
			overflow: auto;
		}
		.right {
			padding: 15px;
			position: absolute;
			left: 315px;
			right: 0px;
			top: 0;
			bottom: 0;
			background-color: white;
			height: 100%;
			padding: 0px;
			.clickbtn {
				margin-bottom: 15px;
				height: 30px;
				.el-input {
					display: inline-block;
					// float: right;
					width: 220px;
					i {
						position: relative;
						cursor: pointer;
					}
				}
				.el-input__inner {
					height: 30px;
					line-height: 30px;
					font-size: 14px;
					position: relative;
				}
				.myselecti {
					.el-input__inner {
						height: 30px!important;
						line-height: 30px
					}
					.el-input {
						width: 150px;
					}
				}
			}
		}
	}
</style>