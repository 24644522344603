<template>
	<div class="expand">
		<div class="mask" v-if="mask">
			<div :class=" liststatus=='del'?' addlistsa del':'addlistsa'"   v-loading="CartridgeLoading" v-if="addlist">
				<div class="head">
					<span class="title" v-if="liststatus=='add'">{{R.Common_Button_Add}}{{title}}</span>
					<span class="title" v-if="liststatus=='edited'">{{R.Common_Button_Edit}}{{title}}</span>
					<span class="title" v-if="liststatus=='del'">{{R.Common_Button_Delete}}{{title}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content" v-if="liststatus!='del'">
					<el-form :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
						<el-form-item class="roapeitem" :label="R.DicTree_4"  prop="NameCN">
							<el-input maxlength="50" :placeholder="R.DicTree_5" v-model="ruleForm2.NameCN"></el-input>
							<span class="red">*</span>
						</el-form-item>
						<el-form-item  class="roapeitem"  :label="R.DicTree_6"  prop="NameEN">
							<el-input maxlength="50"  :placeholder="R.DicTree_16" v-model="ruleForm2.NameEN"></el-input>
							<span class="red">*</span>
						</el-form-item>
						
					</el-form>
				</div>
				<div class="foot" v-if="liststatus!='del'">
					<el-button @click="sureupdate" class="orangebd">{{R.Common_Button_Save}}</el-button>
					<el-button @click='closemask'>{{R.DicTree_17}}</el-button>
				</div>
				<div class="contentdel" v-if="liststatus=='del'">
					<i class="el-icon-info"></i>{{R.DicTree_10}}
				</div>
				<div class="foot" v-if="liststatus=='del'">
					<el-button @click="suredel" class="orangebd">{{R.DicTree_11}}</el-button>
					<el-button @click='closemask'>{{R.DicTree_17}}</el-button>
				</div>
			</div>
		</div>
		<!--默认高亮 未添加-->
		<div>
			<div class="addnew" @click="append('data')" v-if="title">{{R.DicTree_13}}{{title}}</div>
		</div>
		<el-tree ref="tree" class="" :expand-on-click-node="false"  :show-checkbox="checkbox" highlight-current show :data="api" node-key="Id" :props='defaultProps' default-expand-all @node-click="clicktree">
			<span class="custom-tree-node" slot-scope="{ node, data }">
				   <span v-if="noclick&&(node.label==openName)" class="mytreelistitem myadd" :title="node.label">{{ node.label }}</span>
				   <span v-if="!noclick||(node.label!=openName)" class="mytreelistitem" :title="node.label">{{ node.label }}<span class="islocked" v-if="data.islocked">&ensp;&ensp;({{}})</span></span>
			<span class="btns">
		            <i class="el-icon-plus" v-if="data.Key=='UserDefined'&&node.childNodes" @click.stop="append(node,data)"></i>
		            <i class="el-icon-edit-outline" v-if="data.Category=='2'"  @click.stop="eddit(node, data)"></i>
		            <i class="el-icon-delete"  v-if="data.Category=='2'" @click.stop="remove(node, data)"></i>
		    </span>
			</span>
		</el-tree>
	</div>
</template>
<!-- VUE饿了么树形控件添加增删改功能按钮 -->
<script>
	var R = {};
	export default {
		props: [
			//'defaultcheckedkeys',
			'styleaa',
			'highcurrent',
			'checkbox',
			'refsa',
			'title',
			'url',
			'api',
			'openName',
		],
		name: 'cjtree',
		data() {
			var validateCode = (rule, value, callback) => {
					if(value == '') {
						callback(new Error(R.DicTree_14));
					} else {
						callback();
					}

				};
			var validateNameCN = (rule, value, callback) => {
					if(value == '') {
						callback(new Error(R.DicTree_15));
					} else {
						callback();
					}

			};
			var validateNameEN = (rule, value, callback) => {
					if(value == '') {
						callback(new Error(R.DicTree_15));
					} else {
						callback();
					}

			};
			return {
				CartridgeLoading:false,
				R:R,
				defaultProps: {
					children: 'Children',
					label: 'Name'
				},
				liststatus: '',
				rules2: {
						Code: [{
							validator: validateCode,
							trigger: 'blur'
						}],
						NameCN: [{
							validator: validateNameCN,
							trigger: 'blur'
						}],
						NameEN: [{
							validator: validateNameEN,
							trigger: 'blur'
						}],
					},
				ruleForm2:{
					Code: '',
					NameCN: '',
					NameEN: '',
				},
				addlistid: '',
				mask: false,
				addlist: false,
				ParentID: '',
				QSE: '',
				clickID: '',
				clickparentKey: '',
				defaultExpandKeys: [],
				checkedCities: [],
				noclick:true,
			}
		},
		beforeCreate(){
			R = this.getSelfLanguage('SystemControl', 'selector')
		 },
		mounted() {
		},
		methods: {
			handleCheckAllChange(val, data) {
				data.checkedCities = val ? data.role : [];
				data.isIndeterminate = false;
			},
			handleCheckedCitiesChange(data, value) {
				let checkedCount = value.length;
				data.checkAll = checkedCount === data.role.length;
				data.isIndeterminate = checkedCount > 0 && checkedCount < data.role.length;
			},
			checkchange(data, node) {
				//				for(var i =0;i<data.role.length;i++){
				//					data.role[i].checked =true
				//				}

			},
			clicktree(node, data) {
				if(node.ID!=='1'){
					this.noclick = false
				}
				if(node.Children) {
					return false;
				} else {
					this.QSE = data.data.ID;
					this.clickparentKey = data.parent.data.Key;
					this.$emit('childByValue', this.QSE)
					this.$emit('clickparentKey', this.clickparentKey)
					//				this.clickID = data.data.ID;
					//				// console.log(this.clickID)
				}

			},
			closemask() {
				this.mask = false;
				this.addlist = false;
			},
			append(data, node) {
				this.ruleForm2.NameCN=''
				this.ruleForm2.Code=''
				this.listcode = '';
				this.ruleForm2.NameEN = '';
				this.mask = true;
				this.addlist = true;
				this.liststatus = "add";
				this.addlistid = '';
				this.ParentID = data.ParentID ? data.ParentID : this.clickID;
			},
			remove(node, data) {
				this.mask = true;
				this.addlist = true;
				this.liststatus = "del";
				this.addlistid = data.ID
			},
			eddit(node, data) {
				this.addlistid = data.ID
				var params = {
					Data: this.addlistid
				}
				this.mask = true;
					this.addlist = true;
					this.liststatus = "edited";
				this.CartridgeLoading=true;
				this.$http.post(this.url + '/GetById', params).then(res => {
					if(res.data.Code=='0'){
						this.ruleForm2.NameEN = res.data.Data.NameEN;
					this.ruleForm2.NameCN = res.data.Data.NameCN
					this.ruleForm2.Code = res.data.Data.Code
					
					}else{
						
					}
					this.CartridgeLoading=false;
				}).catch(()=>{
					this.CartridgeLoading=false;
				})

			},
			suredel() {
				var params = {
					data: {
						ID: this.addlistid,
					}
				}
				this.$http.post(this.url + '/Delete', params).then(res => {
					if(res.data.Code == '0') {
						this.$message({
							message: res.data.Message,
							type: 'success'
						});
					 this.$parent.treepage();
					}else{
						
					}
				})
				this.mask = false;
				this.addlist = false;
				this.ruleForm2.NameEN = '';
				this.ruleForm2.Code = '';
				this.ruleForm2.NameCN = '';
				this.$parent.pageChange();
			},
			sureupdate() {
			this.$refs.ruleForm2.validate((valid) => {
			if(valid) {
				var params = {
					data: {
						ID: this.addlistid,
						NameEN: this.ruleForm2.NameEN,
						NameCN: this.ruleForm2.NameCN,
					}
				}
				this.CartridgeLoading=true;
				if(this.liststatus == 'add') {
					this.$http.post(this.url + '/Add', params).then(res => {
						if(res.data.Code == '0') {
							this.$message({
								message: res.data.Message,
								type: 'success'
							});
							this.$parent.treepage();
							this.mask = false;
							this.addlist = false;
							this.addlistid = '';
							this.liststatus = '';
							this.ruleForm2.NameEN = '';
							this.ruleForm2.Code = '';
							this.ruleForm2.NameCN = '';
							this.$parent.treepage();
						}else{
							
						}
						this.CartridgeLoading=false;
					}).catch(()=>{
						this.CartridgeLoading=false;
					})
				} else {
					this.$http.post(this.url + '/Edit', params).then(res => {
						if(res.data.Code == '0') {
							this.$message({
								message: res.data.Message,
								type: 'success'
							});
							this.$parent.treepage();
							this.mask = false;
							this.addlist = false;
							this.addlistid = '';
							this.liststatus = '';
							this.ruleForm2.NameEN = '';
							this.ruleForm2.Code = '';
							this.ruleForm2.NameCN = '';
							this.$parent.treepage();
						} else{
							
						}
						this.CartridgeLoading=false;
					}).catch(()=>{
						this.CartridgeLoading=false;
					})
				}
				
//				this.$parent.pageChange();								
				} else {
						return false;
					}
				});
			},
		}

	}
</script>

<style lang="scss">
	.expand {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		.el-tree{
			position: relative!important;
		}
		.islocked {
			color: #FF5402;
		}
		.myadd{
			background-color: #FF5402 !important;
			color: white!important;
		}
		.myrole {
			position: absolute;
			left: 13rem;
			.label.el-checkbox {
				width: 80px;
			}
			.roleitem {
				display: -moz-inline-box;
				display: inline-block;
				width: 115px;
			}
			.allselect {
				margin-right: 40px;
			}
		}
		.el-tree-node__content {
			height: 32px;
			.custom-tree-node .mytreelistitem {
				padding: 5px 16px;
				box-sizing: border-box;
				max-width: 9rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				border-radius: 4px;
				.el-checkbox {
					margin-right: 10px;
				}
			}
		}
		width: 100%;
		height: 98%;
		overflow: auto;
		background-color: white;
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 10000;
			.addlistsa {
				width: 450px;
				height: 200px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background-color: white;
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					.el-form-item__label{
						width: 136px!important;
					}
					.el-form-item__error{
						left:211px;
					}
					padding-left: 10px;
					margin-top: 20px;
						.red {
							color: red;
						}
						.el-input {
							height: 30px;
							width: 160px;
						}
						.el-input__inner {
							height: 30px;
						}
				}
				.foot {
					height: 30px;
					line-height: 30px;
					position: absolute;
					bottom: 15px;
					right: 15px;
					float: right;
			
					.el-button {
						height: 30px!important;
					}
				}
				.contentdel {
					padding-top: 50px;
					padding-left: 100px;
					margin-bottom: 20px;
					i {
						color: deepskyblue;
						margin-right: 10px;
						font-size: 16px;
					}
				}
			}
			.del{
				height: 200px;
			}
		}
		.expand .el-tree {
		    width: 100%;
		    position: absolute;
		    top: 40px;
		    left: 0px;
		    right: 0;
		    bottom: 30px;
		    overflow: auto;
		}
		.custom-tree-node {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			padding-right: 8px;
		}
		
		.yy {
			margin-top: 20px;
		}
		.myinput .el-input {
			width: 150px;
		}
		.myinput .el-input__inner {
			margin-top: 15px;
			height: 30px;
			line-height: 30px;
			width: 150px;
			margin-bottom: 15px;
		}
		.addnew {
			text-align: center;
			margin-top: 0px;
			height: 30px;
			line-height: 30px;
			border: 1px dashed #FF5402;
			color: #FF5402;
			cursor: pointer;
			// position: absolute;
			// bottom: 0;
			// left: 0px;
			// right: 0;
		}

		.expand-tree {
			border: none;
			margin-top: 10px;
		}
		// .el-tree {
		// 	width: 100%;
		// 	/*height: 400px;*/
		// 	overflow: auto;
		// }
		.expand-tree .el-tree-node.is-current,
		.expand-tree .el-tree-node:hover {
			overflow: hidden;
		}
		.expand-tree .is-current>.el-tree-node__content .tree-label {
			font-weight: 600;
		}
	}
</style>