	(function (win, doc) {
	  function change() {
	    // ;
//	    // console.log(doc.documentElement.clientWidth)
	    if (doc.documentElement.clientWidth > 1920) {
	      doc.documentElement.style.fontSize = doc.documentElement.clientWidth / 150 + 'px';
	      window.echartrem='16.8'
	    } else if (doc.documentElement.clientWidth > 1700 && doc.documentElement.clientWidth <= 1920) {
	      doc.documentElement.style.fontSize = doc.documentElement.clientWidth / 120 + 'px';
	      window.echartrem='16.8'
	    } else if (doc.documentElement.clientWidth > 1500 && doc.documentElement.clientWidth <= 1700) {
	      doc.documentElement.style.fontSize = doc.documentElement.clientWidth / 130 + 'px';
	      window.echartrem='16'
	    } else if (doc.documentElement.clientWidth >= 1366 && doc.documentElement.clientWidth <= 1500) {
	      doc.documentElement.style.fontSize = doc.documentElement.clientWidth / 140 + 'px';
	      window.echartrem ='14.6'
	    } else {
	      doc.documentElement.style.fontSize = doc.documentElement.clientWidth / 150 + 'px';
	      window.echartrem='14.6'
	    }
	  };
	  win.addEventListener('resize', change, false);
	  change();
	})(window, document)
	// 
	// 	1、640的图：
	// 	(function(win,doc){
	// 			function change(){
	// 				doc.documentElement.style.fontSize=doc.documentElement.clientWidth/16+'px';
	// 			};
	// 			win.addEventListener('resize',change,false);
	// 			change();
	// 		})(window,document)
	// 	字体大小：20px；
	// 	rem = width/height 除以 40；

	// 2、750的图：
	// 	(function(win,doc){
	// 			function change(){
	// 				doc.documentElement.style.fontSize=doc.documentElement.clientWidth/15+'px';
	// 			};
	// 			win.addEventListener('resize',change,false);
	// 			change();
	// 		})(window,document)
	// 	字体大小：25px；	
	// 	rem = width/height 除以 50；


	// 3、828的图：
	// 	(function(win,doc){
	// 			function change(){
	// 				doc.documentElement.style.fontSize=doc.documentElement.clientWidth/18+'px';
	// 			};
	// 			win.addEventListener('resize',change,false);
	// 			change();
	// 		})(window,document)
	// 	字体大小：23px；	
	// 	rem = width/height 除以 46；