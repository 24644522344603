import { render, staticRenderFns } from "./PasswordPolicy.vue?vue&type=template&id=5134afb4&"
import script from "./PasswordPolicy.vue?vue&type=script&lang=js&"
export * from "./PasswordPolicy.vue?vue&type=script&lang=js&"
import style0 from "./PasswordPolicy.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports