<template>
	<div class="PasswordPolicy" v-loading="loading" v-if="IsPageDome">
        <ul>
            <li>
                <span>{{R.System_SecurityModule_PasswordPolicy_1}}：</span>
                <el-checkbox v-model="PasswordPolicyPasswordStrength">{{R.System_SecurityModule_PasswordPolicy_2}}</el-checkbox>
            </li>
            <li>
                <span>{{R.System_SecurityModule_PasswordPolicy_3}}：</span>
                <el-input-number style="width:80px;" v-model="PasswordPolicyMinLength" :max="50"  :min="6" :step='1' :precision="0" :controls="false"></el-input-number>
            </li>
            <li>
                <span>{{R.System_SecurityModule_PasswordPolicy_4}}：</span>
                <el-checkbox v-model="PasswordPolicyPasswordChangeCycle.IsEnable">
                    {{R.System_SecurityModule_PasswordPolicy_5}}
                    <el-input-number style="width:80px;" v-model="PasswordPolicyPasswordChangeCycle.Cycle"  :min="1" :step='1' :precision="0" :controls="false"></el-input-number>
                    {{R.System_SecurityModule_PasswordPolicy_6}}
                </el-checkbox>
            </li>
            <li>
                <span>{{R.System_SecurityModule_PasswordPolicy_7}}：</span>
                <el-checkbox v-model="PasswordPolicyErrCount.IsEnable">
                    {{R.System_SecurityModule_PasswordPolicy_8}}
                    <el-input-number style="width:80px;" v-model="PasswordPolicyErrCount.Cycle"  :min="1" :step='1' :precision="0" :controls="false"></el-input-number>
                    {{R.System_SecurityModule_PasswordPolicy_9}}。
                </el-checkbox>
            </li>
        </ul>
        <div class="footer">
            <el-button @click="save" v-if="getpermission('SaveSetting')" class="orangebd">{{R.Common_Button_Save}}</el-button>
        </div>
	</div>
</template>

<script>
	var R = {};
	export default {
		data() {
			return{
                R:R,
                permissionCode: [],
                IsPageDome:true,
                loading:false,
                PasswordPolicyPasswordStrength:false,
                PasswordPolicyMinLength:6,
                PasswordPolicyPasswordChangeCycle:{IsEnable:false,Cycle:3},
                PasswordPolicyErrCount:{IsEnable:false,Cycle:3},
                
            };
        },
        methods:{
            Get(){
                this.loading = true;
				this.$http.post('system:/SecuritySetting/PasswordPolicy/Get', {
					Data: {

                    }
				}).then(res => {
					if(res.data.Code == '0') {
                        let data=res.data.Data;
                        this.PasswordPolicyPasswordStrength=data.PasswordPolicy_PasswordStrength;
                        this.PasswordPolicyMinLength=data.PasswordPolicy_MinLength;
                        this.PasswordPolicyPasswordChangeCycle.IsEnable=data.PasswordPolicy_PasswordChangeCycle.IsEnable;
                        this.PasswordPolicyPasswordChangeCycle.Cycle=data.PasswordPolicy_PasswordChangeCycle.Cycle;
                        this.PasswordPolicyErrCount.IsEnable=data.PasswordPolicy_ErrCount.IsEnable;
                        this.PasswordPolicyErrCount.Cycle=data.PasswordPolicy_ErrCount.Cycle;
					} else {
						
					}
					this.loading = false;
				}).catch((error)=>{
					this.loading = false;
				})
            },
            save(){
                this.loading = true;
				this.$http.post('system:/SecuritySetting/PasswordPolicy/Save', {
					Data: [
                        {Key: "PasswordPolicy_MinLength", Value: JSON.stringify(this.PasswordPolicyMinLength)},
                        {Key: "PasswordPolicy_PasswordChangeCycle", Value: JSON.stringify({
                            IsEnable: this.PasswordPolicyPasswordChangeCycle.IsEnable,
                            Cycle: this.PasswordPolicyPasswordChangeCycle.Cycle
                        })},
                        {Key: "PasswordPolicy_ErrCount", Value: JSON.stringify({
                            IsEnable: this.PasswordPolicyErrCount.IsEnable,
                            Cycle: this.PasswordPolicyErrCount.Cycle
                        })},
                        {Key: "PasswordPolicy_PasswordStrength", Value: JSON.stringify(this.PasswordPolicyPasswordStrength)},
                    ]
				}).then(res => {
					if(res.data.Code == '0') {
                        this.Get()
                        this.$message.success(res.data.Message)
					} else {
                        this.loading = false;
						
					}
				}).catch((error)=>{
					this.loading = false;
				})
            },
            //判断权限是否存在
			getpermission(code) {
                if(this.permissionCode && this.permissionCode.length > 0) {
                    for(var i = 0; i < this.permissionCode.length; i++) {
                        if(code == this.permissionCode[i].FunctionCode) {
                            return true
                        }
                    }
                } else {
                    return false
                }
			},
        },
		watch: {
          
		},
		beforeUpdate() {
			
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'System_SecurityModule_PasswordPolicy')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		mounted(){
            this.Get()
            //判断是否包含新增编辑等功能权限
            this.$PermissionUtil.getPermission('System/SecurityModule/PasswordPolicy').then((res)=>{
				this.permissionCode = res;
			});
        },
		
	};
</script>

<style lang='scss'>
	.PasswordPolicy{
        >ul{
            >li{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                >span{
                    min-width: 100px;
                }
            }
        }
        .footer{
            position: absolute;
            height: 30px;
            bottom: 0;
            box-sizing: border-box;
        }
    }
</style>