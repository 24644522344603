<template>
	<div id='StandardApproval' v-loading="loading" v-if="IsPageDome">
		<div class="mask" v-if="maskhidden">
			<!--添加审批流-->
			<div class="addone ApprovalForm" v-if="ApprovalForm">
				<div class="head">
					<span class="title">{{R.BillApproval_StandardApproval_1}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="content">
					<div>
						<span class="leftName">{{R.BillApproval_StandardApproval_2}}：</span>
						<span class="rightName">
							<el-select v-model="ProductId" :placeholder="R.BillApproval_StandardApproval_3">
						    <el-option
						      v-for="item in Productoptions"
						      :key="item.ID"
						      :label="item.Name"
						      :value="item.ID">
						    </el-option>
						  </el-select>
						</span>
						<span style="color: red">*</span>
					</div>
					<div>
						<span class="leftName">{{R.BillApproval_StandardApproval_4}}：</span>
						<span class="rightName">
							<el-select v-model="BillType" :placeholder="R.BillApproval_StandardApproval_3">
						    <el-option
						      v-for="item in Billoptions"
						      :key="item.BillType"
						      :label="item.BillName"
						      :value="item.BillType">
						    </el-option>
						  </el-select>
						</span>
						<span style="color: red">*</span>
					</div>
					<div>
						<span style="line-height: 15px;" class="leftName">{{R.BillApproval_StandardApproval_5}}：</span>
						<span class="rightName">
							<el-input v-model="FlowName" maxlength='50' :placeholder="R.BillApproval_StandardApproval_6"></el-input>
							<span style="color: red">*</span>
						<span v-if="Tips" style="font-size:12px;color:red;">{{R.BillApproval_StandardApproval_6}}</span>
						</span>
					</div>
					<div>
						<span style="line-height: 15px;" class="leftName">{{R.BillApproval_StandardApproval_7}}：</span>
						<span class="rightName">
							<el-input :disabled="true"  :placeholder="R.BillApproval_StandardApproval_9" ></el-input>
							<span style="color: red">*</span>
						</span>
					</div>
					<div>
						<span class="textarealeft leftName">{{R.BillApproval_StandardApproval_16}}：</span>
						<span class="rightName">
							<el-input type="textarea" maxlength='200' :placeholder="R.BillApproval_StandardApproval_29" class="textarea" resize='none'  v-model="Remark"></el-input>
						</span>
					</div>
				</div>
				<div class="foots">
					<el-button style="padding-right: 20px;margin-left: 10px;" @click="closemask">{{R.Common_Button_Cancel}}</el-button>
					<el-button style="padding-right: 20px;margin-left: 10px;" class="orangetext add orangebd" @click="saveDraft">{{R.Common_Button_Save}}</el-button>
				</div>
			</div>
			<!--更多流程-->
			<div class="addone DetailList" v-if="DetailList">
				<div class="head">
					<span class="title">{{R.BillApproval_StandardApproval_12}}</span>
					<span class="icon" @click="closemask"><i class="el-icon-circle-close"></i></span>
				</div>
				<div class="listTable" ref="table">
					<Table sortable='custom' stripe :columns="MasktableColumns" height='325' :data="MaskTableData"></Table>
				</div>
				<div class="foots">
					<el-button @click="closemask">{{R.BillApproval_StandardApproval_20}}</el-button>
				</div>
			</div>
		</div>
		<div class="rightConetnt">
			<div v-for="(item,index) in listMenu" :key="index">
				<div class="productTitle">
					<img :src="item.Icon" alt="" srcset="">
					<span>{{item.ProductName}}</span>
					<span v-if="getpermission('OperateApproval')" @click="addList(item.ProductId)"><icon class="el-icon-circle-plus-outline" style='font-size: 16px;margin-right: 5px;cursor: pointer;'></icon>{{R.BillApproval_StandardApproval_28}}</span>
				</div>
				<div class="productContent">
					<ul>
						<li>{{R.BillApproval_StandardApproval_13}}</li>
						<li>{{R.BillApproval_StandardApproval_14}}</li>
						<li>{{R.BillApproval_StandardApproval_7}}</li>
						<li>{{R.BillApproval_StandardApproval_16}}</li>
						<li>{{R.BillApproval_StandardApproval_17}}</li>
						<li>{{R.Common_Button_Operation}}</li>
					</ul>
					<ul v-for="(Billitem,index) in item.Details" :key="index">
						<li>{{Billitem.BillName}}</li>
						<li :title="Billitem.FlowName" @click="intoUrl(Billitem)" :class="Billitem.FlowName?'Jump':''">{{Billitem.FlowName==''?'-':Billitem.FlowName}}</li>
						<li>{{Billitem.FlowVersion==''?'-':Billitem.FlowVersion}}</li>
						<li :title="Billitem.Remark">{{Billitem.Remark==''?'-':Billitem.Remark}}</li>
						<li>
							<span @click="intoUrl(item)" v-for="(item,myindex) in Billitem.OtherVersions.filter(item=>{return item.IsEnabled==false})" :key="myindex" v-if="myindex<=3">
               				<span class="Jump">{{item.FlowVersion}} </span>

							<span style="color:#07BAD4">(</span>
							<span style="color:red;">{{item.FlowStatus==2?item.IsEnabledTxt:item.FlowStatusTxt}}</span><span style="color:#07BAD4">&nbsp;){{Billitem.OtherVersions.length>1?(Billitem.OtherVersions.length>myindex+1?'、':''):''}}</span>
							</span>
						</li>
						<li>
							<el-button @click="showDetail(Billitem.OtherVersions)">{{R.BillApproval_StandardApproval_19}}</el-button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var R = {};
	export default {
		name: 'StandardApproval',
		data() {
			return {
				IsPageDome: true,
				R: R,
				Tips: false,
				// 流程名称
				FlowName: '',
				// 备注说明
				Remark: '',
				// 产品ID
				ProductId: null,
				Productoptions: [],
				//单据类型
				BillType: null,
				Billoptions: [],

				maskhidden: false,
				DetailList: false,
				ApprovalForm: false,
				addone: false,
				loading: false,
				// 列表数据
				listMenu: [],
				// 详情表头定义
				MasktableColumns: [{
						title: R.BillApproval_StandardApproval_5,
						key: 'FlowName',
						width: 240,
						tooltip: true,
						align: 'center',
						render: (h, params) => {
							return h('div', {
								props: {},
								style: {
									fontSize: '12px',
									color: '#07BAD4',
									textDecoration: 'underline',
									cursor: 'pointer'
								},
								on: {
									click: () => {
										if(params.row.FlowId) {
											if(params.row.FlowId) {
												this.$router.push({
													name: 'ProcessEngine',
													params: {
														type: "edit",
														ID: params.row.FlowId,
														status: 'StandardApproval'
													}
												})
											}
										}

									}
								}
							}, params.row.FlowName);
						},

					},
					{
						title: R.BillApproval_StandardApproval_21,
						key: 'FlowStatusTxt',
						width: 120,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.BillApproval_StandardApproval_25,
						key: 'IsEnabledTxt',
						width: 130,
						tooltip: true,
						align: 'center',
						render: (h, params) => {
							return h('div', {
								props: {},
								style: {
									textAlign: 'center'
								},
							}, params.row.FlowStatus == 2 ? params.row.IsEnabledTxt : '-');
						},
					},
					{
						title: R.BillApproval_StandardApproval_7,
						key: 'FlowVersion',
						width: 120,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.BillApproval_StandardApproval_23,
						key: 'Creator',
						width: 160,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.BillApproval_StandardApproval_8,
						key: 'Remark',
						width: 100,
						tooltip: true,
						align: 'center'
					},
					{
						title: R.BillApproval_StandardApproval_24,
						key: 'CreateTime',
						width: 180,
						tooltip: true,
						align: 'center'
					},
				],
				// 详情表格数据
				MaskTableData: []
			}
		},
		watch: {
			ProductId(oldValue, newValue) {
				if(newValue != oldValue) {
					this.BillType = null
					this.$http.post('system:/BillType/GetByProduct', {
						Data: {
							ProductId: oldValue
						}
					}).then((res) => {
						if(res.data.Code == 0) {
							this.Billoptions = res.data.Data
							if(res.data.Data.length != 0) {
								this.BillType = 1
							}
						} else {
							
						}
					})
				}
			}
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		methods: {
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {

						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}

			},
			// 链接跳转
			intoUrl(res) {
				if(!res.FlowId) {
					return
				}
				this.$router.push({
					name: 'ProcessEngine',
					params: {
						type: "edit",
						ID: res.FlowId,
						status: 'StandardApproval'
					}
				})
			},
			// changeUrl(command) {
			// 	if(command == 'a') {
			// 		this.$router.push('/system/StandardApprovalDetail')
			// 	}
			// },
			// 新增审批流
			addList(index) {
				this.maskhidden = true;
				this.ApprovalForm = true;
				this.ProductId = index
				this.FlowName = ''
				this.Remark = ''
			},
			// 详情
			showDetail(data) {
				this.maskhidden = true;
				this.DetailList = true
				this.MaskTableData = data
			},

			closemask() {
				this.maskhidden = false;
				this.addone = false;
				this.ApprovalForm = false;
				this.DetailList = false;
				this.Tips = false
			},
			// 审批流新建保存
			saveDraft() {
				processlist.pselectedProcessEntity = null
				if(!this.FlowName) {
					this.Tips = true
					return
				} else {
					this.Tips = false
				}
				this.$http.post('system:/BillApproval/ApprovalFlow/Add', {
					Data: {
						"ProductId": this.ProductId,
						/*产品id*/
						"FlowType": 1,
						/*流程类型1.标准 2.自定义*/
						"BillType": this.BillType,
						/*单据类型*/
						"FlowName": this.FlowName,
						/*流程名称*/
						"Remark": this.Remark /*备注说明*/
					}
				}).then((res) => {
					if(res.data.Code == 0) {
						if(res.data.Data.ID) {
							this.$router.push({
								name: 'ProcessEngine',
								params: {
									type: "new",
									ID: res.data.Data.ID,
									status: 'StandardApproval'
								}
							})
						}
					} else {
						
					}
				})

			},
			showmask() {
				this.maskhidden = true;
				this.addone = true
			},
			//左侧树懒加载
			loadNode(node, resolve) {
				if(node.level === 0) { //第一级
					this.getOrgByParentID(null, resolve);
				} else {
					this.getOrgByParentID(node.data.id, resolve);
				}
			},
			getOrgByParentID(parentID, resolve) {
				this.$http.post('system:/Organization/GetTree', {
					Data: {
						Delay: true,
						ParentId: parentID,
						OnlyLoadPrivilegeOrg: true,
						LoadOrganizationType: 2,
						'onlyloadEnableOrg': true,
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) { //成功
						//
						let items = rsp.data.Data;
						resolve(items);
					} else {
						
					}
				})
			},
			updateOrg() {
				this.kAOId = this.node.id;
				this.maskhidden = false;
				this.addone = false
			},
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'system_BillApproval_StandardApproval')
		},
		mounted() {
			this.$PermissionUtil.getPermission('System/BillApproval/StandardApproval').then((res)=>{
				this.permissionCode = res;
			});
			// 获取产品
			this.$http.post('system:/Product/All', {
				Data: {
					"Type": 1
				}
			}).then((res) => {
				if(res.data.Code == 0) {
					this.Productoptions = res.data.Data
				} else {
					
				}
			})
			// 列表渲染
			this.loading = true
			this.$http.post('system:/BillApproval/StandardApprovalFlow/List', {
				Data: null
			}).then((res) => {
				if(res.data.Code == 0) {
					this.listMenu = res.data.Data
				} else {
					
				}
				this.loading = false
			})

		}
	}
</script>

<style lang='scss'>
	#StandardApproval {
		overflow: auto;
		.el-input__icon {
			line-height: 30px;
		}
		.red {
			background-color: red;
		}
		.green {
			background-color: green;
		}
		.grey {
			background-color: grey;
		}
		.mask {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.addone {
				width: 500px;
				height: 500px;
				position: fixed;
				margin: auto;
				background-color: white;
				.listTable {
					margin-top: 10px;
				}
				.foots {
					position: absolute;
					bottom: 10px;
					left: 0;
					right: 0;
					height: 30px;
					line-height: 30px;
					margin-right: 10px;
					.el-button {
						float: right;
						height: 30px!important;
						padding: 0.2rem 1rem;
					}
				}
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					top: 35px;
					left: 0px;
					right: 0px;
					bottom: 40px;
					position: absolute;
					.commontree {
						top: 0;
					}
					>div {
						height: 30px;
						line-height: 30px;
						padding-left: 20px;
						margin-top: 10px;
						.leftName {
							display: inline-block;
							width: 71px;
							text-align: right;
						}
						.rightName {
							.el-input {
								width: 210px;
							}
							.textarea {
								margin-top: 10px;
								width: 210px;
								.el-textarea__inner {
									height: 60px;
								}
							}
						}
						.textarealeft {
							position: relative;
							top: -30px;
						}
					}
					.el-select {
						height: 30px;
						width: 210px;
					}
					.el-input__inner {
						height: 30px;
						line-height: 30px;
					}
				}
			}
			.ApprovalForm {
				width: 420px;
				height: 380px;
			}
			.DetailList {
				width: 800px;
				height: 420px;
			}
		}
		.heads {
			margin-bottom: 10px;
			padding-bottom: 15px;
			border-bottom: 1px solid #EEEEEE;
			/*height: 6%;
			padding-top:14px;
			padding-left: 20px;*/
			display: flex;
			justify-content: space-between;
			align-items: center;
			>div {
				display: flex;
				align-items: center;
			}
			img {
				height: 42px;
			}
			.dots {
				height: 20px;
				width: 5px;
				background-color: #009900;
				margin-right: 10px;
			}
			.title {
				font-size: 16px;
				font-family: FZLTHK--GBK1-0;
				font-weight: bold;
				color: rgba(98, 103, 104, 1);
			}
			.two_title {
				margin-left: 10px;
				font-size: 14px;
				font-family: FZLTHK--GBK1-0;
				font-weight: 400;
				color: rgba(171, 177, 179, 1);
				display: flex;
				align-items: center;
			}
			.set {
				float: right;
				margin-right: 6px;
			}
		}
		.rightConetnt {
			>div {
				margin-bottom: 20px;
				>.productTitle {
					display: flex;
					align-items: center;
					line-height: 30px;
					>span {
						font-size: 14px;
						margin-left: 10px;
						font-weight: bold;
						color: #333333;
					}
				}
				>.productContent {
					padding-left: 38px;
					>ul:nth-child(1) {
						border-top: 1px solid #d7d7d7;
						>li {
							font-size: 14px;
							font-weight: bold;
						}
					}
					>ul {
						display: flex;
						align-items: center;
						border-bottom: 1px solid #d7d7d7;
						.Jump {
							font-size: 14px;
							color: #07BAD4;
							text-decoration: underline;
							cursor: pointer;
							text-align: center;
						}
						>li {
							text-align: center;
							line-height: 30px;
							font-size: 14px;
							color: #333333;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						>li:nth-child(1) {
							width: 180px;
							text-align: left;
						}
						>li:nth-child(2) {
							width: 180px;
							text-align: left;
						}
						>li:nth-child(3) {
							width: 120px;
							text-align: left;
						}
						>li:nth-child(4) {
							width: 120px;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							text-align: left;
						}
						>li:nth-child(5) {
							width: 600px;
							text-align: left;
						}
						>li:nth-child(6) {
							width: 160px;
							.el-button {
								height: 22px!important;
								padding: 0!important;
							}
						}
					}
				}
			}
		}
	}
</style>