import cjButton from "./control/Button.vue";
import cjSelect from "./control/Select.vue";
import Test from "./control/Test.vue";
import cjSearch from "./control/Search.vue";
import cjDropdowntree from "./control/Dropdowntree.vue";
import cjTree from '@/view/tree';
import cjdirTree from '@/view/directory';
import Treeselect from '@riophae/vue-treeselect'
import cjPersonnelselector from "./control/Personnelselector.vue";
import process from "./control/process.vue";
import cjElasticFrame from "./control/ElasticFrame.vue";
import OrganizationSelect from "./control/OrganizationSelect.vue";
import AssetCategorySelect from "./control/AssetCategorySelect.vue";
import LocationSelect from "./control/LocationSelect.vue";
import CjVueEditor from "./control/CjVueEditor.vue";
import UserSelect from "./control/UserSelect.vue";
export default {
  CjVueEditor,
  Test,
  cjSelect,
  cjSearch,
  cjButton,
  cjTree,
  cjDropdowntree,
 	Treeselect,
  cjdirTree,
  cjPersonnelselector,
  process,
  cjElasticFrame,
  OrganizationSelect,
  AssetCategorySelect,
  LocationSelect,
  UserSelect
}
