<template>
      <div class="menuManagement" v-loading="tableLoading">
          <div class="masks" v-if="powerDialog">
              <div class="permissionDialog" v-loading="JurisdictionDialogLoading">
                  <div class="head">
						<span class="title">{{powerTitle}}{{R.System_MenuManagement_5}}</span>
						<img class="icon" src="static/images/backout.png" alt="" @click="powerDialog=false">
				  </div>
                  <div class="menuJurisdiction">
                            <el-form :model="editPermissions" :rules="powerRules" ref="powers" label-width="140px" class="demo-ruleForm element-from">
                                <el-form-item :label="R.System_MenuManagement_from_item_9" prop="MenuCode">
                                &nbsp;
                                <el-input v-model="editPermissions.MenuCode" disabled :placeholder="R.System_MenuManagement_placeholder_3"></el-input>
                                </el-form-item>
                                <el-form-item :label="R.System_MenuManagement_from_item_10">
                                &nbsp;
                                <el-select clearable v-model="editPermissions.FunctionID" style="width:220px;" :placeholder="R.System_MenuManagement_placeholder_1">
                                    <el-option
                                    v-for="item in editPermissions.AllFunctions"
                                    :key="item.ID"
                                    :label="item.FunctionName"
                                    :value="item.ID">
                                    </el-option>
                                </el-select>
                                </el-form-item>
                                <el-form-item :label="R.System_MenuManagement_from_item_11" prop="FunctionNameCN">
                                    &nbsp;
                                    <el-input v-model="editPermissions.FunctionNameCN" :disabled="editPermissions.FunctionID?true:false" :placeholder="R.System_MenuManagement_placeholder_16"></el-input>
                                </el-form-item>
                                <el-form-item :label="R.System_MenuManagement_from_item_12" prop="FunctionNameEN">
                                    &nbsp;
                                    <el-input v-model="editPermissions.FunctionNameEN" :disabled="editPermissions.FunctionID?true:false" :placeholder="R.System_MenuManagement_placeholder_17"></el-input>
                                </el-form-item>
                                <el-form-item :label="R.System_MenuManagement_from_item_13" prop="FunctionCode">
                                    &nbsp;
                                    <el-input v-model="editPermissions.FunctionCode" :disabled="editPermissions.FunctionID?true:false" :placeholder="R.System_MenuManagement_placeholder_18"></el-input>
                                </el-form-item>
                                <el-form-item :label="R.System_MenuManagement_from_item_14">
                                    &nbsp;
                                    <el-input v-model="editPermissions.FunctionCssCls" :disabled="editPermissions.FunctionID?true:false" :placeholder="R.System_MenuManagement_placeholder_19"></el-input>
                                </el-form-item>
                                <div class="footer clear">
                                    <div>
                                        <el-button @click="powerDialog = false">{{R.System_MenuManagement_7}}</el-button>
                                        <el-button  class="orangebd" @click="addPowerSave">{{R.System_MenuManagement_8}}</el-button>
                                    </div>
                                </div>
                            </el-form>
                            
                    </div>
              </div>
          </div>
          <div class="mask" v-if="power">
              <div class="jurisdiction">
                  <p class="header">
                      <span class="title">
                          {{R.System_MenuManagement_from_item_16}}
                      </span>
                      <img src="static/images/backout.png" alt="" @click="power=false">
                  </p>
                  <div class="tableHead clear">
                      <div class="left">
                          <el-button class="orangetext add orangebd" @click="powerAdd('add')">
                          <i class="el-icon-plus"></i>
                          {{R.System_MenuManagement_1}}
                        </el-button>
                        <el-button @click="powerAdd('edit')">
                            <i class="el-icon-edit"></i>
                            {{R.System_MenuManagement_2}}
                        </el-button>
                        <el-button @click="powerDelete" style="color:red;border:1px solid red;">
                                <i class="el-icon-delete"></i >
                                {{R.System_MenuManagement_3}}
                        </el-button>
                      </div>
                      <div class="right">
                          <el-input :placeholder="R.System_MenuManagement_placeholder_20" v-model="powerName" @keyup.enter.native="jurisdictionSearch">
                            <i slot="suffix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                        <el-button @click="jurisdictionSearch">{{R.System_MenuManagement_6}}</el-button>
                      </div>
                  </div>
                  <div class="content" v-loading="jurisdictionListLoading">
                        <el-table
                        height="435"
                        :data="permissionList"
                        @selection-change="powerSelectList"
                        style="width: 100%">
                        <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column>
                        <el-table-column
                            v-for="(item,index) in permissionColumn"
                            :key="index"
                            :prop="item.prop"
                            :label="item.label"
                            :width="item.width">
                        </el-table-column>
                        </el-table>
                  </div>
              </div>
          </div>
          <div class="mask" v-if="memberDisplay">
              <div class="addone" style="width:520px;height:420px;" v-loading="dialogLoading">
					<div class="head">
						<span class="title">{{menuTitle}}{{R.System_MenuManagement_from_item_17}}</span>
						<span class="icon" @click="memberDisplay = false"><i class="el-icon-circle-close"></i></span>
					</div>
					<div class="content">
                        <el-form :model="menuItem" :rules="menuRules" ref="menuItem" label-width="120px" class="demo-ruleForm">
                            <el-form-item :label="R.System_MenuManagement_from_item_1">
                                &nbsp;
                                <el-select :clearable="false" v-model="menuItem.ProductID" style="width:220px;" :placeholder="R.System_MenuManagement_placeholder_1">
                                <el-option
                                v-for="item in Productoptions"
                                :key="item.ProductID"
                                :label="item.ProductName"
                                :value="item.ProductID">
                                </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="R.System_MenuManagement_from_item_2">
                                &nbsp;
                                <el-select clearable v-model="menuItem.ParentID" style="width:220px;" :placeholder="R.System_MenuManagement_placeholder_2">
                                <el-option
                                v-for="item in menuItem.AllMenus"
                                :key="item.ID"
                                :label="item.MenuName"
                                :value="item.ID">
                                </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="R.System_MenuManagement_from_item_3" prop="MenuCode">
                                &nbsp;
                                <el-input v-model="menuItem.MenuCode" :placeholder="R.System_MenuManagement_placeholder_3"></el-input>
                            </el-form-item>
                            <el-form-item :label="R.System_MenuManagement_from_item_4" prop="MenuNameCN">
                                &nbsp;
                                <el-input v-model="menuItem.MenuNameCN" :placeholder="R.System_MenuManagement_placeholder_4"></el-input>
                            </el-form-item>
                            <el-form-item :label="R.System_MenuManagement_from_item_5" prop="MenuNameEN">
                                &nbsp;
                                <el-input v-model="menuItem.MenuNameEN" :placeholder="R.System_MenuManagement_placeholder_5"></el-input>
                            </el-form-item>
                            <el-form-item :label="R.System_MenuManagement_from_item_6" prop="Url">
                                &nbsp;
                                <el-input v-model="menuItem.Url" :placeholder="R.System_MenuManagement_placeholder_6"></el-input>
                            </el-form-item>
                            <el-form-item :label="R.System_MenuManagement_from_item_7" prop="Order">
                                &nbsp;
                                <el-input v-model.number="menuItem.Order" :maxlength="7" :placeholder="R.System_MenuManagement_placeholder_7" ></el-input>
                            </el-form-item>
                            <el-form-item :label="R.System_MenuManagement_from_item_8">
                                &nbsp;
                                <el-input v-model="menuItem.Css" :placeholder="R.System_MenuManagement_placeholder_8"></el-input>
                            </el-form-item>
                        </el-form>
					</div>
					<div class="footer clear" style="padding:12px;">
						<div  style="float:right;">
							<el-button @click="memberDisplay = false">{{R.System_MenuManagement_7}}</el-button>
							<el-button @click="submit" class="orangebd">{{R.System_MenuManagement_8}}</el-button>
						</div>
					</div>
			</div>
          </div>

          <div class="mask" v-if="delDisplay">
              <div class="addone" style="width:400px;height:200px;">
                <div class="head">
                        <span class="title">{{R.System_MenuManagement_title_2}}</span>
                        <span class="icon" @click="delDisplay = false"><i class="el-icon-circle-close"></i></span>
                </div>
                <div class="contentdel">
                    <i class="el-icon-info"></i>{{R.System_MenuManagement_placeholder_9}}
                </div>
                <div class="footer clear" style="padding:12px;">
						<div  style="float:right;">
							<el-button :disabled="SelectList.length!=1" @click="menuDelete"  class="orangebd">{{R.System_MenuManagement_9}}</el-button>
                            <el-button @click="delDisplay = false">{{R.System_MenuManagement_10}}</el-button>
						</div>
					</div>          
               </div>
          </div>
          
         <div class="header clear">
             <div class="left">
                      <el-button @click="adds('add')" class="orangetext add orangebd">
                          <i class="el-icon-plus"></i>
                          {{R.System_MenuManagement_1}}
                        </el-button>
                        <el-button @click="adds('edit')">
                            <i class="el-icon-edit"></i>
                            {{R.System_MenuManagement_2}}
                        </el-button>
                        <el-button @click="deletes" style="color:red;border:1px solid red;">
                                <i class="el-icon-delete"></i >
                                {{R.System_MenuManagement_3}}
                        </el-button>
                        <el-button @click="menuEnable">
                            {{R.System_MenuManagement_4}}
                        </el-button>
                        <el-button @click="menuJurisdiction">
                            {{R.System_MenuManagement_label_13}}
                        </el-button>
             </div>
             <div class="right">
                 <div class="rightName">
							<el-select v-model="ProductId" :placeholder="R.System_MenuManagement_placeholder_1">
						    <el-option
						      v-for="item in Productoptions"
						      :key="item.ProductID"
						      :label="item.ProductName"
						      :value="item.ProductID">
						    </el-option>
						  </el-select>
				</div>
                <div class="rightName">
                    <el-input style="width:240px;" :placeholder="R.System_MenuManagement_placeholder_15" v-model="menuQst" @keyup.enter.native="menuSeach">
                        <i slot="suffix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                </div>
                <div class="rightName">
                    <el-button @click="menuSeach">{{R.System_MenuManagement_6}}</el-button>
                </div>   
             </div>
         </div>
         <div class="content">
             <div class="table">
                 <el-table
                :data="menuList"
                style="width: 100%;margin-bottom: 20px;"
                row-key="ID"
                border
                height="75vh"
                @selection-change="menuSelectionChange"
                :tree-props="{children: 'Children'}">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                prop="MenuName"
                :label="R.System_MenuManagement_label_1"
                align="left"
                width="220"
                :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                prop="MenuCode"
                :label="R.System_MenuManagement_label_2"
                align="center"
                width="300"
                :show-overflow-tooltip="true"
                >
                </el-table-column>
                 <el-table-column
                prop="MenuNameCN"
                :label="R.System_MenuManagement_label_3"
                align="center"
                width="220"
                :show-overflow-tooltip="true"
                >
                </el-table-column>
                 <el-table-column
                prop="MenuNameEN"
                :label="R.System_MenuManagement_label_4"
                align="center"
                width="300"
                :show-overflow-tooltip="true"
                >
                </el-table-column>
                 <el-table-column
                prop="Order"
                :label="R.System_MenuManagement_label_5"
                align="center"
                width="100"
                >
                </el-table-column>
                 <el-table-column
                prop="Url"
                :label="R.System_MenuManagement_label_6"
                align="center"
                width="300"
                :show-overflow-tooltip="true"
                >
                </el-table-column>
                 <el-table-column
                prop="EnableText"
                :label="R.System_MenuManagement_label_7"
                align="center"
                width="100"
               >
                <template v-slot="scope">
                    <span :style="scope.row.IsEnable?'color:#13ce66':'color:#ff4949'">{{scope.row.EnableText}}</span>
                </template>
                </el-table-column>
                <el-table-column
                prop="Css"
                :label="R.System_MenuManagement_label_8"
                align="center"
                :show-overflow-tooltip="true"
                width="300"
               >
                </el-table-column>

            </el-table>
             </div>
         </div>
                  
      </div>      
</template>
<script>
let powerRules;
var R = {};
export default {
   data(){
       return {
           R:R,
           menuQst:'',
           menuTitle:'',
           Productoptions:[],
           menuItem:{
               ProductID:null,
               ParentID:null,
               MenuCode:null,
               MenuNameCN:null,
               MenuNameEN:null,
               Url:null,
               Order:null,
               IsEnable:null,
               MenuNameCN:null,
               Css:null
           },
           ProductId:1,
           mask:false,
           memberDisplay:false,
           delDisplay:false,
           menuList:[],
           SelectList:[],
           tableLoading:false,
           dialogLoading:false,
           dialogType:'',
           menuRules: {
                MenuCode: [
                    { required: true, message: R.System_MenuManagement_placeholder_3, trigger: 'blur' },
                ],
                MenuNameCN: [
                    { required: true, message: R.System_MenuManagement_placeholder_4, trigger: 'blur' }
                ],
                MenuNameEN: [
                    { required: true, message: R.System_MenuManagement_placeholder_5, trigger: 'blur' }
                ],
                Url: [
                    { required: true, message: R.System_MenuManagement_placeholder_6, trigger: 'blur' }
                ],
                Order: [
                    { required: true, message: R.System_MenuManagement_placeholder_7, trigger: 'blur' },
                    {type:'number',message:R.System_MenuManagement_placeholder_23,trigger: 'blur'},
                ]
            },
            deleteType:'',
            menuPower:{
                addPowerTerm:{

                }
            },
            permissionColumn:[{
                label:R.System_MenuManagement_from_item_17+R.System_MenuManagement_label_2,
                prop:'MenuCode',
                width:'200'
            },{
                label:R.System_MenuManagement_label_10,
                prop:'FunctionNameCN',
                width:'200'
            },{
                label:R.System_MenuManagement_label_11,
                prop:'FunctionNameEN',
                width:'200'
            },{
                label:R.System_MenuManagement_label_12,
                prop:'FunctionCode',
                width:'220'
            },{
                label:R.System_MenuManagement_label_8,
                prop:'FunctionCssCls',
                width:'220'
            }],
            powerRules: {
                MenuCode : powerRules.MenuCode,
                FunctionNameCN : powerRules.FunctionNameCN,
                FunctionNameEN : powerRules.FunctionNameEN,
                FunctionCode : powerRules.FunctionCode,
            },
            permissionList:[],
            power:false,
            powerDialog:false,
            powerTitle:"",
            // 权限高级搜索
            powerName:'',
            powerSelectChangeId:[],
            // 编辑权限
            editPermissions:{
                FunctionID:null
            },
            jurisdictionListLoading:false,
            JurisdictionDialogLoading:false,
       }
   },
   watch:{
       "editPermissions.FunctionID":function(newVal){
           if(newVal){
               this.powerRules.FunctionNameCN=[];
               this.powerRules.FunctionNameEN=[];
               this.powerRules.FunctionCode=[];
               return;
           }
            this.powerRules.FunctionNameCN = powerRules.FunctionNameCN;
            this.powerRules.FunctionNameEN = powerRules.FunctionNameEN;
            this.powerRules.FunctionCode = powerRules.FunctionCode;
       }

   },
   beforeCreate() {
			R = this.getSelfLanguage('System', 'System_MenuManagement');
            powerRules = {
                MenuCode: [
                    { required: true, message: R.System_MenuManagement_placeholder_21, trigger: 'blur' }
                ],
                FunctionNameCN: [
                    { required: true, message: R.System_MenuManagement_placeholder_16, trigger: 'blur' }
                ],
                FunctionNameEN: [
                    { required: true, message: R.System_MenuManagement_placeholder_17, trigger: 'blur' }
                ],
                FunctionCode: [
                    { required:true, message: R.System_MenuManagement_placeholder_3, trigger: 'blur' }
                ]
            }
	},
    methods: {
        jurisdictionSearch(){
            this.jurisdictionList(this.powerName);
        },
        addPowerSave(){
            this.jurisdictionSave();
        },
        jurisdictionSave(){
            let power='powers';
            this.$refs[power].validate((valid) => {
                if (valid) {
                    this.$http.post('system:/Menu/Permition/Save',{Data:this.editPermissions}).then((res) => {
                        if(res.data.Code == 0) {
                        this.jurisdictionList();
                        this.$message.success(R.System_MenuManagement_title_3);
                        }
                        this.powerDialog=false;
                    })
                }
            });
        },
        powerSelectList(data){
            let rsp=data.map(item => {
                return item.ID
            });
            this.powerSelectChangeId=rsp;
        },
        // 权限删除
        powerDelete(){
            if(this.powerSelectChangeId.length<1){
                   this.$message.error(R.System_MenuManagement_placeholder_22);
                   return 
             }
             this.delDisplay=true;
             this.deleteType="jurisdictionDelete";
        },

        powerAdd(type){
            if(type=='add'){
                this.powerTitle=R.System_MenuManagement_1;
                this.powerDialog=true;
                this.JurisdictionDialogLoading=true;
                 this.$http.post('system:/Menu/Permition/GetByID',{Data:{"MenuID":this.SelectList[0].ID,"ID":null}}).then((res) => {
                    if(res.data.Code == 0) {
                    this.editPermissions=res.data.Data;
                    }
                    this.JurisdictionDialogLoading=false;
                })
            }
            if(type=='edit'){
                if(this.powerSelectChangeId.length!=1){
                   this.$message.error(R.System_MenuManagement_placeholder_10);
                   return 
                }
                this.powerTitle=R.System_MenuManagement_2;
                this.powerDialog=true;
                this.JurisdictionDialogLoading=true;
                this.$http.post('system:/Menu/Permition/GetByID',{Data:{"MenuID":this.SelectList[0].ID,"ID":this.powerSelectChangeId[0]}}).then((res) => {
				if(res.data.Code == 0) {
                   this.editPermissions=res.data.Data;
				}
                this.JurisdictionDialogLoading=false;
			 })
            }
        },
        // 权限
        menuJurisdiction(){
            if(this.SelectList.length!=1){
                this.$message.error(R.System_MenuManagement_placeholder_10);
                return
            }
            this.power=true;
            this.jurisdictionList();
        },
        jurisdictionList(qst){
            let Qst=null;
            if(qst){
                Qst=qst;
            }
            this.jurisdictionListLoading=true;
            this.$http.post('system:/Menu/Permition/List',{Data:{"MenuID":this.SelectList[0].ID,"QST":Qst}}).then((res) => {
				if(res.data.Code == 0) {
                   this.permissionList=res.data.Data;
				}
                this.jurisdictionListLoading=false;
			})
        },
        deletes(){
            if(this.SelectList.length!=1){
                this.$message.error(R.System_MenuManagement_placeholder_10);
                return
            }
            this.delDisplay=true;
            this.deleteType="menuDelete";
        },
        menuEnable(){
            let Id=null;
            if(this.SelectList.length!=1){
                this.$message.error(R.System_MenuManagement_placeholder_10);
                return;
            }
            if(this.SelectList.length!=0){
               Id=this.SelectList[0].ID;
            }
             this.$http.post('system:/Menu/EnableOrDisable',{Data:{"ID":Id}}).then((res) => {
				if(res.data.Code == 0) {
                   this.menuSeach()
                   this.$message.success(this.SelectList[0].IsEnable?R.System_MenuManagement_placeholder_11:R.System_MenuManagement_placeholder_12);
				} 
			})

        },
        submit(){
            let menuItem='menuItem';
            this.$refs[menuItem].validate((valid) => {
                if (valid) {
                    this.dialogLoading=true;
                    this.$http.post('system:/Menu/Save',{Data:this.menuItem}).then((res) => {
                        if(res.data.Code == 0) {
                            this.menuItem=res.data.Data;
                            this.memberDisplay=false;
                            this.dialogLoading=false;
                            this.menuSeach();
                            this.$message.success(R.System_MenuManagement_placeholder_13);
                        } 
                        this.dialogLoading=false;
                    })
                }
            });
        },
        adds(type){
            this.dialogType=type;
            if(type=='edit'&&this.SelectList.length!==1){
                this.$message.error(R.System_MenuManagement_placeholder_10);
                return;
            }
            this.menuTitle=R.System_MenuManagement_2;
            this.memberDisplay=true;
            let Id=0;
            if(this.SelectList.length!=0){
               Id=this.SelectList[0].ID;
            }
            let productId=this.ProductId;
            if(type=='add'){
                this.menuTitle=R.System_MenuManagement_1;
                Id=0;
            }
             this.dialogLoading=true;
            this.$http.post('system:/Menu/GetByID',{Data:{"ID":Id,"ProductID":productId}}).then((res) => {
				if(res.data.Code == 0) {
                    this.menuItem=res.data.Data;
				}
                this.dialogLoading=false;
			})

        },
         menuSelectionChange(val){
            this.SelectList=val;
        },
        menuDelete(){
            if(this.deleteType=='menuDelete'){
                let Id=this.SelectList[0].ID;
                this.$http.post('system:/Menu/Delete',{Data:{"ID":Id}}).then((res) => {
                    if(res.data.Code == 0) {
                        this.menuSeach();
                        this.$message.success(R.System_MenuManagement_placeholder_14);
                    }
                    this.delDisplay=false;
                })
            }
            if(this.deleteType=='jurisdictionDelete'){
                this.$http.post('system:/Menu/Permition/Delete',{Data:{"IDs":this.powerSelectChangeId}}).then((res) => {
                    if(res.data.Code == 0) {
                            this.jurisdictionList();
                    } 
                this.delDisplay=false;
                })
            }
            
            
        },
        menuSeach(){
            this.tableLoading = true;
            this.$http.post('system:/Menu/Tree',{
                Data:{
                    "ProductID":this.ProductId,
                    "QST":this.menuQst,
                    }
                }).then((res) => {
				if(res.data.Code == 0) {
					this.menuList = res.data.Data
				}
                this.tableLoading = false;
			})
        },
    },
    mounted(){
        const that = this
        that.$http.post('system:/Menu/GetProducts').then((res) => {
				if(res.data.Code == 0) {
					that.Productoptions = res.data.Data
                    that.ProductId=that.Productoptions[0].ProductID
				} else {
					
				}
			})
        that.menuSeach();
    }
}
</script>
<style lang='scss'>
    .menuManagement{
        .mask{
            position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
            .addone {
				height: 480px;
				width: 800px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				background-color: white;
                >.content{
                    padding:20px 0;
                }
                .content{
                    li{
                        text-align: right;
                        padding-right: 80px;
                    }
                }
                .footer{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
				.head {
					height: 35px;
					line-height: 35px;
					background-color: #fa905d;
					.title {
						padding-left: 10px;
						display: block;
						width: 90%;
						float: left;
						font-weight: 600;
						color: white;
					}
					.icon {
						float: right;
						margin-right: 10px;
						text-align: center;
						cursor: pointer;
						color: white;
						i {
							font-size: 16px;
						}
					}
				}
				.content {
					height: 342px;
					padding-left: 20px;
					box-sizing: border-box;
					li {
						margin-top: 15px;
						.el-input {
							height: 30px;
							width: 180px;
						}
                        span{
                            line-height: 30px;
                        }
					}
					.expand {
						height: 220px!important;
					}
				}
                .contentdel{
                    padding-top: 50px;
                    padding-left: 120px;
                    margin-bottom: 20px;
                    i{
                        color: deepskyblue;
                        margin-right: 10px;
                        font-size: 16px;
                    }
                }
			}
            .jurisdiction{
                    width: 1000px;
                    height: 530px;
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    border-radius: 5px;
                    background-size: 100%;
                    z-index: 999;
                    background: #fff;
                .header{
                        height: 37px;
                        background: #fa905d;
                        width: 100%;
                        border-radius: 5px 5px 0 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 15px;
                        span{
                            font-size: 14px;
                            color: #fff;
                            font-weight: bold;
                            line-height: 37px;
                        }
                        img{
                               width: 16px;
                                height: 16px;
                                cursor: pointer; 
                        }

                }
                .tableHead{
                    padding:10px;
                    .left{
                        float: left;
                    }
                    .right{
                        float: right;
                        .el-input{
                            width: 260px;
                        }
                        .el-button{
                            margin-left:10px;
                        }
                    }
                }
                .content{

                }
            }
        }
        .masks{
            width: 100wh;
            height: 100vh;
            position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            .permissionDialog{
                    width:400px;
                    height: 400px;
                    background: #fff;
                    position: relative;
                .head{
                        height: 37px;
                        background: #fa905d;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 15px;
                    .title{
                        font-size: 14px;
                        color: #fff;
                        font-weight: bold;
                        line-height: 37px;
                    }
                    .icon{
                         width: 16px;
                        height: 16px;
                        cursor: pointer; 
                    }
                }
            }
            .menuJurisdiction{
                .el-form-item{
                    text-align: right;
                    padding-top: 10px;
                }
                .el-input{
                    width: 220px;
                }
                .footer{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    padding:12px;
                    >div{
                        float: right;
                    }
                }
            }
        }
        .content{
            height: 100%;
        }
        >.header{
            padding:10px 0;
            .left{
                float: left;
            }
            .right{
                float: right;
                .rightName{
                    margin-left: 10px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
    .clear::after{
        content: '';
        clear: both;
        display: block;
    }
    .el-table td>.cell{
        white-space: nowrap;
}
    .el-tooltip__popper{
        max-width:20%;
    }
    .el-tooltip__popper,.el-tooltip__popper,.is-dark{
        background:#303133 !important;
        color: #fff !important;
        line-height: 24px;
    }
</style>