<template>
	<div class="Dropdowntree">
		<template>
			<el-input :title="input" placeholder="请选择" v-model="input">
				<i slot="suffix" class="el-input__icon el-icon-arrow-down" @click="aa" style="cursor: pointer;"></i>
			</el-input>
			<div class="box" v-if="hidden">
				<div class="expand">
					<div>
						 <span>{{title}}</span> 
							<el-tree
					  		:data="api"
					  		show-checkbox
					  		node-key="ID"
					  		:default-expanded-keys="defaultExpandKeys"
					 		:default-checked-keys="defaultExpandKeys"
					 		:props="defaultProps">
					</el-tree>
						
					</div>
				</div>
			</div>
		</template>

	</div>
</template>
<script>
//	import TreeRender from '../../view/tree_render.vue'
	//	import api from '@/resource/api'

	export default {
		props: [
			'title',
			'defaultExpandKeys',
			'api'
		],
		name: 'tree',
		watch: {
			filterText(val) {
				this.$refs.expandMenuList.filter(val);
			}
		},
		data() {
			return {
				defaultProps: {
					children: 'Children',
					label: 'Name'
				},
				input: '',
				hidden: false,
				checked: '',
				value6: '',
				filterText: '',
				maxexpandId: "7", //新增节点开始id
				non_maxexpandId: "7", //新增节点开始id(不更改)
				isLoadingTree: false, //是否加载节点树
				setTree: this.api, //节点树数据				
			}
		},
		created() {
		//	//			// console.log(api)
			this.initExpand()
			//				// console.log('tree')
		},
		methods: {
			loadNode(node, resolve) {
				if(node.level === 0) {
					return resolve([{
						name: 'region'
					}]);
				}
				if(node.level > 1) return resolve([]);

				setTimeout(() => {
					const data = this.setTree;
					resolve(data);
				}, 500);
			},
			filterNode(value, data) {
				if(!value) return true;
				//			        // console.log(data,value)
				return data.name.indexOf(value) !== -1;
			},
			initExpand() {
				this.setTree.map((a) => {
					this.defaultExpandKeys.push(a.ID)
				});
				this.isLoadingTree = true;
			},
			handleNodeClick(d, n, s) { //点击节点
				// // console.log(d,n)
				d.isEdit = false; //放弃编辑状态
			},

			handleAddTop() {
				this.setTree.push({
					ID: ++this.maxexpandId,
					name: '新增节点',
					pid: '',
					isEdit: true,
					Children: []
				})
			},
			handleAdd(s, d, n) { //增加节点
				if(n.level >= 6) {
					this.$message.error("最多只支持五级！")
					return false;
				}
//				// console.log(d.Children)
				//添加数据
				d.Children.push({
					ID: ++this.maxexpandId,
					name: '新增节点',
					pid: d.ID,
					isEdit: true,
					Children: []
				}); //展开节点
				if(!n.expanded) {
					n.expanded = true;
				}
			},
			handleEdit(s, d, n) { //编辑节点
//				// console.log(s, d, n)
			},

			aa() {

				this.hidden = !this.hidden
//				// console.log(this.hidden)
			},
			handleDelete(s, d, n) { //删除节点
//				// console.log(d)
				let that = this;
				//有子级不删除
				if(d.Children && d.Children.length !== 0) {
					this.$message.error("此节点有子级，不可删除！")
					return false;
				} else {
					//新增节点直接删除，否则要询问是否删除
					let delNode = () => {
						let list = n.parent.data.Children || n.parent.data; //节点同级数据
						//          // console.log(list)
						let ID = d.ID;
						//          // console.log(id)
						//          // console.log(this.setTree.id)
						//				  _index = Children.findIndex(d => d.id === list.id)
						//							_index = 99999; //要删除的index

						//							;
						if(!n.parent.data.Children) { //删除顶级节点，无Children
							list = n.parent.data
						}
						let _index;
						list.map((c, i) => {
							if(d.ID == c.ID) {
								_index = i;
							}
						})
						//							alert(_index)
						//							
						list.splice(_index, 1)
						this.$message.success("删除成功！")

					}
					let isDel = () => {
						that.$confirm("是否删除此节点？", "提示", {
							confirmButtonText: "确认",
							cancelButtonText: "取消",
							type: "warning"
						}).then(() => {
							delNode()
						}).catch(() => {
							return false;
						})
					}
					//判断是否新增
					d.ID > this.non_maxexpandId ? delNode() : isDel()

				}
			},
		}

		// mounted() {
		//     // console.log(this)
		// }

	}
</script>
<style lang="scss">
	.Dropdowntree {
		position: relative;
		display: inline-block;
		.el-tree-node__content {
		box-sizing: content-box;
		min-width: 150px;
	}
	
	.abox {
		display: flex;
		>div {
			width: 50px;
		}
	}
	
	.box {
		min-width: 300px;
		border: 1px solid #dcdfe6;
		padding: 5px;
		box-sizing: border-box;
		position: absolute;
		left: 0;
		top: 100%;
		margin-top: 5px;
		z-index: 9;
		background-color: #fff;
	}
	
	.input {
		width: 198px;
		height: 33px;
	}
	
	.expand {
		width: 100%;
		height: 200px;
		// height: 300px;
		// margin-top: 50px;
		/*overflow: hidden;*/
	}
	
	.yy {
		width: 100%;
		text-align: left;
		padding: 10px 0;
		background: #f2f2f2;
		margin-top: 10px;
	}
	
	.myinput .el-input {
		width: 200px;
	}
	
	.myinput .el-input__inner {
		margin-top: 15px;
		height: 30px;
		line-height: 30px;
		margin-bottom: 15px;
	}
	/*	/*
    .expand>div {
        height: 85%;
        padding-top: 20px;
        width: 50%;
        margin: 20px auto;
        max-width: 400px;
        overflow-y: auto;
    }
    */
	
	 ::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
		border-radius: 5px;
	}
	
	 ::-webkit-scrollbar-thumb {
		background-color: rgba(50, 65, 87, 0.5);
		outline: 1px solid slategrey;
		border-radius: 5px;
	}
	
	 ::-webkit-scrollbar {
		width: 10px;
	}
	
	.expand-tree {
		border: none;
		margin-top: 10px;
	}
	
	.expand .el-tree {
		min-width: 200px;
		height: 158px;
		overflow: auto;
	}
	
	.expand-tree .el-tree-node.is-current,
	.expand-tree .el-tree-node:hover {
		overflow: hidden;
	}
	
	.expand-tree .is-current>.el-tree-node__content .tree-label {
		font-weight: 600;
	}
	
	.el-select-dropdown__item {
		height: auto;
	}
	}
	
	
</style>