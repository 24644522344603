// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import components from "./components/components.js";
import "../public/static/fonts/font_09citdl1zhfe/iconfont.css"
import "../public/static/fonts/font_i4kh8ufdofi/iconfont.css"
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Rem from "./rem.js"
import axios from './httpConfig/http'
import store from 'storejs'
import common from './util/common.js';
import VueDND from 'awe-dnd'
import assist from './util/assist'
import ajax from '../src/util/ajax.js'
import service from './httpConfig/api'
Vue.prototype.$assist = assist
Vue.prototype.CustomizedSetting = window.setting;
import i18n from '../public/static/locales/index.js';
import {PermissionUtil} from './util/serviceUtil.js';
Vue.prototype.$PermissionUtil = PermissionUtil;
Vue.use(ViewUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
//import enlocale from 'element-ui/lib/locale/lang/en'
//import zhlocale from 'element-ui/lib/locale/lang/zh-cn'
//初始化工具函数
if (common.init) {
  var properties = Object.keys(common.init);
  for (var p of properties) {
    common.init[p]();
  }
}

//获取当前密码策略
Vue.prototype.getPassword = function () {
  var Data;
  var systemInternet = service.getServiceRoot('system');
  var token = store.get('token')
  var url = systemInternet + '/SecuritySetting/PasswordPolicy/Get';
  var data = {
    'data': {
      'Token': token
    }
  }
  ajax.ajax.post(url, data, function (res) {
    Data = JSON.parse(res).Data
  }, false);
  console.log(Data)
  return Data;
}
Vue.prototype.getSelflag = function (name, R) {
  var list = store.get('controlLanguage');
  if (list) {
    list = JSON.parse(store.get('controlLanguage'))
    if (list[name]) {
      return list[name];
    }
  }
  return false

}
Vue.prototype.setSelflag = function (name, R) {
  var list = store.get('controlLanguage') ? JSON.parse(store.get('controlLanguage')) : {};
  list[name] = R;
  store.set('controlLanguage', JSON.stringify(list))

}
// 获取权限
Vue.prototype.systemShow = function (name, Key) {
  // 获取保存为草稿的设置
  var assetDicSettings = JSON.parse(store.get(name));
  if (assetDicSettings != null) {
    for (let index = 0; index < assetDicSettings.length; index++) {
      if (assetDicSettings[index].Key == Key) {
        this.isAutoGenerateCode = assetDicSettings[index].Value;
        if (this.isAutoGenerateCode === 'True' || this.isAutoGenerateCode == true) {
          return true
        } else {
          return false
        }
      }
    }
  }
}

Vue.prototype.getSelfLanguage = function (type, name) {
  var lag = 'CN'
  if (store.get('lag') == 'zh') {
    lag = 'CN'
  } else {
    lag = 'EN'
  }
  this.$i18n.locale = store.get('lag') ? store.get('lag') : 'zh';
  var url = '/static/locales/' + type + '/' + lag + '/' + name + '.js?time=' + Date.parse(new Date());
  var Data;
  ajax.ajax.get(url, {}, function (res) {
    let Start = res.indexOf('[');
    let End = res.lastIndexOf(']') + 1;
    let data = res.substring(Start, End)
    var mysource = eval('(' + data + ')');
    var R = {};
    for (var i = 0; i < mysource.length; i++) {
      var resource = mysource[i];
      R[resource.Key] = resource.Value;
    }
    Data = R;
  }, false);
  return Data;
}


//获取当前页面的分页数
Vue.prototype.GetPageSize = function (Key) {
  var assetDicSettings = JSON.parse(store.get('UserSetting'));
  var flag = false;
  if (assetDicSettings != null) {
    for (let index = 0; index < assetDicSettings.Assemble.length; index++) {
      if (assetDicSettings.Assemble[index].ConfigCode == Key) {
        flag = true;
        return Number(assetDicSettings.Assemble[index].ConfigValue)
      }
    }
    if (!flag) {
      return assetDicSettings.DefaultListNumber
    }
  }
}
//设置当前页面的分页数
Vue.prototype.SetPageSize = function (count, Key) {
  var assetDicSettings = JSON.parse(store.get('UserSetting'));
  var flag = false;
  if (assetDicSettings != null) {
    for (let index = 0; index < assetDicSettings.Assemble.length; index++) {
      if (assetDicSettings.Assemble[index].ConfigCode == Key) {
        assetDicSettings.Assemble[index].ConfigValue = count;
        flag = true
      }
    }
    if (!flag) {
      assetDicSettings.Assemble.push({
        "ConfigCode": Key,
        "ConfigType": 1,
        "ConfigValue": count,
        "ID": 1
      })
    }
    store.set('UserSetting', JSON.stringify(assetDicSettings))
  }
}
//获取页码
Vue.prototype.GetPageRange = function (Key) {
  var count = [10, 20, 50, 100]
}
Vue.use(VueDND)


Vue.prototype.$Store = store;
Vue.prototype.$http = axios

// //定义全局变量
Vue.use(Rem)

import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Object.keys(components).forEach((key) => {
  Vue.component(key, components[key])
});
Vue.config.productionTip = false
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
})
