<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
	data() {
		return{
		}
	},
	methods:{
	},
	mounted(){
		
	}
}

</script>

<style>
 *{
    margin: 0;
    padding: 0;
  }
  html{
  	height: 100%;
  	width: 100%;
  }
  #app{
    width: 100%;
    height: 100%;
  }
</style>
<style src="@/style/element-custom.css"></style>