<template>
	<div id="EnableApproval" v-loading="loading" v-if='IsPageDome'>
		<div class="left">
			<div class="titleall" v-if="title">{{title}}</div>
			<el-tree v-if="loadtree" :expand-on-click-node="false" node-key="id" :default-expanded-keys='expandkey' class="commontree" :load="loadNode" lazy ref="tree" highlight-current :props='defaultProps' @node-click="clicktree">
				<span class="custom-tree-node" slot-scope="{ node, data }">
		        	<span  :class="expand==data.id?'mytreelistitem':''" :title=" node.label" >{{ node.label}}<span class="islocked" v-if="data.IsEnabled==false">&ensp;&ensp;({{R.EnableApproval_System_15}})</span></span>
				</span>
				</span>
			</el-tree>
		</div>
		<div class="right">
			<div class="rightConetnt">
				<div v-for="(item,index) in ApprovalData" :key="index">
					<div class="productTitle">
						<img :src="item.Icon" alt="" srcset="">
						<span>{{item.ProductName}}</span>
					</div>
					<div class="productContent">
						<ul>
							<li>{{R.EnableApproval_title_1}}</li>
							<li>{{R.EnableApproval_title_2}}</li>
							<li>{{R.EnableApproval_title_3}}</li>
						</ul>
						<ul v-for="(Billitem,index1) in item.Details" :key="index1">
							<li>{{Billitem.BillName}}</li>
							<li>
								<el-checkbox v-model="Billitem.IsEnabled" @change="IsEnabledChange($event,index,index1)"></el-checkbox>
							</li>
							<li>
								<el-radio-group v-model="Billitem.FlowType" @change="FlowTypeChange($event,index,index1)">
									<el-radio :label="1">{{R.EnableApproval_standard_tooltip_Title}}</el-radio>
									<!-- <el-tooltip class="item" effect="dark" :content="R.EnableApproval_standard_tooltip" placement="bottom"> -->
									<span class="el-icon-question" :title="R.EnableApproval_standard_tooltip"></span>
									<!-- </el-tooltip> -->
									<el-radio :label="2">{{R.EnableApproval_custom_tooltip_Title}}</el-radio>
									<!-- <el-tooltip class="item" effect="dark" :content="R.EnableApproval_custom_tooltip" placement="bottom"> -->
									<span class="el-icon-question" :title="R.EnableApproval_custom_tooltip"></span>
									<!-- </el-tooltip> -->
								</el-radio-group>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="rightBottom">
				<el-button @click="SaveShow" class="orangebd " v-if="getpermission('SaveConsumableSetting')">{{R.Common_Button_Save}}</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { constants } from 'zlib';
	var R = {};
	export default {
		name: 'EnableApproval',
		data() {
			return {
				IsPageDome: true,
				CorpId: null,
				ApprovalData: [],
				loadtree: true,
				expandkey: [],
				expand: null,
				R: R,
				defaultvalue: '',
				value: false,
				permissionCode: [],
				QSE: '',
				ParentID: null,
				Code: '',
				Type: '',
				api: [],
				IsEnabled: true,
				loading: false,
				defaultProps: {
					children: 'children',
					label: 'label',
					isLeaf: 'is_leaf'
				},
				title: R.EnableApproval_System_title,
			};
		},
		beforeCreate() {
			R = this.getSelfLanguage('System', 'BillApproval_EnableApproval')
		},
		beforeDestroy() {
			this.IsPageDome = false;
		},
		destroyed() {
			this.$destroy()
			this.$el.remove()
		},
		methods: {
			IsEnabledChange(event, index, index1) {
				if(event) {
					this.ApprovalData[index].Details[index1].FlowType = 1
				} else {
					this.ApprovalData[index].Details[index1].FlowType = 0
				}
			},
			FlowTypeChange(event, index, index1) {
				this.ApprovalData[index].Details[index1].IsEnabled = true
			},
			// 数据保存
			SaveShow() {
				this.loading = true
				this.$http.post('system:/BillApproval/ApprovalFlowSetting/Save', {
					Data: {
						"CorpId": this.CorpId,
						"Settings": this.ApprovalData
					}
				}).then(res => {
					if(res.data.Code == 0) {
						this.$message.success(res.data.Message)
					} else {
						
					}
					this.loading = false
				})
			},
			// 列表改变
			listLoad(ID) {
				this.loading = true
				this.CorpId = ID;
				this.$http.post('system:/BillApproval/ApprovalFlowSetting/GetByCorpID', {
					Data: {
						"CorpId": ID
					}
				}).then(res => {
					if(res.data.Code == 0) {
						this.ApprovalData = res.data.Data
					} else {
						
					}
					this.loading = false
				})
			},
			//判断权限是否存在
			getpermission(code) {
				if(this.permissionCode && this.permissionCode.length > 0) {
					for(var i = 0; i < this.permissionCode.length; i++) {

						if(code == this.permissionCode[i].FunctionCode) {
							return true
						}
					}
				} else {
					return false
				}

			},
			//点击左侧树结构
			clicktree(node, data) {
				// console.log(node)
				this.expand = node.id
				this.QSE = data.data.QSE;
				this.$http.post('/Organization/GetById', {
					data: {
						ID: node.id,
					}
				}).then(res => {
					if(res.data.Code == '0') {
						this.defaultvalue = res.data.Data.Name == null ? '' : res.data.Data.Name
						this.listLoad(res.data.Data.ID)
					} else {
						
					}

				})
			},
			//左侧树懒加载
			loadNode(node, resolve) {
				if(node.level === 0) { //第一级
					this.getOrgByParentID(null, resolve);
				} else {
					this.getOrgByParentID(node.data.id, resolve);
				}
			},
			// 获取指定的父级公司/部门的ID获取其下级公司/部门
			getOrgByParentID(parentID, resolve) {
				this.$http.post('/Organization/GetTree', {
					Data: {
						'onlyloadEnableOrg': false,
						LoadOrganizationType: 2,
						OnlyLoadPrivilegeOrg: true, //正式应该为true
						Delay: true,
						ParentId: parentID
					}
				}).then((rsp) => {
					if(rsp.data.Code === 0) { //成功
						//
						let items = rsp.data.Data;
						if(parentID == null) {
							var arr = [];
							arr.push(rsp.data.Data[0].id)
							this.expandkey = arr
							this.listLoad(this.expandkey[0])
							this.expand = rsp.data.Data[0].id
						}
						resolve(items);
					} else {
						this.$message.error(rsp.data.Message)
					}
				}).catch((error) => {});
			},
		},
		mounted() {
			//获取页面权限
			this.$PermissionUtil.getPermission('system/BillApproval/EnableApproval').then((res)=>{
				this.permissionCode = res;
			});

		}
	};
</script>

<style lang='scss'>
	#EnableApproval {
		.islocked {
			color: #FF5402;
		}
		.el-button+.el-button {
			margin-left: 0px;
		}
		// .el-form-item__content{
		// 	line-height: 30px;
		// }
		.el-button {
			margin-right: 5px;
		}
		/*height: 100%;
		width: 100%;*/
		background-color: white;
		/*padding-top: 20px;*/
		/*padding-left: 20px;*/
		.mytreelistitem {
			background: #FF5402;
			color: #ffffff;
		}
		.titleall {
			height: 40px;
			line-height: 40px;
			background-color: #EFF1F1;
			text-align: center;
			margin-bottom: 10px;
			font-size: 14px;
			border-right: 1px solid #eee;
			font-family: FZLTHK--GBK1-0;
			font-weight: bold;
			color: rgba(123, 128, 130, 1);
		}
		.left {
			width: 300px;
			float: left;
			background-color: white;
			height: 100%;
			padding: 0;
			position: relative;
			overflow: auto;
		}
		.right {
			padding: 15px;
			position: absolute;
			left: 315px;
			right: 0px;
			top: 0;
			bottom: 0;
			background-color: white;
			height: 100%;
			padding: 0px;
			>.rightConetnt {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 75px;
				overflow: auto;
				>div {
					margin-bottom: 20px;
					>.productTitle {
						display: flex;
						align-items: center;
						line-height: 30px;
						>span {
							font-size: 14px;
							margin-left: 10px;
							font-weight: bold;
							color: #333333;
						}
					}
					>.productContent {
						padding-left: 38px;
						>ul:nth-child(1) {
							border-top: 1px solid #d7d7d7;
							>li {
								font-size: 14px;
								font-weight: bold;
							}
						}
						>ul {
							display: flex;
							align-items: center;
							border-bottom: 1px solid #d7d7d7;
							>li {
								text-align: center;
								line-height: 30px;
								font-size: 14px;
								color: #333333;
								.el-radio__label {
									font-size: 14px;
								}
								.el-radio-group {
									display: flex;
									align-items: center;
									justify-content: center;
									.el-icon-question {
										font-size: 16px;
										color: #009966;
										margin-left: 5px;
										margin-right: 10px;
										cursor: pointer;
									}
									.bottom {
										clear: both;
										text-align: center;
									}
								}
							}
							>li:nth-child(1) {
								width: 160px;
							}
							>li:nth-child(2) {
								width: 186px;
							}
							>li:nth-child(3) {
								width: 303px;
							}
						}
					}
				}
			}
			>.rightBottom {
				position: absolute;
				line-height: 75px;
				bottom: 0;
				display: flex;
				flex-direction: row-reverse;
				width: 100%;
			}
		}
	}
</style>