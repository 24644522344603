import axios from "../httpConfig/http.js";

class PermissionUtil {
    static async getPermission(url) {
        if (url == '' || url == null || url == undefined) {
            return;
        }
        const { data } = await axios.post('system:/Menu/Permition/GetByCode', {
            Data: {
                Code: url
            }
        });
        return data.Data;
    }
}

export { PermissionUtil };